//initial redux
import { put, call, takeLatest, fork, all, take, cancel, select } from "redux-saga/effects";
import securityservice from "services/security/security";
import { types as setuptypes } from "boot/setupRedux";
import * as router from "react-router-redux";
import { persist, string } from "utils/index";
import { ReduxAction, ScheduleGroupDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";
import schedulegroupservice from "services/schedulegroup";
import { types as logintypes } from "redux/loginRedux";

interface State {
	groups: ScheduleGroupDto[];
	/**visible groups are groups one has the role to access but might not have been added to UserGroup table */
	visibleGroups: ScheduleGroupDto[];
	currentGroup: ScheduleGroupDto;
	currentGroupBookingDates: Date[];

	isBusy: boolean;
	errors: any[];
}

const initialState: State = {
	groups: [],
	visibleGroups: [],
	currentGroup: null,
	currentGroupBookingDates: [],

	isBusy: false,
	errors: []
};

const persistConf = {
	whitelist: ["groups", "currentGroup", "currentGroupBookingDates"],
	expireInNumHours: 24 * 7
};

/////////
//types
export const types = {
	GET_LIST: "schedulegroup/GET_LIST",
	ON_GET_LIST: "schedulegroup/ON_GET_LIST",

	GET_VISIBLE_GROUPS: "schedulegroup/GET_VISIBLE_GROUPS",
	ON_GET_VISIBLE_GROUPS: "schedulegroup/ON_GET_VISIBLE_GROUPS",

	GET_BOOKING_DATES: "schedulegroup/GET_BOOKING_DATES",
	SET_BOOKING_DATES: "schedulegroup/SET_BOOKING_DATES",

	SET_CURRENT_GROUP: "schedulegroup/SET_CURRENT_GROUP",

	ON_ERROR: "schedulegroup/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction): State {
		switch (action.type) {
			case types.GET_LIST:
			case types.GET_BOOKING_DATES:
			case types.GET_VISIBLE_GROUPS:
				return { ...state, isBusy: true };

			case types.ON_GET_LIST:
				return { ...state, isBusy: false, groups: action.payload.groups };

			case types.ON_GET_VISIBLE_GROUPS:
				return { ...state, isBusy: false, visibleGroups: action.payload.groups };

			case types.SET_CURRENT_GROUP:
				return { ...state, currentGroup: action.payload.group };

			case types.SET_BOOKING_DATES:
				return { ...state, isBusy: false, currentGroupBookingDates: action.payload.dates };

			case types.ON_ERROR:
				return {
					...state,
					errors: state.errors.concat(action.payload).slice(-10),
					isBusy: false
				};

			default:
				return state;
		}
	}
};
export const reducer = persist("schedulegroup", reducers.reducer, persistConf);

//call getlist on login or page visit
function* getGroups() {
	while (true) {
		let { isLoggedin, roles } = yield select<ReduxState>(x => ({
			isLoggedIn: x.login.isLoggedIn,
			roles: x.login.currentDetails ? x.login.currentDetails.role : []
		}));
		if (!isLoggedin) {
			//wait till logged in
			yield take(logintypes.ON_LOGIN);
			roles = yield select<ReduxState>(x => (x.login.currentDetails ? x.login.currentDetails.role : []));
		}

		if (roles.length && (roles.includes("User") || roles.includes("System Administrator") || roles.includes("IBMS Administrator"))) {
			yield put(actions.getList());
			yield take([logintypes.ON_LOGOUT, types.ON_ERROR]);
		}
	}
}

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([this.getGroups(), this.getBookingDates(), this.getVisibleGroups(), getGroups()]);
	},

	*getVisibleGroups() {
		yield takeLatest<ReduxAction>(types.GET_VISIBLE_GROUPS, function*({ payload }) {
			const result = yield call(schedulegroupservice.GetVisibleGroups);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.onGetVisibleGroups(result.data));
			}
		});
	},

	*getBookingDates() {
		yield takeLatest<ReduxAction>([types.SET_CURRENT_GROUP, types.GET_BOOKING_DATES], function*({ payload }) {
			const groupid = payload.group
				? payload.group.scheduleGroupId
				: yield select<ReduxState>(x => x.schedulegroup.currentGroup && x.schedulegroup.currentGroup.scheduleGroupId);
			if (groupid) {
				const result = yield call(schedulegroupservice.GetBookingDatesForGroup, groupid);
				if (result.error) {
					console.error(result.error);
					yield put(actions.onError(result.error));
				} else {
					yield put(actions.setBookingDates(result.data));
				}
			}
		});
	},

	*getGroups() {
		yield takeLatest<ReduxAction>(types.GET_LIST, function*({ payload }) {
			const result = yield call(schedulegroupservice.GetList);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.onGetList(result.data));
				const existinggroup = yield select<ReduxState>(x => x.schedulegroup.currentGroup);
				if (!existinggroup && result.data.length) {
					yield put(actions.setCurrentGroup(result.data[0]));
				} else {
					//trigger any SET_CURRENT_GROUP listeneres
					yield put(actions.setCurrentGroup(existinggroup));
				}
			}
		});
	}
};

////////
//actions
export const actions = {
	getList() {
		return {
			type: types.GET_LIST,
			payload: {}
		};
	},

	onGetList(groups: ScheduleGroupDto[]) {
		return {
			type: types.ON_GET_LIST,
			payload: { groups }
		};
	},

	getVisibleGroups() {
		return {
			type: types.GET_VISIBLE_GROUPS,
			payload: {}
		};
	},

	onGetVisibleGroups(groups: ScheduleGroupDto[]) {
		return {
			type: types.ON_GET_VISIBLE_GROUPS,
			payload: { groups }
		};
	},

	setCurrentGroup(group: ScheduleGroupDto) {
		return {
			type: types.SET_CURRENT_GROUP,
			payload: { group }
		};
	},

	setBookingDates(dates: Date[]) {
		return {
			type: types.SET_BOOKING_DATES,
			payload: { dates }
		};
	},

	getBookingDates() {
		return {
			type: types.GET_BOOKING_DATES,
			payload: {}
		};
	},

	onError(data) {
		return {
			type: types.ON_ERROR,
			payload: data
		};
	}
};
