export default class {
	/**
	 * Round to 2 decimals and comma seperate 1000's
	 * Remove trailing zeros from decimals
	 * @param {number} x
	 */
	static formatNumber(x: number, decimals = 2) {
		const parts = x
			.toFixed(decimals)
			.toString()
			.split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return parts.join(".").replace(/0*$|\.0*$|\.$/g, "");
	}

	//show 2 decimal places always
	static formatNumberCurrency(x: number) {
		return x
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	static formatInt(x: number) {
		return x
			.toFixed(0)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	static getRandomColor() {
		var letters = "0123456789ABCDEF";
		var color = "#";
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	static minDigits(number: number, amount: number): string {
		const str = number.toString();
		if (str.length < amount) {
			return new Array(amount).join("0").concat(...str.split("").slice(-amount));
		} else {
			return str;
		}
	}

	static isNumber(val: any) {
		return !isNaN(parseFloat(val));
	}

	static suffixNumber(num: number) {
		let str = num.toString();
		const lastDigit = str[str.length - 1];
		switch (lastDigit) {
			case "1":
				return str + "st";
			case "2":
				return str + "nd";
			case "3":
				return str + "rd";
			case "0":
			case "4":
			case "5":
			case "6":
			case "7":
			case "8":
			case "9":
				return str + "th";
		}
	}
}
