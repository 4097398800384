import * as React from "react";
import * as PropTypes from "prop-types";
import * as CSSModules from "react-css-modules";
import * as styles from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AngleDown, AngleUp } from "@fortawesome/fontawesome-free";
import { shouldUpdate, MergeStyles, dom, string, number, deepClone } from "utils/index";
import autoBind from "../../libs/react-autobind/index";
import ShellPortal from "components/ShellPortal/ShellPortal";
import { CommonComponentProps } from "redi-types";
import { render } from "react-dom";
import { isThursdayWithOptions } from "date-fns/fp";

@CSSModules(styles, { allowMultiple: true })
class DefaultTemplate extends React.PureComponent<{ row: any } & Partial<TypeaheadProps<any>>, {}> {
	render() {
		return <div styleName="_template-root">{this.props.titleTransform(this.props.row)}</div>;
	}
}

type TemplateProps<T> = Partial<TypeaheadProps<T>> & { row: T | string; query: string; isLast?: boolean };
type LastRowProps<T> = Partial<TypeaheadProps<T>> & {
	/** Call `props.refresh()` inside template to refresh list */
	refresh?: () => void;
};

export interface TypeaheadProps<T> extends CommonComponentProps {
	/** Optional component that will render as the last row in the popup */
	lastRowTemplate?: new (props?: LastRowProps<T>) => React.Component<LastRowProps<T>>;
	/**optional template for each row in popup. `props.row` is the given object */
	rowTemplate?: new (props: TemplateProps<T>) => React.Component<TemplateProps<T>>;
	/**Call the service with this. Called whenever the user types or focuses the input */
	getData: (val: string) => Promise<{ data?: T[]; error?: any }>;
	/**Called when the user selects a row */
	onChange: (s: T | string | T[], ims: boolean) => void;
	/**Show selected value in input field */
	value?: T;
	/**zindex of popup */
	zIndex?: number;
	/**extract string from object value */
	titleTransform?: (s: T) => string;
	/**Allow user to enter typed value even if its not in dropdown list */
	allowBlankSelections?: boolean;
	disabled?: boolean;

	placeHolder?: string;

	popupClass?: string;
	rowClass?: string;
	multiselect?: boolean;

	/** spread any other props to the templates */
	[x: string]: any;
}

/**

Use:

							<Typeahead
								onChange={query => {
									return new Promise((resolve, reject) => {
										... get/filter data
										resolve({ data: data });
									});
								}}
								onValue={val => this.setState({ currentArea: val })}
								titleTransform={x => x && x.name}
								value={this.state.currentArea}
							/>

*/

@MergeStyles(styles)
export default class Typeahead<T> extends React.Component<TypeaheadProps<T>, State<T>> {
	static defaultProps = {
		titleTransform: x => x,
		rowTemplate: DefaultTemplate,
		zIndex: 5,

		popupClass: "",
		rowClass: ""
	};
	static propTypes = {
		classes: PropTypes.object //style overrides
	};
	popupRef: React.RefObject<HTMLDivElement>;
	inputRef: HTMLInputElement;
	template: new () => React.Component<{ row: T | string; query: string; isLast: boolean }, any>;
	_unmounted: boolean;
	rootRef: React.RefObject<HTMLDivElement>;
	ignoreNextBlur: boolean;

	constructor(props) {
		super(props);
		autoBind(this);
		this.state = {
			currentData: [],
			focusIndex: 0,
			open: false,
			popupStyle: null
		};

		this.popupRef = React.createRef<HTMLDivElement>();
		this.rootRef = React.createRef<HTMLDivElement>();

		/**
		 * A component that extends the given template to underline text that matches the query.
		 * add attribute 'nounderline' to any element to not underline text within it.
		 */
		this.template = class Underliner extends (this.props.rowTemplate as new () => React.Component<
			{ row: T; query: string; isLast: boolean },
			any
		>) {
			id: number;
			render() {
				//first get the result of the template's render fnction
				const elemss = super.render();

				const arr = React.Children.toArray(elemss) as React.ReactElement<any>[];
				const query = this.props.query.toLowerCase();

				//iterate thru all elements returned and look for children that is plain text.
				//find any charactors that match the query and replace them with a span that has an underline on it
				//add attribute 'nounderline' to any element to not underline text within it
				const iterate = (elems: React.ReactElement<any>[] | string[]) => {
					this.id = 0;
					for (let index = 0; index < elems.length; index++) {
						const el = elems[index];
						if (typeof el === "string") {
							let result = [];
							let lowerd = el.toLowerCase();
							let copy = el;
							for (let index = lowerd.indexOf(query); index > -1; index = lowerd.indexOf(query)) {
								if (index > 0) {
									result.push(copy.slice(0, index));
									copy = copy.substr(index);
									lowerd = lowerd.substr(index);
								}
								const matchedString = copy.slice(0, query.length);
								copy = copy.substr(query.length);
								lowerd = lowerd.substr(query.length);
								result.push(
									React.createElement("span", {
										children: matchedString,
										className: "typeahead-underlined-text",
										key: this.id++
									})
								);
							}
							if (copy.length > 0) {
								result.push(copy);
							}
							elems[index] = React.createElement("span", { children: result, key: index });
						} else if (el.props && el.props.children && !("nounderline" in el.props)) {
							const subarr = React.Children.toArray(el.props.children) as React.ReactElement<any>[];
							elems[index] = React.cloneElement(el, { children: iterate(subarr) });
						}
					}
					return elems;
				};

				return this.props.query.length > 0 ? iterate(arr) : elemss;
			}
		};
	}

	onInputRef(ref: HTMLInputElement) {
		this.inputRef = ref;
		if (this.inputRef) {
			if (this.props.value) {
				this.inputRef.value = this.props.titleTransform(this.props.value);
			} 
		}
	}

	onChange(e?: React.ChangeEvent<HTMLInputElement>) {
		this.props.getData(this.inputRef.value).then(data => {
			if (data.data && this.inputRef) {
				let arr: (string | T)[] = data.data.slice();
				if (this.props.allowBlankSelections) {
					if (this.inputRef.value) {
						arr.unshift(this.inputRef.value);
					}
				}
				this.open(arr);
			}
		});
		if (this.props.allowBlankSelections) {
			if (!this.state.currentData.length || this.state.currentData[0] !== this.inputRef.value) {
				let arr = this.state.currentData.length ? this.state.currentData.slice() : new Array(1);
				if (this.inputRef.value) {
					arr[0] = this.inputRef.value;
				}
				this.open(arr);
			}
		}
	}

	multiselectCount: number = 0;
	multiselectList: any[] = [];

	isInMultiselect(row: T | string) {
		let foundIndex = this.multiselectList.findIndex(x => x == row);
		return foundIndex != -1;
	}

	addToMultiselect(row: T | string) {
		this.cleanScheduleFromMultiselect(row);
		if(!this.isInMultiselect(row)) {
			this.multiselectList.push(deepClone(row));
		}
	}

	removeScheduleFromMultiselect(id: string) {
		let index = this.multiselectList.findIndex(x => x.scheduleId == id);
		if(index != -1) {
			this.multiselectCount -= 1;
			this.multiselectList.splice(index, 1);
		
			for(var jj = 0, jlen = this.state.currentData.length; jj < jlen; jj++) {
				if((this.state.currentData[jj] as any).scheduleId == id) {
					(this.state.currentData[jj] as any).isSelected = false;
					break;
				}
			}
		}
	}

	removeFromMultiselect(row: T | string) {
		let index = this.multiselectList.findIndex(x => x == row || x.scheduleId == (row as any).scheduleId);
		if(index != -1) {
			this.multiselectList.splice(index, 1);
		}
	}

	cleanScheduleFromMultiselect(row: T | string) {
		if(row["schedules"] != undefined) {
			for(var ii = 0, ilen = row["schedules"].length; ii < ilen; ii++) {
				this.removeScheduleFromMultiselect(row["schedules"][ii].scheduleId);
			}
		}
	}

	confirmMultiselect() {
		// Clean up the list in case someone decided to be smart and put both a top-level and it's sublevel schedules in
		for(let ii = this.multiselectList.length; ii > 0; ii--) {
			if(this.multiselectList[ii - 1] && this.multiselectList[ii - 1]["schedules"] != undefined) {
				this.cleanScheduleFromMultiselect(this.multiselectList[ii - 1]);
			}
		}

		this.props.onChange(deepClone(this.multiselectList), true);
		if(this.props.value) {
			this.inputRef.value = this.props.titleTransform(this.props.value);
		} 
		this.close();
	}

	clickRow(row: T | string) {
		if(this.props.multiselect == true) {
			(row as any).isSelected = !(row as any).isSelected;
			if((row as any).isSelected) {
				this.multiselectCount += 1;
				this.addToMultiselect(row);
			} else {
				this.multiselectCount -= 1;
				this.removeFromMultiselect(row);
			}
			this.forceUpdate();
		} else {
			this.close();
			this.props.onChange(row, false);
			if (this.props.value) {
				this.inputRef.value = this.props.titleTransform(this.props.value);
			}
		}
	}

	handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
		if (this.state.currentData.length) {
			if (event.key === "Enter") {
				//32 = space
				this.clickRow(this.state.currentData[this.state.focusIndex]);
			} else {
				switch (event.key) {
					case "ArrowDown":
						{
							let newfocus = this.state.focusIndex + 1;
							if (newfocus >= this.state.currentData.length) {
								newfocus = 0;
							}
							this.setState({ focusIndex: newfocus });
						}
						break;
					case "ArrowUp":
						{
							let newfocus = this.state.focusIndex - 1;
							if (newfocus < 0) {
								newfocus = this.state.currentData.length - 1;
							}
							this.setState({ focusIndex: newfocus });
						}
						break;
				}
			}
		}
	}

	open(data: any[]) {
		let style: React.StyleHTMLAttributes<HTMLDivElement> = { height: "" };

		if (this.inputRef && data.length) {
			const pos = this.inputRef.getBoundingClientRect();

			const xLimit = pos.left + this.popupRef.current.clientWidth;
			const yLimit = pos.bottom + this.popupRef.current.clientHeight;
			style.top = pos.bottom;
			style.left = pos.left;
			style.zIndex = this.props.zIndex + 1;

			if (yLimit > window.innerHeight) {
				style.top -= yLimit - window.innerHeight;
			}
			if (xLimit > window.innerWidth) {
				style.left -= xLimit - window.innerWidth;
			}
			style.width = Math.min(this.rootRef.current.clientWidth, window.innerWidth);
		}

		this.setState({ currentData: data, open: !!data.length, popupStyle: style }, () => {
			if (this.popupRef.current && this.inputRef && this.state.currentData.length) {
				const pos = this.popupRef.current.getBoundingClientRect();

				if (pos.bottom > window.innerHeight) {
					style = { ...this.state.popupStyle };
					style.height = window.innerHeight - pos.top + "px";
					this.setState({ popupStyle: style });
				}
			}
		});
	}

	close() {
		this.multiselectList = [];
		for(let ii = 0, ilen = this.state.currentData.length; ii < ilen; ii++) {
			(this.state.currentData[ii] as any).isSelected = undefined;
		}
		this.setState({ currentData: [], focusIndex: 0, open: false });
	}

	render() {
		const open =
			this.state.open &&
			!this.props.disabled &&
			this.popupRef.current &&
			this.inputRef &&
			(!!this.state.currentData.length || this.props.allowBlankSelections);

		return (
			<React.Fragment>
				<div className={this.props.className} ref={this.rootRef} styleName="_root">
					<input
						ref={this.onInputRef}
						onChange={this.onChange}
						onFocus={this.onChange}
						onBlur={() => {
							if(!this.props.multiselect) {
								setTimeout(() => {
									if (!this.ignoreNextBlur && this.state.open && this.state.currentData.length && !this._unmounted) {
										this.close();
									}
									this.ignoreNextBlur = false;
								}, 600)
							}
						}}
						onKeyDown={this.handleKeyDown}
						disabled={this.props.disabled}
						styleName="_input"
						placeholder={this.props.placeHolder}
					/>
					<FontAwesomeIcon icon="chevron-down" onClick={this.onChange} />
				</div>
				<ShellPortal>
					<div styleName="close-area" onClick={this.close} open={open} style={{ zIndex: this.props.zIndex }} />
					<div ref={this.popupRef} style={this.state.popupStyle} open={open} styleName={`_popup-root ${this.props.popupClass}`}>
						{this.state.currentData.map((x, i) => {
							return (
								<React.Fragment key={`${i}_fragementTypeAhead`}>
									{
										<div
											key={i}
											onClick={() => this.clickRow(x)}
											styleName={`_popup-row _popup-row-multiselect ${this.props.rowClass}`}
											isfocused={i === this.state.focusIndex ? "true" : "false"}
										>
											<this.template
												{...this.props}
												query={this.inputRef.value}
												row={x}
												isLast={i == this.state.currentData.length - 1}
											/>
											{
												(x as any).isSelected == true &&
												<div styleName="row-multiselect">
													<FontAwesomeIcon color="#3BB273" icon="check" />	
												</div>
											}
										</div>
									}
									{i === this.state.currentData.length - 1 && this.props.lastRowTemplate && (
										<div
											styleName={`_popup-row ${this.props.rowClass}`}
											onClick={e => {
												this.ignoreNextBlur = true;
											}}
										>
											<this.props.lastRowTemplate {...this.props} refresh={this.onChange} />
										</div>
									)}
								</React.Fragment>
							);
						})}
						{
							this.multiselectCount > 0 &&
							<div styleName="multiselect-done-button" onClick={this.confirmMultiselect}>
								Confirm Selected
							</div>
						}
					</div>
				</ShellPortal>
			</React.Fragment>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["getData", "onChange", "titleTransform"]);
	}

	componentWillUnmount() {
		this._unmounted = true;
	}

	componentDidUpdate(prevProps: TypeaheadProps<T>) {
		if (prevProps.value !== this.props.value && this.props.value) {
			this.inputRef.value = this.props.titleTransform(this.props.value);
		}
	}
}

interface State<T> {
	currentData: (string | T)[];
	focusIndex: number;
	open: boolean;
	popupStyle: React.StyleHTMLAttributes<HTMLDivElement>;
}
