import React, { PureComponent } from "react";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import styles from "./styles.scss";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import RadioButton from "components/Radio/radio";
import Select from "components/Select/select";
import { DateTime, TimeRange, array, shouldUpdate, MergeStyles } from "utils/index";
import TimeSliderImpl from "components/TimeSlider/timeslider";
import Checkbox from "components/Checkbox/checkbox";
import { Calendar, DateRangePicker } from "libs/react-date-range/index";
import Button from "components/Button/Button";
import MultiSelect from "components/MultiSelect/multiselect";
import Radio from "components/Radio/radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * all `other` props will be forwarded to Select
 */
class FormDropdown extends React.Component {
	static defaultProps = {
		inputClass: "",
		actions: [],
		titleTransform: undefined,
		titleClass: "",
		rootStyle: ""
	};
	render() {
		let {
			input,
			label,
			placeholder,
			type,
			actions,
			inputClass,
			meta: { touched, error },
			className,
			rootStyle,
			classes,
			...other
		} = this.props;

		return (
			<div className={className} styleName="input-group">
				<div>{label}</div>
				<Select
					actions={actions}
					widthOfRoot={true}
					{...other}
					{...input}
					styleName={rootStyle}
					classes={{ ...styles, ...classes }}
					titleClass={`_form_select ${other.titleClass}`}
				/>
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["titleTransform", "onBlur", "onChange"]);
	}
}
export const RenderDropdown = MergeStyles(styles, { forwardRef: false })(FormDropdown);
