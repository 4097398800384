import * as React from "react";
import * as styles from "./styles.scss";
import * as CSSModules from "react-css-modules";
import * as PropTypes from "prop-types";
import { actions } from "./redux";
import { connect } from "react-redux";
import { CommonComponentProps } from "redi-types";
import { ReduxState } from "config/reduxRoot";
import { QueryConfig, QueryGroup } from "services/query/buildquery";
import { props, __SmartTableImpl } from "./logic";

export interface SmartTablePublicProps<T> extends CommonComponentProps {
	/**Required */
	smartTableId: string;

	/** Default zero for no pagination */
	rowsPerPage?: number;
	allowColumnSort?: boolean;
	/**Size for responsive table to break on.  */
	breakOn?: "sm" | "md" | "xs" | "lg";
	/**Dont show th in mobile responsive mode */
	hideHeaderWhenCollapsed?: boolean;
	/**Required. If the number of columns change this must also be updated */
	columns: number;
	/**Pass Ref of parent scrolling element to show sticky headers when that element scrolls past the top of the table*/
	stickyHeaderScrollRef?: React.RefObject<HTMLElement> | HTMLElement; //pass the ref to the parent scroll element to show sticky headers

	/**Use `data` if handling data client side */
	data?: T[];

	/**Use `callServer` and `changeData` if handling data server side */
	callServer?: {
		/**
		 * One of the arguments for the given func must be a `PagingParameters` object. Specify the
		 * index of said argument with `pagingParametersIndex`
		 */
		func: (...args: any[]) => Promise<{ data?: DataSourceResult<T>; error?: any }>;
		pagingParametersIndex: number;
		/**Query builder config. `expireInNumHours` is useless here */
		queryBuilderConf?: QueryConfig;
		/**Any additional arguments to pass to `func`. `PagingParameters` will be auto injected at `pagingParametersIndex` */
		funcArgs?: any[];
	};

	/**Called when data changes (on client side data processing) or when data is fetched from the server (server side data querying).
	 * required if allow column sort or callServer
	 */
	changeData?: (s: T[], type?: ChangeDataTypes) => void;

	children: JSX.Element | JSX.Element[];

	// styleName overrides. (styleNames put on child tr/td/th elements; where this component is used; are preserved;
	// some of these props are redundant for css wizards.)
	tableClass?: string;
	/**class to be applied to div that contains responsive header title */
	collapsedTableHeadersClass?: string;
	theadClass?: string;
	tbodyClass?: string;
	theadrowClass?: string;
	tbodyrowClass?: string;
	sortChevronClass?: string;
	tdContentClass?: string;
	thContentClass?: string;
	thClass?: string;
	tdClass?: string;
	paginationTextClass?: string;
}

/**
 * The type of new data that comes in.
 *
 * `"Sort"` -> Data was sorted but might not be different
 *
 * `"ChangePage"` -> New Page
 *
 * `"New"` -> Any other effect that refreshes the data
 */
export type ChangeDataTypes = "New" | "Sort" | "ChangePage";

/**
 * fold columns like in excel.
	disables responsive columns.
	Any items in `scrollProps` prop will be spread to the scroll div
 */
export class CollapsableTableHead extends React.Component<{ index: number; scrollProps?: object }> {
	static defaultProps = {
		index: null
	};
	static propTypes = {
		scrollProps: PropTypes.object
	};
}
/**
 * fold columns like in excel.
	disables responsive columns.
	Any items in `scrollProps` prop will be spread to the scroll div
 */
export class CollapsableTableBody extends React.Component<{ index: number; scrollProps?: object }> {
	static defaultProps = {
		index: null
	};
	static propTypes = {
		scrollProps: PropTypes.object
	};
}

//register table in parent HoC so we dont load the table until it is registered
class SmartTable extends React.PureComponent<HoCProps, { wrapped: React.ComponentClass }> {
	static defaultProps = {
		data: null,
		rowsPerPage: 0, // zero for no pagination
		allowColumnSort: false,
		changeData: null, //func. required if allow column sort
		breakOn: "", // sm | md | xs | lg
		hideHeaderWhenCollapsed: false,
		columns: null, // num columns
		stickyHeaderScrollRef: null, //pass the ref to the parent scroll element to show sticky headers

		// styleName overrides. (styleNames put on child tr/td/th elements, where this component is used, are preserved;
		// some of these props are redundant for css wizards.)
		tableClass: "",
		collapsedTableHeadersClass: "", //class to be applied to div that contains responsive header title
		theadClass: "",
		tbodyClass: "",
		theadrowClass: "",
		tbodyrowClass: "",
		sortChevronClass: "",
		tdContentClass: "",
		thContentClass: "",
		thClass: "",
		tdClass: "",
		paginationTextClass: ""
	};
	static propTypes = {
		data: function(props) {
			//required if sorting or paging (for sorting)
			const test =
				!props.callServer && (props.allowColumnSort || props.rowsPerPage > 0) ? PropTypes.array.isRequired : PropTypes.array;
			const result = test.apply(this, arguments);
			if (result && !props.callServer) {
				result.message =
					"The prop `data` is required in `SmartTable` if `allowColumnSort` is set to true or `rowsPerPage` > 0 (paging enabled)";
			}
			if (props.callServer && props.data) {
				result.message = "The prop `callServer` is incompatable with prop `data`. Use one or the other.";
			}
			return result;
		},
		smartTableId: PropTypes.string.isRequired,
		rowsPerPage: PropTypes.number.isRequired,
		breakOn: PropTypes.oneOf(["sm", "md", "xs", "lg"]),
		columns: PropTypes.number, //not required if no paging or folding columns
		children: PropTypes.array.isRequired, //set as child elements, not as a prop like this -> children={...}
		changeData: function(props) {
			//required if allowColumnSort or changeData
			const test = props.allowColumnSort || props.changeData ? PropTypes.func.isRequired : PropTypes.func;
			const result = test.apply(this, arguments);
			if (result) {
				result.message = "The prop `changeData` is required in `SmartTable` if `allowColumnSort` is set to true.";
			}
			return result;
		},
		classes: PropTypes.object //style overrides
	};

	constructor(props) {
		super(props);

		if (!this.props.smartTableId) {
			throw new Error("SmartTable must have 'smartTableId' prop set.");
		}

		this.props.registerTable(this.props.smartTableId, this.props.children);

		const bindAction = dispatch => {
			return {
				setSortColumn: (tableId, thKey, direction) => dispatch(actions.setSortColumn(tableId, thKey, direction)),
				changePage: (tableId, increment, totalPages) => dispatch(actions.changePage(tableId, increment, totalPages)),
				updateTable: (tableId, data) => dispatch(actions.updateTable(tableId, data)),
				updateSortableColumns: tableId => dispatch(actions.updateSortableColumns(tableId, this.props.children))
			};
		};

		//only map the props for this table id
		const mapStateToProps = state => {
			const table = state.smartTable.tables[this.props.smartTableId];
			const { tables, ...rest } = state.smartTable;
			return {
				...rest,
				...table
			};
		};

		this.state = {
			wrapped: connect(
				mapStateToProps,
				bindAction
			)(
				CSSModules(
					__SmartTableImpl,
					{ ...styles, ...this.props.classes },
					{
						allowMultiple: true
					}
				)
			)
		};
	}

	render() {
		const Elem = this.state.wrapped;
		return this.props.hasTable ? <Elem {...this.props} /> : null;
	}
}

export default connect(
	(state: ReduxState, props: props<any>) => {
		const table = state.smartTable.tables[props.smartTableId];
		return {
			hasTable: !!table
		};
	},
	dispatch => ({
		registerTable: (tableId, tableHeadBody) => dispatch(actions.registerTable(tableId, tableHeadBody))
	})
)(SmartTable) as new <R>(props: SmartTablePublicProps<R>) => React.PureComponent<SmartTablePublicProps<R>>;

interface HoCProps extends props<any> {
	registerTable: (id: string, children: React.ReactNode) => void;
}
