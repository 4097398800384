import { persistType } from "utils";
export type MediaExtensions = "" | "jpg" | "jpeg" | "png" | "gif" | "mp4" | "pdf" | "mov";

@persistType("MediaFileLink")
export class MediaFileLink {
	name: string = null;
	size: number = null;
	url: string = null;
	extension: MediaExtensions = "";
	data?: string = null;
}

@persistType("MediaFileWithThumbNail")
export class MediaFileWithThumbNail extends MediaFileLink {
	//cast constructor.
	constructor(base?: MediaFileLink) {
		super();
		if (base) {
			for (const prop in base) {
				if (base.hasOwnProperty(prop)) {
					this[prop] = base[prop];
				}
			}
		}
	}

	thumbnailUrl?: string = null;
}

@persistType("MediaImage")
export class MediaImage extends MediaFileWithThumbNail {
	//cast constructor.
	constructor(base?: MediaFileLink | MediaFileWithThumbNail) {
		super();
		if (base) {
			for (const prop in base) {
				if (base.hasOwnProperty(prop)) {
					this[prop] = base[prop];
				}
			}
		}
	}

	height: number = null;
	width: number = null;
}
