import * as React from "react";
import * as PropTypes from "prop-types";



interface ThemeStore {
	[component: string]: Array<{ styles: object; classToPropMapping: ClassToPropMapping }>;
}

interface ClassToPropMapping {
	[prop: string]: string;
}

let themeStore: ThemeStore = {};

function ApplyThemeImpl<P, T = new (props: P) => React.Component<P>>(component: T, name: string, conf: IConf) {
	if (themeStore[name] && themeStore[name].length) {
		conf = { ...defaultconf, ...conf };
		const base = conf.pure ? React.PureComponent : React.Component;

		class ApplyTheme extends base<{ classes?: object; forwardedRef?: any }, { wrapped: T }> {
			static propTypes = {
				classes: PropTypes.object //style overrides
			};
			merged: object;
			classMaps: {};
			static __APPLY_THEME_ = true;

			constructor(props) {
				super(props);

				if (themeStore[name] && themeStore[name].length) {
					this.merged = themeStore[name].reduce((prev, next) => ({ ...prev, ...next.styles }), {});
					//now add props.classes
					this.merged = { ...this.merged, ...this.props.classes };
					
					this.classMaps = themeStore[name].reduce((prev, next) => {
						for (const prop in next.classToPropMapping) {
							if (next.classToPropMapping.hasOwnProperty(prop)) {
								const map = next.classToPropMapping[prop];
								if (prev[prop]) {
									prev[prop] += " " + map;
								} else {
									prev[prop] = map;
								}
								if (this.props[prop]) {
									prev[prop] += " " + this.props[prop];
								}
							}
						}
						return prev;
					}, {});
				} else {
					this.merged = this.props.classes;
					this.classMaps = {};
				}
			}

			render() {
				const Elem: any = component;

				return <Elem {...this.props} {...this.classMaps} classes={this.merged} />;
			}
		}

		return conf.forwardRef ? React.forwardRef((props, ref) => <ApplyTheme {...props} forwardedRef={ref} />) : ApplyTheme;
	} else {
		//if theres no specified themes, just return original component
		return component;
	}
}


interface IConf {
	pure?: boolean;
	forwardRef?: boolean;
}

const defaultconf = {
	pure: true,
	forwardRef: false
};

/**
 * Merge given style sheet(s) into component's existing style sheet.
 *
 * Add this decorator to components, before  `@MergeStyles`, and call `AddTheme` to add a global stylesheet to the given component
 *
 *
 * @param componentName Unique name to identify this component
 * @param conf see `IConf` interface
 */
export function RegisterTheme(componentName: string, conf: IConf = defaultconf) {
	return function<P, T = new (props: P) => React.Component<P>>(component: T): T {
		return (ApplyThemeImpl(component, componentName, conf) as any) as T;
	};
}

/**
 * Merge the given stylesheet over the default styles for the component. Class names that match will be overriden.
 *
 * Alternatively, if the component implements any 'Class' props, new classes can be linked to those props and injected globaly to all component instances
 *
 * @param componentName Component to add styles to
 * @param styles the styles
 */
export function AddTheme(componentName: string, styles: object);
/**
 * Merge the given stylesheet over the default styles for the component. Class names that match will be overriden.
 *
 * Alternatively, if the component implements any 'Class' props, new classes can be linked to those props and injected globaly to all component instances
 *
 * @param classToPropMapping Map class names to components that specify `somethingClass` props. This allows the addition of classes that would not normally exist in the default stylesheet
 *
 * Example, the `Select` component specifies a `titleClass` prop that applies the given class to the title div.
 * Here the `"me-title-class"` class is auto injected into the `titleClass` prop globaly on all `Selects`
 *
 * `AddTheme("Select", themeStyles, { titleClass: "me-title-class" });`
 */
export function AddTheme(componentName: string, styles: object, classToPropMapping: ClassToPropMapping);


export function AddTheme(componentName: string, styles: object, classToPropMapping?: ClassToPropMapping) {
	themeStore[componentName] = themeStore[componentName] || [];
	themeStore[componentName].push({ styles, classToPropMapping: classToPropMapping || {} });
}
