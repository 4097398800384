/**
 * Define icons here:
 *
 * 	`<FontAwesomeIcon icon="times" />` === `import {faTimes} ... library.add(faTimes)`
 *
 */

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faTimes,
	faChevronLeft,
	faChevronRight,
	faUserCircle,
	faChevronUp,
	faChevronDown,
	faTrashAlt,
	faFileAlt,
	faUpload,
	faCheck,
	faSearch,
	faFileDownload,
	faPencilAlt,
	faBars,
	faCamera,
	faEllipsisV,
	faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

library.add(faTimes);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faFileDownload);
library.add(faPencilAlt);
library.add(faCheck);
library.add(faBars);
library.add(faExclamationTriangle);
library.add(faUserCircle);
library.add(faTrashAlt);
library.add(faCamera);
library.add(faFileAlt);
library.add(faChevronUp);
library.add(faUpload);
library.add(faEllipsisV);
library.add(faChevronDown);
library.add(faSearch);
