import React, { Component } from "react";
import styles from "./styles.scss";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import { shouldUpdate, MergeStyles } from "utils/index";

/**

Use:  
*/

@CSSModules(styles)
export default class Button extends Component {
	static defaultProps = {
		onClick: null,
		variant: "normal", // normal | circle
		type: "button", // button|submit|reset
		theme: "default",
		raised: true,
		disabled: false
	};
	static propTypes = {
		variant: PropTypes.oneOf(["normal", "circle"]),
		type: PropTypes.oneOf(["button", "submit", "reset"]),
		theme: PropTypes.oneOf(["default", "primary", "secondary"]),
		raised: PropTypes.bool,
		disabled: PropTypes.bool,
		onClick: PropTypes.func.isRequired,
		classes: PropTypes.object //style overrides
	};

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onClick"]);
	}

	render() {
		const { children, variant, raised, disabled, forwardedRef, theme, color, ...rest } = this.props;
		return (
			<button
				style={color ? { backgroundColor: color } : undefined}
				{...rest}
				theme={theme}
				variant={variant}
				raised={raised.toString()}
				disabled={disabled}
				styleName="_btn_root"
				ref={forwardedRef}
			>
				{children}
			</button>
		);
	}
}
