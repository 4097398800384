import { http, DateTime } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";

const route = "ScheduleArea/";

export default class scheduleareaservice {
	static GetList(scheduleGroupId: string) {
		let url = `${config.baseUrl + route}GetList`;

		return http({ url, method: "GET", scheduleGroupId })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}
}
