import * as React from "react";
import * as styles from "./styles.scss";
import * as PropTypes from "prop-types";
import { MergeStyles, array } from "utils/index";
import ShellPortal from "components/ShellPortal/ShellPortal";
import { CommonComponentProps } from "redi-types";
import * as router from "react-router-redux";
import { dispatch, history } from "boot/configureStore";

type RegisterModal = { modal: BaseModal; lastOpenClose?: Date; isOpen?: boolean };

let modals: RegisterModal[] = [];

function keypress(e) {
	if (e.key === "Escape") {
		//close modals in teh reverse order they where opened
		const lastOpen = array.sort(modals.filter(x => x.isOpen), x => x.lastOpenClose, true)[0];
		if (lastOpen) {
			lastOpen.modal.props.onClose(e);
		}
	}
}

window.addEventListener("keydown", keypress);

@MergeStyles(styles)
export default class BaseModal extends React.PureComponent<Props, {}> {
	static defaultProps = {
		isOpen: false,
		zIndex: 99,
		onClose: null,

		rootClass: ""
	};
	static propTypes = {
		isOpen: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		zIndex: PropTypes.number,
		classes: PropTypes.object
	};

	id: number;
	urlHash: string;
	locationBeforeOpen: string;
	backPressed: boolean;

	constructor(props) {
		super(props);
		this.stopPropagation = this.stopPropagation.bind(this);

		modals.push({ modal: this, isOpen: this.props.isOpen, lastOpenClose: new Date() });

		this.id = modals.length;
		this.urlHash = "#Modal" + this.id;

		if (this.props.hashUrl) {
			let lastLocation;

			//create listener to clone modal on nav
			history.listen(location => {
				if (
					location.hash !== this.urlHash &&
					lastLocation &&
					lastLocation.hash === this.urlHash &&
					(!location.hash || parseInt(location.hash.match(/\d*$/)[0], 10) <= this.id)
				) {
					lastLocation = location;
					this.backPressed = true;
					//back btn pressed. only close if was open
					this.props.onClose();
				} else {
					lastLocation = location;
				}
			});

			if (this.props.isOpen) {
				this.locationBeforeOpen = window.location.pathname + window.location.hash;
				dispatch(router.push(window.location.pathname + this.urlHash));
			}
		}
	}
	stopPropagation(e) {
		e.stopPropagation();
	}

	componentWillUnmount() {
		modals = modals.filter(x => x.modal !== this);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.isOpen !== this.props.isOpen) {
			let modal = modals.find(x => x.modal === this);
			modal.isOpen = this.props.isOpen;
			modal.lastOpenClose = new Date();

			if (this.props.hashUrl) {
				if (this.props.isOpen) {
					if (window.location.pathname + this.urlHash !== this.locationBeforeOpen) {
						this.locationBeforeOpen = window.location.pathname + window.location.hash;
						dispatch(router.push(window.location.pathname + this.urlHash));
					}
				} else {
					if (!this.backPressed) {
						if (window.location.pathname + this.urlHash !== this.locationBeforeOpen) {
							dispatch(router.push(this.locationBeforeOpen));
						}
					}
					this.backPressed = false;
				}
			}
		}
	}

	render() {
		return (
			<ShellPortal>
				<div
					styleName={`___modal__background ${this.props.rootClass}`}
					open={this.props.isOpen}
					style={{ zIndex: this.props.zIndex - 1 }}
					onMouseDown={this.props.onClose}
					onTouchStart={this.props.onClose}
				>
					{this.props.isOpen && (
						<div
							className={this.props.className}
							styleName="__modal-root"
							style={this.props.style}
							onMouseDown={this.stopPropagation}
							onTouchStart={this.stopPropagation}
						>
							{this.props.children}
						</div>
					)}
				</div>
			</ShellPortal>
		);
	}
}

interface Props extends CommonComponentProps {
	isOpen: boolean;
	rootClass?: string;
	onClose: (e?: Event | React.MouseEvent | React.TouchEvent) => void;
	zIndex?: number;
	style?: object;
	/**Add a hash url so back button closes modal instead of just routing back */
	hashUrl?: boolean;
}
