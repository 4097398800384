// a deep cloner that ensures getters/setter/Types remain

function newOfType(obj) {
	if (obj === null || obj === undefined || typeof obj !== "object") {
		return obj;
	}
	return Object.create(Object.getPrototypeOf(obj));
}

function clone(obj, orig) {
	if (orig === null || orig === undefined) {
		return orig;
	}
	if (Array.isArray(orig)) {
		obj = new Array(orig.length);
		for (let i = 0; i < orig.length; i++) {
			const item = orig[i];
			obj[i] = clone(newOfType(item), item);
		}
	} else if (orig instanceof Date) {
		obj = new Date(orig);
	} else if (typeof orig === "object" && orig !== null) {
		for (var prop in orig) {
			if (orig.hasOwnProperty(prop)) {
				const item = orig[prop];
				if (item instanceof Date) {
					obj[prop] = new Date(item);
				} else if ((typeof item === "object" && item !== null) || Array.isArray(item)) {
					obj[prop] = clone(newOfType(item), item);
				} else {
					obj[prop] = item;
				}
			}
		}
	} else {
		obj = orig;
	}
	return obj;
}

/**
 * a deep cloner that ensures getters/setter/Types remain
 * @param {object} obj 
 */
export function deepClone<T>(obj: T): T {
	return clone(newOfType(obj), obj);
}
