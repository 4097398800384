import * as React from "react";
import * as styles from "./styles.scss";
import * as CSSModules from "react-css-modules";
import * as PropTypes from "prop-types";
import { CommonComponentProps } from "redi-types";
import { WrappedFieldProps } from "redux-form";
import SwitchButton, { SwitchButtonProps } from "components/Switch/switch";
import { MergeStyles, shouldUpdate } from "utils";
import Tabs, { TabAction, TabsProps } from "components/Tabs/Tabs";

class FormTabs extends React.Component<Props & WrappedFieldProps, State> {
	static defaultProps = {};
	render() {
		let {
			input,
			meta: { touched, error },
			classes,
			label,
			className,
			actions,
			...other
		} = this.props;

		const tabactions = actions.map(x => new TabAction(x, () => input.onChange(x), val => val === x));

		return (
			<div className={className} styleName="input-group">
				<div>{label}</div>
				<Tabs {...other} {...input} actions={tabactions} classes={{ ...styles, ...classes }} />
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onBlur", "onChange"]);
	}
}

export const RenderTabs = MergeStyles(styles, { forwardRef: false })(FormTabs);

interface Props extends CommonComponentProps {
	actions: (string | JSX.Element)[]; //redefine actions
	label?: string;
}

interface State {}
