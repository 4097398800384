export default class {
	static capitalizeFirstLetter(string: string) {
		if (string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		} else {
			return null;
		}
	}

	static toCamelCase(value: string | object): string | any {
		if (Array.isArray(value)) {
			for (let index = 0; index < value.length; index++) {
				const item = value[index];
				value[index] = this.toCamelCase(item);
			}
		} else if (value && typeof value === "object") {
			for (let k in value) {
				if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
					value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
					delete value[k];
				}
			}
		} else if (typeof value === "string") {
			return value.charAt(0).toLowerCase() + value.substring(1);
		}
		return value;
	}

	/**
	 * ACROText -> ACRO Text
	 * 
		UserNameTest -> User Name Test
	 * @param {string} string 
	 */
	static insertSpaces(string: string) {
		string = string.replace(/([a-z])([A-Z])/g, "$1 $2");
		string = string.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
		return string;
	}

	static operator(operator: "gt" | "gte" | "lt" | "lte" | "eq" | "neq" | "range") {
		switch (operator) {
			case "gt":
				return "Greater Than";
			case "gte":
				return "Greater Than or Equal";
			case "lt":
				return "Less Than";
			case "lte":
				return "Less Than or Equal";
			case "eq":
				return "Equal";
			case "neq":
				return "Not Equal";
			case "range":
				return "Range";
			default:
				return "";
		}
	}

	static path(obj: object, path: string) {
		let rtns = { ...obj };
		const paths = path.split(".");
		for (let index = 0; index < paths.length; index++) {
			let p = paths[index];
			const arr = /\[(\d*)\]$/.exec(p);
			if (arr && arr.length) {
				//get array prop then index of item
				p = /^[^\[]*/.exec(p)[0];
				rtns = { ...rtns[p][arr[1]] };
			} else {
				rtns = { ...rtns[p] };
			}
		}
		return rtns;
	}
}
