import { deepClone } from "./deepCloner";
import { persistType } from "utils/persist";

@persistType("TreePos")
class TreePos {
	own: number = null;
	parent: number = null;
	children : number[] = [];
	level: number = 0;
	get hasChildren() {
		return this.children.length > 0;
	}
}

interface Node {
	_tree: TreePos
}

@persistType("TreeMap")
export class TreeMap<T extends Node> {
	private _lastkey: number = 0;
	[x: number]: T

	private get NewKey() {
		return this._lastkey++;
	}
	/**
	 * Get elements with no parents
	 */
	get roots() {
		let rtns = [];
		for (var prop in this) {
			if (this.hasOwnProperty(prop)) {
				if (Number.isInteger(parseInt(prop, 10))) {
					let item = this[prop];
					if (item._tree.parent === null) {
						rtns.push(item);
					}
				}
			}
		}
		return rtns;
	}

	/**
	 * Set returnNeg1IfNotFound to `true` if using to find parent for `TreeFlattener.Flatten()`
	 * @param {Function} selector
	 */
	find(selector: (item: T) => boolean) {
		for (var prop in this) {
			if (this.hasOwnProperty(prop)) {
				if (Number.isInteger(parseInt(prop, 10))) {
					let item = this[prop];
					if (selector(item)) {
						return item;
					}
				}
			}
		}
		return null;
	}

	/**
	 *
	 * @param {Function} selector
	 */
	filter(selector:  (item: T) => boolean) {
		let rtns = [];
		for (var prop in this) {
			if (this.hasOwnProperty(prop)) {
				if (Number.isInteger(parseInt(prop, 10))) {
					let item = this[prop];
					if (selector(item)) {
						rtns.push(item);
					}
				}
			}
		}
		return rtns;
	}

	/**
	 * Get all elements
	 */
	get values() {
		let rtns = [];
		for (var prop in this) {
			if (this.hasOwnProperty(prop)) {
				if (Number.isInteger(parseInt(prop, 10))) {
					rtns.push(this[prop]);
				}
			}
		}
		return rtns;
	}

	/**
	 * Remove an item and all its children recursivly
	 * @param {*} item
	 */
	deleteBranch(item: T) {
		let deleted = [];
		if (item && item._tree) {
			if (item._tree.parent) {
				let parentItem = this[item._tree.parent];
				if (parentItem) {
					parentItem._tree.children = parentItem._tree.children.filter(x => x !== item._tree.own);
				}
			}

			const delChildren = parent => {
				if (parent) {
					parent._tree.children.forEach(x => {
						delChildren(this[x]);
					});
					deleted.push(this[parent._tree.own]);
					delete this[parent._tree.own];
				}
			};
			delChildren(item);
		}
		return deleted;
	}

	/**
	 * Remove all items and their children recursivly
	 * @param {Array} items
	 */
	deleteBranchs(items: Array<T>) {
		for (let i = 0; i < items.length; i++) {
			const element = items[i];
			this.deleteBranch(element);
		}
	}

	/**
	 * Returns the root element for this item
	 * @param {object} child Child Item
	 */
	getRootFor(child: T) {
		while (child._tree.parent !== null) {
			child = this[child._tree.parent];
		}
		return child;
	}

	/**
	 * Get Children nodes for parent Node
	 * @param {object} parent Node
	 */
	getChildrenFor(parent: T) {
		let rtns = [];
		parent._tree.children.forEach(x => rtns.push(this[x]));
		return rtns;
	}
}

function flattenItem(dictionary, parent, item) {
	item._tree = new TreePos();
	item._tree.own = dictionary.NewKey;
	item._tree.parent = parent ? parent._tree.own : null;
	if (parent) {
		parent._tree.children.push(item._tree.own);
		item._tree.level = parent._tree.level + 1;
	}
}

function flattenChildren(dictionary, parent, childrenSelector, recurseCount) {
	let data = childrenSelector(parent);
	//when count reaches zero stop flattening
	recurseCount--;
	for (let i = 0; data && i < data.length; i++) {
		const item = data[i];

		flattenItem(dictionary, parent, item);

		if (recurseCount !== 0) {
			flattenChildren(dictionary, item, childrenSelector, recurseCount);
		}

		dictionary[item._tree.own] = item;
	}
}

function toTree(dictionary, parent, childrenSelector, recurse) {
	const selector = childrenSelector(parent);
	parent[selector] = [];

	for (let i = 0; i < parent._tree.children.length; i++) {
		const index = parent._tree.children[i];
		let child = dictionary[index];
		if (recurse) {
			toTree(dictionary, child, childrenSelector, recurse);
		}
		parent[selector].push(child);
	}
}

/**
 * Turns a tree based structure into a flat map structure with ids pointing to parents/children
 */
class TreeFlattener {
	/**
	 * Flatten a tree structure based on a given funciton pointing to the children array.
	 * Pass a parent object and an existing map to map children to that object
	 * @param {*} data Array or object
	 * @param {Function} childrenSelector Lambda pointing to children prop. Redundunt if recurseCount == 0
	 * @param {number} recurseCount Number of levels to flatten
	 * @param {object} parent Optional parent object to specify as root for adding more data to an existing map
	 * @param {TreeMap} dictionary Existing map to add more data to
	 * @returns {TreeMap} TreeMap
	 */
	static Flatten<Y, T extends Y & Node = Y & Node>(data: Y|Y[], childrenSelector: (node: Y) => Y[keyof Y], recurseCount: number = null, parent: T|number = null, dictionary: TreeMap<T> = new TreeMap<T>()): TreeMap<T> {
		const action = item => {
			flattenItem(dictionary, parent, item);
			if (childrenSelector && (recurseCount === null || recurseCount !== 0)) {
				recurseCount = recurseCount || -1;
				flattenChildren(dictionary, item, childrenSelector, recurseCount);
			}
			dictionary[item._tree.own] = item;
		};

		if (Array.isArray(data)) {
			for (let i = 0; i < data.length; i++) {
				const item = data[i];
				action(item);
			}
		} else {
			action(data);
		}
		return dictionary;
	}

	/**
	 * Revert a TreeMap into a tree structure
	 * @param {TreeMap} dictionary TreeMap
	 * @param {Function} childrenSelector Lambda returning string name of children prop
	 * @param {*} parentId Optional tree id to map from
	 * @param {*} recurse
	 * @returns {Array|Object} Tree structure
	 */
	static ToTree<T extends Node>(dictionary: TreeMap<T>, childrenSelector:  (node: T) => T[keyof T], parentId: number = null, recurse: boolean = true): Array<T> | T {
		let rtns = [];

		for (var prop in dictionary) {
			if (dictionary.hasOwnProperty(prop)) {
				if (Number.isInteger(parseInt(prop, 10))) {
					let item = dictionary[prop];
					if (item._tree.parent === parentId) {
						rtns.push(item);
						toTree(dictionary, item, childrenSelector, recurse);
					}
				}
			}
		}

		return rtns.length === 1 ? rtns[0] : rtns;
	}

	/**
	 * Copy an existing branch to a new or existing map and maintian its children hierarchy.
	 * Because items are stored in a map by referenc only, simply copying one item to anotehr map will break
	 * the existing map, so use this to create copies
	 * @param {T} nodeToCopy Parent node to copy + all its children
	 * @param {TreeMap} copyFromDictionary Existing map
	 * @param {TreeMap} copyToDictionary Copy to map
	 * @param {object} copyUnder Existing node in copyToDictionary to make copied object a child
	 * @returns {TreeMap} TreeMap
	 */
	static CopyBranch<Y, T extends Y & Node = Y & Node>(nodeToCopy: T, copyFromDictionary: TreeMap<T>, copyToDictionary: TreeMap<T> = new TreeMap<T>(), copyUnder: T = null): TreeMap<T> {
		const copyItems = (parent, current, kidIndicesToCopy) => {
			const _clone = deepClone(current);
			TreeFlattener.Flatten(_clone, null, 0, parent, copyToDictionary);
			for (let i = 0; i < kidIndicesToCopy.length; i++) {
				const index = kidIndicesToCopy[i];
				const kid = copyFromDictionary[index];
				copyItems(_clone, kid, kid._tree.children);
			}
		};

		copyItems(copyUnder, nodeToCopy, nodeToCopy._tree.children);

		return copyToDictionary;
	}
}

export default TreeFlattener;
