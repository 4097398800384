//initial redux
import { put, call, takeLatest, fork, all, take, cancel, select, takeEvery } from "redux-saga/effects";
import { ReduxAction, BookingDto, ScheduleDto, ScheduleAreaDto } from "redi-types";
import bookingservice from "services/booking";
import { persist, DateTime } from "utils";
import { actions as scheduleactions } from "redux/scheduleRedux";
import { ReduxState } from "config/reduxRoot";
import { actions as schedulegroupactions } from "redux/scheduleGroupRedux";
import * as styles from "config/theme/vars.scss";

interface State {
	currentBooking: BookingDto;
	bookings: BookingDto[];

	isBusy: boolean;
	errors: any[];
}

const initialState: State = {
	currentBooking: null,
	bookings: [],

	isBusy: false,
	errors: []
};

const persistConf = {
	whitelist: [],
	expireInNumHours: 0 //dont expire
};

/////////
//types
export const types = {
	SET_CURRENT_BOOKING: "booking/SET_CURRENT_BOOKING",
	CREATE_BOOKING: "booking/CREATE_BOOKING",
	UPDATE_BOOKING: "booking/UPDATE_BOOKING",
	DELETE_BOOKING: "booking/DELETE_BOOKING",

	SET_LIST: "booking/SET_LIST",

	IS_BUSY: "booking/IS_BUSY",
	ON_ERROR: "booking/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction): State {
		switch (action.type) {
			case types.CREATE_BOOKING:
			case types.UPDATE_BOOKING:
			case types.DELETE_BOOKING:
				return { ...state, isBusy: true };

			case types.IS_BUSY:
				return { ...state, isBusy: action.payload.val };

			case types.SET_CURRENT_BOOKING:
				return { ...state, currentBooking: action.payload.booking, isBusy: false };

			case types.SET_LIST:
				return { ...state, isBusy: false, bookings: action.payload.bookings };

			case types.ON_ERROR:
				return {
					...state,
					errors: state.errors.concat(action.payload).slice(-10),
					isBusy: false
				};

			default:
				return state;
		}
	}
};
export const reducer = persist("booking", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([this.createBooking(), this.updateBooking(), this.deleteBooking()]);
	},

	*deleteBooking() {
		yield takeLatest<ReduxAction>(types.DELETE_BOOKING, function*({ payload }) {
			const result = yield call(bookingservice.Delete, payload.bookingId, payload.deleteGroup);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.setCurrentBooking(null));
				const { areaIds, date } = yield select<ReduxState>(x => ({
					areaIds: x.scheduleArea.scheduleAreas
						.filter(
							(d: ScheduleAreaDto) =>
								d.schedules && d.schedules.some(f => f.bookings.some(t => t.bookingId === payload.bookingId))
						)
						.map(x => x.scheduleAreaId),
					date: x.time.bookingSelectedDay
				}));
				if (window.innerWidth < styles.small) {
					yield put(scheduleactions.getList(date, DateTime.endOf("day", date), areaIds));
				} else {
					yield put(scheduleactions.getList(DateTime.startOf("week", date), DateTime.endOf("week", date), areaIds));
				}
				yield put(schedulegroupactions.getBookingDates());
			}
		});
	},

	*updateBooking() {
		yield takeLatest<ReduxAction>(types.UPDATE_BOOKING, function*({ payload }) {
			const result = yield call(bookingservice.Update, payload.booking, payload.updateRepeating);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.setCurrentBooking(null));
				const { areaIds, date } = yield select<ReduxState>(x => ({
					areaIds: x.scheduleArea.scheduleAreas
						.filter((d: ScheduleAreaDto) => d.schedules && d.schedules.some(f => payload.booking.scheduleId === f.scheduleId))
						.map(x => x.scheduleAreaId),
					date: x.time.bookingSelectedDay
				}));
				if (window.innerWidth < styles.small) {
					yield put(scheduleactions.getList(date, DateTime.endOf("day", date), areaIds));
				} else {
					yield put(scheduleactions.getList(DateTime.startOf("week", date), DateTime.endOf("week", date), areaIds));
				}
				yield put(schedulegroupactions.getBookingDates());
			}
		});
	},

	*createBooking() {
		yield takeLatest<ReduxAction>(types.CREATE_BOOKING, function*({ payload }) {
			const result = yield call(
				bookingservice.CreateList,
				payload.forSchedules.map(x => {
					return { ...payload.booking, scheduleId: x.scheduleId };
				})
			);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.setCurrentBooking(null));
				const { areaIds, date } = yield select<ReduxState>(x => ({
					areaIds: x.scheduleArea.scheduleAreas
						.filter(
							(d: ScheduleAreaDto) =>
								d.schedules && d.schedules.some(f => payload.forSchedules.some(t => t.scheduleId === f.scheduleId))
						)
						.map(x => x.scheduleAreaId),
					date: x.time.bookingSelectedDay
				}));
				if (window.innerWidth < styles.small) {
					yield put(scheduleactions.getList(date, DateTime.endOf("day", date), areaIds));
				} else {
					yield put(scheduleactions.getList(DateTime.startOf("week", date), DateTime.endOf("week", date), areaIds));
				}
				yield put(schedulegroupactions.getBookingDates());
			}
		});
	}
};

////////
//actions
export const actions = {
	isBusy(val: boolean) {
		return {
			type: types.IS_BUSY,
			payload: { val }
		};
	},

	setCurrentBooking(booking: BookingDto) {
		return {
			type: types.SET_CURRENT_BOOKING,
			payload: { booking }
		};
	},

	setList(bookings: BookingDto[]) {
		return {
			type: types.SET_LIST,
			payload: { bookings }
		};
	},

	createBooking(booking: BookingDto, forSchedules: ScheduleDto[]) {
		return {
			type: types.CREATE_BOOKING,
			payload: { booking, forSchedules }
		};
	},

	updateBooking(booking: BookingDto, updateRepeating: boolean) {
		return {
			type: types.UPDATE_BOOKING,
			payload: { booking, updateRepeating }
		};
	},

	deleteBooking(bookingId: string, deleteGroup: boolean) {
		return {
			type: types.DELETE_BOOKING,
			payload: { bookingId, deleteGroup }
		};
	},

	onError(data) {
		return {
			type: types.ON_ERROR,
			payload: data
		};
	}
};
