import { Component, PureComponent } from "react";

let wontBind = [
	"constructor",
	"render",
	"componentWillMount",
	"componentDidMount",
	"componentWillReceiveProps",
	"shouldComponentUpdate",
	"componentWillUpdate",
	"componentDidUpdate",
	"componentWillUnmount"
];

let toBind = [];

/**
 * Automatic binding of `this` to member functions.
 * @param {component} context 
 */
export default function autoBind(context) {
	if (context === undefined) {
		console.error("Autobind error: No context provided.");
		return;
	}

	const options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
	let objPrototype = Object.getPrototypeOf(context);

	if (objPrototype instanceof PureComponent) {
		//remove all HoCs until we get the actual component
		while (objPrototype instanceof PureComponent && Object.getPrototypeOf(objPrototype) instanceof PureComponent) {
			objPrototype = Object.getPrototypeOf(objPrototype); //walk up the inheritence hierarchy
		}
	} else {
		while (objPrototype instanceof Component && Object.getPrototypeOf(objPrototype) instanceof Component) {
			objPrototype = Object.getPrototypeOf(objPrototype); //walk up the inheritence hierarchy
		}
	}

	if (options.bindOnly) {
		// If we want to bind *only* a set list of methods, then do that (nothing else matters)
		toBind = options.bindOnly;
	} else {
		// Otherwise, bind all available methods in the class
		toBind = Object.getOwnPropertyNames(objPrototype);

		// And exclude anything explicitly passed in wontBind
		wontBind = wontBind.concat(options.wontBind || []);
	}

	toBind.forEach(function(method) {
		let descriptor = Object.getOwnPropertyDescriptor(objPrototype, method);

		if (descriptor === undefined) {
			console.warn(`Autobind: "${method}" method not found in class.`);
			return;
		}

		// Return if it's special case function or if not a function at all
		if (wontBind.indexOf(method) !== -1 || typeof descriptor.value !== "function") {
			return;
		}

		Object.defineProperty(objPrototype, method, boundMethod(objPrototype, method, descriptor));
	});
}

/**
 * From autobind-decorator (https://github.com/andreypopp/autobind-decorator/tree/master)
 * Return a descriptor removing the value and returning a getter
 * The getter will return a .bind version of the function
 * and memoize the result against a symbol on the instance
 */
function boundMethod(objPrototype, method, descriptor) {
	let fn = descriptor.value;

	return {
		configurable: true,
		get() {
			if (this === objPrototype || this.hasOwnProperty(method)) {
				return fn;
			}

			let boundFn = fn.bind(this);
			Object.defineProperty(this, method, {
				value: boundFn,
				configurable: true,
				writable: true
			});
			return boundFn;
		}
	};
}
