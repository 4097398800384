/**
 * a promise that can be resolved/rejected from outside itself IE: promise.Resolve()
 */
export default class ManualPromise<T> {
	_promiseResolve;
	_promiseReject;

	promise = new Promise<T>((resolve, reject) => {
		this._promiseResolve = resolve;
		this._promiseReject = reject;
	});
	running: boolean;
	action: Function;

	Resolve(...args) {
		this._promiseResolve(...args);
		this._promiseResolve = () => null; //noop;
	}

	Reject(...args) {
		this.promise.catch(x => x);
		this._promiseReject(...args);
		this._promiseReject = () => null; //noop;
	}

	then(...args) {
		return this.promise.then(...args);
	}

	catch(...args) {
		return this.promise.catch(...args);
	}

	/**
	 * Start `action` passed into constructor. `args` are forwared to `action`
	 * @param {*} args
	 */
	Run(...args) {
		if (!this.running) {
			this.running = true;
			new Promise((resolve, reject) => {
				try {
					const result = this.action(...args);
					this.Resolve(result);
				} catch (e) {
					this.Reject(e);
				}
			});
		}

		return this.promise;
	}

	/**
	 *
	 * @param {function} action Optional action to run inside promise. Call Run(...) to start this action. Promise will resolve when action runs to completion.
	 */
	constructor(action?: Function) {
		this.action = action;
	}
}
