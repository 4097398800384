//initial redux
import { put, call, takeLatest, fork, all, take, cancel, select, takeEvery } from "redux-saga/effects";
import securityservice from "services/security/security";
import { types as setuptypes } from "boot/setupRedux";
import * as router from "react-router-redux";
import { persist, string, DateTime, array } from "utils/index";
import { actions as schedulegroupactions } from "redux/scheduleGroupRedux";
import { ReduxAction, ScheduleDto, ScheduleSummaryDto, UserScheduleDto, ScheduleAreaDto, PublicHolidayDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";
import scheduleservice from "services/schedule";
import { delay } from "redux-saga";
import * as styles from "config/theme/vars.scss";

interface State {
	schedules: ScheduleDto[];
	scheduleSummarys: ScheduleSummaryDto[];
	publicHolidays: PublicHolidayDto[];

	backgroundSyncRunning: boolean;

	isBusy: boolean;
	errors: any[];
}

const initialState: State = {
	schedules: [],
	publicHolidays: [],
	scheduleSummarys: [],
	backgroundSyncRunning: false,
	isBusy: false,
	errors: []
};

const persistConf = {
	whitelist: [],
	expireInNumHours: 0 //dont expire
};

/////////
//types
export const types = {
	GET_LIST_IN_GROUPS: "schedule/GET_LIST_IN_GROUPS",
	SET_SCHEDULE_LIST: "schedule/SET_SCHEDULE_LIST",

	GET_LIST: "schedule/GET_LIST",
	SET_LIST: "schedule/SET_LIST",

	START_BOOKING_SYNC: "schedule/START_BOOKING_SYNC",
	STOP_BOOKING_SYNC: "schedule/STOP_BOOKING_SYNC",
	MERGE_LIST: "schedule/MERGE_LIST",

	UPDATE_USER_SCHEDULES: "schedule/UPDATE_USER_SCHEDULES",

	SET_SUMMARY_LIST: "schedule/SET_SUMMARY_LIST",

	IS_BUSY: "schedule/IS_BUSY",
	ON_ERROR: "schedule/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction): State {
		switch (action.type) {
			case types.GET_LIST_IN_GROUPS:
			case types.GET_LIST:
			case types.UPDATE_USER_SCHEDULES:
				return { ...state, isBusy: true };

			case types.IS_BUSY:
				return { ...state, isBusy: action.payload.val };

			case types.START_BOOKING_SYNC:
				return { ...state, backgroundSyncRunning: true };
			case types.STOP_BOOKING_SYNC:
				return { ...state, backgroundSyncRunning: false };

			case types.SET_SCHEDULE_LIST:
				return { ...state, isBusy: false, schedules: action.payload.schedules };

			case types.SET_LIST: {
				const schedules: ScheduleDto[] = array.selectMany(array.mapProps(action.payload.schedules, x => x), x => x);
				const publicHolidays = array.removeDuplicates(array.selectMany(schedules, x => x.publicHolidays), x => x.publicHolidayId);
				return { ...state, isBusy: false, schedules, publicHolidays };
			}

			case types.SET_SUMMARY_LIST:
				return { ...state, isBusy: false, scheduleSummarys: action.payload.summarys };

			case types.ON_ERROR:
				return {
					...state,
					errors: state.errors.concat(action.payload).slice(-10),
					isBusy: false
				};

			default:
				return state;
		}
	}
};
export const reducer = persist("schedule", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([this.getSchedules(), this.getScheduleInGroup(), this.UpdateUserSchedules(), this.bookingSync()]);
	},

	*bookingSync() {
		yield takeLatest<ReduxAction>(types.START_BOOKING_SYNC, function*({ payload }) {
			let syncing;
			let lastUpdateTime = DateTime.add("minute", new Date(), new Date().getTimezoneOffset());
			do {
				const { areaIds, date } = yield select<ReduxState>(x => ({
					areaIds: x.scheduleArea.scheduleAreas.filter(d => d && d.schedules).map(x => x.scheduleAreaId),
					date: x.time.bookingSelectedDay
				}));
				let result;
				const time = lastUpdateTime;
				lastUpdateTime = DateTime.add("minute", new Date(), lastUpdateTime.getTimezoneOffset());
				if (window.innerWidth < styles.small) {
					result = yield call(scheduleservice.GetList, areaIds, date, DateTime.endOf("day", date), time);
				} else {
					result = yield call(
						scheduleservice.GetList,
						areaIds,
						DateTime.startOf("week", date),
						DateTime.endOf("week", date),
						time
					);
				}

				if (result.data) {
					yield put(actions.mergeList(result.data));
					if (Object.keys(result.data).length) {
						yield put(schedulegroupactions.getBookingDates());
					}
				}

				yield delay(5000);
				syncing = yield select<ReduxState>(x => x.schedule.backgroundSyncRunning);
			} while (syncing);
		});
	},

	*UpdateUserSchedules() {
		yield takeLatest<ReduxAction>(types.UPDATE_USER_SCHEDULES, function*({ payload }) {
			const result = yield call(scheduleservice.UpdateUserSchedules, payload.userschedules);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.isBusy(false));
			}
		});
	},
	*getScheduleInGroup() {
		yield takeLatest<ReduxAction>(types.GET_LIST_IN_GROUPS, function*({ payload }) {
			const result = yield call(scheduleservice.GetListInGroups, payload.scheduleGroupIds);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.setScheduleList(result.data));
			}
		});
	},

	*getSchedules() {
		yield takeLatest<ReduxAction>(types.GET_LIST, function*({ payload }) {
			const result = yield call(scheduleservice.GetList, payload.scheduleAreaIds, payload.startDate, payload.endDate);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.setList(result.data));
			}
		});
	}
};

////////
//actions
export const actions = {
	startBookingSync() {
		return {
			type: types.START_BOOKING_SYNC,
			payload: {}
		};
	},

	stopBookingSync() {
		return {
			type: types.STOP_BOOKING_SYNC,
			payload: {}
		};
	},

	isBusy(val: boolean) {
		return {
			type: types.IS_BUSY,
			payload: { val }
		};
	},

	setSummarys(summarys: ScheduleSummaryDto[]) {
		return {
			type: types.SET_SUMMARY_LIST,
			payload: { summarys }
		};
	},

	updateUserSchedules(userschedules: UserScheduleDto[]) {
		return {
			type: types.UPDATE_USER_SCHEDULES,
			payload: { userschedules }
		};
	},

	getList(startDate: Date, endDate: Date, scheduleAreaIds: string[]) {
		return {
			type: types.GET_LIST,
			payload: { startDate, endDate, scheduleAreaIds }
		};
	},

	getListInGroups(scheduleGroupIds: string[]) {
		return {
			type: types.GET_LIST_IN_GROUPS,
			payload: { scheduleGroupIds }
		};
	},

	setScheduleList(schedules: ScheduleDto[]) {
		return {
			type: types.SET_SCHEDULE_LIST,
			payload: { schedules }
		};
	},

	setList(schedules: { [areaId: string]: ScheduleDto[] }) {
		return {
			type: types.SET_LIST,
			payload: { schedules }
		};
	},

	mergeList(schedules: { [areaId: string]: ScheduleDto[] }) {
		return {
			type: types.MERGE_LIST,
			payload: { schedules }
		};
	},

	onError(data) {
		return {
			type: types.ON_ERROR,
			payload: data
		};
	}
};
