import * as React from "react";

import * as styles from "./styles.scss";
import * as mobilestyles from "./mobile.scss";
import * as desktopstyles from "./desktop.scss";
import { connect } from "react-redux";
import * as router from "react-router-redux";
import Spinner from "libs/spinner";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { ReduxState } from "config/reduxRoot";
import { AuthHoC } from "components/Auth/Auth";
import { DateTime, array, number, deepClone } from "utils";
import { actions as scheduleactions } from "redux/scheduleRedux";
import { actions as scheduleareaactions, types as scheduleareatypes } from "redux/scheduleAreaRedux";
import { ScheduleGroupDto, ScheduleDto, ScheduleAreaDto, BookingDto, PublicHolidayDto } from "redi-types";
import { actions as timeactions } from "redux/timeRedux";
import { actions as bookingactions } from "redux/bookingRedux";

import * as switchon from "assets/icons/switchon.png";

import { subscribeAction } from "boot/configureStore";
import { renderMobile } from "./BookingMobile";
import { renderDesktop } from "./BookingDesktop";
import scheduleiconservice from "utils/scheduleTypeIconService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip, { TooltipContent } from "components/Tooltip/tooltip";
import NewBooking from "../NewBooking/NewBooking";
import Button from "components/Button/Button";

import * as add from "assets/icons/add.png";
import * as swipecard from "assets/icons/card.png";

@CSSModules(styles, { allowMultiple: true })
class Booking extends React.PureComponent<Props, State> {
	actions: Actions;
	isMobile: boolean;
	renderMobile: any;
	renderDesktop: any;
	actionUnsub: () => void;
	timout: any;
	scrolled: boolean;
	scrollRef: React.RefObject<HTMLDivElement>;
	schedulesDays: Date[];
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			showPastSwipes: false,
			showNewBooking: false
		};

		this.actions = this.props.exposeActions(this);

		this.actions.startBookingSync();

		let scrolledFirstTime = false;
		this.actionUnsub = subscribeAction(scheduleareatypes.SET_LIST, action => {
			this.getSchedules(this.props.selectedDate, action.payload.scheduleAreas.map(x => x.scheduleAreaId));
			if (!scrolledFirstTime) {
				this.scrollToDay();
				scrolledFirstTime = true;
			}
		});

		if (this.props.currentGroup) {
			this.actions.getScheduleAreas(this.props.currentGroup.scheduleGroupId);
		}

		this.renderMobile = CSSModules(renderMobile.bind(this), mobilestyles);
		this.renderDesktop = CSSModules(renderDesktop.bind(this), desktopstyles);

		this.scrollRef = React.createRef<HTMLDivElement>();

		window.addEventListener("resize", this.onResize);
	}

	onResize() {
		if (this.timout) {
			clearTimeout(this.timout);
		}
		this.timout = setTimeout(() => {
			this.forceUpdate();
		}, 100);
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		//get schedules for new group
		if (prevProps.currentGroup !== this.props.currentGroup) {
			this.actions.getScheduleAreas(this.props.currentGroup.scheduleGroupId);
		}
		if (prevProps.selectedDate !== this.props.selectedDate) {
			this.scrollToDay();
		}
	}

	scrollToDay() {
		if (!this.isMobile) {
			if (this.scrollRef.current) {
				//scroll to day
				const index = this.schedulesDays.findIndex(x => x.getTime() === this.props.selectedDate.getTime());
				this.scrollRef.current.scrollTo(300 * (index - 1), this.scrollRef.current.scrollTop);
			}
		}
	}

	getSchedules(date: Date, scheduleIds: string[]) {
		//desktop gets all shedules for the week
		if (this.isMobile) {
			this.actions.getSchedules(date, DateTime.endOf("day", date), scheduleIds);
		} else {
			this.actions.getSchedules(DateTime.startOf("week", date), DateTime.endOf("week", date), scheduleIds);
		}
	}

	openAllAreas() {
		let days = deepClone(this.props.scheduleAreasByDay);
		let schedulesToGet = [];
		array.forEachProps(days, areas => {
			areas.forEach(x => {
				x.closed = false;
				schedulesToGet.push(x.scheduleAreaId);
			});
		});
		this.getSchedules(this.props.selectedDate, schedulesToGet);
		this.actions.setScheduleAreasByDayList(days);
	}

	closeAllAreas() {
		let days = deepClone(this.props.scheduleAreasByDay);
		array.forEachProps(days, areas => {
			areas.forEach(x => {
				x.closed = true;
			});
		});
		this.actions.setScheduleAreasByDayList(days);
	}

	toggleOpen(sch: ScheduleAreaDto) {
		if (!sch.schedules || !sch.schedules.length) {
			this.getSchedules(this.props.selectedDate, [sch.scheduleAreaId]);
		}

		let newschs = { ...this.props.scheduleAreasByDay };
		for (const key in newschs) {
			if (newschs.hasOwnProperty(key)) {
				const area = newschs[key];
				const index = area.findIndex(x => x === sch);
				if (index > -1) {
					newschs[key][index] = { ...sch, closed: !sch.closed };
					this.actions.setScheduleAreasByDayList(newschs);
					break;
				}
			}
		}
	}

	openBooking(booking: BookingDto): void {
		this.actions.setCurrentBooking(booking);
		this.actions.setSelectedDate(DateTime.startOf("day", booking.beginTimeLocal));
		this.setState({
			showNewBooking: true,
			isEdit: true,
			schedulesForBooking: booking.schedulesInGroup.length ? booking.schedulesInGroup : [booking.schedule]
		});
	}

	renderScheduleArea(area: ScheduleAreaDto, key: number, date = this.props.selectedDate) {
		const now = new Date();
		const numbookings = area.schedules
			? area.schedules.reduce((prev, next) => {
					if (this.state.showPastSwipes) {
						return prev + next.bookings.length;
					} else {
						return prev + next.bookings.filter(x => !x.isSwipeBooking || x.endTimeLocal >= now).length;
					}
			  }, 0)
			: 0;
		if (numbookings) {
			return (
				<div key={area.scheduleAreaId} styleName="area-wrap">
					<div styleName="area">
						<div styleName="sch-header" onClick={() => this.toggleOpen(area)}>
							<div styleName="flex1">{area.name}</div>
							<div styleName="circle">{numbookings}</div>
							{area.closed && <FontAwesomeIcon icon="chevron-right" />}
							{!area.closed && <FontAwesomeIcon icon="chevron-down" />}
						</div>
						{!area.closed &&
							area.schedules &&
							area.schedules.map((sch: ScheduleDto, sckey: number) => {
								const bookings = this.state.showPastSwipes
									? sch.bookings
									: sch.bookings.filter(x => !x.isSwipeBooking || x.endTimeLocal >= now);
								if (bookings.length) {
									return (
										<div key={sch.scheduleId}>
											<div styleName="schedule-header">
												{sch.name}
												{!this.props.isBusy && (
													<Button
														raised={false}
														styleName="small-add-btn"
														onClick={e => {
															e.stopPropagation();
															if (date.getTime() !== this.props.selectedDate.getTime()) {
																this.actions.setSelectedDate(date);
															}
															this.setState({ showNewBooking: true, currentSchedule: sch });
														}}
													>
														<img src={add} />
													</Button>
												)}
											</div>
											<div styleName="bookings-wrap">
												{bookings.map((booking: BookingDto, bookkey: number) => {
													return (
														<div
															key={booking.bookingId}
															styleName="booking"
															error={booking.status && booking.status === "Fault" ? 1 : undefined}
															isswipe={booking.isSwipeBooking.toString()}
															onClick={() => !booking.isSwipeBooking && this.openBooking(booking)}
														>
															<div styleName="icons-wrap">
																{scheduleiconservice.GetIcon(booking.scheduleType)}
															</div>
															<div styleName="bubblething">
																<div />
																<div />
																<div />
																<div />
																<div />
																<div />
															</div>
															<div styleName="times">
																<div
																	isday={DateTime.isSame("day", booking.beginTimeLocal, date).toString()}
																>
																	{DateTime.format(booking.beginTimeLocal, "h:mm a")}
																</div>
																<div isday={DateTime.isSame("day", booking.endTimeLocal, date).toString()}>
																	{DateTime.format(booking.endTimeLocal, "h:mm a")}
																</div>
															</div>
															<div styleName="repeats">
																{booking.repeats && (
																	<Tooltip>
																		<img src={switchon} />
																		<TooltipContent>This is a repeat Booking</TooltipContent>
																	</Tooltip>
																)}
															</div>
															<div styleName="name">
																{booking.isSwipeBooking ? (
																	<Tooltip>
																		<img src={swipecard} styleName="swipecard" />
																		<TooltipContent>{booking.cardId}</TooltipContent>
																	</Tooltip>
																) : (
																	booking.createdByName
																)}
															</div>
														</div>
													);
												})}
											</div>
										</div>
									);
								}
							})}
					</div>
				</div>
			);
		}
	}

	render() {
		this.isMobile = window.innerWidth < styles.small;

		return (
			<React.Fragment>
				{this.isMobile ? this.renderMobile() : this.renderDesktop()}
				{this.state.showNewBooking && (
					<NewBooking
						schedulesForBooking={this.state.schedulesForBooking}
						isEdit={this.state.isEdit}
						currentSchedule={this.state.currentSchedule}
						onClose={() => {
							//since modal never actually closes, remove the hash here
							this.actions.navigate(window.location.pathname);
							this.setState({ showNewBooking: false, isEdit: false, schedulesForBooking: null, currentSchedule: null });
						}}
					/>
				)}
			</React.Fragment>
		);
	}

	componentWillUnmount() {
		this.actionUnsub();
		window.removeEventListener("resize", this.onResize);
		this.actions.stopBookingSync();
	}
}

const bindAction = dispatch => ({
	exposeActions: (component: Booking) => {
		return {
			startBookingSync: () => dispatch(scheduleactions.startBookingSync()),
			stopBookingSync: () => dispatch(scheduleactions.stopBookingSync()),

			getSchedules: (start, end, areaid) => dispatch(scheduleactions.getList(start, end, areaid)),
			getScheduleAreas: groupid => dispatch(scheduleareaactions.getList(groupid)),
			setSelectedDate: date => dispatch(timeactions.setBookingTime(date)),

			setCurrentBooking: data => dispatch(bookingactions.setCurrentBooking(data)),

			setSchedulesList: (schedules: ScheduleDto[]) =>
				dispatch(scheduleactions.setList(array.groupBy(schedules, x => x.scheduleAreaId))),
			setScheduleAreasByDayList: scheduleareas => dispatch(scheduleareaactions.setScheduleAreasByDayList(scheduleareas)),

			navigate: (uri, data) => dispatch(router.push(uri, data)),
			goBack: () => dispatch(router.goBack())
		};
	}
});

const mapStateToProps = (state: ReduxState) => ({
	currentGroup: state.schedulegroup.currentGroup,
	scheduleAreas: state.scheduleArea.scheduleAreas,
	scheduleAreasByDay: state.scheduleArea.scheduleAreasByDay,
	selectedDate: state.time.bookingSelectedDay,
	datesWithBookings: state.schedulegroup.currentGroupBookingDates,
	publicHolidays: state.schedule.publicHolidays,

	isBusy: state.schedule.isBusy || state.scheduleArea.isBusy || state.schedulegroup.isBusy
});

export default AuthHoC(
	connect(
		mapStateToProps,
		bindAction
	)(Booking),
	{ roles: ["IBMS Administrator", "System Administrator", "User"] }
);

interface State {
	showPastSwipes: boolean;
	showNewBooking: boolean;
	isEdit?: boolean;
	schedulesForBooking?: ScheduleDto[];
	currentSchedule?: ScheduleDto;
}
interface Props {
	currentGroup: ScheduleGroupDto;
	isBusy: boolean;
	selectedDate: Date;
	publicHolidays: PublicHolidayDto[];
	datesWithBookings: Date[];
	scheduleAreas: ScheduleAreaDto[];
	scheduleAreasByDay: {
		[date: string]: ScheduleAreaDto[];
	};

	exposeActions: (c: Booking) => Actions;
}

interface Actions {
	startBookingSync: () => void;
	stopBookingSync: () => void;

	getSchedules: (start: Date, end: Date, areaid: string[]) => void;
	getScheduleAreas: (groupid: string) => void;
	setSelectedDate: (date: Date) => void;

	setCurrentBooking: (dd: BookingDto) => void;

	setSchedulesList: (schedules: ScheduleDto[]) => void;
	setScheduleAreasByDayList: (scheduleareas: { [date: string]: ScheduleAreaDto[] }) => void;

	navigate: (uri, data?) => void;
	goBack: () => void;
}

export type BookingComponentType = Booking;
