import * as React from "react";

import * as watericon from "assets/icons/water@2x.png";
import * as airconicon from "assets/icons/aircon@2x.png";
import * as equipmenticon from "assets/icons/equipment@2x.png";
import * as lightsicon from "assets/icons/lights@2x.png";
import * as meetingicon from "assets/icons/meeting@2x.png";
import * as signageicon from "assets/icons/signage@2x.png";
import * as dooricon from "assets/icons/doors@2x.png";
import { ScheduleTypes } from "redi-types";

export default class scheduleiconservice {
	static GetIcon(type: ScheduleTypes, props: any = {}) {
		const { key, ...rest } = props;
		switch (type) {
			case "Aircon":
				return <img src={airconicon} {...props} />;
			case "Aircon_and_Lighting":
				return (
					<React.Fragment key={key}>
						<img src={lightsicon} {...rest} />
						<img src={airconicon} {...rest} />
					</React.Fragment>
				);
			case "Building_Signage":
				return <img src={signageicon} {...props} />;
			case "Equipment":
				return <img src={equipmenticon} {...props} />;
			case "Lighting":
				return <img src={lightsicon} {...props} />;
			case "Meeting_Room":
				return <img src={meetingicon} {...props} />;
			case "Plant":
				return null; // <img src={} {...props}  />
			case "Water":
				return <img src={watericon} {...props} />;
			case "Access_Control":
				return <img src={dooricon} {...props} />;
		}
		return null;
	}
}
