import { http, DateTime } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";

const route = "Import/";

export default class importservice {
	static Import(file: File, fileName?: string) {
		let url = `${config.baseUrl + route}Import`;

		fileName = fileName || DateTime.format(new Date(), "hh_mm_ss_dd_MM_yyyy");

		const arr = file.name.split(".");
		const extension = "." + arr[(arr.length || 1) - 1];

		const name = fileName + extension;

		const headers = {
			"Content-Disposition": 'attachment; filename="' + file.name + '"'
		};

		return http({ url, method: "POST", fileName: name, data: new Blob([file]), headers }, false, null, false)
			.then(data => data)
			.catch(error => error);
	}
}
