import React, { PureComponent } from "react";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import styles from "./styles.scss";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import RadioButton from "components/Radio/radio";
import Select from "components/Select/select";
import { DateTime, TimeRange, array, shouldUpdate, MergeStyles } from "utils/index";
import TimeSliderImpl from "components/TimeSlider/timeslider";
import Checkbox from "components/Checkbox/checkbox";
import { Calendar, DateRangePicker } from "libs/react-date-range/index";
import Button from "components/Button/Button";
import MultiSelect, { MltiSelectAction } from "components/MultiSelect/multiselect";
import Radio from "components/Radio/radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FormMultiDropdown extends React.PureComponent {
	static defaultProps = {
		inputClass: "",
		actions: [], //array of MltiSelectAction
		titleTransform: undefined,
		titleClass: "",
		rootStyle: ""
	};

	render() {
		let {
			input,
			label,
			actions,
			meta: { touched, error },
			className,
			rootStyle,
			classes,
			...other
		} = this.props;

		const value = Array.isArray(input.value) ? input.value.slice() : input.value ? [input.value] : [];

		const selectactions = this.props.actions.map(x => {
			return new MltiSelectAction(x, value.some(f => f === x));
		});

		return (
			<div className={className} styleName="input-group">
				<div>{label}</div>
				<MultiSelect
					actions={selectactions}
					widthOfRoot={true}
					{...other}
					onChange={v => {
						if (Array.isArray(v)) {
							if (v.length) {
								//if an item is in v then remove it from value
								for (let index = v.length - 1; index >= 0; index--) {
									const item = v[index];
									const oi = value.indexOf(item);
									if (~oi) {
										v.splice(index, 1);
										value.splice(oi, 1);
									}
								}
								v = v.concat(value);
							}
							input.onChange(v);
						} else {
							const index = value.indexOf(v);
							if (~index) {
								value.splice(index, 1);
							} else {
								value.push(v);
							}
							input.onChange(value);
						}
					}}
					styleName={rootStyle}
					classes={{ ...styles, ...classes }}
					titleClass={`_form_select ${other.titleClass}`}
				/>
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	// shouldComponentUpdate(nextProps, nextState) {
	// 	return shouldUpdate(this, nextProps, nextState, ["titleTransform", "onBlur", "onChange"]);
	// }
}
export const RenderMultiDropdown = MergeStyles(styles, { forwardRef: false })(FormMultiDropdown);
