import * as React from "react";

import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";

import * as styles from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime, array, Time, number } from "utils";
import { NewBookingContext } from "../NewBooking";
import TimePickerDay, { TP_TimeRange, ElPos, insideEnd, insideStart, TP_DateRange, inside } from "./TimePickerDay";
import Button from "components/Button/Button";
import { ScheduleDto } from "redi-types";

const SNAP_MINS = 5; //snap every X mins

@CSSModules(styles, { allowMultiple: true })
export default class TimePicker extends React.PureComponent<Props, State> {
	static contextType = NewBookingContext;
	context: NewBookingContext;
	rootRef: React.RefObject<HTMLDivElement>;
	bookingEl: DragData;
	scrollRef: HTMLDivElement;
	days: Day[];
	input1: HTMLInputElement;
	input2: HTMLInputElement;
	invalidInput: boolean;

	constructor(props, context: NewBookingContext) {
		super(props);
		autoBind(this);
		//add to this.context so we can access in ctor
		this.context = context;

		const publicHolidays = array.removeDuplicates(
			array.selectMany(this.context.currentSchedulesForBooking || [], x => x.publicHolidays).filter(x => x),
			x => x.publicHolidayId
		);

		this.bookingEl = new DragData();

		this.days = array.fill(2, x => {
			const date = DateTime.addDays(this.context.selectedDate, x);
			let noBlockout = false;
			const pubHol = publicHolidays.find(x => x.date.getDate() === date.getDate() && x.date.getMonth() === date.getMonth());
			if (!!pubHol && this.context.currentSchedulesForBooking.every(x => !x.blockoutApplysOnPublicHoliday)) {
				noBlockout = true;
			}
			const blockout = noBlockout
				? null
				: genBlockout(this.context.currentSchedulesForBooking, DateTime.addDays(this.context.selectedDate, x));
			const day = new Day(date, blockout);
			return day;
		});
		const pos = this.days[0].calcPos({ start: this.context.bookingStartTime, end: this.context.bookingEndTime });

		this.state = {
			rootWidth: 350,
			rootHeight: 1010,
			bookingPos: pos,
			keyboardEditMode: false
		};

		this.rootRef = React.createRef<HTMLDivElement>();

		window.addEventListener("resize", this.onresize);
	} //end ctor

	componentDidMount() {
		const offset = this.days[0].offsetIfInBlockout(this.context.bookingStartTime, this.context.bookingEndTime);
		if (offset.bookingStartTime !== this.context.bookingStartTime || offset.bookingEndTime !== this.context.bookingEndTime) {
			this.context.setContext(offset);
			const pos = this.days[0].calcPos({ start: offset.bookingStartTime, end: offset.bookingEndTime });
			this.setState({ bookingPos: pos });
		}
	}

	onresize() {
		if (this.rootRef.current.clientWidth !== this.state.rootWidth || this.rootRef.current.clientHeight !== this.state.rootHeight) {
			this.setState({ rootWidth: this.rootRef.current.clientWidth, rootHeight: this.rootRef.current.clientHeight });
		}
	}

	onScrollRef(ref) {
		this.scrollRef = ref;
		if (this.scrollRef) {
			setTimeout(() => {
				if (this.bookingEl.ref.current) {
					const top = this.bookingEl.ref.current.offsetTop;
					this.scrollRef.scrollTo(0, top - this.scrollRef.clientHeight / 2);
				}
			});
		}
	}

	drag(
		e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
		flags: "move" | "dragstart" | "dragend" = "move",
		istouch = false
	) {
		if (this.state.keyboardEditMode) {
			//keyboard edit mode is on. disable drag
			return;
		}

		const elementDrag = (e: TouchEvent | MouseEvent) => {
			// calculate the new cursor position:
			e.preventDefault();
			e.stopPropagation();
			let touchPos: number;
			if (e instanceof MouseEvent) {
				this.bookingEl.accumulator += e.clientY - this.bookingEl.prevValue;
				this.bookingEl.prevValue = e.clientY;
				touchPos = e.clientY;
			} else if (e.touches) {
				this.bookingEl.accumulator += e.touches[0].clientY - this.bookingEl.prevValue;
				this.bookingEl.prevValue = e.touches[0].clientY;
				touchPos = e.touches[0].clientY;
			}

			// if (this.scrollRef.scrollTop > 0 && this.scrollRef.scrollTop < this.scrollRef.scrollHeight - this.scrollRef.clientHeight) {
			// 	this.bookingEl.accumulator *= 2;
			// }

			this.bookingEl.delta = Math.floor(this.bookingEl.accumulator / SNAP_MINS) * SNAP_MINS;

			if (this.bookingEl.delta !== 0) {
				let bookingStartTime = this.context.bookingStartTime;
				let bookingEndTime = this.context.bookingEndTime;

				const currentDay = this.days.find(x => {
					const date = this.bookingEl.delta > 0 ? bookingEndTime : bookingStartTime;
					return DateTime.isSame("day", date, x.date);
				});

				let offsetTop = 0;
				for (let index = 0; index < this.days.length; index++) {
					const day = this.days[index];
					if (day === currentDay) {
						break;
					} else {
						offsetTop += day.rootHeight;
					}
				}

				if (flags === "dragstart") {
					if (this.bookingEl.delta > 0) {
						//dont allow shrink booking less tahn 30 mins
						if (bookingEndTime.getTime() - bookingStartTime.getTime() - this.bookingEl.delta > 1800000) {
							bookingStartTime = DateTime.add("minute", bookingStartTime, this.bookingEl.delta);
						}
					} else {
						bookingStartTime = DateTime.add("minute", bookingStartTime, this.bookingEl.delta);
					}
					if (currentDay && currentDay.blockout && this.bookingEl.delta < 0) {
						const blockoutstart = currentDay.blockout.start.toDate(currentDay.date).getTime();
						const blockoutend = currentDay.blockout.end.toDate(currentDay.date).getTime();
						const millis = bookingStartTime.getTime();
						if (insideEnd({ v1: blockoutstart, v2: blockoutend }, { v1: millis, v2: 2000 })) {
							bookingStartTime = DateTime.add("milliseconds", bookingStartTime, blockoutend - millis);
						}
					}
				}
				if (flags === "dragend") {
					if (this.bookingEl.delta < 0) {
						//dont allow shrink booking less tahn 30 mins
						if (bookingEndTime.getTime() - bookingStartTime.getTime() + this.bookingEl.delta > 1800000) {
							bookingEndTime = DateTime.add("minute", bookingEndTime, this.bookingEl.delta);
						}
					} else {
						bookingEndTime = DateTime.add("minute", bookingEndTime, this.bookingEl.delta);
					}
					if (currentDay && currentDay.blockout && this.bookingEl.delta > 0) {
						const blockoutstart = currentDay.blockout.start.toDate(currentDay.date).getTime();
						const blockoutend = currentDay.blockout.end.toDate(currentDay.date).getTime();
						const millis = bookingEndTime.getTime();
						if (insideStart({ v1: blockoutstart, v2: blockoutend }, { v1: 0, v2: millis })) {
							bookingEndTime = DateTime.add("milliseconds", bookingEndTime, blockoutstart - millis);
						}
					}
				}

				if (flags === "move") {
					bookingStartTime = DateTime.add("minute", bookingStartTime, this.bookingEl.delta);
					bookingEndTime = DateTime.add("minute", bookingEndTime, this.bookingEl.delta);
					//if booking is moved inside blockout then move it to the other side of the blockout

					// if (currentDay) {
					// 	const offset = currentDay.offsetIfInBlockout(bookingStartTime, bookingEndTime);
					// 	bookingStartTime = offset.bookingStartTime;
					// 	bookingEndTime = offset.bookingEndTime;
					// }
				}
				if (currentDay) {
					const pos = currentDay.calcPos({ start: bookingStartTime, end: bookingEndTime }, offsetTop);

					this.context.setContext({ bookingStartTime, bookingEndTime });
					this.setState({ bookingPos: pos });
				}
				this.bookingEl.accumulator = 0;

				// if (this.bookingEl.ref.current) {
				// 	setTimeout(() => {
				// 		const top = this.bookingEl.ref.current.offsetTop + this.bookingEl.ref.current.clientHeight / 2;
				// 		this.scrollRef.scrollTo(0, top - this.scrollRef.clientHeight / 2);
				// 	});
				// }
			}
		};

		const closeDragElement = () => {
			if (istouch) {
				document.removeEventListener("touchmove", elementDrag);
				document.ontouchend = null;
			} else {
				/* stop moving when mouse button is released:*/
				document.removeEventListener("mousemove", elementDrag);
				document.removeEventListener("mouseup", closeDragElement);
			}

			if (istouch && this.scrollRef) {
				this.scrollRef.style.overflow = "";
				this.scrollRef.style.marginRight = "";
			}

			let bookingStartTime = this.context.bookingStartTime;
			let bookingEndTime = this.context.bookingEndTime;

			const currentDay = this.days.find(x => {
				const date = this.bookingEl.delta > 0 ? bookingEndTime : bookingStartTime;
				return DateTime.isSame("day", date, x.date);
			});

			if (currentDay) {
				let offsetTop = 0;
				for (let index = 0; index < this.days.length; index++) {
					const day = this.days[index];
					if (day === currentDay) {
						break;
					} else {
						offsetTop += day.rootHeight;
					}
				}

				const offset = currentDay.offsetIfInBlockout(bookingStartTime, bookingEndTime);
				bookingStartTime = offset.bookingStartTime;
				bookingEndTime = offset.bookingEndTime;

				const pos = currentDay.calcPos({ start: bookingStartTime, end: bookingEndTime }, offsetTop);

				this.context.setContext({ bookingStartTime, bookingEndTime });
				this.setState({ bookingPos: pos }, () => {
					if (this.bookingEl.ref.current) {
						const top = this.bookingEl.ref.current.offsetTop + this.bookingEl.ref.current.clientHeight / 2;
						this.scrollRef.scrollTo(0, top - this.scrollRef.clientHeight / 2);
					}
				});
			}
		};

		e.preventDefault();
		e.stopPropagation();

		if (istouch && this.scrollRef) {
			if (this.scrollRef.offsetWidth != this.scrollRef.clientWidth) {
				//add margin to replace scroll bar
				this.scrollRef.style.marginRight = this.scrollRef.offsetWidth - this.scrollRef.clientWidth + "px";
			}
			this.scrollRef.style.overflow = "hidden";
		}

		if (!istouch) {
			this.bookingEl.prevValue = (e as React.MouseEvent).clientY;
			document.addEventListener("mousemove", elementDrag);
			document.addEventListener("mouseup", closeDragElement);
		} else if ((e as React.TouchEvent).touches) {
			this.bookingEl.prevValue = (e as React.TouchEvent).touches[0].clientY;
			document.addEventListener("touchmove", elementDrag, { passive: false });
			document.ontouchend = closeDragElement;
		}
	}

	//keyboard edit code
	edit() {
		if (this.state.keyboardEditMode) {
			this.setState({ keyboardEditMode: false });

			let bookingStartTime = this.context.bookingStartTime;
			let bookingEndTime = this.context.bookingEndTime;

			const currentDay = this.days.find(x => {
				return DateTime.isSame("day", bookingStartTime, x.date);
			});

			const begin = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.exec(this.input1.value);
			const end = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.exec(this.input2.value);

			if (currentDay && begin && end) {
				let offsetTop = 0;
				for (let index = 0; index < this.days.length; index++) {
					const day = this.days[index];
					if (day === currentDay) {
						break;
					} else {
						offsetTop += day.rootHeight;
					}
				}

				const startTime = {
					hour: parseInt(begin[1], 10),
					min: parseInt(begin[2], 10),
					total: parseInt(begin[1] + begin[2], 10)
				};

				const endTime = {
					hour: parseInt(end[1], 10),
					min: parseInt(end[2], 10),
					total: parseInt(end[1] + end[2], 10)
				};

				const beginDayIndex = this.days.indexOf(currentDay);
				const sameDay = startTime.total <= endTime.total;
				if (!sameDay && beginDayIndex === this.days.length - 1) {
					//at last day, cannot go off end
					this.invalidInput = true;
					return;
				}

				bookingStartTime = new Date(
					bookingStartTime.getFullYear(),
					bookingStartTime.getMonth(),
					bookingStartTime.getDate(),
					startTime.hour,
					startTime.min,
					0,
					0
				);

				if (sameDay) {
					bookingEndTime = bookingStartTime;
				} else {
					bookingEndTime = DateTime.addDays(bookingStartTime, 1);
				}

				bookingEndTime = new Date(
					bookingEndTime.getFullYear(),
					bookingEndTime.getMonth(),
					bookingEndTime.getDate(),
					endTime.hour,
					endTime.min,
					0,
					0
				);

				if (DateTime.diff("minute", bookingStartTime, bookingEndTime) < 30) {
					//cannot be less than 30 mins
					this.invalidInput = true;
					return;
				}

				if (sameDay) {
					const offset = currentDay.offsetIfInBlockout(bookingStartTime, bookingEndTime);
					bookingStartTime = offset.bookingStartTime;
					bookingEndTime = offset.bookingEndTime;
				} else {
					let offset = currentDay.offsetIfInBlockout(bookingStartTime, bookingEndTime);
					bookingStartTime = offset.bookingStartTime;
					offset = this.days[beginDayIndex + 1].offsetIfInBlockout(bookingStartTime, bookingEndTime);
					bookingEndTime = offset.bookingEndTime;
				}

				const pos = currentDay.calcPos({ start: bookingStartTime, end: bookingEndTime }, offsetTop);

				this.context.setContext({ bookingStartTime, bookingEndTime });
				this.setState({ bookingPos: pos }, () => {
					if (this.bookingEl.ref.current) {
						const top = this.bookingEl.ref.current.offsetTop + this.bookingEl.ref.current.clientHeight / 2;
						this.scrollRef.scrollTo(0, top - this.scrollRef.clientHeight / 2);
					}
				});
			} else {
				this.invalidInput = true;
			}
		} else {
			this.setState({ keyboardEditMode: true });
		}
	}

	componentDidUpdate() {
		this.invalidInput = false;
	}

	inputKeypress(event) {
		if (this.state.keyboardEditMode) {
			switch (event.key) {
				case "Tab":
				case "Enter":
					event.preventDefault();
					this.edit();
					break;
			}
		}
	}

	onInputRef(ref: HTMLInputElement, start: boolean) {
		if (start) {
			this.input1 = ref;
			if (this.input1) {
				this.input1.value = DateTime.format(this.context.bookingStartTime, "HH:mm");
			}
		} else {
			this.input2 = ref;
			if (this.input2) {
				this.input2.value = DateTime.format(this.context.bookingEndTime, "HH:mm");
			}
		}
	}

	render() {
		let style: React.StyleHTMLAttributes<HTMLDivElement> = {
			top: this.state.bookingPos.start
		};
		if (this.state.keyboardEditMode) {
			style.minHeight = Math.max(102, this.state.bookingPos.height);
		} else {
			style.height = this.state.bookingPos.height;
		}
		return (
			<div styleName="scroller" ref={this.onScrollRef}>
				<div styleName="root" ref={this.rootRef}>
					{this.days.map((x, i) => {
						return x.render({ key: i });
					})}

					<div
						style={style}
						styleName="booking floatelem"
						ref={this.bookingEl.ref}
						onMouseDown={this.drag}
						onTouchStart={e => this.drag(e, "move", true)}
					>
						<div>
							{this.state.keyboardEditMode ? (
								<div styleName="textinput">
									<div styleName="header">
										<div>From</div>
										<div>To</div>
									</div>
									<div styleName="inputwrap">
										<input size={1} ref={ref => this.onInputRef(ref, true)} type="time" />
										<span> > </span>
										<input
											size={1}
											ref={ref => this.onInputRef(ref, false)}
											type="time"
											onKeyDown={this.inputKeypress}
										/>
									</div>
									<div styleName="btn-wraps">
										<Button raised={false} color="#fff" onClick={() => this.setState({ keyboardEditMode: false })}>
											Cancel
										</Button>
										<Button raised={false} color="#fff" onClick={this.edit}>
											Ok
										</Button>
									</div>
								</div>
							) : (
								<React.Fragment>
									<span>{`${DateTime.format(this.context.bookingStartTime, "hh:mm a")} > ${DateTime.format(
										this.context.bookingEndTime,
										"hh:mm a"
									)}`}</span>
									{this.invalidInput && <div>Invalid input</div>}
								</React.Fragment>
							)}
							{!this.state.keyboardEditMode && (
								<div
									styleName="knob knob1"
									onMouseDown={e => this.drag(e, "dragstart")}
									onTouchStart={e => this.drag(e, "dragstart", true)}
								>
									<div>
										<FontAwesomeIcon icon="bars" />
									</div>
								</div>
							)}
							{!this.state.keyboardEditMode && (
								<div
									styleName="knob knob2"
									onMouseDown={e => this.drag(e, "dragend")}
									onTouchStart={e => this.drag(e, "dragend", true)}
								>
									<div>
										<FontAwesomeIcon icon="bars" />
									</div>
								</div>
							)}
							{!this.state.keyboardEditMode && (
								<div styleName="knob pencil" onClick={this.edit} tabIndex={-1}>
									<div>
										<FontAwesomeIcon icon="pencil-alt" />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.onresize);
	}
}

interface State {
	rootWidth: number;
	rootHeight: number;
	bookingPos: ElPos;
	keyboardEditMode: boolean;
}
interface Props {}

class DragData {
	delta: number;
	accumulator: number;
	pos: any;
	ref: React.RefObject<HTMLDivElement>;
	prevValue: number;
	constructor() {
		this.delta = 0;
		this.accumulator = 0;
		this.prevValue = 0;
		this.pos = null;
		this.ref = React.createRef<HTMLDivElement>();
	}
}

export function genBlockout(schedules: ScheduleDto[], day: Date) {
	if(!schedules) {
		return null;
	}

	const blockout = schedules
		.map(x => {
			if(x.blockOutTimes == undefined) {
				return (x as any).schedules[0].blockOutTimes.days[day.getDay()];
			}
			return x.blockOutTimes.days[day.getDay()]
		})
		.reduce((prev, next) => {
			if (!prev) {
				if (next && next.start) {
					return { start: next.start, end: next.end };
				} else {
					return null;
				}
			} else {
				const d = { start: next.start, end: next.end };
				return { start: DateTime.min(d.start, prev.start), end: DateTime.max(d.end, prev.end) };
			}
		}, null) as TP_TimeRange;
	return blockout;
}

export class Day {
	date: Date;
	blockout: TP_TimeRange;
	rootHeight: number;
	blockoutPos: ElPos;
	constructor(date: Date, blockout: TP_TimeRange) {
		this.date = date;
		this.blockout = blockout;
		this.rootHeight = 1010;
		this.changeRootHeight = this.changeRootHeight.bind(this);
		this.calcBlockoutPos();
	}

	private changeRootHeight(heihght) {
		this.rootHeight = heihght;
	}

	calcBlockoutPos() {
		let blockoutStart = 0;
		let blockoutend = 0;
		let blockoutHeight = 0;
		if (this.blockout) {
			blockoutStart = (this.rootHeight / 86400) * this.blockout.start.totalSeconds();
			blockoutend = (this.rootHeight / 86400) * this.blockout.end.totalSeconds();
			blockoutHeight = blockoutend - blockoutStart;
		}
		this.blockoutPos = { height: blockoutHeight, start: blockoutStart, end: blockoutend };
	}

	calcPos(booking: TP_DateRange, offset = 0): ElPos {
		let bookingStart = (this.rootHeight / 86400) * DateTime.diff("second", this.date, booking.start);
		let bookingend = (this.rootHeight / 86400) * DateTime.diff("second", this.date, booking.end);
		let bookingHeight = bookingend - bookingStart;
		this.calcBlockoutPos();
		return { height: bookingHeight, start: bookingStart + offset, end: bookingend + offset };
	}

	offsetIfInBlockout(bookingStartTime: Date, bookingEndTime: Date, forwardOnly?: boolean) {
		if (this.blockout) {
			const blockoutStart = this.blockout.start.toDate(bookingStartTime).getTime();
			const blockoutend = this.blockout.end.toDate(bookingStartTime).getTime();
			const bookingStart = bookingStartTime.getTime();
			const bookingend = bookingEndTime.getTime();

			const half = (blockoutStart + blockoutend) / 2;

			if (insideStart({ v1: blockoutStart, v2: half }, { v1: bookingStart, v2: bookingend })) {
				const endDiff = blockoutend - bookingStart;
				bookingStartTime = DateTime.add("milliseconds", bookingStartTime, endDiff);
				bookingEndTime = DateTime.add("milliseconds", bookingEndTime, endDiff);
			} else if (!forwardOnly && insideEnd({ v1: half, v2: blockoutend }, { v1: bookingStart, v2: bookingend })) {
				const endDiff = bookingend - blockoutStart;
				bookingStartTime = DateTime.add("milliseconds", bookingStartTime, -endDiff);
				bookingEndTime = DateTime.add("milliseconds", bookingEndTime, -endDiff);
			} else if (inside({ v1: blockoutStart, v2: blockoutend }, { v1: bookingStart, v2: bookingend })) {
				const endDiff = blockoutend - bookingStart;
				bookingStartTime = DateTime.add("milliseconds", bookingStartTime, endDiff);
				bookingEndTime = DateTime.add("milliseconds", bookingEndTime, endDiff);
			}
		}
		return { bookingStartTime, bookingEndTime };
	}

	render(props: any) {
		return (
			<TimePickerDay
				{...props}
				onSetRootHeight={this.changeRootHeight}
				rootHeight={this.rootHeight}
				blockout={this.blockout}
				blockoutPos={this.blockoutPos}
				selectedDate={this.date}
			/>
		);
	}
}
