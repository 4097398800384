import reducer, { rootSaga, ReduxState } from "config/reduxRoot";
import createHistory from "history/createBrowserHistory";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, createStore, Store } from "redux";
import { persistStore, Persistor } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { deleteExpiredPersistsAsync, persist } from "utils/index";
import sendError from "./sendError";
import { persistor, types as setuptypes } from "./setupRedux";
import { ReduxAction } from "redi-types";

export const history = createHistory();

let store: Store<ReduxState> = null;

let actionCallbacks: ActionCallbacks = {};

const persistConfig = {
	whitelist: ["router", "form"],
	dontClearKeysOnReset: ["router"]
};

export default async function configureStore(): Promise<{
	persistor?: Persistor;
	store?: Store<ReduxState>;
	history?: History;
}> {
	return new Promise(async function(resolve) {
		//middlewares
		//router
		const routerMiddlewareFunc = routerMiddleware(history);

		//saga
		const sagaMiddleware = createSagaMiddleware();

		//logger
		const logger = () => next => action => {
			if (action.payload && action.payload.pathname) {
				console.log(`Redux: ${action.type}   Route: ${action.payload.pathname}`);
			} else {
				console.log(`Redux: ${action.type}`);
			}
			next(action);
		};

		const actionEvent = state => next => (action: ReduxAction) => {
			if (action.type in actionCallbacks) {
				actionCallbacks[action.type].forEach(x => x(action));
			}
			next(action);
		};

		//send errors to backend
		const errors = state => next => action => {
			next(action);
			//ends with FAILED
			if (/FAILED$|ERROR$/.test(action.type)) {
				sendError(state.getState(), action, "WebApp error");
			}
		};

		const persistedReducer = persist("root", reducer, persistConfig);

		// Create Redux Store
		store = createStore(
			persistedReducer.root,
			undefined,
			applyMiddleware(routerMiddlewareFunc, actionEvent, sagaMiddleware, logger, errors)
		);

		await deleteExpiredPersistsAsync();

		const loca = Object.assign({}, window.location);

		// Persist Redux Store to storage.
		persistor.persistor = persistStore(store, null, () => {
			const rehydratedpersists = store.getState().setup.$persist;
			const rootpersist = rehydratedpersists.find(x => x && "router" in x);

			//run saga
			sagaMiddleware.run(rootSaga);

			store.dispatch({
				type: setuptypes.STORE_URI,
				payload: {
					uri: loca.pathname,
					params: rootpersist && rootpersist.router && rootpersist.router.location ? rootpersist.router.location.state : undefined
				}
			});
			resolve({ store, persistor: persistor.persistor, history });
		});

		// if (config.enviroment === "DEV") {
		// 	persistor.purge();
		// }
	});
}

//if code changed update state layout accordingly. prevent old persist clashing
// if (module.hot) {
// 	module.hot.accept(() => {
// 		const newReducer = persist("root", rootReducer, {}, persistConfig);
// 		store.replaceReducer(newReducer.root);
// 	});
// }

/**
 * Called on state change. Returns unsubscribe function
 * @param {function} func
 */
export function subscribeState(func: (state: ReduxState) => void) {
	return store.subscribe(() => {
		func(store.getState());
	});
}

export function getState<R>(selector: (s: ReduxState) => R = state => state as any) {
	return selector(store.getState());
}

/**Subscribe to redux action types. Returns an unsubscribe function */
export function subscribeAction(reduxType: string | string[], action: (arg?: ReduxAction) => void) {
	if (Array.isArray(reduxType)) {
		reduxType.forEach(x => {
			actionCallbacks[x] = actionCallbacks[x] || [];
			actionCallbacks[x].push(action);
		});
	} else {
		actionCallbacks[reduxType] = actionCallbacks[reduxType] || [];
		actionCallbacks[reduxType].push(action);
	}
	let subbed = true;
	return function() {
		if (subbed) {
			if (Array.isArray(reduxType)) {
				reduxType.forEach(x => {
					actionCallbacks[x].splice(actionCallbacks[x].findIndex(x => x === action), 1);
				});
			} else {
				actionCallbacks[reduxType].splice(actionCallbacks[reduxType].findIndex(x => x === action), 1);
			}
			subbed = false;
		}
	};
}

export function dispatch(action: ReduxAction) {
	store.dispatch(action);
}

interface ActionCallbacks {
	[reduxType: string]: Array<(arg?: ReduxAction) => void>;
}
