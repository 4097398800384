import * as React from "react";
import * as router from "react-router-redux";
import { connect } from "react-redux";
import { actions as loginactions } from "redux/loginRedux";
import * as CSSModules from "react-css-modules";

import * as styles from "./styles.scss";
import Button from "components/Button/Button";
// import logo from "assets/logo_momentum.png";
import Menu, { MenuAction } from "components/PopupMenu/menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as menubars from "assets/icons/menu_bars.png";
import { ReduxState } from "config/reduxRoot";
import { actions as schedulegroupactions } from "redux/scheduleGroupRedux";
import { ScheduleGroupDto } from "redi-types";
import InlineSelect from "components/InlineSelect/InlineSelect";
import Tabs, { TabAction } from "components/Tabs/Tabs";
import * as logo from "assets/icons/avani-white-logo.png";
import { checkAuth } from "components/Auth/Auth";

@CSSModules(styles, { allowMultiple: true })
class Header extends React.PureComponent<Props, State> {
	actions: Actions;
	desktopTabActions: TabAction[];
	constructor(props) {
		super(props);

		this.actions = this.props.exposeActions(this);
		this.state = {
			showReloadBar: false,
			swEvent: null
		};

		this.desktopTabActions = [
			new TabAction("Home", () => this.actions.navigate("/Booking"), val => val === "/Booking"),
			new TabAction(
				"Reports",
				() => this.actions.navigate("/Reports"),
				val => val === "/Reports",
				() => checkAuth(["IBMS Administrator", "System Administrator"])
			),
			new TabAction(
				"Users",
				() => this.actions.navigate("/User/Admin"),
				val => val === "/User/Admin",
				() => checkAuth(["IBMS Administrator", "System Administrator"])
			),
			new TabAction(
				"Notifications",
				() => this.actions.navigate("/Notifications"),
				val => val === "/Notifications",
				() => checkAuth(["IBMS Administrator", "System Administrator"])
			),
			new TabAction("Account", () => this.actions.navigate("/User/Account"), val => val === "/User/Account"),
			new TabAction("Logout", () => this.actions.logout(), val => false)
		];
	}

	componentDidMount() {
		//add a listener to the service worker here
		//we will show a reload bar below the header if site needs reloading
		if ("serviceWorker" in navigator) {
			// Handler for messages coming from the service worker
			navigator.serviceWorker.addEventListener("message", event => {
				const message = JSON.parse(event.data);
				console.log("Client 1 Received Message: ", message);
				if (message && message.type === "refresh" && !this.state.showReloadBar) {
					this.setState({ showReloadBar: true, swEvent: event });
				} else if (message.type === "onskipwaiting") {
					setTimeout(() => {
						location.reload();
					});
				}
			});
		}
	}

	render() {
		const isMobile = window.innerWidth < 960;

		return (
			<div styleName="header">
				{this.props.isLoggedIn && (
					<div styleName="header-content">
						{this.props.toggleMenu && isMobile && this.props.isLoggedIn && (
							<div styleName="menubtn" onClick={this.props.toggleMenu}>
								<img src={menubars} />
							</div>
						)}
						{!isMobile && (
							<div styleName="logo">
								<img src={logo} />
								<div>Revata Schedules</div>
							</div>
						)}
						<div styleName="main">
							{this.props.isLoggedIn && this.props.route.startsWith("/Booking") && (
								<InlineSelect
									onChange={this.actions.setCurrentGroup}
									actions={this.props.scheduleGroups}
									value={this.props.currentScheduleGroup}
									titleTransform={x => x && (x.siteName + ": " + x.name)}
									maxMenuHeight={133}
								/>
							)}
						</div>
						{!isMobile && this.props.isLoggedIn && (
							<Tabs
								actions={this.desktopTabActions}
								value={this.props.route}
								styleName="tabs"
								tabClass="tabsclass"
								classes={styles}
							/>
						)}
					</div>
				)}
				{this.state.showReloadBar && (
					<div
						styleName="reload-bar"
						onClick={() => {
							if ("serviceWorker" in navigator && this.state.swEvent) {
								//tell new sw to become main sw
								this.state.swEvent.ports[0].postMessage("skipwaiting");
							} else {
								location.reload(true);
							}
						}}
					>
						New content is available. Click here to reload.
					</div>
				)}
			</div>
		);
	}
}

const bindAction = dispatch => {
	return {
		exposeActions: component => {
			return {
				setCurrentGroup: group => dispatch(schedulegroupactions.setCurrentGroup(group)),

				logout: () => dispatch(loginactions.logout()),
				navigate: (uri, data) => dispatch(router.push(uri, data)),
				goBack: () => dispatch(router.goBack())
			};
		}
	};
};

const mapStateToProps = (state: ReduxState) => ({
	currentScheduleGroup: state.schedulegroup.currentGroup,
	scheduleGroups: state.schedulegroup.groups,

	route: state.router.location.pathname,
	isLoggedIn: state.login.isLoggedIn,
	currentDetails: state.login.currentDetails,
	currentUsername: state.login.currentUsername
});

export default connect(
	mapStateToProps,
	bindAction
)(Header);

interface Props {
	height?: number;
	width?: number;
	toggleMenu?: () => void;
	isLoggedIn?: boolean;
	route: string;

	currentScheduleGroup: ScheduleGroupDto;
	scheduleGroups: ScheduleGroupDto[];

	exposeActions: (component) => Actions;
}

interface State {
	showReloadBar: boolean;
	swEvent: MessageEvent;
}

interface Actions {
	setCurrentGroup: (group: ScheduleGroupDto) => void;

	logout: () => void;
	navigate: (uri: string, data?: any) => void;
	goBack: () => void;
}
