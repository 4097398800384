import securityservice from "services/security/security";
import databaseservice from "./database";
import config from "config/config";
import * as uuid4 from "uuid/v4";
import { deepClone, number, Time } from "utils";
import { HttpUtils, IHttpObject } from "utils/http";
import { OfflineHttpRecord } from "./typings";
const MeWorker = require("services/offline/workers/httprequest.worker.js");

export default class offlinerequest {
	static tableName = "httpRequest";
	static httpRequestWorker: Worker = null;

	static initialize() {
		//create the worker
		this.httpRequestWorker = new MeWorker();
		this.httpRequestWorker.onmessage = e => {
			switch (e.data[0]) {
				default:
					throw "unknown command sent from offline request web worker";
					break;
			}
		};

		this.startWorker();
	}

	private static startWorker() {
		this.httpRequestWorker.postMessage(["start", config.indexedDbName, databaseservice.dbVersion]);
	}

	static stopWorker() {
		this.httpRequestWorker.postMessage(["stop"]);
	}

	/**
		url: string required - the url to make the request to.
		method: string required - the http request method.
		params: object optional - name-value pairs of request params. Default null.
		data: object optional - request content. Default null.
		requestId: string optional - the database id of theis request. Useful to set if expecting to update entries and need a handle on it. Must be unique(i.e guid). Default - guid is generated 
		RETURNS: promise. resolved if request is successfully added to database, else rejects with reason.
  */
	static saveRequest(httpObject: IHttpObject, requestId?: string, addNullToParams: boolean = false, timeoutms: number = 15000) {
		let { url, method, data, headers, ...params } = httpObject;

		let token = localStorage.getItem(config.tokenKey);
		let setHeaders: any = headers || {
			Accept: "application/json",
			"Content-Type": "application/json"
		};

		if (token) {
			setHeaders.Authorization = "Bearer " + token;
		}

		let querystring: string = null;
		if (!HttpUtils.isEmpty(params)) {
			const querys = HttpUtils.serialize(params, addNullToParams);
			if (querys) {
				querystring = querys;
			}
		}

		let body: any = null;
		if (data) {
			if (typeof data === "string" || data instanceof FormData || data instanceof Blob || data instanceof ArrayBuffer) {
				//dont stringify a string or certian objects
				body = data;
			} else {
				body = JSON.stringify(HttpUtils.convertDate(deepClone(data), true), function(key, val) {
					if (val instanceof Time) {
						//str time compatable with .NET TimeSpan
						return `${HttpUtils.minDigits(val.hours, 2)}:${HttpUtils.minDigits(val.mins, 2)}:${HttpUtils.minDigits(val.secs, 2)}`;
					} else {
						return val;
					}
				});
			}
		}

		return new Promise((resolve, reject) => {
			if (!url) {
				reject("Must supply url creating offline request");
			} else if (!method) {
				reject("Must supply request method creatign offline request");
			} else {
				//create the record
				let queueRecord: OfflineHttpRecord = {
					url: url,
					method: method,
					querystring: querystring,
					data: body,
					timeout: timeoutms,
					requestId: requestId ? requestId : uuid4(),
					retryCount: 0,
					headers: setHeaders
				};
				databaseservice.upsert(this.tableName, queueRecord, true).then(x => {
					//tell thread to upload now
					this.httpRequestWorker.postMessage(["process"]);
					console.log("Offline Request Added -> " + url);
					resolve(x);
				}, reject);
			}
		});
	}
}
