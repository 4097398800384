//initial redux
import { put, call, takeLatest, fork, all, take, cancel, select } from "redux-saga/effects";
import config from "config/config";
import securityservice from "services/security/security";
import { types as setuptypes, actions as setupactions } from "boot/setupRedux";
import * as router from "react-router-redux";
import { persist, string } from "utils/index";
import { ReduxAction } from "redi-types";
import { ReduxState } from "config/reduxRoot";

interface CurrentDetails {
	"AspNet.Identity.SecurityStamp": string;
	DisplayName: string;
	EmailAddress: string;
	FirstName: string;
	LastName: string;
	Phone: string;
	SecurityQuestion: string;
	UserId: string;
	aud: string;
	exp: string;
	iss: string;
	nameid: string;
	nbf: string;
	role: string[];
	unique_name: string;
	RoleClaims?: string[];
	cliendId?: string;
}

const initialState = {
	loginFailReason: "",
	isBusy: false,
	isLoggedIn: false,
	currentUsername: "",
	currentDetails: {} as CurrentDetails,

	showForgotPass: false,
	resetPassSuccess: null,

	loaded: false,

	errors: []
};

const persistConf = {
	whitelist: ["roles", "currentUsername", "currentDetails"],
	expireInNumHours: 0 //dont expire
};

/////////
//types
export const types = {
	ON_LOAD: "login/ON_LOAD",

	LOGIN: "login/LOGIN",
	ON_LOGIN: "login/ON_LOGIN",
	ON_LOGIN_FAILED: "login/ON_LOGIN_FAILED",

	LOGOUT: "login/LOGOUT",
	ON_LOGOUT: "login/ON_LOGOUT",
	ON_LOGOUT_FAILED: "login/ON_LOGOUT_FAILED",

	STORE_DETAILS: "login/STORE_DETAILS",

	RESET_PASS: "login/RESET_PASS",
	ON_RESET_PASS: "login/ON_RESET_PASS",
	ON_RESET_PASS_FAILED: "login/ON_RESET_PASS_FAILED",

	CHANGE_PASS: "login/CHANGE_PASS",
	ON_CHANGE_PASS: "login/ON_CHANGE_PASS",
	ON_CHANGE_PASS_FAILED: "login/ON_CHANGE_PASS_FAILED"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction) {
		switch (action.type) {
			case types.ON_LOAD:
				return { ...state, loaded: true };

			case types.LOGIN:
			case types.LOGOUT:
			case types.RESET_PASS:
			case types.CHANGE_PASS:
				return { ...state, isBusy: true };
			case types.ON_LOGIN:
				return {
					...state,
					isLoggedIn: true,
					isBusy: false,
					loginFailReason: ""
				};

			case types.ON_LOGOUT:
				return {
					...state,
					isLoggedIn: false,
					currentUsername: action.payload.currentUsername,
					isBusy: false
				};

			case types.ON_RESET_PASS:
			case types.ON_CHANGE_PASS:
				return { ...state, isBusy: false };

			case types.STORE_DETAILS: {
				if (!Array.isArray(action.payload.data.role)) {
					action.payload.data.role = [action.payload.data.role];
				}
				return {
					...state,
					currentDetails: action.payload.data as CurrentDetails,
					currentUsername: action.payload.data.unique_name,
					isLoggedIn: true
				};
			}

			case types.ON_LOGOUT_FAILED:
			case types.ON_LOGIN_FAILED:
			case types.ON_RESET_PASS_FAILED:
			case types.ON_CHANGE_PASS_FAILED:
				return {
					...state,
					errors: state.errors.concat(action.payload).slice(-10),
					isBusy: false
				};

			default:
				return state;
		}
	}
};
export const reducer = persist("login", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([this.onLogout(), this.loginFlow(), this.onResetPass(), this.onchangePass()]);
	},

	*onLogout() {
		yield takeLatest<ReduxAction>(types.ON_LOGOUT, function*({ payload }) {
			yield put(router.push(config.defaultRoute));
		});
	},

	*loginFlow() {
		while (true) {
			//first check if token exists and is valid.
			const token = yield call(securityservice.checkTokenExists);
			let authTask;
			if (!token) {
				yield put(setupactions.clearState());
				//show login page
				yield put(actions.onLoad());

				//if no token, block on login call
				const { payload } = yield take(types.LOGIN);
				//on login send userpass to serv and set authorization accordingly
				//dont block on this. immediatly go to wait logout.
				authTask = yield fork(this.authorize.bind(this), payload.username, payload.password);
			} else {
				//fetch personal detials
				yield fork(this.initData, true);
				const currentUsername = yield select<ReduxState>(x => x.login.currentUsername);
				yield put(actions.onLogin(true, currentUsername));
				yield put(actions.onLoad());
			}
			//block on logout or login error.
			const action = yield take([types.LOGOUT, types.ON_LOGIN_FAILED]);
			if (action.type === types.LOGOUT) {
				if (authTask) {
					//cancel auth if its still running.
					yield cancel(authTask);
				}
				yield put(actions.onLogout());
			}
			yield call(this.clearBearer);
		}
	},

	//call with fork. dont add to root saga
	*authorize(user, password) {
		try {
			const token = yield call(securityservice.login, user, password);
			if (token.error) {
				throw token.error;
			} else {
				yield call(securityservice.storeBearer, token.data);
				yield call(this.initData, false);
				yield put(actions.onLogin(true, user));
			}
		} catch (error) {
			console.error(error);
			yield put(actions.genericAction(types.ON_LOGIN_FAILED, error || {}));
		}
	},

	*initData(logerror) {
		try {
			const details = yield call(securityservice.getDetails);
			if (details.error) {
				throw details.error;
			}
			yield put(actions.storeDetails(details.data));
		} catch (error) {
			if (logerror) {
				console.error(error);
				yield put(actions.genericAction(types.ON_LOGIN_FAILED, error));
			} else {
				throw error;
			}
		}
	},

	//call with fork. dont add to root saga
	*clearBearer() {
		try {
			yield call(() => securityservice.removeBearer());
		} catch (error) {
			console.log(error);
			yield put(actions.genericAction(types.ON_LOGOUT_FAILED, error));
		}
	},

	*onResetPass() {
		yield takeLatest<ReduxAction>(types.RESET_PASS, function*({ payload }) {
			let data = yield call(securityservice.ResetPassword, payload.user);
			if (data.error) {
				console.error(data.error);
				yield put(actions.genericAction(types.ON_RESET_PASS_FAILED, data.error));
			} else {
				yield put(actions.onResetPass());
			}
		});
	},

	*onchangePass() {
		yield takeLatest<ReduxAction>(types.CHANGE_PASS, function*({ payload }) {
			let data = yield call(securityservice.ChangePassword, payload.user);
			if (data.error) {
				console.error(data.error);
				yield put(actions.genericAction(types.ON_CHANGE_PASS_FAILED, data.error));
			} else {
				yield put(actions.onchangePass());
			}
		});
	}
};

////////
//actions
export const actions = {
	onLoad() {
		return {
			type: types.ON_LOAD,
			payload: {}
		};
	},

	resetPass(user) {
		return {
			type: types.RESET_PASS,
			payload: { user }
		};
	},
	onResetPass() {
		return {
			type: types.ON_RESET_PASS,
			payload: {}
		};
	},

	changePass(user) {
		return {
			type: types.CHANGE_PASS,
			payload: { user }
		};
	},
	onchangePass() {
		return {
			type: types.ON_CHANGE_PASS,
			payload: {}
		};
	},

	login(username, password) {
		return {
			type: types.LOGIN,
			payload: {
				username,
				password
			}
		};
	},
	storeDetails(data) {
		return {
			type: types.STORE_DETAILS,
			payload: { data }
		};
	},
	logout() {
		return {
			type: types.LOGOUT,
			payload: {}
		};
	},
	onLogout() {
		return {
			type: types.ON_LOGOUT,
			payload: {}
		};
	},
	onLogin(success, currentUsername) {
		return {
			type: types.ON_LOGIN,
			payload: {
				isLoggedIn: success,
				currentUsername
			}
		};
	},

	genericAction(type, data) {
		return {
			type,
			payload: data
		};
	}
};
