import * as React from "react";

/**
 * Use in shouldComponentUpdate() to check if any props or state changed.
 *
 * Returns true if any props or state differ that are not in the respective blacklists
 * @param {array} propBlacklist
 * @param {array} stateBlacklist
 */
export function shouldUpdate(component, nextProps, nextState, propBlacklist = [], stateBlacklist = []) {
	for (var prop in nextProps) {
		//					propBlacklist does NOT contain prop
		if (nextProps.hasOwnProperty(prop) && !~propBlacklist.indexOf(prop)) {
			if (nextProps[prop] !== component.props[prop]) {
				return true;
			}
		}
	}

	for (var prop in nextState) {
		//					stateBlacklist does NOT contain prop
		if (nextState.hasOwnProperty(prop) && !~stateBlacklist.indexOf(prop)) {
			if (nextState[prop] !== component.state[prop]) {
				return true;
			}
		}
	}

	return false;
}

/**
 * Merge multiple props into a single `initialValues` for redux form
 * @param Component
 * @param reinitializeOnPropChange Reinitilize when given prop changes
 * @param mergers Array of props to merge
 */
export function FormDefaultValues<P>(
	Component: new (props: P) => React.Component<{ initialValues: any } & any>,
	reinitializeOnPropChange: (props: P, prevProps: P) => boolean,
	mergers: Array<(props: P) => object>
) {
	class FormDefVals extends React.PureComponent<P, {}> {
		store: object[];
		vals: {};
		prevProps: Readonly<{ children?: React.ReactNode }> & Readonly<P>;

		genVals() {
			let vals = {};
			this.store = mergers.map(x => x(this.props as any));
			for (let index = 0; index < this.store.length; index++) {
				const item = this.store[index];
				vals = { ...vals, ...item };
			}
			this.vals = vals;
		}

		render() {
			if (!this.prevProps || reinitializeOnPropChange(this.props as any, this.prevProps)) {
				this.genVals();
			}
			this.prevProps = this.props;
			return <Component {...this.props} initialValues={this.vals} />;
		}
	}

	return FormDefVals;
}
