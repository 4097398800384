import React, { PureComponent, Component } from "react";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";
import { shouldUpdate, MergeStyles } from "utils/index";
import ShellPortal from "components/ShellPortal/ShellPortal";
import autoBind from "../../libs/react-autobind/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/**
 * Use:
		3 Dot menu:					<Popup actions={this.menuActions} classes={styles} />
 * 						
 * Or:
 * 	Click anywhere menu:					<Popup actions={this.menuActions} classes={styles}>
														<tr> etc
															...
														</tr>
													</Popup>
 */
@MergeStyles(styles)
export default class Popup extends Component {
	static defaultProps = {
		actions: [],
		zIndex: 5,

		menuWrapClass: "",
		menuClass: "",
		rowClass: ""
	};
	static propTypes = {
		actions: PropTypes.array.isRequired,
		zIndex: PropTypes.number,
		classes: PropTypes.object //style overrides
	};

	constructor(props) {
		super(props);
		autoBind(this);
		this.state = {
			open: false,
			pos: null
		};
		this.popup = React.createRef();
	}

	onChange(e, action) {
		e.stopPropagation();
		action.action(e);
		this.setState({ open: false });
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState);
	}

	click(e) {
		this.setState({ open: !this.state.open, pos: { x: e.clientX, y: e.clientY } });
	}

	close() {
		this.setState({ open: false });
	}

	render() {
		const actions = this.props.actions.filter(x => !x.condition || x.condition());
		if (!actions.length) {
			//hide element if all actions are filtered out
			return null;
		}

		let style = {};
		if (this.popup.current && this.state.pos) {
			const xLimit = this.state.pos.x + this.popup.current.clientWidth + 10;
			const yLimit = this.state.pos.y + this.popup.current.clientHeight + 10;
			style.top =
				yLimit > window.innerHeight ? this.state.pos.y - (yLimit - window.innerHeight) : this.state.pos.y;
			style.left =
				xLimit > window.innerWidth ? this.state.pos.x - (xLimit - window.innerWidth) : this.state.pos.x;
		}

		const kids = React.Children.map(this.props.children, x => {
			const existingClick = x.props.onClick;
			const clone = React.cloneElement(x, {
				onClick: e => {
					this.click(e);
					existingClick && existingClick(e);
				}
			});
			return clone;
		});

		return (
			<React.Fragment>
				{kids || <FontAwesomeIcon icon="ellipsis-v"onClick={this.click} className={this.props.className} styleName="_3dot"  />}
				<ShellPortal>
					<div
						styleName="close-area"
						onClick={this.close}
						open={this.state.open}
						style={{ zIndex: this.props.zIndex }}
					/>
					<div styleName="_root">
						<div
							styleName={`_menu-wrap ${this.props.menuWrapClass}`}
							open={this.state.open}
							style={{ zIndex: this.props.zIndex ? this.props.zIndex + 1 : undefined, ...style }}
							ref={this.popup}
						>
							<div styleName={`_menu ${this.props.menuClass}`}>
								{actions.map((action, i) => {
									const disabled = action.disabled && action.disabled();
									return (
										<div
											styleName={`_row ${this.props.rowClass}`}
											key={i}
											onClick={e => !disabled && this.onChange(e, action)}
											disabled={disabled}
										>
											{action.title}
										</div>
									);
								})}
								{!this.props.actions.length && (
									<div styleName={`_row ${this.props.rowClass}`} onClick={this.close}>
										<i styleName="none">None</i>
									</div>
								)}
							</div>
						</div>
					</div>
				</ShellPortal>
			</React.Fragment>
		);
	}
}

export class MenuAction {
	action = null;
	condition = null;
	title = "";
	disabled = null;

	constructor(title, action, condition = null, disabled = null) {
		this.action = action;
		this.title = title;
		this.condition = condition;
		this.disabled = disabled;
	}
}
