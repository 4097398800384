import React, { PureComponent } from "react";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import styles from "./styles.scss";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import RadioButton from "components/Radio/radio";
import Select from "components/Select/select";
import { DateTime, TimeRange, array, shouldUpdate, MergeStyles } from "utils/index";
import TimeSliderImpl from "components/TimeSlider/timeslider";
import Checkbox from "components/Checkbox/checkbox";
import { Calendar, DateRangePicker } from "libs/react-date-range/index";
import Button from "components/Button/Button";
import MultiSelect from "components/MultiSelect/multiselect";
import Radio from "components/Radio/radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FormCheckBox extends React.PureComponent {
	render() {
		let {
			input,
			label,
			meta: { touched, error },
			disabled,
			...other
		} = this.props;

		return (
			<div>
				<Checkbox checked={input.value} {...input} label={label} disabled={disabled} />
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}
}

class FormManyCheckBox extends React.PureComponent {
	render() {
		let {
			input,
			label,
			meta: { touched, error },
			disabled,
			...other
		} = this.props;

		const value = Array.isArray(input.value) ? input.value.slice() : input.value ? [input.value] : [];

		const checked = this.props.actions.map(x => value.some(f => f === x));

		return (
			<div>
				{this.props.actions.map((x, i) => {
					return (
						<Checkbox
							key={i}
							checked={checked[i]}
							onChange={x => {
								if (x) {
									value.push(this.props.actions[i]);
								} else {
									value.splice(value.indexOf(this.props.actions[i]), 1);
								}
								input.onChange(value.slice());
							}}
							label={x}
							disabled={disabled}
						/>
					);
				})}
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}
}

export const RenderCheckBox = MergeStyles(styles, { forwardRef: false })(FormCheckBox);
export const RenderMultiCheckBox = MergeStyles(styles, { forwardRef: false })(FormManyCheckBox);
