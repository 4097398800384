import * as React from "react";
import * as styles from "./styles.scss";
import * as PropTypes from "prop-types";
import * as CSSModules from "react-css-modules";
import { CommonComponentProps } from "redi-types";
import Spinner from "libs/spinner";
import { shouldUpdate, string } from "utils";
import * as theme from "config/theme/theme.scss";

const MAX_RETRY_COUNT = 4;

/**
 * <img/> wrapper the shows a spinner and retrys loading the image if fails
 */
@CSSModules(styles, { allowMultiple: true })
export default class ImageLoader extends React.Component<Props & React.ImgHTMLAttributes<HTMLImageElement>, State> {
	static defaultProps = {
		src: null,
		fallBackSrc: undefined,
		spinnerColor: theme.primaryColor,

		classes: null //styleName overrides
	};
	static propTypes = {
		src: PropTypes.string.isRequired,
		classes: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
			useFallback: false
		};

		this.load();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onClick"]);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.src !== this.props.src) {
			this.setState({ loaded: false, useFallback: false });
			this.load();
		}
	}

	/**
	 * try and load the url and show a spinner until it loads.
	 * retry if it fails, but only up till MAX_RETRY_COUNT
	 */
	load() {
		const img = new Image();
		let retryCount = 0;
		img.onload = () => {
			this.setState({ loaded: true });
		};

		img.onerror = () => {
			if (retryCount++ < MAX_RETRY_COUNT) {
				setTimeout(() => {
					img.src = this.props.src;
				}, 250);
			} else {
				this.setState({ loaded: true, useFallback: true });
			}
		};

		img.src = this.props.src;
	}

	render() {
		if (this.state.loaded) {
			const { classes, src, spinnerColor, fallBackSrc, ...rest } = this.props;
			return (
				<img
					{...rest}
					src={this.state.useFallback ? this.props.fallBackSrc : this.props.src}
					crossOrigin="anonymous"
					className={this.props.className}
					styleName="_imgl_"
				/>
			);
		} else {
			return (
				<div styleName="_imgl_center _imgl_" className={this.props.className}>
					<Spinner size={20} color={this.props.spinnerColor} />
				</div>
			);
		}
	}
}

interface Props extends CommonComponentProps {
	spinnerColor?: string;
	fallBackSrc?: string;
}

interface State {
	loaded: boolean;
	useFallback: boolean;
}
