import { persistReducer, createTransform, PersistPartial } from "redux-persist";
// import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
// import createExpirationTransform from "redux-persist-transform-expire";
import { DateTime } from ".";
import { CircularJSON } from "./json";
import { types as setuptypes } from "boot/setupRedux";
import { addMinutes } from "date-fns";
import { ReduxAction } from "redi-types";
import { Reducer } from "redux";
import config from "config/config";
import { any } from "prop-types";

/** https://github.com/rt2zz/redux-persist/blob/master/docs/api.md#type-persistconfig
 * {
 * version?: number, // the state version as an integer (defaults to -1)
 * blacklist?: Array<string>, // do not persist these keys
 * whitelist?: Array<string>, // only persist these keys
 * migrate?: (Object, number) => Promise<Object>,
 * transforms?: Array<Transform>,
 * throttle?: number, // ms to throttle state writes
 * keyPrefix?: string, // will be prefixed to the storage key
 * debug?: boolean, // true -> verbose logs
 * stateReconciler?: false | StateReconciler, // false -> do not automatically reconcile state
 * serialize?: boolean, // false -> do not call JSON.parse & stringify when setting & getting from storage
 * }
 */
export interface IPersistConfig<STATE = any, PERSIST_TRNSFRM_KEY extends keyof STATE = keyof STATE, VAL = STATE[PERSIST_TRNSFRM_KEY]> {
	whitelist?: Array<keyof STATE>;
	blacklist?: Array<keyof STATE>;
	expireInNumHours?: number;
	/**an array of keys to serialize as json with circular refs. Much slower tho */
	circularJson?: Array<keyof STATE>;
	/**dont remove these keys on STATE_CLEAR */
	dontClearKeysOnReset?: Array<keyof STATE>;
	/**Apply a transformation func to STATE values before persisting them in storage */
	persistTransform?: (key: PERSIST_TRNSFRM_KEY, val: VAL) => VAL;
	[x: string]: any;
}

interface PersistTypeInterface<T> {
	/**pass JS object parsed from storage to the type's ctor as the first arg in the args pack */
	passStateToCtor?: boolean;
	/**pass ctor arguments at arg number. (all prior args will recieve null) */
	passArgsAtPos?: number;
	/**a func that takes the JS object parsed from storage and returns an array that maps args to ctor. overrides `passStateToCtor` */
	ctorArgMap?: (item: T) => any[];
}

const defaultPersistConf = {
	whitelist: undefined,
	blacklist: undefined,
	expireInNumHours: 1,
	circularJson: undefined //an array of keys to serialize as json with circular refs. Much slower tho
};

const defaultPersistTypeConf = {
	passStateToCtor: false, //pass JS object parsed from storage to the type's ctor as the first arg in the args pack
	passArgsAtPos: 0 //pass ctor arguments at arg number. (all prior args will recieve null)
};

/**
 * Add an es6 class Type to the persist map.
 * If this class is persisted, it will be parsed from storage as this type.
 * 
 * `@persistType("TreePos")
	class TreePos { ...`

	or

	`TreePos = persistType("TreePos")(TreePos);`

	If the type needs arguments in the ctor, use `passStateToCtor` `args` or `ctorArgMap`
 * 
 * @param {string} id
 * @param {PersistTypeInterface} conf
 * @param {any} args Args to pass to the ctor when read from storage.
 */
export function persistType<T = any>(id: string, conf?: PersistTypeInterface<T>, ...args: Array<any>) {
	return (originalConstructor: new (...args: any[]) => T) => {
		return persistTypeImpl(id, originalConstructor, conf, ...args);
	};
}

function persistTypeImpl<T>(id: string, type: new (...args: any[]) => T, conf: PersistTypeInterface<T>, ...args: Array<any>) {
	conf = { ...defaultPersistTypeConf, ...conf };

	type.prototype._PERSIST_AS_TYPE = id;
	type.prototype._constructor = type.prototype.constructor;
	const oldProto = type.prototype;
	type = (function(...c_args: any[]): T {
		let item = new type.prototype._constructor(...c_args);
		item._PERSIST_AS_TYPE = type.prototype._PERSIST_AS_TYPE;
		return item;
	} as unknown) as new (...args: any[]) => T;
	oldProto.constructor = type.prototype.constructor;
	type.prototype = oldProto;
	if (persistTypes.find(x => x.type.prototype._PERSIST_AS_TYPE === id)) {
		throw new Error("A Type with id '" + id + "' has already been persisted");
	}
	persistTypes.push({ type, args, conf });
	return type;
}

/**
 * Automatically persist a given reducer. Returns the persisted reducer function ready for MergeReducers.
 * @param {string} name Name of prop in state/parent reducer
 * @param {function} reducer Reducer to persist
 * @param {IPersistConfig} config Config. See config options in this file
 */
export default function persist<STATE, RKEY extends string>(
	name: RKEY,
	reducer: (state: STATE, action: ReduxAction) => STATE,
	config?: IPersistConfig
) {
	config = { ...defaultPersistConf, ...config, serialize: false };

	const timeFactory = () => {
		return addMinutes(new Date(), config.expireInNumHours * 60).toISOString();
	};

	_persistKeys.push({ name: "persist_timeout:" + name, hours: config.expireInNumHours });

	const typesTransform = createTypesTransform(name, config);

	const transforms = [typesTransform];

	if (config.expireInNumHours > 0) {
		const expireTransform = createExpirationTransform(name, timeFactory, config);
		transforms.push(expireTransform);
	}
	if (config.persistTransform) {
		const transformTransform = createValueTransformTransform(name, config);
		transforms.push(transformTransform);
	}

	const persistConfig = {
		...config,
		key: name,
		storage: createStorage(config),
		transforms
	};

	const persistedReducer = (state: STATE & PersistPartial, action: ReduxAction) => {
		if (action.type === setuptypes.CLEAR_STATE) {
			//first get teh default state
			const defaultState = reducer(undefined, action) as STATE & PersistPartial;
			if (!defaultState._persist) {
				defaultState._persist = (state && state._persist) || { version: -1, rehydrated: true };
			}
			if (config.dontClearKeysOnReset) {
				config.dontClearKeysOnReset.forEach(x => {
					defaultState[x] = state[x];
				});
			}
			return defaultState;
		}
		return reducer(state, action);
	};

	return { [name]: persistReducer(persistConfig as any, persistedReducer) } as {
		[K in RKEY]: Reducer<STATE & PersistPartial, ReduxAction>
	};
}

//create a custom store so we can parse objects with circular references
function createStorage(config) {
	return {
		setItem: async function(key, val) {
			if (config.circularJson && config.circularJson.find(x => x === key)) {
				val = CircularJSON.stringify(val);
			} else {
				val = JSON.stringify(val);
			}
			await localStorage.setItem(key, val);
		},
		getItem: async function(key) {
			const item = await localStorage.getItem(key);
			if (config.circularJson && config.circularJson.find(x => x === key)) {
				return CircularJSON.parse(item);
			} else {
				return JSON.parse(item);
			}
		},
		removeItem: async function(key) {
			await localStorage.removeItem(key);
		}
	};
}

//create a timeout key whenever this reducer is fired.
function createExpirationTransform(name, timeFactory, config) {
	function inbound(state, key) {
		if (!state || /^[_\$@&]/.test(key)) {
			return state;
		}
		const newTime = timeFactory();
		localStorage.setItem("persist_timeout:" + name, newTime);
		return state;
	}

	function outbound(state, key) {
		return state;
	}

	return createTransform(inbound, outbound);
}

//transform a value before persisting it in storage
function createValueTransformTransform(name, config: IPersistConfig) {
	function inbound(state, key) {
		if (!state || /^[_\$@&]/.test(key)) {
			return state;
		}

		return config.persistTransform(key, state);
	}

	function outbound(state, key) {
		return state;
	}

	return createTransform(inbound, outbound);
}

//a transform that correctly unserializes Date types + any types recorded with persistType()
function createTypesTransform(name, config) {
	function inbound(state, key) {
		if (!state || /^[_\$@&]/.test(key)) {
			return state;
		}
		return state;
	}

	function outbound(state, key) {
		if (!state || /^[_\$@&]/.test(key)) {
			return state;
		}

		function checkItem(item: any) {
			//unserialize Date objects
			if (typeof item === "string" && DateTime.isDateString(item)) {
				item = new Date(item);
			} else if (typeof item === "object") {
				item = traverse(item);
			}
			return item;
		}

		function traverse(obj) {
			if (Array.isArray(obj)) {
				for (let index = 0; index < obj.length; index++) {
					const item = obj[index];
					obj[index] = traverse(item);
				}
			} else if (typeof obj === "object" && obj !== null) {
				obj = parseObjAsType(obj);

				for (var prop in obj) {
					if (obj.hasOwnProperty(prop)) {
						obj[prop] = checkItem(obj[prop]);
					}
				}
			} else {
				obj = checkItem(obj);
			}
			return obj;
		}

		return traverse(state);
	}

	return createTransform(inbound, outbound);
}

// traverse object and see if type has been registered with persistType
function parseObjAsType(state) {
	if (typeof state === "object" && state !== null) {
		let astype = null;
		if (state._PERSIST_AS_TYPE !== undefined) {
			astype = dynamicClass(state, state._PERSIST_AS_TYPE);
		}

		if (astype) {
			for (var prop in state) {
				if (state.hasOwnProperty(prop)) {
					const item = state[prop];
					astype[prop] = item;
				}
			}
			state = astype;
		}

		return state;
	}
}

function dynamicClass(state, id) {
	const item = persistTypes.find(x => x.type.prototype._PERSIST_AS_TYPE === id);
	if (item) {
		let args = item.args;
		if (item.conf.ctorArgMap) {
			args = item.conf.ctorArgMap(state).concat(args);
		} else if (item.conf.passStateToCtor) {
			args.unshift(state);
		}
		if (!isNaN(item.conf.passArgsAtPos)) {
			for (let index = 0; index < item.conf.passArgsAtPos; index++) {
				args.unshift(null);
			}
		}
		return new item.type(...args);
	} else {
		return null;
	}
}

let persistTypes: { conf: PersistTypeInterface<any>; args: any[]; type: new (...args) => any }[] = [];

const _persistKeys = [];

/**
 * Delete any persists that have not been updated within their givin time window
 */
export async function deleteExpiredPersistsAsync(deleteAll = false) {
	const version = localStorage.getItem("__persist_version");
	if (version && version != config.persistVersion) {
		console.log("Delete all persists", config.persistVersion);
		deleteAll = true;
	}
	localStorage.setItem("__persist_version", config.persistVersion);
	for (let index = 0; index < _persistKeys.length; index++) {
		const pKey = _persistKeys[index];
		const time = await localStorage.getItem(pKey.name);
		if (deleteAll || (pKey.hours > 0 && time && new Date(time) < new Date())) {
			const name = /\:.*/.exec(pKey.name);
			await localStorage.removeItem(pKey.name);
			await localStorage.removeItem("persist" + name);
			console.log("Purge redux: " + name);
		}
	}
}
