import React from "react";
import styles from "./styles.scss";
import { shouldUpdate, MergeStyles } from "utils/index";
import Calender from "components/Calendar/Calendar";

class FormCalander extends React.Component {
	static defaultProps = {};
	render() {
		let {
			input,
			label,
			maxDate,
			minDate,
			meta: { touched, error },
			classes,
			...other
		} = this.props;

		return (
			<div className={other.className} styleName="input-group">
				<div>{label}</div>
				<Calender
					{...other}
					value={input.value}
					onChange={d => {
						input.onChange(d);
					}}
					showSelectedDayBar={false}
					classes={{ ...styles, ...classes }}
				/>
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onBlur", "onChange"]);
	}
}

export const RenderCalender = MergeStyles(styles, { forwardRef: false })(FormCalander);
