import * as React from "react";
import * as PropTypes from "prop-types";
import * as CSSModules from "react-css-modules";
import * as styles from "./styles.scss";
import { MergeStyles, string, number } from "utils/index";
import { CommonComponentProps } from "redi-types";
import * as uuid from "uuid/v4";


export interface SwitchButtonProps extends CommonComponentProps {
	onChange: (checked: boolean) => void;
	checked: boolean;
	name?: string;
	label?: string;
	labelLeft?: string;
	id?: string;
	disabled?: boolean;
	size?: number;

	children?: JSX.Element;

	sliderClass?: string;
}

@MergeStyles(styles)
export default class SwitchButton extends React.PureComponent<SwitchButtonProps, {}> {
	static defaultProps = {
		size: 34,
		sliderClass: ""
	};

	id: string;
	constructor(props) {
		super(props);

		this.state = {
			checked: props.defaultChecked ? props.defaultChecked : props.checked
		};
		this.id = this.props.id || uuid();

		this.onToggle = this.onToggle.bind(this);
	}

	render() {
		const trnslate = `translateX(${this.props.size}px)`;
		let sliderStyle: React.StyleHTMLAttributes<HTMLDivElement> = {
			borderRadius: this.props.size
		};
		const knobStyle: React.StyleHTMLAttributes<HTMLDivElement> = {
			height: this.props.size,
			width: this.props.size,
			borderRadius: this.props.size
		};

		if (!this.props.children) {
			sliderStyle.height = this.props.size;
			sliderStyle.width = this.props.size * 2;
		}
		return (
			<div className={this.props.className} styleName="_switch_root">
				{this.props.labelLeft ? (
					<label styleName="_switch_text-left" htmlFor={this.id}>
						{this.props.labelLeft}
					</label>
				) : null}
				<label styleName="_switch_switch" style={{ height: this.props.size, width: this.props.size * 2 }}>
					<input
						onChange={this.onToggle}
						checked={this.props.checked}
						disabled={this.props.disabled}
						id={this.id}
						name={this.props.name}
						type="checkbox"
						value="1"
					/>
					<span styleName={`_switch_slider ${this.props.sliderClass}`} style={sliderStyle}>
						<span
							haschildren={(!!this.props.children).toString()}
							style={
								this.props.checked
									? {
											transform: trnslate,
											msTransform: trnslate,
											WebkitTransform: trnslate,
											...knobStyle
									  }
									: knobStyle
							}
						>
							{this.props.children}
						</span>
					</span>
				</label>
				{this.props.label ? (
					<label styleName="_switch_text" htmlFor={this.id}>
						{this.props.label}
					</label>
				) : null}
			</div>
		);
	}

	onToggle(evt) {
		this.props.onChange(!this.props.checked); // backward compatibility
	}
}

