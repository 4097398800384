import string from "utils/string";
import number from "./number";
import { PropertiesOfType } from "redi-types";

export default class {
	static groupBy<T, K extends string | number | symbol>(arr: T[], selector: (s: T) => K) {
		let grouped: { [J in K]?: T[] } = {};
		for (let index = 0; index < arr.length; index++) {
			const item = arr[index];
			const val = selector(item);
			grouped[val] = grouped[val] || [];
			grouped[val].push(item);
		}
		return grouped;
	}

	static groupByTransform<T, R, K extends string | number | symbol>(
		arr: T[],
		selector: (s: T) => K,
		transfrom: (a: T[], key?: string) => R
	) {
		const grouped = this.groupBy(arr, selector);
		const rtn = Object.keys(grouped).map(x => transfrom(grouped[x], x));
		return rtn;
	}

	static sort<T>(arr: T[], selector: (s: T) => any = x => x, reverse = false) {
		const dir = reverse ? -1 : 1;
		let copy = arr.slice();
		copy.sort((a, b) => {
			let aval = selector(a);
			let bval = selector(b);

			if (typeof aval === "boolean" || typeof bval === "boolean") {
				if (aval && !bval) {
					return -1 * dir;
				} else if (bval && !aval) {
					return 1 * dir;
				} else {
					return 0;
				}
			} else if (aval instanceof Sortable) {
				return aval.sort(bval) * dir;
			} else if (bval instanceof Sortable) {
				return bval.sort(aval) * dir;
			}

			//remove commas from string formated ints. so parseFloat works
			let t_aval = parseFloat(tryReplace(aval, ",", ""));
			let t_bval = parseFloat(tryReplace(bval, ",", ""));

			if (!isNaN(t_aval) || !isNaN(t_bval)) {
				//is number data
				if (t_aval < t_bval || isNaN(t_bval)) {
					return -1 * dir;
				}
				if (t_aval > t_bval || isNaN(t_aval)) {
					return 1 * dir;
				}
			} else if (aval && !bval) {
				//sort null items to end
				return -1;
			} else if (bval && !aval) {
				//sort null items to end
				return 1;
			} else if (typeof aval === "string") {
				//compare as string
				return aval.localeCompare(bval) * dir;
			} else if (typeof bval === "string") {
				//compare as string
				return bval.localeCompare(aval) * dir;
			} else if (aval == bval) {
				// double == soft compare
				return 0;
			} else {
				let result = aval > bval;
				result = reverse ? !result : result;
				return result ? 1 : -1;
			}
			return 0;
		});
		return copy;
	}

	/**
	 * Like array.map but on object props
	 * @param {object} obj
	 * @param {(item: any, prop?:string, index?:number) => any} action
	 */
	static mapProps<T>(obj: T, action: (r: T[keyof T], prop?: string, index?: number) => any) {
		let rtns = [];
		let index = 0;
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				rtns.push(action(obj[prop], prop, index++));
			}
		}
		return rtns;
	}

	static forEachProps<T>(obj: T, action: (r: T[keyof T], prop?: string, index?: number) => any) {
		let index = 0;
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				action(obj[prop], prop, index++);
			}
		}
	}

	static fill<T>(count: number, factory: (i?: number) => T = x => x as any): T[] {
		count = Math.abs(count);
		let rtns = new Array(count);
		for (let index = 0; index < count; index++) {
			rtns[index] = factory(index);
		}
		return rtns;
	}

	static removeDuplicates<T>(myArr: T[], selector: (s: T) => T[keyof T]) {
		return myArr.filter((obj, pos, arr) => {
			return arr.map(mapObj => selector(mapObj)).indexOf(selector(obj)) === pos;
		});
	}

	static selectMany<T, R>(arr: T[], selector: (s: T) => R | R[] = (x: any) => x) {
		return arr.reduce<R[]>((prev, next) => prev.concat(selector(next)), []);
	}

	static indexClamp<T>(arr: T[], index: number) {
		return arr[Math.round(Math.max(Math.min(index, arr.length - 1), 0))];
	}

	/**True if given prop is the same for all items */
	static same<T>(arr: T[], selector: (s: T) => T[keyof T] = x => x as any) {
		if (arr.length) {
			const first = selector(arr[0]);
			return arr.every(x => first === selector(x));
		} else {
			return false;
		}
	}
}

function tryReplace(item, from, to) {
	if (typeof item === "string") {
		return item.replace(from, to);
	} else {
		return item;
	}
}

/**virtual class for sorting objects in `array.sort` */
export class Sortable {
	sort(other: Sortable) {
		return 0;
	}
}
