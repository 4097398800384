import React, { PureComponent } from "react";

import { Field, reduxForm, formValueSelector } from "redux-form";
import styles from "./styles.scss";
import { connect } from "react-redux";
import * as router from "react-router-redux";
import { actions } from "redux/loginRedux";
import Spinner from "libs/spinner";
import toast from "components/Toast/Toast";
import CSSModules from "react-css-modules";
import { RenderInput } from "components/FormInputs";
import Button from "components/Button/Button";
import autoBind from "libs/react-autobind";
import Modal from "components/BaseModal/Modal";
import * as logo from "assets/icons/avani-white-logo.png";

@CSSModules(styles, { allowMultiple: true })
class LoginForm_ extends PureComponent {
	login(data) {
		if (this.props.valid) {
			this.props.login(data.username, data.password);
		} else {
			toast("error", "Enter Valid Username & Password!");
		}
	}

	render() {
		const { handleSubmit } = this.props;
		return (
			<form name="meform" styleName="meform">
				{/* <img styleName="logo" src={logo} /> */}
				<Field name="username" component={RenderInput} styleName="input-field" placeholder="Username" />
				<Field name="password" component={RenderInput} styleName="input-field" placeholder="Password" secure />
				<Button styleName="loginButton" type="submit" onClick={handleSubmit(data => this.login(data))}>
					Login
				</Button>
				<Button styleName="resetButton" raised={false} onClick={this.props.toggleForgotPass}>
					Reset Password
				</Button>
			</form>
		);
	}
}

@CSSModules(styles, { allowMultiple: true })
class ResetForm_ extends PureComponent {
	reset(data) {
		this.props.resetPass(data.username);
		this.props.toggleForgotPass();
	}

	render() {
		const { handleSubmit } = this.props;
		return (
			<form name="resetform" styleName="resetform">
				<Field name="username" component={RenderInput} styleName="input-field" label="Username" />
				<div styleName="end">
					<Button onClick={handleSubmit(data => this.reset(data))} type="submit">
						Reset
					</Button>
					<Button theme="secondary" onClick={this.props.toggleForgotPass}>
						Cancel
					</Button>
				</div>
			</form>
		);
	}
}

@CSSModules(styles, { allowMultiple: true })
class Login extends PureComponent {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			showForgotPass: false
		};
	}

	toggleForgotPass() {
		this.setState({ showForgotPass: !this.state.showForgotPass });
	}

	render() {
		return (
			<div styleName="content">
				{!this.props.loaded || this.props.isBusy ? (
					<Spinner styleName="spinner" color={styles.secondaryOffColor} />
				) : (
					<div styleName="wraps">
						<div styleName="logo">
							<img src={logo} />
							<div>Revata Schedules</div>
						</div>
						<div styleName="card-wrap">
							<div styleName="card">
								<div styleName="title">Login</div>
								<LoginForm {...this.props} toggleForgotPass={this.toggleForgotPass} />
								<Modal isOpen={this.state.showForgotPass} title="Reset Password" onClose={this.toggleForgotPass}>
									<ResetForm {...this.props} toggleForgotPass={this.toggleForgotPass} />
								</Modal>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const LoginForm = reduxForm({
	form: "login",
	enableReinitialize: true,
	validate: v => {
		const errors = {};
		if (!v.username) {
			errors.username = "Required";
		}

		if (!v.password) {
			errors.password = "Required";
		}
		return errors;
	}
})(LoginForm_);

const ResetForm = reduxForm({
	form: "login-reset-password",
	enableReinitialize: true,
	validate: v => {
		const errors = {};
		if (!v.username) {
			errors.username = "Required";
		}
		return errors;
	}
})(ResetForm_);

const bindAction = dispatch => {
	return {
		login: (user, pass) => dispatch(actions.login(user, pass)),
		logout: () => dispatch(actions.logout()),
		resetPass: username => dispatch(actions.resetPass(username)),

		navigate: (uri, data) => dispatch(router.push(uri, data))
	};
};

const mapStateToProps = state => ({
	isLoggedIn: state.login.isLoggedIn,
	currentUsername: state.login.currentUsername,
	currentScreen: state.login.currentScreen,
	loaded: state.login.loaded,

	isBusy: state.login.isBusy,
	errors: state.login.errors
});

export default connect(
	mapStateToProps,
	bindAction
)(Login);
