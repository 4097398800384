import * as React from "react";
import { MergeStyles, shouldUpdate, string } from "utils";
import * as styles from "./styles.scss";
import * as PropTypes from "prop-types";
import { CommonComponentProps } from "redi-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import autoBind from "libs/react-autobind";
import mediaservice from "services/media/mediaservice";
import { MediaFileLink } from "services/media/mediatypes";
import * as icon from "assets/icons/Image_upload.png";

/**
 * File drag drop/click field.
 *
 * auto creates MediaFileLinks and uploads them to amazon.
 *
 * Give child elements to render them instead of the default field
 */
@MergeStyles(styles)
export default class FileUpload extends React.Component<Props, State> {
	static defaultProps = {
		//check file types on upload
		fileTypeRegex: /\.(png|jpg|jpeg)$/i,
		//check file types on input field
		inputFilter: "image/x-png,image/jpeg",
		onChange: null,
		color: "#67666785",

		classes: null //styleName overrides
	};
	static propTypes = {
		onChange: PropTypes.func.isRequired
	};
	inputRef: React.RefObject<HTMLInputElement>;

	constructor(props) {
		super(props);
		autoBind(this);

		this.inputRef = React.createRef();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onChange"]);
	}

	dragOverHandler(e: React.DragEvent<HTMLElement>) {
		e.preventDefault();
	}

	handleDragUpload(e: React.DragEvent<HTMLElement>) {
		e.preventDefault();
		let files: File[] = [];

		if (e.dataTransfer.items) {
			// Use DataTransferItemList interface to access the file(s)
			for (var i = 0; i < e.dataTransfer.items.length; i++) {
				// If dropped items aren't files, reject them
				if (e.dataTransfer.items[i].kind === "file") {
					files.push(e.dataTransfer.items[i].getAsFile());
				}
			}
			// Use DataTransferItemList interface to remove the drag data
			e.dataTransfer.items.clear();
		} else {
			// Use DataTransfer interface to access the file(s)
			for (var i = 0; i < e.dataTransfer.files.length; i++) {
				files.push(e.dataTransfer.files[i]);
			}
			// Use DataTransfer interface to remove the drag data
			e.dataTransfer.clearData();
		}
		this.upload(files);
	}

	handleClickUpload(e: React.ChangeEvent<HTMLElement>) {
		e.preventDefault();
		let files: File[] = [];

		for (let index = 0; index < this.inputRef.current.files.length; index++) {
			files.push(this.inputRef.current.files[index]);
		}
		this.upload(files);

		this.inputRef.current.value = null;
	}

	upload(files: File[]) {
		files = files.filter(x => this.props.fileTypeRegex.test(x.name));
		if (files.length) {
			Promise.all(files.map(x => mediaservice.createFileLink(x)))
				.then(result => {
					this.props.onChange(result);
				})
				.catch(e => console.error(e));
		}
	}

	render() {
		const childs = React.Children.map(this.props.children, (x: React.ReactElement<any>) => {
			const existingClick = x.props.onClick;
			//add our click handler alongside any existing click handlers
			return React.cloneElement(x, {
				onClick: e => {
					this.inputRef.current && this.inputRef.current.click();
					existingClick && existingClick(e);
				}
			});
		});

		return (
			<React.Fragment>
				{this.props.children ? (
					childs
				) : (
					<div
						className={this.props.className}
						styleName="_fupload_uploadfooter"
						onDrop={this.handleDragUpload}
						onDragOver={this.dragOverHandler}
						onClick={() => this.inputRef.current && this.inputRef.current.click()}
						style={{ borderColor: this.props.color }}
					>
						<div style={{ borderColor: this.props.color }}>
							<img src={icon} />
							<div>Upload Image</div>
						</div>
					</div>
				)}

				<input
					accept={this.props.inputFilter}
					id="image_viewer_input"
					type="file"
					ref={this.inputRef}
					style={{ display: "none" }}
					onChange={this.handleClickUpload}
				/>
			</React.Fragment>
		);
	}
}

interface Props extends CommonComponentProps {
	onChange: (files: MediaFileLink[]) => void;
	inputFilter?: string;
	fileTypeRegex?: RegExp;
	color?: string;
}

interface State {}
