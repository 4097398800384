import config from "config/config";
import { CircularJSON, http } from "utils/index";
import { ReduxState } from "config/reduxRoot";

export default function sendError(state: Partial<ReduxState> = { login: null }, action, type) {
	const url = `${config.baseUrl}Error/PostError`;

	try {
		if (!/redux\-form/.test(action.type)) {
			let data = {
				application: "TSA Attendance web",
				host: window.location.host,
				type: type,
				stack: action.payload.stackTrace || action.payload.stack || "",
				message: action.payload.message || JSON.stringify(action.payload),
				user: state.login && state.login.currentDetails ? state.login.currentDetails.unique_name || "nobody" : "nobody",
				timeLocal: new Date().toISOString()
				// reduxState: CircularJSON.stringify(state, null, null, true)
			};

			//dont send http errors
			if (
				data.message !== "HTTP Cancelled" &&
				!data.message.includes("HTTP Error") &&
				!data.stack.includes("XMLHttpRequest.xhr.onreadystatechange")
			) {
				return http({ url, method: "POST", data })
					.then(data => data)
					.catch(error => error);
			}
		}
	} catch (e) {
		console.log(e);
	}
}
