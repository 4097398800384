import React, { PureComponent } from "react";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";
import { DateTime, array, ManualPromise, MergeStyles, string } from "utils/index";
import PropTypes from "prop-types";
import * as datefns from "date-fns";
/**

Use:

	<TimeSlider value={this.props.dateRange} onChange={d => this.setTime(d)} />
*/
@MergeStyles(styles)
export default class TimeSlider extends PureComponent {
	static defaultProps = {
		rangeStart: DateTime.startOf("day", DateTime.add("month", new Date(), -3)), // Date
		rangeEnd: DateTime.endOf("day", DateTime.add("month", new Date(), 3)), // Date
		value: { startDate: null, endDate: null }, // TimeRange object
		timeFormat: "d/M",
		onChange: x => x,
		granularity: "day", //time 'day' 'year' 'minute' 'month' etc
		allowTextInput: false,
		maxRange: 0, //max number of granularity units allowed in range. zero for no limit

		//classes
		knobClass: "" //class to put on ye knob
	};
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		value: PropTypes.object,
		maxRange: PropTypes.number,
		granularity: PropTypes.string.isRequired,
		classes: PropTypes.object
	};

	constructor(props) {
		super(props);

		this.units = this.props.granularity.toLowerCase();

		const getValue = index => (index === 0 ? this.props.value.startDate : this.props.value.endDate);

		this.state = {
			sliders: array.fill(2, x => new Slider(x, getValue)),
			timeInRange: DateTime.diff(this.units, this.props.rangeStart, this.props.rangeEnd)
		};
		this.bar = React.createRef();
		this.slider = this.slider.bind(this);
		this.drag = this.drag.bind(this);
		this.onLoad = this.onLoad.bind(this);

		this.initPromises = [];
	}

	componentDidMount() {
		if (!(this.props.value && this.props.value.startDate && this.props.value.endDate)) {
			this.props.onChange({
				startDate: DateTime.startOf("day"),
				endDate: DateTime.endOf("day")
			});
		}

		window.addEventListener("resize", this.onLoad);

		if (document.readyState === "complete") {
			setTimeout(() => {
				this.onLoad();
			}, 10);
		} else {
			window.addEventListener("load", this.onLoad);
		}
	}

	//wait page to flow properly
	onLoad() {
		Promise.all(this.initPromises.map(x => x.Run())).then(() => {
			this.initPromises = [];
			this.setState(
				{
					sliderPixels: this.state.sliders.reduce((prev, next) => prev + (next.ref.clientWidth || 0), 0)
				},
				() => {
					this.state.sliders.forEach(x => {
						this.calcPos(x.value(), x);
					});
				}
			);
		});
	}

	maxPixels() {
		return this.bar.current.clientWidth - this.state.sliderPixels;
	}

	calcPos(date, elem, triggerChange = true) {
		const timeUnitDiff = DateTime.diff(this.units, this.props.rangeStart, date);
		let newValue = Object.assign({}, this.props.value);
		if (this.bar.current) {
			const setDate = () => {
				elem.date = date;
				elem.label = DateTime.format(date, this.props.timeFormat);

				if (elem.index === 0) {
					newValue.startDate = date;
				} else if (elem.index === 1) {
					newValue.endDate = date;
				}
			};

			const ref = elem.ref;

			const pxPerTimeUnit = this.maxPixels() / this.state.timeInRange;

			let pos = pxPerTimeUnit * timeUnitDiff;

			let sliders = this.state.sliders;
			if (elem.delta > 0) {
				//moving right
				for (let index = 0; index < sliders.length; index++) {
					if (index === elem.index) {
						continue;
					}
					const otherVal = sliders[index].value();
					if (index > elem.index && date >= otherVal) {
						//sliders to the right
						return false;
					} else if (index < elem.index && this.props.maxRange > 0) {
						//sliders to the left
						const diff = DateTime.diff(this.units, otherVal, date);
						if (diff > this.props.maxRange) {
							//pass false to not trigger props.onChange
							//instead merge result back into the current result
							const result = this.calcPos(
								DateTime.add(this.units, otherVal, diff - this.props.maxRange),
								sliders[index],
								false
							);
							if (result) {
								newValue = { ...newValue, ...result };
							}
						}
					}
				}
			} else if (elem.delta < 0) {
				//moving left
				for (let index = 0; index < sliders.length; index++) {
					if (index === elem.index) {
						continue;
					}
					const otherVal = sliders[index].value();
					if (index < elem.index && date <= otherVal) {
						//sliders to the left
						return false;
					} else if (index > elem.index && this.props.maxRange > 0) {
						//sliders to the right
						const diff = DateTime.diff(this.units, date, otherVal);
						if (diff > this.props.maxRange) {
							//pass false to not trigger props.onChange
							//instead merge result back into the current result
							const result = this.calcPos(
								DateTime.add(this.units, otherVal, -diff + this.props.maxRange, this.props.granularity),
								sliders[index],
								false
							);
							if (result) {
								newValue = { ...newValue, ...result };
							}
						}
					}
				}
			}

			if (date <= this.props.rangeEnd && date >= this.props.rangeStart) {
				setDate();

				pos -= pxPerTimeUnit;
				for (let index = 0; index < this.state.sliders.length && index < elem.index; index++) {
					const slider = this.state.sliders[index];
					pos += slider.ref.clientWidth + pxPerTimeUnit;
				}

				ref.style.left = pos + "px";
				elem.pos = pos;

				if (
					newValue.endDate !== this.props.value.endDate ||
					newValue.startDate !== this.props.value.startDate
				) {
					this.props.onChange(newValue);
				}
				return newValue;
			}
			return false;
		}
	}

	drag(elem, e) {
		const pxPerTimeUnit = this.maxPixels() / this.state.timeInRange;

		function elementDrag(e) {
			e = e || window.event;
			e.preventDefault();
			// calculate the new cursor position:
			elem.accumulator += e.clientX - elem.prevValue;
			elem.prevValue = e.clientX;
			elem.delta = parseInt(elem.accumulator / pxPerTimeUnit);

			if (elem.delta !== 0) {
				this.calcPos(DateTime.add(this.units, elem.value(), elem.accumulator / pxPerTimeUnit), elem);
				elem.accumulator = 0;
			}
		}

		function closeDragElement() {
			/* stop moving when mouse button is released:*/
			document.onmouseup = null;
			document.onmousemove = null;
		}

		e = e || window.event;
		e.preventDefault();
		// get the mouse cursor position at startup:
		elem.prevValue = e.clientX;
		document.onmouseup = closeDragElement.bind(this);
		// call a function whenever the cursor moves:
		document.onmousemove = elementDrag.bind(this);
	}

	calcBlur(elem, e, index) {
		const pxPerTimeUnit = this.maxPixels() / this.state.timeInRange;

		e = e || window.event;
		e.preventDefault();

		const mm = DateTime.parse(e.target.value, this.props.timeFormat);

		elem.delta = mm > elem.date ? 1 : -1;

		if (DateTime.isValid(mm) && this.calcPos(mm, elem)) {
			elem.accumulator = 0;
			elem.delta = 0;
			elem.prevValue = elem.pos;
		} else {
			elem.label = DateTime.format(elem.date, this.props.timeFormat);
			const sliders = this.state.sliders.slice();
			this.setState({ sliders });
		}
	}

	slider(index, date) {
		const elem = this.state.sliders[index];
		return (
			<div
				ref={ref => {
					if (ref) {
						elem.ref = ref;
						this.initPromises.push(
							new ManualPromise(() => {
								elem.delta = this.calcPos(date, elem);
							})
						);
					}
				}}
				styleName={`_knob ${this.props.knobClass}`}
				key={index}
				onMouseDown={e => this.drag(elem, e)}
				onDoubleClick={e => this.props.allowTextInput && elem.inputRef.current.focus()}
			>
				{this.props.allowTextInput ? (
					<input
						type="text"
						ref={elem.inputRef}
						value={elem.label}
						onBlur={e => this.calcBlur(elem, e, index)}
						onChange={e => {
							const sliders = this.state.sliders.slice();
							sliders[index].label = e.target.value;
							this.setState({ sliders });
						}}
						styleName="_input"
					/>
				) : (
					elem.label
				)}
			</div>
		);
	}

	render() {
		return (
			<div className={this.props.className} styleName="_root">
				<div styleName="_bar" ref={this.bar} />
				{this.slider(0, this.props.value.startDate)}
				{this.slider(1, this.props.value.endDate)}
			</div>
		);
	}

	componentWillUnmount() {
		window.removeEventListener("load", this.onLoad);
		window.removeEventListener("resize", this.onLoad);
	}
}


class Slider {
	constructor(index, getValue) {
		this.index = index;
		this.delta = 0;
		this.accumulator = 0;
		this.pos = null;
		this.ref = null;
		this.date = null;
		this.label = "";
		this.inputRef = React.createRef();
		this.value = () => getValue(index);
	}
}
