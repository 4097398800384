export default class {
	static getPosition() {
		return new Promise((resolve, reject) => {
			try {
				navigator.geolocation.getCurrentPosition(position => {
					resolve(position.coords);
				});
			} catch (e) {
				console.error("Get Geolocation failed: " + e.message);
				reject(e);
			}
		});
	}

	static GetDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
		var R = 6371;
		var dLat = this.deg2rad(lat2 - lat1);
		var dLon = this.deg2rad(lon2 - lon1);

		var a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c; // Distance in km

		var result = 0;
		if (d < 10) {
			result = this.roundPrecise(d, 1);
		} else {
			result = Math.ceil(d);
		}

		return result;
	}

	static deg2rad(deg) {
		return deg * (Math.PI / 180);
	}

	/*
     * MidpointRounding away from zero ('arithmetic' rounding)
     * Uses a half-epsilon for correction. (This offsets IEEE-754
     * half-to-even rounding that was applied at the edge cases).
     * 
     * console.log(RoundCorrect(1.005, 2));  // 1.01
     * console.log(RoundCorrect(-1.005, 2));  // -1.01 rather than -1
     */
	static roundPrecise(num, precision = 2) {
		var c = 0.5 * Number.EPSILON * num;
		var p = 1;
		while (precision-- > 0) {
			p *= 10;
		}
		if (num < 0) {
			p *= -1;
		}
		return Math.round((num + c) * p) / p;
	}
}
