import * as React from "react";

import * as styles from "./styles.scss";
import * as router from "react-router-redux";
import { connect } from "react-redux";

import * as CSSModules from "react-css-modules";
import { AuthHoC } from "components/Auth/Auth";
import Spinner from "libs/spinner";
import CheckBox from "components/Checkbox/checkbox";
import Button from "components/Button/Button";
import importservice from "services/import";
import { string } from "utils";

@CSSModules(styles, { allowMultiple: true })
class Import extends React.PureComponent<Props, State> {
	_scroll: React.RefObject<HTMLDivElement>;
	_scrollto: React.RefObject<HTMLDivElement>;
	_inputRef: React.RefObject<HTMLInputElement>;
	constructor(props) {
		super(props);
		this.state = {
			showStack: false,
			console: [],
			isBusy: false
		};
	}

	componentWillMount() {
		this._scroll = React.createRef();
		this._scrollto = React.createRef();
		this._inputRef = React.createRef();
	}

	uploadFile(result) {
		this.Upload(result.target.files[0]);
	}

	scrollToBottom() {
		if (this._scrollto.current) {
			this._scrollto.current.scrollIntoView({ behavior: "instant" });
		}
	}

	componentDidUpdate(oldProps, oldState) {
		if (this._scroll.current) {
			if (oldState.console.length != this.state.console.length) {
				//mew message
				if (this._scroll.current.scrollHeight <= this._scroll.current.scrollTop + this._scroll.current.clientHeight + 100) {
					this.scrollToBottom();
				}
			}
		}
	}

	print(msg: string) {
		if (msg) {
			this.setState({ console: [...this.state.console, msg] });
		}
	}

	Upload(file: File) {
		this.setState({ isBusy: true });
		this.print(`%C-- BEGIN UPLOAD ${file.name} --`);
		if (/\.xlsx$/.test(file.name)) {
			return importservice.Import(file).then(data => {
				if (data.error) {
					this.print("%EAn Error has occurred");

					const error = string.toCamelCase(data.error.response);

					if (error.exceptionMessage || error.stackTrace) {
						if (error.exceptionMessage) {
							this.print(`Outer Exception: ${error.exceptionMessage}`);
							let ex = error.innerException;
							for (let i = 1; ex; i++, ex = ex.innerException) {
								this.print(`Inner Exception ${i}: ${ex.exceptionMessage}`);
							}
						} else {
							this.print(error.message);
						}

						if (error.stackTrace) {
							this.print("%S ");
							this.print(`%SStack: ${error.stackTrace}`);
							let ex = error.innerException;
							for (let i = 1; ex; i++, ex = ex.innerException) {
								this.print("%S ");
								this.print(`%SInner Stack ${i}: ${ex.stackTrace}`);
							}
							this.print("%S ");
						}
					} else {
						//print any keys
						const iterate = obj => {
							for (const prop in obj) {
								if (obj.hasOwnProperty(prop)) {
									const item = obj[prop];
									if (typeof item === "string") {
										this.print(`${prop}: ${item}`);
									} else if (typeof item === "object" && item) {
										iterate(item);
									}
								}
							}
						};
						iterate(error);
					}

					console.error(error);
					this.print(`%C-- UPLOAD ${file.name} FAILED --`);
				} else {
					this.print(`Success`);
					this.print(`%C-- UPLOAD ${file.name} COMPLETE --`);
				}

				this.print(" ");
				this.setState({ isBusy: false });
			});
		} else {
			this.print("%ENot a valid file. Must be an Excel spreadsheet");
		}
	}

	render() {
		return (
			<div styleName="content">
				<div styleName="console" ref={this._scroll}>
					{this.state.console.map((x, i) => {
						//regex matches any number of whitespace, at start, then %C or %E or %S
						const comment = /^[^\S]*%C/.test(x);
						const error = /^[^\S]*%E/.test(x);
						const stack = /^[^\S]*%S/.test(x);

						if (stack && !this.state.showStack) {
							return null;
						}

						let prints: any = x.replace(/^[^\S]*%C|^[^\S]*%E|^[^\S]*%S/, "").split("\n");

						if (prints.length > 1) {
							//replace newline with <br/>
							prints = prints.map((x, i) => (
								<React.Fragment key={i}>
									{x}
									<br />
								</React.Fragment>
							));
						}

						return (
							<div
								key={i}
								styleName={`line ${error ? "line-error" : comment ? "line-green" : stack ? "line-yellow" : "line-black"}`}
							>
								{prints}
							</div>
						);
					})}
					<div ref={this._scrollto} />
				</div>
				<input
					type="file"
					onChange={s => {
						this.uploadFile(s);
						this._inputRef.current.value = "";
					}}
					ref={this._inputRef}
					style={{ display: "none" }}
				/>

				<div styleName="wraps">
					{this.state.isBusy && <Spinner />}

					<CheckBox
						onChange={e => {
							this.setState({ showStack: !this.state.showStack });
						}}
						checked={this.state.showStack}
						label="Show Stacktrace"
					/>

					<Button variant="normal" onClick={() => this._inputRef.current.click()}>
						Upload Spreadsheet
					</Button>
					<Button variant="normal" onClick={() => this.setState({ console: [] })}>
						Clear Console
					</Button>
				</div>
			</div>
		);
	}
}

const bindAction = dispatch => {
	return {
		navigate: (uri, data) => dispatch(router.push(uri, data)),
		goBack: () => dispatch(router.goBack())
	};
};

const mapStateToProps = state => ({});

export default AuthHoC(
	connect(
		mapStateToProps,
		bindAction
	)(Import)
);

interface Props {}

interface State {
	isBusy: boolean;
	console: string[];
	showStack: boolean;
}
