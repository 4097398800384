import * as React from "react";

import * as styles from "./styles.scss";
import { connect } from "react-redux";
import * as router from "react-router-redux";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { CommonUIProps, ScheduleGroupDto, BookingDto, ScheduleSummaryDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";

import { actions as smarttableactions } from "components/SmartTable/redux";
import { QueryPart, QueryGroup } from "services/query/buildquery";
import { actions as scheduleactions } from "redux/scheduleRedux";
import { AuthHoC } from "components/Auth/Auth";
import * as ReactHighcharts from "react-highcharts";
import * as Highcharts from "highcharts-declarations-beta";
import SmartTable from "components/SmartTable/SmartTable";
import bookingservice from "services/booking";
import { TimeRange, DateTime, array, seriesColor, number } from "utils";
import SwitchButton from "components/Switch/switch";
import Select from "components/Select/select";
import scheduleservice from "services/schedule";
import Tooltip, { TooltipContent } from "components/Tooltip/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "config/config";
import { formatSecs, download } from "../Reports";

@CSSModules(styles, { allowMultiple: true })
class SchSummary extends React.PureComponent<Props, State> {
	actions: Actions;
	rowsPerPageActions: number[];
	chart: React.RefObject<any>;
	defaultQuery: QueryGroup;

	constructor(props) {
		super(props);
		autoBind(this);

		this.actions = this.props.exposeActions(this);

		this.state = {
			showByDay: true,
			showAsHours: true,
			rowsPerPage: 20,
			graphData: [],

			chartConfig: {
				chart: {
					height: window.innerWidth > styles.small ? 300 : undefined,
					animation: false,
					zoomType: "x",
					panning: true,
					panKey: "shift",
					style: {
						fontFamily: "Helvetica"
					}
				},
				credits: {
					enabled: false
				},
				colors: seriesColor.getSeriesColors(),
				title: {
					text: null
				},
				legend: {
					itemStyle: {
						color: "#999",
						fontWeight: "normal"
					}
				},
				plotOptions: {
					column: {
						shadow: false
					}
				},
				xAxis: {
					type: "datetime",
					lineColor: "#e6e6e6",
					tickLength: 0
				},
				yAxis: {
					title: {
						text: "Hours"
					},
					tickInterval: 2,
					stackLabels: {
						enabled: false,
						style: {
							fontWeight: "bold"
						}
					},
					labels: {
						format: "{value} h"
					}
				},

				series: []
			}
		};

		this.rowsPerPageActions = [10, 20, 40, 80, 160];
		this.chart = React.createRef<any>();
		this.defaultQuery = new QueryGroup([
			new QueryPart("scheduleName", "contains", "string", this.props.query, "or"),
			new QueryPart("scheduleGroupName", "contains", "string", this.props.query)
		]);
	}

	drawChart(summarys: ScheduleSummaryDto[], showAsHours: boolean, showByDay: boolean) {
		//add new data to chart
		const groupedBySchedule = array.groupBy(array.sort(summarys.slice(), x => x.dateLocal), x => x.scheduleId);
		let seriesArr = [];
		let newConfig: Highcharts.Options = { ...this.state.chartConfig };

		array.mapProps(groupedBySchedule, (group, scheduleId) => {
			const grouped = array.groupBy(group, x => {
				if (showByDay) {
					return DateTime.startOf("day", x.dateLocal).getTime() - new Date().getTimezoneOffset() * 60 * 1000;
				} else {
					return x.displayName;
				}
			});

			let series: Highcharts.SeriesColumnOptions = {
				type: "column",
				data: [],
				name: group[0].displayName
			};

			if (showAsHours) {
				series.tooltip = {
					pointFormatter: function() {
						return `<span style="color:{point.color}">\u25CF</span> ${this.series.name}: <b> ${number.formatNumber(
							this.y
						)} h</b><br/>`;
					}
				};
				newConfig.yAxis = {
					...newConfig.yAxis,
					title: {
						text: "Hours"
					},
					tickInterval: 2,
					labels: {
						format: "{value} h"
					}
				};

				array.mapProps(grouped, (x, prop) => {
					const secs = x.reduce((prev, next) => prev + next.durationSeconds, 0);
					series.data.push([showByDay ? parseInt(prop, 10) : prop, secs / 60 / 60]);
				});
			} else {
				series.tooltip = {
					pointFormatter: function() {
						return `<span style="color:{point.color}">\u25CF</span> ${this.series.name}: <b> ${number.formatNumber(
							this.y
						)} Bookings(s)</b><br/>`;
					}
				};
				newConfig.yAxis = {
					...newConfig.yAxis,
					title: {
						text: "Bookings"
					},
					tickInterval: 1,
					labels: {
						format: "{value}"
					}
				};

				array.mapProps(grouped, (x, prop) => {
					const count = x.length;
					series.data.push(
						showByDay ? [parseInt(prop, 10), count] : [prop, x.reduce((prev, next) => prev + next.bookingsCount, 0)]
					);
				});
			}
			seriesArr.push(series);
		});

		if (showByDay) {
			newConfig.xAxis = {
				...newConfig.xAxis,
				type: "datetime"
			};
			newConfig.legend.enabled = true;
		} else {
			newConfig.xAxis = {
				...newConfig.xAxis,
				type: "category"
			};
			newConfig.legend.enabled = false;
		}

		newConfig.series = seriesArr;
		this.setState({ chartConfig: newConfig });
	}

	callServer(pagingParams: PagingParameters, groupIds: string[], startTime: Date, endTime: Date, asCSV: boolean) {
		this.actions.isBusy(true);
		//asCSV comes in as additional arguments passed in from tableactions.REFRESH_DATA
		if (asCSV) {
			pagingParams.export = "schedule_summary";
			return scheduleservice
				.GetSummaryList(pagingParams, groupIds, startTime, endTime, this.props.includeSwipes, !this.state.showByDay)
				.then(result => {
					if (result.data) {
						download(config.apiBaseUrl + result.data.fileName);
					}
					this.actions.isBusy(false);
					return result;
				});
		} else {
			let graphQuery = { ...pagingParams };
			graphQuery.pageIndex = 0;
			graphQuery.pageSize = 0;
			//do a second call with no paging to get graph data
			scheduleservice
				.GetSummaryList(graphQuery, groupIds, startTime, endTime, this.props.includeSwipes, !this.state.showByDay)
				.then(result => {
					if (result.data) {
						this.setState({ graphData: result.data.data });
						//add new data to chart
						this.drawChart(result.data.data, this.state.showAsHours, this.state.showByDay);
					} else {
						this.drawChart([], this.state.showAsHours, this.state.showByDay);
					}
				});
			return scheduleservice.GetSummaryList(
				pagingParams,
				groupIds,
				startTime,
				endTime,
				this.props.includeSwipes,
				!this.state.showByDay
			);
		}
	}

	render() {
		return (
			<div styleName="root">
				<div styleName="chart-wrap">
					<div styleName="switch-wrap">
						<SwitchButton
							styleName="chart-switch"
							size={20}
							label="Hours"
							labelLeft="Bookings"
							checked={this.state.showAsHours}
							onChange={val => {
								this.setState({ showAsHours: val });
								this.drawChart(this.state.graphData, val, this.state.showByDay);
							}}
						/>
						<SwitchButton
							styleName="chart-switch"
							size={20}
							label="By Day"
							labelLeft="By Schedule"
							checked={this.state.showByDay}
							onChange={val => {
								this.actions.setList([]);
								this.setState({ showByDay: val }, () => this.actions.refreshData());
							}}
						/>
					</div>
					<ReactHighcharts config={this.state.chartConfig} ref={this.chart} isPureConfig={true} />
				</div>
				<div styleName="table-wrap">
					<SmartTable
						smartTableId={this.props.tableName}
						columns={this.state.showByDay ? 7 : 6}
						changeData={summarys => {
							this.actions.setList(summarys);
						}}
						callServer={{
							func: this.callServer,
							pagingParametersIndex: 0,
							funcArgs: [this.props.currentGroupsSelected, this.props.time.startDate, this.props.time.endDate],
							queryBuilderConf: {
								defaultFilters: [this.defaultQuery]
							}
						}}
						allowColumnSort={true}
						breakOn="sm"
						stickyHeaderScrollRef={this.props.scrollRef}
						rowsPerPage={this.state.rowsPerPage}
						tableClass="table-root"
						thClass="th"
						tdClass="td"
						classes={styles}
					>
						<thead>
							<tr>
								<th styleName="th-spacer">
									<Tooltip>
										<div onClick={() => this.actions.refreshData(null, true)}>
											<FontAwesomeIcon icon="file-download" />
										</div>
										<TooltipContent>Generate CSV</TooltipContent>
									</Tooltip>
								</th>
								{this.state.showByDay && <th sortable="dateLocal">Date</th>}
								<th sortable="displayName">Schedule</th>
								<th sortable="scheduleGroupName">Group</th>
								<th sortable="bookingsCount">Bookings</th>
								<th sortable="durationSeconds">Duration</th>
								<th styleName="th-spacer" />
							</tr>
						</thead>
						<tbody>
							{this.props.summarys.map((row, i) => {
								return (
									<tr key={i}>
										<td styleName="th-spacer" />
										{this.state.showByDay && <td>{DateTime.format(row.dateLocal, "dd MMM yyyy")}</td>}
										<td>{row.displayName}</td>
										<td>{row.scheduleGroupName}</td>
										<td>{row.bookingsCount}</td>
										<td>{formatSecs(row.durationSeconds)}</td>
										<td styleName="th-spacer" />
									</tr>
								);
							})}
						</tbody>
					</SmartTable>

					<div styleName="rows-selector">
						<span>Rows per page: </span>
						<Select
							styleName="rows-select"
							value={this.state.rowsPerPage}
							onChange={val => {
								this.setState({ rowsPerPage: val }, () => {
									this.actions.refreshData();
								});
							}}
							actions={this.rowsPerPageActions}
						/>
					</div>
				</div>
			</div>
		);
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.query !== this.props.query || prevProps.includeSwipes !== this.props.includeSwipes) {
			this.actions.refreshData(
				new QueryGroup([
					new QueryPart("scheduleName", "contains", "string", this.props.query, "or"),
					new QueryPart("scheduleGroupName", "contains", "string", this.props.query)
				])
			);
		}
	}
}

const bindAction = dispatch => {
	return {
		exposeActions: (component: SchSummary) => {
			return {
				refreshData: (query, s) => dispatch(smarttableactions.refreshData(component.props.tableName, query, s)),

				setList: data => dispatch(scheduleactions.setSummarys(data)),
				isBusy: s => dispatch(scheduleactions.isBusy(s)),

				navigate: (uri, data) => dispatch(router.push(uri, data)),
				goBack: () => dispatch(router.goBack())
			};
		}
	};
};

const mapStateToProps = (state: ReduxState) => ({
	summarys: state.schedule.scheduleSummarys,
	isBusy: state.users.isBusy
});

export default connect(
	mapStateToProps,
	bindAction
)(SchSummary);

interface State {
	rowsPerPage: number;
	chartConfig: Highcharts.Options;
	showAsHours: boolean;
	graphData: ScheduleSummaryDto[];
	showByDay: boolean;
}

interface Props extends CommonUIProps {
	exposeActions: (component: SchSummary) => Actions;
	scrollRef: React.RefObject<HTMLDivElement>;
	isBusy: boolean;
	time: TimeRange;
	currentGroupsSelected: string[];
	summarys: ScheduleSummaryDto[];
	tableName: string;
	query: string;
	includeSwipes: boolean;
}

interface Actions {
	refreshData: (s?: QueryGroup | QueryPart | "reset", ascsv?: boolean) => void;
	setList: (data: ScheduleSummaryDto[]) => void;
	isBusy: (val: boolean) => void;

	navigate: (uri: string, data?: any) => void;
	goBack: () => void;
}
