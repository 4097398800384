import * as React from "react";

import * as styles from "./styles.scss";
import { connect } from "react-redux";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { UserDto, ScheduleGroupDto, ScheduleDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";

import { actions as useractions, types as usertypes } from "redux/userRedux";
import { actions as scheduleActions, types as scheduletypes } from "redux/scheduleRedux";
import Button from "components/Button/Button";
import CheckBox from "components/Checkbox/checkbox";
import SearchBar from "components/SearchBar/SearchBar";
import { array } from "utils";
import { subscribeAction } from "boot/configureStore";

@CSSModules(styles, { allowMultiple: true })
class AddRemoveGroupSched extends React.PureComponent<Props, State> {
	actions: Actions;
	allSame: boolean;
	isRemove: boolean;
	unsub: () => void;
	inGroups: string[];
	schedules: ScheduleDto[];
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			selectedSchedules: []
		};

		this.schedules = [];

		this.actions = this.props.exposeActions(this);
		if (!this.props.existingSchedules || !this.props.existingSchedules.length) {
			this.actions.getSchedules(this.props.selectedGroupIds);
		} else {
			this.filterSchedules();
		}

		this.isRemove = this.props.existingSchedules && !!this.props.existingSchedules.length;

		if (!this.isRemove) {
			//only modify if all selected users are in the same exact groups
			this.allSame = array.same(this.props.selectedUsers, x => array.sort(x.groups.map(g => g.scheduleGroupId)).join(","));
		}

		this.unsub = subscribeAction(usertypes.ON_SUCCESS, action => {
			this.props.onClose();
		});
	}

	filterSchedules() {
		this.inGroups = array
			.removeDuplicates(array.selectMany(this.props.selectedUsers, x => x.groups), x => x.scheduleGroupId)
			.map(x => x.scheduleGroupId);

		this.schedules = this.props.schedules.filter(x => this.inGroups.includes(x.scheduleGroupId));
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.schedules !== this.props.schedules) {
			this.filterSchedules();
			this.forceUpdate();
		}
	}

	add() {
		this.actions.addUsersToSchedule(this.props.selectedUsers.map(x => x.id), this.state.selectedSchedules);
	}

	remove() {
		this.actions.removeUsersFromSchedules(this.props.selectedUsers.map(x => x.id), this.state.selectedSchedules);
	}

	render() {
		if (this.allSame || this.isRemove) {
			const arr = this.isRemove ? this.props.existingSchedules : this.schedules;
			let reg;
			try {
				reg = this.state.searchText && new RegExp(this.state.searchText, "i");
			} catch {}

			const rows = this.state.searchText
				? arr.filter(x =>
						reg
							? reg.test(x.scheduleGroupName + x.scheduleAreaName + x.name)
							: (x.scheduleGroupName + x.scheduleAreaName + x.name).includes(this.state.searchText)
				  )
				: arr;

			return (
				<div styleName="root">
					<SearchBar styleName="search" onChange={val => this.setState({ searchText: val })} />
					<div styleName="fields">
						{rows.length > 1 && (
							<CheckBox
								label="All"
								checked={this.state.selectedSchedules.length === rows.length}
								onChange={val => {
									if (val) {
										this.setState({
											selectedSchedules: rows.map(c => c.scheduleId)
										});
									} else {
										this.setState({
											selectedSchedules: []
										});
									}
								}}
							/>
						)}

						{rows.map((row, i) => {
							return (
								<CheckBox
									key={row.scheduleId}
									label={row.scheduleGroupName + " " + row.scheduleAreaName + " " + row.name}
									checked={this.state.selectedSchedules.some(x => x === row.scheduleId)}
									onChange={val => {
										if (val) {
											this.setState({
												selectedSchedules: this.state.selectedSchedules.concat(row.scheduleId)
											});
										} else {
											this.setState({
												selectedSchedules: this.state.selectedSchedules.filter(f => f !== row.scheduleId)
											});
										}
									}}
								/>
							);
						})}
					</div>
					<div styleName="footer">
						{this.isRemove ? (
							<Button disabled={this.state.selectedSchedules.length === 0} onClick={this.remove}>
								Remove from Schedules
							</Button>
						) : (
							<Button disabled={this.state.selectedSchedules.length === 0} onClick={this.add}>
								Add to Schedules
							</Button>
						)}
						<Button theme="secondary" onClick={this.props.onClose}>
							Cancel
						</Button>
					</div>
				</div>
			);
		} else {
			return <div styleName="error">Not all selected users are in the same groups</div>;
		}
	}

	componentWillUnmount() {
		this.unsub();
	}
}

const bindAction = dispatch => {
	return {
		exposeActions: () => {
			return {
				removeUsersFromSchedules: (userIds, scheduleIds) => dispatch(useractions.removeUsersFromSchedules(userIds, scheduleIds)),
				addUsersToSchedule: (userIds, scheduleIds) => dispatch(useractions.addUsersToSchedules(userIds, scheduleIds)),
				getSchedules: groupIds => dispatch(scheduleActions.getListInGroups(groupIds))
			};
		}
	};
};

const mapStateToProps = (state: ReduxState) => ({
	schedules: state.schedule.schedules
});

export default connect(
	mapStateToProps,
	bindAction
)(AddRemoveGroupSched);

interface State {
	selectedSchedules: string[];
	searchText?: string;
}

interface Props {
	exposeActions: (component: AddRemoveGroupSched) => Actions;
	onClose: () => void;
	existingSchedules: ScheduleDto[];
	selectedGroupIds: string[];
	schedules: ScheduleDto[];
	selectedUsers: UserDto[];
}

interface Actions {
	getSchedules: (groupIds: string[]) => void;
	addUsersToSchedule: (userIds: string[], scheduleIds: string[]) => void;
	removeUsersFromSchedules: (userIds: string[], scheduleIds: string[]) => void;
}
