import * as React from "react";
import * as ReactDOM from "react-dom";
import * as styles from "./styles.scss";
import * as PropTypes from "prop-types";
import * as CSSModules from "react-css-modules";
import { CommonComponentProps } from "redi-types";
import { shouldUpdate, string, MergeStyles, DateTime, array, number, RegisterTheme } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Day from "./calender/Day/Day";
import autoBind from "libs/react-autobind";
import { MonthView, Week } from "./calender/classes";
import ScrollSnap from "libs/scroll-snap";

const NUM_WEEKS = 5;
const NUM_WEEKS_MOBILE = 2;
const MAX_WEEKS_RENDERED = 25;
const WEEKS_TO_GEN = 5;

interface Props extends CommonComponentProps {
	value: Date;
	minValue?: Date;
	maxValue?: Date;
	onChange: (d: Date) => void;
	dayFormat?: string;
	/**
	 * show bar on footer of selected day
	 */
	showSelectedDayBar?: boolean;
	/**
	 * collapse weeks down to 2 rows
	 */
	shrinkOnMobile?: boolean;
	/**
	 * scale days' height/width to the width of the calender's container el
	 *
	 * Leave false to set as default width
	 */
	autoWidth?: boolean;
	/**Put a dot on these days. Used to mark days with, for example, a booking on them. Must be ordered ASC */
	dayMarks?: Date[];
	disabled?: boolean;
	showMonthOverlays?: boolean;

	headerClass?: string;
	dateHeaderClass?: string;
	weekDayHeader?: string;
	bodyClass?: string;
	dayClass?: string;
}

@RegisterTheme("REDI_Calender")
@MergeStyles(styles)
export default class Calender extends React.Component<Props, State> {
	static defaultProps = {
		dayFormat: "d",
		showSelectedDayBar: true,
		shrinkOnMobile: false,
		autoWidth: false,
		dayMarks: [],
		showMonthOverlays: true,

		dateHeaderClass: "",
		headerClass: "",
		weekDayHeader: "",
		bodyClass: "",
		dayClass: "",
		classes: null //styleName overrides
	};
	static propTypes = {
		classes: PropTypes.object
	};
	bodyRef: HTMLDivElement;
	readonly numWeeks: number;
	readonly numWeeksMobile: number;
	prevCenterLeftMonth: number;
	_mobileExpanded: boolean;
	rootRef: HTMLDivElement;
	__makingWeeks: boolean;
	closeAreaRef: React.RefObject<HTMLDivElement>;
	_scrollSnap: ScrollSnap;
	_resizeTimeout: any;
	maxTime: number;
	minTime: number;
	dayMarks: Date[];
	portal: React.RefObject<HTMLDivElement>;
	overlayTimeout: any;
	_ignoreNextOverlay: boolean;
	overlayKeys: number;

	constructor(props) {
		super(props);
		autoBind(this);

		this.dayMarks = [];
		this.numWeeks = NUM_WEEKS;
		this.numWeeksMobile = NUM_WEEKS_MOBILE;
		this.closeAreaRef = React.createRef<HTMLDivElement>();
		this.portal = React.createRef<HTMLDivElement>();
		this.maxTime = this.props.maxValue && this.props.maxValue.getTime();
		this.minTime = this.props.minValue && this.props.minValue.getTime();
		this.overlayKeys = 0;

		this.state = {
			isShowingMonthOverlays: false,
			rowHeight: styles.rowHeight.match(/\d*/)[0],
			//prefill array with max number of weeks rendered
			weeks: this.generateWeeks(
				Math.ceil(MAX_WEEKS_RENDERED / 2),
				true,
				this.generateWeeks(Math.floor(MAX_WEEKS_RENDERED / 2), false, [])
			),
			//an internal date that determins what month to show
			viewDate: new MonthView(this.props.value || new Date()),
			//loaded only applies to autowidth true. we already know the default width/size
			loaded: !this.props.autoWidth
		};

		if (this.props.autoWidth) {
			window.addEventListener("resize", this.onResize);
		}
	}

	onResize() {
		if (this._resizeTimeout) {
			clearTimeout(this._resizeTimeout);
		}
		this._resizeTimeout = setTimeout(this.checkResize, 10);
	}

	scrollToCurrentMonth() {
		if (this.bodyRef) {
			this._ignoreNextOverlay = true;
			let focusDate = DateTime.startOf("month", this.state.viewDate.viewDate);

			if (window.innerWidth < styles.small && this.props.shrinkOnMobile && !this._mobileExpanded && this.props.value) {
				focusDate = this.props.value;
			}

			let weekNum = this.state.weeks.findIndex(x => x.startDate >= focusDate);
			if (this.state.weeks[weekNum] && this.state.weeks[weekNum].startDate.getDate() !== 1) {
				//start of month is on prev week so show one week prior
				--weekNum;
			}
			this.bodyRef.scrollTop = weekNum * this.state.rowHeight;

			const leftCenterDate = this.getLeftDate(this.bodyRef.clientHeight / this.state.rowHeight / 2 - 1);
			this.prevCenterLeftMonth = parseInt(leftCenterDate.getFullYear() + DateTime.format(leftCenterDate, "MM"), 10);
		}
	}

	onBodyScroll(e: Event) {
		if (!this.__makingWeeks) {
			if (this.bodyRef.scrollTop < this.state.rowHeight * WEEKS_TO_GEN) {
				const currentScroll = this.bodyRef.scrollTop;
				this.__makingWeeks = true;
				//create at start of array
				this.setState({ weeks: this.generateWeeks(WEEKS_TO_GEN, true, this.state.weeks) }, () => {
					setTimeout(() => {
						this.bodyRef.scrollTop = currentScroll + this.state.rowHeight * WEEKS_TO_GEN;
					}, 10);
					this.__makingWeeks = false;
				});
			} else if (
				this.bodyRef.scrollTop >
				this.bodyRef.scrollHeight - this.bodyRef.clientHeight - this.state.rowHeight * WEEKS_TO_GEN
			) {
				//create at end of array
				const currentScroll = this.bodyRef.scrollTop;
				this.__makingWeeks = true;
				this.setState({ weeks: this.generateWeeks(WEEKS_TO_GEN, false, this.state.weeks) }, () => {
					setTimeout(() => {
						this.bodyRef.scrollTop = currentScroll - this.state.rowHeight * WEEKS_TO_GEN;
					}, 10);
					this.__makingWeeks = false;
				});
			}
		}
	}

	onEndScroll() {
		if (this.bodyRef) {
			if (this.props.showMonthOverlays) {
				this.setState({ isShowingMonthOverlays: false });
			}
			const leftCenterDate = this.getLeftDate(this.bodyRef.clientHeight / this.state.rowHeight / 2 - 1);
			const centerLeftMonth = parseInt(leftCenterDate.getFullYear() + DateTime.format(leftCenterDate, "MM"), 10);
			if (centerLeftMonth !== this.prevCenterLeftMonth) {
				this.changeToMonth(leftCenterDate, false);
			}
			this.prevCenterLeftMonth = centerLeftMonth;
		}
	}

	onBodyRef(ref: HTMLDivElement) {
		this.bodyRef = ref;
		if (this.bodyRef) {
			const snapConfig = {
				// scrollSnapDestination: `0px ${this.state.rowHeight}px`, // *REQUIRED* scroll-snap-destination css property, as defined here: https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-snap-destination
				yValFunc: () => this.state.rowHeight,
				xValFunc: () => 0,
				scrollTimeout: 100, // *OPTIONAL* (default = 100) time in ms after which scrolling is considered finished
				scrollTime: 80 // *OPTIONAL* (default = 300) time in ms for the smooth snap
			};
			this._scrollSnap = new ScrollSnap(this.bodyRef, snapConfig);
			this._scrollSnap.bind(this.onEndScroll);
			this._scrollSnap.bindScrollStart(() => {
				if (!this._ignoreNextOverlay) {
					if (this.props.showMonthOverlays && !this.state.isShowingMonthOverlays) {
						this.setState({ isShowingMonthOverlays: true });
					}
				}
				this._ignoreNextOverlay = false;
			});
			this.bodyRef.addEventListener("scroll", this.onBodyScroll);
			this.bodyRef.style.height = this.state.rowHeight * this.numWeeks + "px";
			setTimeout(() => {
				if (this.bodyRef) {
					if (this.bodyRef.offsetWidth != this.bodyRef.clientWidth) {
						//hide the scroll bar but allow scrolling
						this.bodyRef.style.marginRight = -(this.bodyRef.offsetWidth - this.bodyRef.clientWidth) + "px";
					}
					this.scrollToCurrentMonth();
					if (this.props.autoWidth) {
						//re render with new width
						const width = this.bodyRef && this.bodyRef.clientWidth / 7;
						const newHeight = Math.round(width);
						this.setState({ rowHeight: newHeight, loaded: true }, () => {
							this.makeDefaultHeight();
						});
					}
				}
			});
		}
	}

	onRootRef(ref: HTMLDivElement) {
		this.rootRef = ref;
		if (this.rootRef && this.props.shrinkOnMobile) {
			this.rootRef.addEventListener("click", this.onFocus, { capture: true });
			// this.rootRef.addEventListener("touchstart", this.onFocus, { capture: true });
			this.rootRef.addEventListener("touchmove", this.onFocus);
		}
		if (this.rootRef) {
			if (this.props.autoWidth) {
				this.rootRef.style.width = "100%";
			} else {
				this.rootRef.style.width = this.state.rowHeight * 7 + "px";
			}
		}
	}

	onFocus(e: Event) {
		if (window.innerWidth < styles.small && this.props.shrinkOnMobile) {
			this.addMobileOpenHeight();
		}
	}

	selectDate(d: Date) {
		this.setState({ viewDate: new MonthView(d), isShowingMonthOverlays: false });
		this.props.onChange(d);
		if (window.innerWidth < styles.small) {
			this._ignoreNextOverlay = true;
			if (d >= this.getLeftDate(4)) {
				this.bodyRef.scrollTop += this.state.rowHeight * 3;
			} else if (d >= this.getLeftDate(2)) {
				this.bodyRef.scrollTop += this.state.rowHeight * 2;
			}
			this.makeDefaultHeight();
		}
	}

	generateWeek(start: Date) {
		return array.fill(7, i => {
			const date = DateTime.add("day", start, i);
			const time = date.getTime();
			return (size: number) => {
				//anything in this func gets called on every render
				let showMark = false;

				for (let mark = this.dayMarks[0]; this.dayMarks.length && date >= mark; mark = this.dayMarks[0]) {
					if (mark.getTime() === time) {
						showMark = true;
						break;
					} else if (date > mark) {
						this.dayMarks.shift();
					}
				}
				const enabled =
					!this.props.disabled &&
					(!this.props.maxValue || time <= this.maxTime) &&
					(!this.props.minValue || time >= this.minTime);
				return (
					<Day
						{...this.props}
						key={time}
						currentMonth={this.state.viewDate}
						value={date}
						selected={DateTime.isSame("day", date, this.props.value)}
						onClick={this.selectDate}
						showSelectedDayBar={this.props.showSelectedDayBar}
						dayClass={this.props.dayClass}
						size={this.props.autoWidth ? size : undefined}
						enabled={enabled}
						markDay={showMark}
					/>
				);
			};
		});
	}

	/**
	 * create X weeks in past (start of weeks arr) or on future (end of weeks arr)
	 */
	generateWeeks(amount: number, past: boolean, existingWeeks: Week[]) {
		let weekStart = null;
		if (existingWeeks.length === 0) {
			const date = this.state ? this.state.viewDate.viewDate : this.props.value || new Date();
			weekStart = DateTime.startOf("week", past ? DateTime.add("week", date, -amount) : date);
		} else if (past) {
			//start making dates at current start - amount; will create weeks up to current start week
			weekStart = DateTime.add("week", existingWeeks[0].startDate, -amount);
		} else {
			//create weeks from week after current end week
			weekStart = DateTime.add("week", existingWeeks.last().startDate, 1);
		}

		const newweeks = array.fill(amount, i => {
			const date = i > 0 ? DateTime.add("week", weekStart, i) : weekStart;
			const els = this.generateWeek(date);
			return new Week(els, date);
		});

		if (past) {
			//add to start of array
			const existing = newweeks.concat(existingWeeks);
			return existing.slice(0, MAX_WEEKS_RENDERED);
		} else {
			//add to end of array
			const existing = existingWeeks.concat(newweeks);
			return existing.length > MAX_WEEKS_RENDERED ? existing.slice(existing.length - MAX_WEEKS_RENDERED) : existing;
		}
	}

	makeDefaultHeight() {
		if (window.innerWidth < styles.small) {
			this.bodyRef.style.height =
				this.numWeeksMobile && this.props.shrinkOnMobile
					? this.state.rowHeight * this.numWeeksMobile + "px"
					: this.numWeeks
					? this.state.rowHeight * this.numWeeks + "px"
					: "";
		} else {
			this.bodyRef.style.height = this.numWeeks ? this.state.rowHeight * this.numWeeks + "px" : "";
		}
		this._mobileExpanded = false;
		if (this.closeAreaRef.current) {
			this.closeAreaRef.current.style.display = "none";
		}
	}

	addMobileOpenHeight() {
		if (!this._mobileExpanded) {
			this.bodyRef.style.height = this.state.rowHeight * this.numWeeks + "px";
			this._mobileExpanded = true;
			if (this.closeAreaRef.current) {
				this.closeAreaRef.current.style.display = "inherit";
			}
		}
	}

	changeMonth(amount: number, focusMonth = true) {
		this.setState(
			{ viewDate: new MonthView(DateTime.add("month", this.state.viewDate.viewDate, amount)) },
			focusMonth ? this.scrollToCurrentMonth : undefined
		);
	}

	getLeftDate(row = 0) {
		return array.indexClamp(this.state.weeks, this.bodyRef.scrollTop / this.state.rowHeight + row).startDate;
	}

	changeToMonth(date: Date, focusMonth = true) {
		this.setState({ viewDate: new MonthView(date) }, focusMonth ? this.scrollToCurrentMonth : undefined);
	}

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		if (nextProps.maxValue !== this.props.maxValue || nextProps.minValue !== this.props.minValue) {
			//do this here so its only calced once
			this.maxTime = nextProps.maxValue && nextProps.maxValue.getTime();
			this.minTime = nextProps.minValue && nextProps.minValue.getTime();
		}

		return shouldUpdate(this, nextProps, nextState, ["onChange"]);
	}

	checkResize() {
		const width = this.bodyRef && this.bodyRef.clientWidth / 7;
		if (width && this.props.autoWidth) {
			const newHeight = Math.round(width);
			if (newHeight !== this.state.rowHeight) {
				this.setState({ rowHeight: newHeight }, () => {
					this.makeDefaultHeight();
				});
			}
		}
	}

	componentDidUpdate() {
		this.checkResize();
	}

	render() {
		if (this.props.dayMarks.length) {
			// this.dayMarks = array.sort(this.props.dayMarks);
			//assume already ordered
			this.dayMarks = this.props.dayMarks.slice();
		}

		let lastWeek: Week;
		let weekAccumulator = -1;

		return (
			<React.Fragment>
				{this.props.shrinkOnMobile && (
					<div styleName="_calandar_closearea" ref={this.closeAreaRef} onClick={e => this.makeDefaultHeight()} />
				)}
				<div styleName="_calandar_root" className={this.props.className} ref={this.onRootRef} isloaded={this.state.loaded.toString()}>
					<div styleName={`_calandar_header ${this.props.headerClass}`}>
						<div styleName={`_calandar_dateheader-header ${this.props.dateHeaderClass}`}>
							<FontAwesomeIcon icon="chevron-left" onClick={e => this.changeMonth(-1)} />
							<div>{DateTime.format(this.state.viewDate.viewDate, "MMM yyyy")}</div>
							<FontAwesomeIcon icon="chevron-right" onClick={e => this.changeMonth(1)} />
						</div>
						<div styleName={`_calandar_dayHeader ${this.props.weekDayHeader}`}>
							<table>
								<tbody>
									<tr>
										<th isday={this.props.value && this.props.value.getDay() === 0 ? "true" : undefined}>S</th>
										<th isday={this.props.value && this.props.value.getDay() === 1 ? "true" : undefined}>M</th>
										<th isday={this.props.value && this.props.value.getDay() === 2 ? "true" : undefined}>T</th>
										<th isday={this.props.value && this.props.value.getDay() === 3 ? "true" : undefined}>W</th>
										<th isday={this.props.value && this.props.value.getDay() === 4 ? "true" : undefined}>T</th>
										<th isday={this.props.value && this.props.value.getDay() === 5 ? "true" : undefined}>F</th>
										<th isday={this.props.value && this.props.value.getDay() === 6 ? "true" : undefined}>S</th>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div
						styleName={`_calandar_body ${this.props.bodyClass}`}
						ref={this.onBodyRef}
						shrinkonmobile={this.props.shrinkOnMobile.toString()}
					>
						<table>
							<tbody>
								{this.state.weeks.map((x, i) => {
									let newOverlay = false;
									const holder = lastWeek;
									if (this.props.showMonthOverlays) {
										if (
											i === this.state.weeks.length - 1 ||
											(lastWeek && !DateTime.isSame("month", lastWeek.startDate, x.startDate))
										) {
											newOverlay = true;
										}
										++weekAccumulator;
										lastWeek = x;
									}
									const key = x.startDate.getTime();

									if (newOverlay) {
										const overlayStyle = {
											height: this.state.rowHeight * weekAccumulator + "px"
										};
										weekAccumulator = 0;
										return (
											<React.Fragment key={key}>
												{/* we need the scope inside this lambda to build the overlays yet the overlays
												are outside of the table elem, so port these elems into overlay div */}
												<Portal calender={this}>
													{/* fool the diff algortithm into always redrawing these elems by adding uniq keys */}
													<div key={++this.overlayKeys} styleName="_calandar_month-overlay" style={overlayStyle}>
														{DateTime.format(holder.startDate, "MMM yyyy")}
													</div>
												</Portal>
												<tr styleName="_calandar_row">{x.elements.map(ff => ff(this.state.rowHeight))}</tr>
											</React.Fragment>
										);
									} else {
										return (
											<tr key={key} styleName="_calandar_row">
												{x.elements.map(ff => ff(this.state.rowHeight))}
											</tr>
										);
									}
								})}
							</tbody>
						</table>
						<div styleName="_calandar_month-overlays" ref={this.portal} show={this.state.isShowingMonthOverlays.toString()} />
					</div>
				</div>
			</React.Fragment>
		);
	}

	componentWillUnmount() {
		if (this.bodyRef) {
			// this.bodyRef.removeEventListener("scroll", this.onBodyScroll);
			this._scrollSnap.unbind();
			this.bodyRef.removeEventListener("scroll", this.onBodyScroll);
		}
		if (this.rootRef) {
			this.rootRef.removeEventListener("click", this.onFocus);
			// this.rootRef.removeEventListener("touchstart", this.onFocus);
			this.rootRef.removeEventListener("touchmove", this.onFocus);
		}
		if (this.props.autoWidth) {
			window.removeEventListener("resize", this.onResize);
		}
	}
}

class Portal extends React.Component<{ calender: Calender }> {
	constructor(props) {
		super(props);
	}

	render() {
		if (this.props.calender.portal.current) {
			return ReactDOM.createPortal(this.props.children, this.props.calender.portal.current);
		} else {
			return null;
		}
	}
}

interface State {
	viewDate: MonthView;
	weeks: Week[];
	rowHeight: number;
	loaded: boolean;
	isShowingMonthOverlays: boolean;
}
