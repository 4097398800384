import { http, DateTime } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";
import { BookingDto } from "redi-types";
import { HttpResult } from "utils/http";

const route = "Booking/";

export default class bookingservice {
	static GetList(
		pagingParams: PagingParameters,
		groupIds: string[],
		startTime: Date,
		endTime: Date,
		includeSwipes: boolean,
		simplifyOutput: boolean
	): Promise<HttpResult<DataSourceResult<BookingDto>>> {
		let url = `${config.baseUrl + route}GetList`;

		return http({ url, method: "POST", pagingParams, data: groupIds, startTime, endTime, includeSwipes, simplifyOutput })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static Create(booking: BookingDto) {
		let url = `${config.baseUrl + route}Create`;

		return http({ url, method: "POST", data: booking })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static Update(booking: BookingDto, updateBookingGroup: boolean) {
		let url = `${config.baseUrl + route + booking.bookingId}/Update`;

		return http({ url, method: "POST", data: booking, updateBookingGroup })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static Delete(bookingId: string, deleteGroup: boolean) {
		let url = `${config.baseUrl + route + bookingId}/Delete`;

		return http({ url, method: "POST", deleteGroup })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static CreateList(bookingList: BookingDto[]) {
		let url = `${config.baseUrl + route}CreateList`;

		return http({ url, method: "POST", data: bookingList })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}
}
