import * as React from "react";
import { Field } from "redux-form";
import * as styles from "./styles.scss";
import { MergeStyles } from "utils/index";
import Button from "components/Button/Button";

class FormInput extends React.PureComponent {
	static defaultProps = {
		inputClass: ""
	};
	render() {
		if (this.props.fields) {
			//is array of fields
			const {
				label,
				fields,
				inputClass,
				addFieldText,
				meta: { error, submitFailed },
				className,
				...other
			} = this.props;

			return (
				<div className={className} styleName="input-group">
					<div>{label}</div>
					<div styleName={inputClass}>
						{fields.map((member, index) => {
							return <Field key={index} name={member} component={RenderInput} {...other} styleName="__input-sub-spacing" />;
						})}
						<Button onClick={() => fields.push(undefined)}>{addFieldText || "Add Field"}</Button>
						{submitFailed && error && <div styleName="_input-error">{error}</div>}
					</div>
				</div>
			);
		} else {
			//is single field
			const {
				input,
				label,
				multiline,
				type,
				inputClass,
				meta: { touched, error },
				defaultValue,
				classes,
				secure,
				className,
				...other
			} = this.props;
			return (
				<div className={className} styleName="input-group">
					<label>{label}</label>
					{multiline ? (
						<textarea
							styleName={`_form_input ${inputClass}`}
							label={label}
							{...other}
							{...input}
							onChange={e => {
								if (type === "number") {
									input.onChange(parseFloat(e.target.value));
								} else {
									input.onChange(e.target.value);
								}
							}}
							onBlur={e => {
								if (type === "number") {
									input.onBlur(parseFloat(e.target.value));
								} else {
									input.onBlur(e.target.value);
								}
							}}
							type={secure ? "password" : type || "text"}
						/>
					) : (
						<input
							size={1}
							styleName={`_form_input ${inputClass}`}
							label={label}
							{...other}
							{...input}
							onChange={e => {
								if (type === "number") {
									input.onChange(parseFloat(e.target.value));
								} else {
									input.onChange(e.target.value);
								}
							}}
							onBlur={e => {
								if (type === "number") {
									input.onBlur(parseFloat(e.target.value));
								} else {
									input.onBlur(e.target.value);
								}
							}}
							type={secure ? "password" : type || "text"}
						/>
					)}
					{touched && error && <div styleName="_input-error">{error}</div>}
				</div>
			);
		}
	}
}
export const RenderInput = MergeStyles(styles, { forwardRef: false })(FormInput);

// interface Props extends CommonComponentProps {
// 	//for multifields
// 	fields?: string[];
// 	addFieldText?: string;

// 	//single fields
// 	secure?: boolean;
// 	label?: string;
// 	placeholder?: string;
// 	multiline?: string;
// 	type?: string;
// 	inputClass?: string;
// 	defaultValue?: string;
// }

// interface State {}
