import React, { PureComponent } from "react";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import styles from "./styles.scss";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import RadioButton from "components/Radio/radio";
import Select from "components/Select/select";
import { DateTime, TimeRange, array, shouldUpdate, MergeStyles } from "utils/index";
import TimeSliderImpl from "components/TimeSlider/timeslider";
import Checkbox from "components/Checkbox/checkbox";
import { Calendar, DateRangePicker } from "libs/react-date-range/index";
import Button from "components/Button/Button";
import MultiSelect from "components/MultiSelect/multiselect";
import Radio from "components/Radio/radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FormButtonList extends React.Component {
	static defaultProps = {
		actions: [],
		titleTransform: x => x,
		valueTransform: x => x
	};

	setVal(value, exisitnig) {
		if (exisitnig === this.props.valueTransform(value)) {
			this.props.input.onChange(null);
		} else {
			this.props.input.onChange(this.props.valueTransform(value));
		}
	}

	render() {
		let {
			input,
			label,
			actions,
			meta: { touched, error },
			classes,
			titleTransform,
			className,
			valueTransform,
			...other
		} = this.props;

		//turn string defautl values into real values
		if (input.value === "false") {
			input.value = false;
		}
		if (input.value === "true") {
			input.value = true;
		}

		return (
			<div className={className} styleName="input-group">
				<div>{label}</div>
				<div styleName="_fblContainer">
					{actions.map((x, i) => {
						return (
							<Button
								style={{
									backgroundColor: input.value === this.props.valueTransform(x) ? x.color || "#69fc3c" : "#e5e5e5",
									color: "#333"
								}}
								onClick={() => this.setVal(x, input.value)}
								{...other}
								key={i}
							>
								{this.props.titleTransform(x)}
							</Button>
						);
					})}
				</div>
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["valueTransform", "titleTransform", "onBlur", "onChange"]);
	}
}

class FormButtonListMulti extends React.Component {
	static defaultProps = {
		actions: [],
		titleTransform: x => x,
		valueTransform: x => x,
		selectedColor: "#333",
		notSelectedColor: "#333",
		selectedBackColor: "#69fc3c",
		notSelectedBackColor: "#e5e5e5",
		containerClass: ""
	};

	setVal(value, list) {
		if (list.includes(value)) {
			list = list.filter(x => x !== value);
		} else {
			list = list.concat(value);
		}
		this.props.input.onChange(list.map(x => this.props.valueTransform(x)));
	}

	render() {
		let {
			input,
			label,
			actions,
			meta: { touched, error },
			classes,
			titleTransform,
			className,
			valueTransform,
			selectedColor,
			notSelectedColor,
			selectedBackColor,
			notSelectedBackColor,
			containerClass,
			...other
		} = this.props;

		const value = Array.isArray(input.value) ? input.value.slice() : input.value ? [input.value] : [];

		return (
			<div className={className} styleName="input-group">
				<div>{label}</div>
				<div styleName={`_fblContainer ${containerClass}`}>
					{actions.map((x, i) => {
						const selected = value.includes(x);
						return (
							<Button
								style={{
									backgroundColor: selected ? x.backgroundColor || selectedBackColor : notSelectedBackColor,
									color: selected ? x.color || selectedColor : notSelectedColor
								}}
								onClick={() => this.setVal(x, value)}
								{...other}
								key={i}
							>
								{this.props.titleTransform(x)}
							</Button>
						);
					})}
				</div>
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["valueTransform", "titleTransform", "onBlur", "onChange"]);
	}
}

export const RenderButtonList = MergeStyles(styles, { forwardRef: false })(FormButtonList);
export const RenderButtonListMulti = MergeStyles(styles, { forwardRef: false })(FormButtonListMulti);
