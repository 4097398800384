import asyncLoader from "utils/asyncLoader";

const Login = asyncLoader(() => import("ui/Login/Login"));
const Account = asyncLoader(() => import("ui/User/Account/Account"));
const Booking = asyncLoader(() => import("ui/Booking/Booking/Booking"));
const Import = asyncLoader(() => import("ui/Import/Import"));
const Reports = asyncLoader(() => import("ui/Reports/Reports"));
const Administrator = asyncLoader(() => import("ui/User/Administrator/Administrator"));
const Notifications = asyncLoader(() => import("ui/Notifications/Notifications"));

export default [
	{
		path: "/User/Account",
		exact: true,
		component: Account
	},
	{
		path: "/User/Admin",
		exact: true,
		component: Administrator
	},
	{
		path: "/Notifications",
		exact: true,
		component: Notifications
	},
	{
		path: "/Booking",
		exact: true,
		component: Booking
	},
	{
		path: "/Reports",
		exact: true,
		component: Reports
	},
	{
		path: "/Import/Spreadsheet",
		exact: true,
		component: Import
	},
	{
		path: "/Login",
		exact: true,
		component: Login
	}
];
