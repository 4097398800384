import config from "config/config";
import { DateTime, http } from "../../utils/index";
import showToast from "components/Toast/Toast";
import { RegisterDto, UserDto } from "redi-types";

const route = "security/";

export default class SecurityService {
	static login(username, password) {
		if (!username || !password) {
			return { error: "Username or password empty" };
		}

		let url = `${config.apiBaseUrl + route}oauth/token`;

		return http({
			url,
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
			data: `grant_type=password&password=${password}&username=${username}`
		})
			.then(data => data)
			.catch(error => {
				return Error(`Username or password incorrect`);
			});
	}

	static getDetails() {
		if (config.getDetailsFromJwt) {
			try {
				const token = localStorage.getItem(config.tokenKey);
				return { data: parseJwt(token) };
			} catch (e) {
				return { error: e };
			}
		} else {
			let url = `${config.baseUrl + route}GetDetails`;
			return http({ url, method: "GET" })
				.then(data => data)
				.catch(error => {
					showToast("error", error.error);
					return error;
				});
		}
	}

	static ResetPassword(userName) {
		let url = `${config.baseUrl + route}ResetPassword`;

		return http({ url, method: "POST", data: `'${userName}'` })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static ChangePassword(data) {
		let url = `${config.baseUrl + route}ChangePassword`;

		return http({ url, method: "POST", data })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static CreateUser(data: RegisterDto) {
		let url = `${config.baseUrl + route}Register`;

		return http({ url, method: "POST", data })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static BulkCreateUser(data: RegisterDto[]) {
		let url = `${config.baseUrl + route}BulkRegister`;

		return http({ url, method: "POST", data })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static BulkDeleteUsers(userIds: string[]) {
		let url = `${config.baseUrl + route}BulkRemoveLogin`;

		return http({ url, method: "POST", userIds })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static UpdateUser(userAccount: UserDto, password?: string, oldPassword?: string) {
		let url = `${config.baseUrl + route}UpdateAccount`;

		const data = {
			userAccount,
			password,
			oldPassword,
			roles: userAccount.roles
		};
		delete userAccount.roles;

		return http({ url, method: "POST", data })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static checkUserName(username) {
		let url = `${config.baseUrl + route}checkUserName`;

		return http({ url, method: "GET", username })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static async storeBearer(token) {
		localStorage.setItem(config.tokenKey, token.access_token);
		localStorage.setItem(config.tokenExpDateKey, DateTime.add("second", new Date(), token.expires_in).toISOString());
	}
	static async removeBearer() {
		localStorage.removeItem(config.tokenKey);
		localStorage.removeItem(config.tokenExpDateKey);
	}

	/**
	 * Returns token if exists, else returns null
	 */
	static async checkTokenExists(): Promise<string | null> {
		let token = localStorage.getItem(config.tokenKey);
		let exp = localStorage.getItem(config.tokenExpDateKey);

		if (!exp || new Date() > DateTime.parse(exp)) {
			//expired
			token = null;
			await SecurityService.removeBearer();
		}

		return token;
	}
}

function parseJwt(token: string) {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	return JSON.parse(window.atob(base64));
}
