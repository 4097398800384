import * as React from "react";

import * as styles from "./styles.scss";
import { connect } from "react-redux";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { UserDto, ScheduleGroupDto, ScheduleDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";

import { actions as useractions, types as usertypes } from "redux/userRedux";
import { actions as scheduleActions } from "redux/scheduleRedux";
import Button from "components/Button/Button";
import CheckBox from "components/Checkbox/checkbox";
import SearchBar from "components/SearchBar/SearchBar";
import { array } from "utils";
import { subscribeAction } from "boot/configureStore";

@CSSModules(styles, { allowMultiple: true })
class AddRemoveGroupGroup extends React.PureComponent<Props, State> {
	actions: Actions;
	isRemove: boolean;
	unsub: () => void;
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			selectedGroups: []
		};

		this.actions = this.props.exposeActions(this);

		this.isRemove = this.props.existingGroups && !!this.props.existingGroups.length;

		this.unsub = subscribeAction(usertypes.ON_SUCCESS, action => {
			this.props.onClose();
		});
	}

	add() {
		this.actions.addUsersToGroups(this.props.selectedUsers.map(x => x.id), this.state.selectedGroups);
	}

	remove() {
		this.actions.removeUsersFromGroups(this.props.selectedUsers.map(x => x.id), this.state.selectedGroups);
	}

	render() {
		const arr = this.isRemove ? this.props.existingGroups : this.props.groups;
		let reg;
		try {
			reg = this.state.searchText && new RegExp(this.state.searchText, "i");
		} catch {}

		const rows = this.state.searchText ? arr.filter(x => (reg ? reg.test(x.name) : x.name.includes(this.state.searchText))) : arr;

		return (
			<div styleName="root">
				<SearchBar styleName="search" onChange={val => this.setState({ searchText: val })} />
				<div styleName="fields">
					{rows.length > 1 && (
						<CheckBox
							label="All"
							checked={this.state.selectedGroups.length === rows.length}
							onChange={val => {
								if (val) {
									this.setState({
										selectedGroups: rows.map(c => c.scheduleGroupId)
									});
								} else {
									this.setState({
										selectedGroups: []
									});
								}
							}}
						/>
					)}
					{rows.map((row, i) => {
						return (
							<CheckBox
								key={i}
								label={row.siteName + ": " + row.name}
								checked={this.state.selectedGroups.some(x => x === row.scheduleGroupId)}
								onChange={val => {
									if (val) {
										this.setState({
											selectedGroups: this.state.selectedGroups.concat(row.scheduleGroupId)
										});
									} else {
										this.setState({
											selectedGroups: this.state.selectedGroups.filter(f => f !== row.scheduleGroupId)
										});
									}
								}}
							/>
						);
					})}
				</div>
				<div styleName="footer">
					{this.isRemove ? (
						<Button disabled={this.state.selectedGroups.length === 0} onClick={this.remove}>
							Remove from Groups
						</Button>
					) : (
						<Button disabled={this.state.selectedGroups.length === 0} onClick={this.add}>
							Add to Groups
						</Button>
					)}
					<Button theme="secondary" onClick={this.props.onClose}>
						Cancel
					</Button>
				</div>
			</div>
		);
	}

	componentWillUnmount() {
		this.unsub();
	}
}

const bindAction = dispatch => {
	return {
		exposeActions: () => {
			return {
				removeUsersFromGroups: (userIds, scheduleIds) => dispatch(useractions.removeUsersFromGroups(userIds, scheduleIds)),
				addUsersToGroups: (userIds, scheduleIds) => dispatch(useractions.addUsersToGroups(userIds, scheduleIds))
			};
		}
	};
};

const mapStateToProps = (state: ReduxState) => ({
	groups: state.schedulegroup.visibleGroups
});

export default connect(
	mapStateToProps,
	bindAction
)(AddRemoveGroupGroup);

interface State {
	selectedGroups: string[];
	searchText?: string;
}

interface Props {
	exposeActions: (component: AddRemoveGroupGroup) => Actions;
	onClose: () => void;
	existingGroups: ScheduleGroupDto[];
	selectedGroupIds: string[];
	schedules: ScheduleGroupDto[];
	selectedUsers: UserDto[];
	groups: ScheduleGroupDto[];
}

interface Actions {
	addUsersToGroups: (userIds: string[], scheduleIds: string[]) => void;
	removeUsersFromGroups: (userIds: string[], scheduleIds: string[]) => void;
}
