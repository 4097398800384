export default class{
    static getSeriesColors(){
        return [
            "#fdb827",
            "#556cab",
            "#e83e3b",
            "#2D9BB1",
            "#7b52a6",
            "#29c8a7",
            "#5a86fe",
            "#e80f9f",
            "#320065",
        ];
    }

    //Returns undefined if no color is specified for this point name
    static getColorForPoint(pointName){
        if(pointName.indexOf("Ambient.Temperature") !== -1){
            return "#3c9946";
        }else if(pointName.indexOf("Upper Limit") !== -1 || pointName.indexOf("Lower Limit") !== -1){
            return "#38425e";
        }
        return undefined;
    }
}