import * as React from "react";

import { connect } from "react-redux";
import * as router from "react-router-redux";
import { actions } from "redux/loginRedux";
import Spinner from "libs/spinner";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { ReduxState } from "config/reduxRoot";

import Typeahead from "components/Typeahead/Typeahead";
import * as styles from "./styles.scss";
import BaseModal from "components/BaseModal/ModalImpl/ModalImpl";
import Button from "components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScheduleGroupDto, ScheduleAreaDto, BookingDto } from "redi-types";
import { actions as schedulegroupactions } from "redux/scheduleGroupRedux";
import InlineSelect from "components/InlineSelect/InlineSelect";
import { actions as scheduleactions } from "redux/scheduleRedux";
import { DateTime, array, number } from "utils";
import Calender from "components/Calendar/Calendar";
import TimePicker from "../TimePicker/TimePicker";
import { NewBookingContext } from "../NewBooking";

@CSSModules(styles, { allowMultiple: true })
export default class NewBookingModal extends React.PureComponent<Props, State> {
	isMobile: boolean;
	constructor(props, context) {
		super(props);

		this.state = {
			showTime: true
		};
		if (!context.isEdit) {
			this.createBooking(context);
		}
	}
	static contextType = NewBookingContext;
	context: NewBookingContext;

	createBooking(context: NewBookingContext) {
		let booking: Partial<BookingDto> = { ...context.currentBooking } || {};

		booking.repeatConfig = {
			repeatEveryXOfType: 1,
			type: "Week",
			ends: "never",
			month: context.selectedDate.getDate().toString()
		};

		booking.beginTimeLocal = context.bookingStartTime;
		booking.endTimeLocal = context.bookingEndTime;

		this.props.setCurrentBooking(booking);
		this.context && this.props.onClose();
	}

	getSchedules(date: Date, scheduleIds: string[]) {
		//desktop gets all shedules for the week
		if (this.isMobile) {
			this.context.getSchedules(date, DateTime.endOf("day", date), scheduleIds);
		} else {
			this.context.getSchedules(DateTime.startOf("week", date), DateTime.endOf("week", date), scheduleIds);
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.showDate !== this.props.showDate) {
			this.setState({ showTime: !this.props.showDate });
		}
	}

	render() {
		this.isMobile = window.innerWidth < styles.small;
		const bookingIsPast = this.context.bookingStartTime < new Date();
		return (
			<BaseModal
				isOpen={this.props.newBookingOpen}
				onClose={this.props.onClose}
				zIndex={199}
				styleName="new-modal-root"
				rootClass="justifstart"
				classes={styles}
			>
				<div>
					<div styleName="new-modal-header">
						<Button onClick={this.props.onClose} raised={false} variant="circle" color="#E83D3B">
							<FontAwesomeIcon icon="times" />
						</Button>
						{this.state.showTime ? <span>Choose a Time</span> : <span>Choose a Date</span>}
						<Button onClick={() => this.createBooking(this.context)} raised={false} variant="circle" styleName="tick">
							<FontAwesomeIcon icon="check" />
						</Button>
					</div>
					<div styleName="new-modal-header-buttons">
						<div onClick={() => this.setState({ showTime: false })} isselected={!this.state.showTime ? "true" : "false"}>
							{DateTime.format(this.context.selectedDate, "EEE d MMM")}
						</div>
						<div onClick={() => this.setState({ showTime: true })} isselected={this.state.showTime ? "true" : "false"}>
							{`${DateTime.format(this.context.bookingStartTime, "hh:mm a")} > ${DateTime.format(
								this.context.bookingEndTime,
								"hh:mm a"
							)}`}
						</div>
					</div>
					{this.state.showTime ? (
						<TimePicker />
					) : (
						<div>
							<Calender
								value={this.context.selectedDate}
								onChange={d => {
									if (d.getTime() !== this.context.selectedDate.getTime()) {
										const dayDiff = DateTime.diff("day", this.context.selectedDate, d);
										this.context.setContext({
											bookingStartTime: DateTime.addDays(this.context.bookingStartTime, dayDiff),
											bookingEndTime: DateTime.addDays(this.context.bookingEndTime, dayDiff)
										});

										this.context.setSelectedDate(d);
										let schs = this.context.scheduleAreas.slice();
										let schedulesToGet = [];
										//clear each schedule and schedules for new date
										schs.forEach(x => {
											x.schedules = [];
											schedulesToGet.push(x.scheduleAreaId);
										});
										this.getSchedules(d, schedulesToGet);
										this.context.setScheduleAreasList(schs);
									}
								}}
								showSelectedDayBar={false}
								//cannot change date on edit mode for bookings that have past
								disabled={this.context.isEdit && bookingIsPast}
								autoWidth={true}
							/>
						</div>
					)}
				</div>
			</BaseModal>
		);
	}
}

interface State {
	showTime: boolean;
}
interface Props {
	onClose: () => void;
	setCurrentBooking: (dd: Partial<BookingDto>) => void;
	newBookingOpen: boolean;
	showDate: boolean;
}
