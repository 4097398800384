import * as React from "react";
import * as styles from "./styles.scss";
import * as CSSModules from "react-css-modules";
import * as PropTypes from "prop-types";
import { CommonComponentProps, ScheduleDto } from "redi-types";
import { WrappedFieldProps } from "redux-form";
import SwitchButton, { SwitchButtonProps } from "components/Switch/switch";
import { MergeStyles, shouldUpdate, array } from "utils";
import Tabs, { TabAction, TabsProps } from "components/Tabs/Tabs";
import Typeahead from "components/Typeahead/Typeahead";

class FormTypeahead extends React.Component<Props<any> & WrappedFieldProps, State> {
	static defaultProps = {
		typeaheadClass: "",
		errorClass: ""
	};
	render() {
		let {
			input,
			meta: { touched, error },
			classes,
			label,
			className,
			getData,
			typeaheadClass,
			errorClass,
			...other
		} = this.props;

		return (
			<div className={className} styleName="input-group">
				<div>{label}</div>

				<Typeahead multiselect={true} {...other} {...input} styleName={typeaheadClass} getData={getData} />

				{touched && error && <div styleName={`_input-error ${errorClass}`}>{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onBlur", "onChange"]);
	}
}

export const RenderTypeahead = MergeStyles(styles, { forwardRef: false })(FormTypeahead);

interface Props<T> extends CommonComponentProps {
	label?: string;
	getData: (val: string) => Promise<{ data?: T[]; error?: any }>;

	typeaheadClass?: string;
	errorClass?: string;
}

interface State {}
