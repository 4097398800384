import * as React from "react";
import * as CSSModules from "react-css-modules";
import * as PropTypes from "prop-types";

const defaultconf = {
	pure: true,
	forwardRef: true
};

function MergeStylesImpl(component: typeof React.Component, styles: object, conf) {
	conf = { ...defaultconf, ...conf };
	const base = conf.pure ? React.PureComponent : React.Component;
	//do this to merge css classes
	class StylesMerged extends base<{ classes?: object; forwardedRef: React.Ref<any> }, { wrapped: typeof base }> {
		static propTypes = {
			classes: PropTypes.object //style overrides
		};

		static __MERGE_STYLES_ = true;

		constructor(props) {
			super(props);
			if ((component as any).prototype.constructor.__APPLY_THEME_) {
				throw new Error(
					"Cannot Apply theme after styles merge. Swap the order of the decorators `@MergeStyles` and `@RegisterTheme`. `@MergeStyles` should be last"
				);
			}

			this.state = {
				wrapped: CSSModules(
					component,
					{ ...styles, ...this.props.classes },
					{
						allowMultiple: true
					}
				)
			};
		}

		render() {
			const Elem = this.state.wrapped;
			return <Elem {...this.props} />;
		}
	}

	return conf.forwardRef ? React.forwardRef((props, ref) => <StylesMerged {...props} forwardedRef={ref} />) : StylesMerged;
}

/**
 * Extract consumer css from props.classes and merge into base css.
 *
 * NOTE: any class names that match in props.classes and the base css will
 * override the base class of that name. Prefix base css classes with '_' or similiar char to
 * prevent this.
 * 
 * Must set `forwardRef` to false if passing to `connect()`
 * 
 * `@MergeStyles(styles)
	export default class Tabs extends PureComponent`

	OR

`export const RenderCalender = MergeStyles(styles)(FormCalander);`
	
 * @param {object} styles Components' base css
 * @param {defaultconf} conf See `defaultconf` object
 */
export function MergeStyles(
	styles: object,
	conf: {
		pure?: boolean;
		forwardRef?: boolean;
	} = defaultconf
) {
	return function<P, T = new (props: P) => React.Component<P>>(component: T): T {
		return (MergeStylesImpl(component as any, styles, conf) as any) as T;
	};
}
