import { DateTime } from "utils";

export class Week {
	elements: ((size: number) => JSX.Element)[];
	startDate: Date;
	constructor(els: ((size: number) => JSX.Element)[], startDate: Date) {
		this.elements = els;
		this.startDate = startDate;
	}
}

/**
 * class to hold the current viewed month and its date extremes
 */
export class MonthView {
	viewDate: Date;
	start: any;
	end: any;
	constructor(date: Date) {
		this.viewDate = date;
		this.start = DateTime.startOf("month", date);
		this.end = DateTime.endOf("month", date);
	}
}
