import React, { PureComponent } from "react";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import styles from "./styles.scss";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import RadioButton from "components/Radio/radio";
import Select from "components/Select/select";
import { DateTime, TimeRange, array, shouldUpdate, MergeStyles } from "utils/index";
import TimeSliderImpl from "components/TimeSlider/timeslider";
import Checkbox from "components/Checkbox/checkbox";
import { Calendar, DateRangePicker } from "libs/react-date-range/index";
import Button from "components/Button/Button";
import MultiSelect from "components/MultiSelect/multiselect";
import Radio from "components/Radio/radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FormTimeSlider extends React.PureComponent {
	render() {
		let {
			input,
			label,
			placeholder,
			multiline,
			type,
			inputClass,
			meta: { touched, error },
			classes,
			...other
		} = this.props;

		inputClass = inputClass || "";

		const range = new TimeRange(input.value.startDate || other.startTime, input.value.endDate || other.endTime);
		if (!input.value) {
			input.onChange(range);
		}

		return (
			<div>
				<div styleName="space-between">
					<span>Start</span>
					<span>Finish</span>
				</div>
				<div styleName="slider">
					<TimeSliderImpl
						value={input.value || range}
						onChange={d => input.onChange(d)}
						rangeStart={DateTime.startOf("day")}
						rangeEnd={DateTime.endOf("day")}
						timeFormat="HH:mm"
						granularity="minute"
						allowTextInput={true}
						classes={styles}
					/>
				</div>
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}
}

export const RenderSlider = MergeStyles(styles, { forwardRef: false })(FormTimeSlider);
