import { http, DateTime } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";
import { UserDto, UserBookingSummaryDto } from "redi-types";
import { HttpResult } from "utils/http";

const route = "Users/";

export default class usersservice {
	static GetList(pagingParams: PagingParameters, groupIds: string[], roles: string[]): Promise<HttpResult<DataSourceResult<UserDto>>> {
		let url = `${config.baseUrl + route}GetList`;

		return http({ url, method: "POST", pagingParams, data: groupIds, roles })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetUsersSummary(
		pagingParams: PagingParameters,
		groupIds: string[],
		startTime: Date,
		endTime: Date,
		includeSwipes: boolean,
		groupByUser: boolean
	): Promise<HttpResult<DataSourceResult<UserBookingSummaryDto>>> {
		let url = `${config.baseUrl + route}GetUsersSummary`;

		return http({ url, method: "POST", pagingParams, data: groupIds, startTime, endTime, includeSwipes, groupByUser })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static AddUsersToSchedules(userIds: string[], scheduleIds: string[]) {
		let url = `${config.baseUrl + route}AddUsersToSchedules`;

		return http({ url, method: "POST", data: { userIds, scheduleIds } })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static RemoveUsersFromSchedules(userIds: string[], scheduleIds: string[]) {
		let url = `${config.baseUrl + route}RemoveUsersFromSchedules`;

		return http({ url, method: "POST", data: { userIds, scheduleIds } })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static AddUsersToGroups(userIds: string[], groupIds: string[]) {
		let url = `${config.baseUrl + route}AddUsersToGroups`;

		return http({ url, method: "POST", data: { userIds, groupIds } })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static RemoveUsersFromGroups(userIds: string[], groupIds: string[]) {
		let url = `${config.baseUrl + route}RemoveUsersFromGroups`;

		return http({ url, method: "POST", data: { userIds, groupIds } })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}
}
