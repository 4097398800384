import * as datefns from "date-fns";
import { string, array } from "utils";
import { persistType } from "./persist";
import number from "./number";
import { Sortable } from "./array";

let locale;

function getLocale() {
	locale = require("date-fns/locale/en-GB");
}

getLocale();

type DateUnits = "day" | "week" | "month" | "hour" | "year" | "minute" | "second" | "milliseconds";

export default class DateTime {
	private static __months: string[];
	private static __days: string[];
	static get locale() {
		return locale;
	}

	static toUTC(date = new Date()) {
		return datefns.addMinutes(date, date.getTimezoneOffset());
	}

	static toLocal(utcDate: Date) {
		return datefns.addMinutes(utcDate, -utcDate.getTimezoneOffset());
	}

	static parse(timestamp: string, formate: string = null) {
		if (timestamp && formate) {
			return datefns.parse(timestamp, formate, new Date(), { locale });
		} else if (timestamp) {
			return new Date(timestamp);
		} else {
			return null;
		}
	}

	static dayOfWeek(date: Date) {
		return datefns.format(date, "EEEE", { locale });
	}

	static format(date: Date, outputFormat: string);
	static format(date: string, outputFormat: string, inputFormat: string);
	static format(date: Date | string, outputFormat: string, inputFormat: string = undefined) {
		return datefns.format(inputFormat ? this.parse(date as string, inputFormat) : date, outputFormat, { locale });
	}

	static formatToLocale(date: Date);
	static formatToLocale(date: string, inputFormat: string);
	static formatToLocale(date: Date | string, inputFormat: string = undefined) {
		return datefns.format(inputFormat ? this.parse(date as string, inputFormat) : date, null, { locale });
	}

	static formatToLocaleDate(date: Date);
	static formatToLocaleDate(date: string, inputFormat: string);
	static formatToLocaleDate(date: Date | string, inputFormat: string = undefined) {
		return datefns.format(inputFormat ? this.parse(date as string, inputFormat) : date, "dd-MM-yyyy", { locale });
	}

	static toISOstring(date: Date) {
		if (typeof date === "string") {
			return this.parse(date).toISOString();
		} else if (date && this.isValid(date)) {
			return date.toISOString();
		} else {
			return null;
		}
	}

	static isSame(units: DateUnits, left: Date, right: Date): boolean {
		return datefns["isSame" + string.capitalizeFirstLetter(units.toLowerCase())](left, right, { locale });
	}

	static startOf(units: DateUnits, date = new Date()): Date {
		return datefns["startOf" + string.capitalizeFirstLetter(units.toLowerCase())](date, { locale, weekStartsOn: 0 });
	}
	static endOf(units: DateUnits, date = new Date()): Date {
		return datefns["endOf" + string.capitalizeFirstLetter(units.toLowerCase())](date, { locale, weekStartsOn: 0 });
	}

	static daysInRange(startDate: Date, endDate: Date, inclusive?: boolean) {
		return datefns.differenceInDays(endDate, startDate, { locale }) + (inclusive ? 1 : 0);
	}

	static diff(units: DateUnits, startDate: Date, endDate: Date) {
		switch (units) {
			case "day":
				return datefns.differenceInDays(endDate, startDate, { locale });
			case "month":
				return datefns.differenceInMonths(endDate, startDate, { locale });
			case "hour":
				return datefns.differenceInHours(endDate, startDate, { locale });
			case "year":
				return datefns.differenceInYears(endDate, startDate, { locale });
			case "minute":
				return datefns.differenceInMinutes(endDate, startDate, { locale });
			case "second":
				return datefns.differenceInSeconds(endDate, startDate, { locale });
			default:
				throw new Error("Invalid units: " + units);
		}
	}

	static isDateString(str: string) {
		if (str.length >= 19) {
			const date = datefns.parse(str.substr(0, 19), "yyyy-MM-dd'T'HH:mm:ss", new Date());
			return date instanceof Date && !isNaN(date.getTime());
		} else {
			return false;
		}
	}

	static isValid(...dates: Date[]) {
		return dates.every(x => x && x instanceof Date && !isNaN(x.getTime()));
	}

	static months() {
		if (!this.__months) {
			const start = datefns.startOfYear(new Date(), { locale });
			const arr = datefns.differenceInCalendarMonths(datefns.endOfYear(start), start, { locale }) + 1;
			this.__months = array.fill(arr, x => {
				return datefns.format(datefns.addMonths(start, x, { locale }), "MMMM", { locale });
			});
		}
		return this.__months.slice();
	}

	static addDays(date: Date, amount: number) {
		return datefns.addDays(date, amount);
	}

	static add(units: DateUnits, date: Date, amount: number) {
		switch (units) {
			case "day":
				return datefns.addDays(date, amount);
			case "month":
				return datefns.addMonths(date, amount);
			case "hour":
				return datefns.addHours(date, amount);
			case "year":
				return datefns.addYears(date, amount);
			case "minute":
				return datefns.addMinutes(date, amount);
			case "second":
				return datefns.addSeconds(date, amount);
			case "week":
				return datefns.addWeeks(date, amount);
			case "milliseconds":
				return datefns.addMilliseconds(date, amount);
			default:
				throw new Error("Invalid units: " + units);
		}
	}

	static daysOfWeek() {
		if (!this.__days) {
			const now = new Date();
			const arr = datefns.eachDayOfInterval({ start: datefns.startOfWeek(now, { locale }), end: datefns.endOfWeek(now, { locale }) });
			this.__days = arr.reduce((a, d) => {
				a.push(datefns.format(d, "EEEE", { locale }));
				return a;
			}, []);
		}
		return this.__days.slice();
	}

	static min(...dates: Time[]);
	static min(...dates: Date[]);
	static min(...dates: Date[] | Time[]) {
		if (isDateArray(dates)) {
			if (dates.length === 2) {
				return dates[0] < dates[1] ? dates[0] : dates[1];
			} else {
				return array.sort(dates, x => x)[0];
			}
		} else {
			if (dates.length === 2) {
				return dates[0].dailyMilliSeconds() < dates[1].dailyMilliSeconds() ? dates[0] : dates[1];
			} else {
				return array.sort(dates, x => x)[0];
			}
		}
	}

	static max(...dates: Time[]);
	static max(...dates: Date[]);
	static max(...dates: Date[] | Time[]) {
		if (isDateArray(dates)) {
			if (dates.length === 2) {
				return dates[0] > dates[1] ? dates[0] : dates[1];
			} else {
				return array.sort(dates, x => x, true)[0];
			}
		} else {
			if (dates.length === 2) {
				return dates[0] > dates[1] ? dates[0] : dates[1];
			} else {
				return array.sort(dates, x => x, true)[0];
			}
		}
	}

	static timeOfDay(date: Date): Time {
		return new Time(datefns.getHours(date), datefns.getMinutes(date), datefns.getSeconds(date), datefns.getMilliseconds(date));
	}

	static round(units: DateUnits, date: Date, interval = 1): Date {
		return dateround(units, date, interval, "round");
	}
	static floor(units: DateUnits, date: Date, interval = 1): Date {
		return dateround(units, date, interval, "floor");
	}
	static ceil(units: DateUnits, date: Date, interval = 1): Date {
		return dateround(units, date, interval, "ceil");
	}

	static getDayNumberOfMonth(date: Date): string {
		const daysInRange = date.getDate() - 1;
		const weeks = DateTime.daysOfWeek();
		const weekCount = Math.floor(daysInRange / weeks.length) + 1;
		const isLastWeek = DateTime.endOf("month", date).getDate() - date.getDate() < 7;
		return isLastWeek ? "Last" : number.suffixNumber(weekCount);
	}

	static eachDayOfInterval(start: Date, end: Date) {
		return datefns.eachDayOfInterval({ start, end }, { locale });
	}
}

@persistType("_TimeRange")
export class TimeRange {
	startDate: Date;
	endDate: Date;
	constructor(start?: Date, end?: Date) {
		this.startDate = start || DateTime.startOf("day");
		this.endDate = end || DateTime.endOf("day");
	}
}

/**time of day since midnight. Like c# TimeSpan */
@persistType("_Time")
export class Time extends Sortable {
	private _hours: number;
	private _mins: number;
	private _secs: number;
	private _millis: number;
	private dayWraps: number;
	private _date: Date;

	constructor(date: Date);
	constructor(hours: number, mins: number, secs: number, millis: number);
	constructor(hoursOrDate: number | Date, mins?: number, secs?: number, millis?: number) {
		super();
		if (hoursOrDate instanceof Date) {
			this._hours = hoursOrDate.getHours();
			this._mins = hoursOrDate.getMinutes();
			this._secs = hoursOrDate.getSeconds();
			this._millis = hoursOrDate.getMilliseconds();
			this._date = DateTime.startOf("day", hoursOrDate);
			this.dayWraps = 0;
		} else {
			this._hours = hoursOrDate || 0;
			this._mins = mins || 0;
			this._secs = secs || 0;
			this._millis = millis || 0;
			this.dayWraps = 0;
			this._date = DateTime.startOf("day");
		}
	}

	set(hours: number, mins: number, secs: number, millis: number) {
		this._hours = hours;
		this._mins = mins;
		this._secs = secs;
		this._millis = millis;
	}

	//override sortable sort func
	sort(other: Time) {
		if (!other) {
			return -1;
		} else {
			const othermill = other.dailyMilliSeconds();
			const thismills = this.dailyMilliSeconds();
			if (othermill === thismills) {
				return 0;
			} else {
				return othermill > thismills ? 1 : -1;
			}
		}
	}

	get hours() {
		return this._hours;
	}
	get mins() {
		return this._mins;
	}
	get secs() {
		return this._secs;
	}
	get millis() {
		return this._millis;
	}
	get numDayWraps() {
		return this.dayWraps;
	}
	get date() {
		return this._date;
	}

	addMilliseconds(amount: number) {
		this._millis += amount;
		if (this._millis >= 1000 || this._millis < 0) {
			const remain = Math.floor(this._millis / 1000);
			this.addSeconds(remain);
			this._millis = this._millis % 1000;
			if (this._millis < 0) {
				this._millis = 1000 + this._millis;
			}
		}
	}

	addSeconds(amount: number) {
		this._secs += amount;
		if (this._secs >= 60 || this._secs < 0) {
			const remain = Math.floor(this._secs / 60);
			this.addMinutes(remain);
			this._secs = this._secs % 60;
			if (this._secs < 0) {
				this._secs = 60 + this._secs;
			}
		}
	}

	addMinutes(amount: number) {
		this._mins += amount;
		if (this._mins >= 60 || this._mins < 0) {
			const remain = Math.floor(this._mins / 60);
			this.addHours(remain);
			this._mins = this._mins % 60;
			if (this._mins < 0) {
				this._mins = 60 + this._mins;
			}
		}
	}

	addHours(amount: number) {
		this._hours += amount;
		if (this._hours >= 24) {
			this.dayWraps += Math.ceil(this._hours / 24);
			this._hours = this._hours % 24;
		}
		if (this._hours < 0) {
			this.dayWraps += Math.floor(this._hours / 24);
			this._hours = this._hours % 24;
			this._hours = 24 + this._hours;
		}
	}

	dailySeconds() {
		return (this.hours * 60 + this.mins) * 60 + this.secs;
	}

	dailyMinutes() {
		return this.hours * 60 + this.mins;
	}

	dailyMilliSeconds() {
		return ((this.hours * 60 + this.mins) * 60 + this.secs) * 1000 + this.millis;
	}

	totalMinutes() {
		return this.dailyMinutes() + 1440 * this.dayWraps;
	}

	totalSeconds() {
		return this.dailySeconds() + 86400 * this.dayWraps;
	}

	toDate(date = this._date) {
		if (this.dayWraps !== 0) {
			//add any wrapped days
			date = DateTime.addDays(date, this.dayWraps);
		}
		return new Date(date.getFullYear(), date.getMonth(), date.getDate(), this.hours, this.mins, this.secs, this.millis);
	}

	format(format: string) {
		return DateTime.format(this.toDate(), format);
	}
}

function dateround(units: DateUnits, date: Date, interval = 1, func: "round" | "ceil" | "floor" = "round"): Date {
	const unitName = string.capitalizeFirstLetter(units);
	var roundedIntervals = Math[func](datefns[`get${unitName}s`](date, { locale }) / interval) * interval;
	return datefns[`set${unitName}s`](datefns[`startOf${unitName}`](date, { locale }), roundedIntervals, { locale });
}

function isDateArray(item: any[]): item is Date[] {
	return item[0] instanceof Date;
}
