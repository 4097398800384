import React, { PureComponent } from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import { MergeStyles } from "utils/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**

Use:
    timeout = a delay on typeahead. optional
    noTypeahead = optional
	minChars = optional
	params = optional
	classes = optional parent scss import to override class names


*/

@MergeStyles(styles)
export default class SearchBar extends PureComponent {
	static defaultProps = {
		inputClass: "",
		inputWrapClass: "",
		iconClass: "",
		onChange: null,
		timeout: 100,
		onSubmit: null
	};
	static propTypes = {
		timeout: PropTypes.number,
		noTypeahead: PropTypes.bool,
		minChars: PropTypes.number,
		inputClass: PropTypes.string,
		inputWrapClass: PropTypes.string,
		containerClass: PropTypes.string,
		classes: PropTypes.object, //style overrides,
		onChange: PropTypes.func.isRequired,
		onSubmit: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
			text: ""
		};
	}

	cancel = null;

	delay(ms, query) {
		//cancel any pending promises
		if (this.cancel) {
			this.cancel();
			this.cancel = null;
		}
		const prom = new Promise((resolve, reject) => {
			this.cancel = reject;
			setTimeout(() => {
				if (this.cancel == reject) {
					resolve();
				} else {
					reject();
				}
			}, ms);
		});

		prom.then(
			() => {
				if (!this.props.minChars || query.length >= this.props.minChars) {
					this.props.onChange(query);
				}
			},
			() => {
				/*rip*/
			}
		);
	}

	onTextChange(text) {
		this.setState({ text });

		if (!this.props.noTypeahead) {
			if (this.props.timeout) {
				this.delay(this.props.timeout, text);
			} else {
				this.props.onChange(text);
			}
		}
	}

	search(text) {
		if (this.cancel) {
			this.cancel();
			this.cancel = null;
		}
		if (!this.props.minChars || text.length >= this.props.minChars) {
			if (this.props.onSubmit) {
				this.props.onSubmit(text);
			} else {
				this.props.onChange(text);
			}
		}
	}

	render() {
		return (
			<div styleName={`_sb__inputWrap ${this.props.inputWrapClass}`} className={this.props.className}>
				<input
					styleName={`_sb__input ${this.props.inputClass}`}
					placeholder="Search"
					id={this.props.searchBarId}
					value={this.state.text}
					onChange={e => {
						this.onTextChange(e.target.value);
					}}
					onKeyPress={e => {
						if (e.key === "Enter") {
							e.preventDefault();
							this.search(this.state.text);
						}
					}}
				/>

				<FontAwesomeIcon icon="search" styleName={`_sb_icon ${this.props.iconClass}`} onClick={() => this.search(this.state.text)} />
			</div>
		);
	}
}
