import * as React from "react";

import * as styles from "./styles.scss";
import { connect } from "react-redux";
import * as router from "react-router-redux";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { CommonUIProps, CommonComponentProps, UserDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";

import { actions as useractions } from "redux/userRedux";
import SmartTable from "components/SmartTable/SmartTable";
import { DateTime } from "utils";
import Button from "components/Button/Button";
import Modal from "components/BaseModal/Modal";
import { WrappedFieldProps, InjectedFormProps, reduxForm, RecusiveFormErrors, FormErrors, Field } from "redux-form";
import { RenderInput, RenderDropdown, RenderMultiDropdown } from "components/FormInputs";
import { subscribeAction } from "boot/configureStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Auth } from "components/Auth/Auth";

@CSSModules(styles, { allowMultiple: true })
class UserEdit extends React.PureComponent<Props, State> {
	actions: Actions;
	roleactions: string[];
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			showUserModal: false
		};

		this.roleactions = ["IBMS Administrator", "System Administrator", "User", "API User"];

		this.actions = this.props.exposeActions(this);
	}

	save(data: UserDto) {
		this.actions.updateUser(data);
		this.props.onClose();
	}

	render() {
		return (
			<div styleName="root">
				<div styleName="fields">
					<Field name="userName" component={RenderInput} label="Username" styleName="inputfield" disabled />
					<Field name="firstName" component={RenderInput} label="First name" styleName="inputfield" />
					<Field name="lastName" component={RenderInput} label="Last name" styleName="inputfield" />
					<Field name="email" component={RenderInput} label="Email" styleName="inputfield" />
					<Auth config={{ roles: ["IBMS Administrator"] }}>
						<Field
							name="roles"
							component={RenderMultiDropdown}
							label="Role"
							styleName="inputfield"
							actions={this.roleactions}
							zIndex={105}
							classes={styles}
							titleComponent={props => {
								let checked = props.actions.filter(g => g.checked).map(g => props.titleTransform(g.item));
								if (checked.length === props.actions.length) {
									checked = ["All"];
								}
								return (
									<div styleName="multichevron">
										<div>{checked.join(", ")}</div>
										{props.open ? <FontAwesomeIcon icon="chevron-up" /> : <FontAwesomeIcon icon="chevron-down" />}
									</div>
								);
							}}
						/>
					</Auth>
				</div>
				<div styleName="footer">
					<Button onClick={this.props.handleSubmit(data => this.save(data))}>Save</Button>
					<Button theme="secondary" onClick={this.props.onClose}>
						Cancel
					</Button>
				</div>
			</div>
		);
	}
}

const UserForm = reduxForm<UserDto, Props>({
	form: "user-edit",
	enableReinitialize: true,
	validate: (v, props) => {
		const errors: FormErrors<UserDto> = {};

		if (!v.userName) {
			errors.userName = "Required";
		} else if (v.userName.length < 5) {
			errors.userName = "Username must be at least 5 characters";
		}
		if (!v.firstName) {
			errors.firstName = "Required";
		}
		if (!v.lastName) {
			errors.lastName = "Required";
		}
		if (!v.roles || !v.roles.length) {
			errors.roles = "Required";
		}

		return errors;
	}
})(UserEdit);

const bindAction = dispatch => {
	return {
		exposeActions: (component: UserEdit) => {
			return {
				updateUser: user => dispatch(useractions.updateUser(user))
			};
		}
	};
};

const mapStateToProps = (state: ReduxState) => ({});

export default connect(
	mapStateToProps,
	bindAction
)(UserForm);

interface State {}

interface Props extends InjectedFormProps<UserDto, Props> {
	exposeActions: (component: UserEdit) => Actions;
	onClose: () => void;
}

interface Actions {
	updateUser: (user: UserDto) => void;
}
