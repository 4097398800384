import * as React from "react";
import * as styles from "./styles.scss";
import * as PropTypes from "prop-types";
import * as CSSModules from "react-css-modules";
import { CommonComponentProps } from "redi-types";
import { shouldUpdate, string, MergeStyles, DateTime, number, RegisterTheme } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MonthView } from "../classes";

@RegisterTheme("REDI_Calender_Day")
@MergeStyles(styles)
export default class Day extends React.PureComponent<Props, State> {
	static defaultProps = {
		dayClass: "",
		classes: null //styleName overrides
	};
	static propTypes = {
		classes: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.state = {
			label: DateTime.format(this.props.value, this.props.dayFormat)
		};
		//dont use autobind here. too laggy
		this.click = this.click.bind(this);
	}

	click(e: React.MouseEvent<any>) {
		if (this.props.enabled) {
			this.props.onClick(this.props.value);
		}
		e.stopPropagation();
	}

	render() {
		const isMonth = this.props.currentMonth.start <= this.props.value && this.props.currentMonth.end >= this.props.value;
		return (
			<td
				styleName={`_calandar_day_root ${this.props.dayClass}`}
				onClick={this.click}
				selectedday={this.props.selected ? "true" : undefined}
				ismonth={isMonth ? "true" : undefined}
				style={this.props.size ? { height: this.props.size } : undefined}
				disabled={!this.props.enabled}
			>
				<div label="1">{this.state.label}</div>
				{this.props.markDay && (
					<div styleName="_calandar_day_mark" mark="1">
						<div />
					</div>
				)}
				{this.props.selected && this.props.showSelectedDayBar && <div styleName="_calandar_day_selected" footer="1" />}
			</td>
		);
	}
}

interface Props extends CommonComponentProps {
	value: Date;
	onClick: (d: Date) => void;
	dayFormat?: string;
	currentMonth: MonthView;
	showSelectedDayBar: boolean;
	size?: number;
	selected: boolean;
	dayClass?: string;
	enabled: boolean;
	markDay?: boolean;
}

interface State {
	label: string;
}
