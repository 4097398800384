import * as React from "react";
import * as styles from "./mobile.scss";
import Calender from "components/Calendar/Calendar";

import * as close_up from "assets/icons/close_up.png";
import * as close_down from "assets/icons/close_down.png";
import * as open_up from "assets/icons/open_up.png";
import * as open_down from "assets/icons/open_down.png";
import * as add from "assets/icons/add.png";

import Button from "components/Button/Button";
import NewBooking from "../NewBooking/NewBooking";
import Spinner from "libs/spinner";
import { deepClone, array } from "utils";
import { BookingComponentType } from "./Booking";
import SwitchButton from "components/Switch/switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip, { TooltipContent } from "components/Tooltip/tooltip";

export function renderMobile(this: BookingComponentType) {
	let scheduleAreas = null;
	for (const key in this.props.scheduleAreasByDay) {
		if (this.props.scheduleAreasByDay.hasOwnProperty(key)) {
			const area = this.props.scheduleAreasByDay[key];
			scheduleAreas = area;
			//should only be one item here on mobile
			break;
		}
	}
	const allOpen = scheduleAreas && scheduleAreas.every(x => x.closed);
	const allClose = scheduleAreas && scheduleAreas.every(x => !x.closed);

	const pubhol = this.props.publicHolidays.find(x => x.date.getTime() === this.props.selectedDate.getTime());

	return (
		<div styleName="mobile-content" key="mobile">
			<div>
				<Calender
					value={this.props.selectedDate}
					onChange={d => {
						if (d.getTime() !== this.props.selectedDate.getTime()) {
							this.actions.setSelectedDate(d);
							let days = deepClone(this.props.scheduleAreasByDay);
							let schedulesToGet = [];
							array.forEachProps(days, areas => {
								areas.forEach(x => {
									x.schedules = [];
								});
							});
							this.props.scheduleAreas.forEach(x => {
								schedulesToGet.push(x.scheduleAreaId);
							});
							this.getSchedules(d, schedulesToGet);
							this.actions.setScheduleAreasByDayList(days);
						}
					}}
					showSelectedDayBar={false}
					shrinkOnMobile={true}
					autoWidth={true}
					dayMarks={this.props.datesWithBookings}
					classes={styles}
				/>
			</div>
			<div styleName="main">
				<div styleName="mobile-header">
					{allOpen && <img onClick={this.openAllAreas} styleName="icon" src={close_down} />}
					{!allOpen && <img onClick={this.openAllAreas} styleName="icon" src={open_down} />}
					{allClose && <img onClick={this.closeAllAreas} styleName="icon" src={close_up} />}
					{!allClose && <img onClick={this.closeAllAreas} styleName="icon" src={open_up} />}
					<SwitchButton
						size={20}
						styleName="switch"
						checked={this.state.showPastSwipes}
						onChange={val => this.setState({ showPastSwipes: val })}
						label="Past Swipes"
					/>
					{pubhol && (
						<Tooltip styleName="pub-hol-tooltip">
							<div styleName="pub-icon">
								<FontAwesomeIcon icon="exclamation-triangle" color="#FFA500" />
							</div>
							<TooltipContent>
								<div styleName="pubhol-text">Public Holiday</div>
								<div>{pubhol.name}</div>
							</TooltipContent>
						</Tooltip>
					)}
					{this.props.isBusy && <Spinner size={30} />}
				</div>
				<div styleName="areas-wrap">{scheduleAreas && scheduleAreas.map((x, i) => this.renderScheduleArea(x, i))}</div>
				<div styleName="footer-bar">
					{!this.props.isBusy && (
						<Button raised={false} styleName="add-btn" onClick={() => this.setState({ showNewBooking: true })}>
							<img src={add} />
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}
