import * as React from "react";
import * as styles from "./styles.scss";
import * as CSSModules from "react-css-modules";
import * as PropTypes from "prop-types";
import { CommonComponentProps } from "redi-types";
import { WrappedFieldProps } from "redux-form";
import SwitchButton, { SwitchButtonProps } from "components/Switch/switch";
import { MergeStyles, shouldUpdate } from "utils";

class FormSwitch extends React.Component<Props & WrappedFieldProps, State> {
	static defaultProps = {
	};
	render() {
		let {
			input,
			meta: { touched, error },
			classes,
			label,
			className,
			switchLabel,
			...other
		} = this.props;

		return (
			<div className={className} styleName="input-group">
				<div>{label}</div>
				<SwitchButton {...other} {...input} label={switchLabel} checked={input.value} classes={{ ...styles, ...classes }} />
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onBlur", "onChange"]);
	}
}

export const RenderSwitch = MergeStyles(styles, { forwardRef: false })(FormSwitch);

interface Props extends Partial<SwitchButtonProps> {
	switchLabel?: string
}

interface State {}
