import { getState } from "boot/configureStore";
import { string } from "utils/index";
import { BaseAuthConfType } from "./Auth";
import config from "config/config";
import { ReduxState } from "config/reduxRoot";

/**
 *
 * @param {string[]} roles array
 * @param {object} authConf
 */
export function __authImp(roles: string | string[], authConf: Partial<BaseAuthConfType>) {
	if (authConf.disable) {
		return true;
	}

	const state = getState((state: ReduxState) => ({
		route: state.router.location.pathname,
		isLoggedIn: state.login.isLoggedIn,
		loaded: state.login.loaded,
		currentDetails: state.login.currentDetails,
		roles: state.login.currentDetails.role
	}));

	const rolesarr = config.defaultRoles.concat(roles || []);

	let authed = true;

	if (!state.isLoggedIn && authConf.checkLogin) {
		authed = false;
	}

	if (authed && rolesarr && rolesarr.length) {
		authed = rolesarr.some(x => state.roles.some(d => d === x));
	}

	return authed;
}
