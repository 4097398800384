import * as React from "react";
import * as styles from "./styles.scss";
import * as CSSModules from "react-css-modules";
import * as PropTypes from "prop-types";
import { SortableColumn, types } from "./redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { array, ManualPromise, shouldUpdate } from "../../utils/index";
import Button from "components/Button/Button";
import buildqueryservice, { QuerySort } from "services/query/buildquery";
import { SmartTablePublicProps, CollapsableTableHead, CollapsableTableBody, ChangeDataTypes } from "./SmartTable";
import { subscribeAction } from "boot/configureStore";

/**include some internal props along side public props */
export interface props<T> extends SmartTablePublicProps<T> {
	sortableColumns: SortableColumn[];
	setSortColumn: (tableId: string, thKey: string, direction: number) => void;
	updateSortableColumns: (tableId: string) => void;
	changePage: (id: string, amount: number, max: number) => void;
	currentPageNumber: number;
	hasTable: boolean;
}

interface State {
	serverSideAvailableDataCount: number;
}

interface IHead {
	head: boolean;
	index: number;
	//table index for multi tables
	map: number;
	//a ref the thead element
	theadref: React.RefObject<HTMLTableSectionElement>;
	stickytheadref: React.RefObject<HTMLTableSectionElement>;
	stickyHeaderColRefs: React.RefObject<HTMLDivElement>[];
	//a ref to the all the first th in every column, per table
	headerColRefs: React.RefObject<HTMLDivElement>[];
	collapsable: boolean;
	showingSticky?: boolean;
	scrollProps?: { [x: string]: any };
}

interface IBody {
	index: number;
	map: number;
	collapsable: boolean;
	scrollProps?: { [x: string]: any };
	head: boolean;
}

/**Implementation */
export class __SmartTableImpl<T> extends React.Component<props<T>, State> {
	_hasStickyHeaders: boolean;
	sortPropName: string;
	SmartTableHead: new (props: STHeadProps<T>) => React.Component<STHeadProps<T>>;
	SmartTableBody: new (props: STBodyProps<T>) => React.Component<STBodyProps<T>>;
	Paging: new (props: PagingProps<T>) => React.Component<PagingProps<T>>;
	_hasCollapsable: boolean;
	refProms: ManualPromise<{ height: number; key: string }>[];
	tableDimension: TableDimension;
	_isSorting: boolean;
	_currentSortedCol: SortableColumn;
	dataCount: number;
	isServerSide: boolean;
	queryBuilder: buildqueryservice;
	serverCallFunc: (...funcArgs: any[]) => Promise<{ data?: DataSourceResult<T>; error?: any }>;
	/**position of header while at no scroll */
	stickyTopOffset: number;
	unsubActionEvent: () => void;
	_stickyScrollEventBound: boolean;
	_unmounted: boolean;
	tables: { head?: IHead; body?: IBody; scrollProps?: { [s: string]: any } }[];
	lastColCount: number;
	renderElems: any[] | JSX.Element;

	constructor(props) {
		super(props);
		this.handleStickyScroll = this.handleStickyScroll.bind(this);
		this.changePage = this.changePage.bind(this);

		this.stickyTopOffset = 0;
		this._hasStickyHeaders = !!this.props.stickyHeaderScrollRef;
		//create the name of the prop that defines client side sort order
		this.sortPropName = "_st_orig_sort_order_" + this.props.smartTableId;

		if (this.props.data) {
			this.props.data.forEach((x, i) => {
				x[this.sortPropName] = i;
			});
		} else {
			//use server side data
			this.isServerSide = true;
			//create a query builder
			//all config is persisted in redux so just blow away exising query store
			this.queryBuilder = buildqueryservice.initialise(
				"_smarttable_" + this.props.smartTableId,
				this.props.callServer.queryBuilderConf
			);
			const sortcol = this.props.sortableColumns.find(x => x.sortDir !== 0);
			if (sortcol && sortcol.sortDir) {
				this.queryBuilder.addSort(
					sortcol.sortDir > 0 ? new QuerySort(sortcol.dataName, "asc") : new QuerySort(sortcol.dataName, "desc")
				);
			}
			this.queryBuilder.setPage(this.props.rowsPerPage, this.props.currentPageNumber);
			this.serverCallFunc = this.queryBuilder.buildQuery(this.props.callServer.func, this.props.callServer.pagingParametersIndex);
		}

		//apply styleName classes to these components
		this.SmartTableHead = CSSModules(
			SmartTableHead,
			{ ...styles, ...this.props.classes },
			{
				allowMultiple: true
			}
		) as any;

		this.SmartTableBody = CSSModules(
			SmartTableBody,
			{ ...styles, ...this.props.classes },
			{
				allowMultiple: true
			}
		) as any;

		this.Paging = CSSModules(
			SmartTablePaging,
			{ ...styles, ...this.props.classes },
			{
				allowMultiple: true
			}
		) as any;

		this.tables = this.genTableConfigData();

		this.refProms = [];
		//table dimensions is used to define row heights when collapsable columsn is enabled
		this.tableDimension = new TableDimension();

		if (this._hasStickyHeaders) {
			let scroller: HTMLElement;
			if (instanceOfRefObject(this.props.stickyHeaderScrollRef)) {
				scroller = this.props.stickyHeaderScrollRef.current;
			} else {
				scroller = this.props.stickyHeaderScrollRef;
			}
			if (scroller) {
				this._stickyScrollEventBound = true;
				scroller.addEventListener("scroll", this.handleStickyScroll);
			}
		}

		const sortcol = this.props.sortableColumns.find(x => x.sortDir !== 0);
		if (sortcol) {
			this.sortCol(sortcol, true);
		}

		this.unsubActionEvent = subscribeAction(types.REFRESH_DATA, action => {
			if (this.isServerSide && action.payload.tableId === this.props.smartTableId) {
				this.queryBuilder.setPage(this.props.rowsPerPage, this.props.currentPageNumber);
				//add any query passed in
				if (action.payload.query) {
					this.queryBuilder.clearFilters();
					this.queryBuilder.addFilter(action.payload.query);
				} else if (action.payload.query === "reset") {
					this.queryBuilder.clearFilters();
				}
				this.getData("New", action.payload.args);
			}
		});

		this.state = {
			serverSideAvailableDataCount: 0
		};
	}

	genTableConfigData() {
		let tables = [],
			els: (IHead | IBody)[] = [];

		//construct table config for each table within.
		//can have multiple 'sub tables' if collapsable cols is enabled
		//loop over all immediate SmartTable children
		React.Children.forEach(this.props.children, (childEl: React.ReactElement<any>, i) => {
			if (childEl.type === "thead") {
				els.push({
					head: true,
					index: i,
					//table index for multi tables
					map: childEl.props.index,
					//a ref the thead element
					theadref: this._hasStickyHeaders ? React.createRef() : null,
					stickytheadref: this._hasStickyHeaders ? React.createRef() : null,
					stickyHeaderColRefs: this._hasStickyHeaders ? array.fill(this.props.columns, () => React.createRef()) : [],
					//a ref to the all the first th in every column, per table
					headerColRefs: this._hasStickyHeaders ? array.fill(this.props.columns, () => React.createRef()) : [],
					collapsable: false
				});
			}
			if (childEl.type === "tbody") {
				els.push({ index: i, map: childEl.props.index, collapsable: false, head: false });
			}
			if (childEl.type === (CollapsableTableHead as any)) {
				this._hasCollapsable = true;
				els.push({
					head: true,
					index: i,
					//table index for multi tables
					map: childEl.props.index,
					collapsable: true,
					scrollProps: childEl.props.scrollProps,
					//a ref to the all the first th in every column, per table
					headerColRefs: this._hasStickyHeaders ? array.fill(this.props.columns, () => React.createRef()) : [],
					stickyHeaderColRefs: this._hasStickyHeaders ? array.fill(this.props.columns, () => React.createRef()) : [],
					//a ref the thead element
					theadref: this._hasStickyHeaders ? React.createRef() : null,
					stickytheadref: this._hasStickyHeaders ? React.createRef() : null
				});
			}
			if (childEl.type === (CollapsableTableBody as any)) {
				this._hasCollapsable = true;
				els.push({
					index: i,
					map: childEl.props.index,
					collapsable: true,
					scrollProps: childEl.props.scrollProps,
					head: false
				});
			}
		});

		const grouped = array.groupBy(els, x => x.map);

		//now link thead to tbodys
		//any sub elements that didnt specify index should be linked up in numerical order
		array.mapProps(grouped, (x, prop) => {
			if (prop !== "undefined" && prop !== "null") {
				const head = x.find(f => f.head);
				const body = x.find(f => !f.head);
				tables.push({ head, body, scrollProps: { ...body.scrollProps, ...head.scrollProps } });
			} else {
				const heads = x.filter(f => f.head);
				const bodys = x.filter(f => !f.head);

				while (heads.length || bodys.length) {
					tables.push({ head: heads.shift(), body: bodys.shift() });
				}
			}
		});
		return tables;
	}

	/**Execute server side query is table is serverside data table */
	getData(type: ChangeDataTypes, args?: any[]) {
		if (this.isServerSide) {
			this.serverCallFunc(...(this.props.callServer.funcArgs || []), ...args).then(result => {
				if (!this._unmounted) {
					if (result.data) {
						if (result.data.data) {
							this.setState({ serverSideAvailableDataCount: result.data.total });
							this.props.changeData(result.data.data, type);
						}
					} else if (result.error) {
						console.error("Error getting data in SmartTable: ", result.error);
						this.setState({ serverSideAvailableDataCount: 0 });
						this.props.changeData([], type);
					}
				}
			});
		}
	}

	componentDidMount() {
		this.getData("New");
	}

	changePage(id: string, amount: number, max: number) {
		if (this.isServerSide) {
			this.props.changePage(id, amount, this.state.serverSideAvailableDataCount);
			this.queryBuilder.setPage(this.props.rowsPerPage, this.props.currentPageNumber + amount);
			this.serverCallFunc = this.queryBuilder.buildQuery(this.props.callServer.func, this.props.callServer.pagingParametersIndex);
			this.getData("ChangePage");
		} else {
			this.props.changePage(id, amount, max);
		}
	}

	/**
	 * called on scroll when an element is passed to props.stickyHeaderScrollRef
	 *
	 * enables sticky header
	 */
	handleStickyScroll() {
		const tables = this.tables.filter(x => x.head.theadref && x.head.theadref.current && x.head.stickytheadref.current);

		for (let index = 0; index < tables.length; index++) {
			const table = tables[index];
			const thead = table.head.theadref.current;
			const box = thead.getBoundingClientRect();
			if (box.top <= this.stickyTopOffset) {
				if (!table.head.showingSticky) {
					table.head.stickytheadref.current.style.display = "inherit";
					for (let index = 0; index < table.head.headerColRefs.length; index++) {
						//assign the real cols widths to each stich th. do this becuse floating them removes table col width
						const mainTh = table.head.headerColRefs[index];
						const stickyTh = table.head.stickyHeaderColRefs[index];
						if (stickyTh.current) {
							stickyTh.current.style.width = mainTh.current.clientWidth + "px";
						}
					}
				}
				table.head.showingSticky = true;
			} else {
				table.head.showingSticky = false;
				table.head.stickytheadref.current.style.display = "none";
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		//stop unessecary spam
		const update = shouldUpdate(this, nextProps, nextState, ["dataCount", "changeData", "callServer"]);
		if (update && nextProps.data) {
			if (nextProps.data.length > 0 && nextProps.data[0][this.sortPropName] === undefined) {
				nextProps.data.forEach((x, i) => {
					x[this.sortPropName] = i;
				});
			}
		}
		return update;
	}

	sortCol(sortableData: SortableColumn, dontChangeOrder = false) {
		if (sortableData && this.props.allowColumnSort) {
			//the next time this component updates, dont resort in componentDidUpdate
			this._isSorting = true;
			this._currentSortedCol = sortableData;
			let dir = sortableData.sortDir;
			if (!dontChangeOrder) {
				dir = ((dir + 2) % 3) - 1;
			}
			this.props.setSortColumn(this.props.smartTableId, sortableData.th_Key, dir);

			//if we are gonna sort on server side do the call here
			//else sort the array here
			if (this.isServerSide) {
				//build query then query server
				this.queryBuilder.clearSorts();
				if (sortableData && sortableData.sortDir) {
					this.queryBuilder.addSort(
						sortableData.sortDir > 0
							? new QuerySort(sortableData.dataName, "asc")
							: new QuerySort(sortableData.dataName, "desc")
					);
				}
				this.serverCallFunc = this.queryBuilder.buildQuery(this.props.callServer.func, this.props.callServer.pagingParametersIndex);
				this.getData("Sort");
			} else {
				const propPath = sortableData.dataName.split(".");

				if (dir === 0) {
					//sort back to the orignal order
					const sorted = array.sort(this.props.data.slice(), x => x[this.sortPropName], true);
					this._currentSortedCol = null;
					this.props.changeData(sorted, "Sort");
				} else {
					const sorted = array.sort(this.props.data.slice(), x => getValue(x, propPath), dir === -1);
					//pass sorted data back up to consumer component/redux etc
					this.props.changeData(sorted, "Sort");
				}
			}
		}
	}

	/**
	 * IF this table uses colapsable cols, then we need to get teh max height of the same
	 * row index for all the other tables. so rows all line up
	 */
	getHeight(row, existingStyle: React.StyleHTMLAttributes<HTMLDivElement> = {}) {
		if (this.tableDimension.rowHeights[row]) {
			existingStyle.height = this.tableDimension.rowHeights[row];
		}

		return existingStyle;
	}

	/**
	 *
	 * @param {Array} rows
	 * @param {Array} headers
	 * @param {number} tableIndex index of table when using collapsable columns
	 * @param {object} tblConfig item constructed in ctor, tables
	 * @param {bool} isSticky is facade sticky header
	 */
	renderHead(rows, headers, tblConfig, isSticky) {
		rows = React.Children.toArray(rows);

		return rows.map((tr, key) => {
			if (!tr || !tr.props) {
				return null;
			}
			const { children, ...restProps } = tr.props;
			let prom = null;
			if (this._hasCollapsable) {
				//create  a promise to be resolved when the real dom renders
				prom = new ManualPromise();
				this.refProms.push(prom);
			}
			const rowchildren = React.Children.toArray(tr.props.children);
			return (
				<tr
					{...restProps}
					key={tr.key}
					styleName={`_theadrow _responsive-hide-header ${this.props.theadrowClass}`}
					ref={ref => {
						if (ref && this._hasCollapsable) {
							//coz refs arnt created until dom renders, create a promise to calculate el height
							prom.Resolve({
								height: ref.offsetHeight,
								key: "head_" + key
							});
						}
					}}
				>
					{/* //Children.Foreach no longer creates keys so use toArray and iterate */}
					{rowchildren.map((th: any, key2) => {
						const { children, onClick, style, ...restProps } = th.props;
						let newStyle = { ...style } || {};
						//get sort data to see if this col is sorted
						const sortableData = this.props.sortableColumns.find(x => x.th_Key === th.key);
						headers.add(th);
						//if this header is the sticky header and its <tr> index 0, then get the real headers' col widths
						//and assign them to this cols' top cell
						//NULL REF ERROR? prop column count doenst match the number of columns. set it correct n00b
						if (isSticky && key === 0 && tblConfig.head.headerColRefs[key2].current) {
							newStyle.width = tblConfig.head.headerColRefs[key2].current.clientWidth;
						}
						return (
							<th
								{...restProps}
								key={th.key}
								onClick={e => {
									this.sortCol(sortableData);
									onClick && onClick(e);
								}}
								styleName={`_th ${this.props.thClass}`}
								issorted={sortableData && sortableData.sortDir !== 0 ? "true" : "false"}
								style={this.getHeight("head_" + key, newStyle)}
								ref={ref => {
									if (th.ref) {
										if (th.ref.current) {
											th.ref.current = ref;
										} else {
											//is function ref
											th.ref(ref);
										}
									}
									if (!isSticky && key === 0 && tblConfig.head.headerColRefs[key2]) {
										tblConfig.head.headerColRefs[key2].current = ref;
									}
									//if this header is the sticky header and its <tr> index 0, then get the real headers' col widths
									//and assign them to this cols' top cell
									if (isSticky && key === 0 && tblConfig.head.stickyHeaderColRefs[key2]) {
										tblConfig.head.stickyHeaderColRefs[key2].current = ref;
									}
								}}
							>
								<div styleName={`_th-content ${this.props.thContentClass}`}>
									{th.props.children}
									{sortableData && sortableData.sortDir === 1 && (
										<FontAwesomeIcon icon="chevron-up" styleName={this.props.sortChevronClass} key="up" />
									)}
									{sortableData && sortableData.sortDir === -1 && (
										<FontAwesomeIcon icon="chevron-down" styleName={this.props.sortChevronClass} key="down" />
									)}
								</div>
							</th>
						);
					})}
				</tr>
			);
		});
	}

	renderBody(rows, headers) {
		rows = React.Children.toArray(rows);

		if (!this.isServerSide) {
			//client side paging
			if (this.props.rowsPerPage > 0) {
				rows = rows.slice(
					this.props.rowsPerPage * this.props.currentPageNumber,
					this.props.rowsPerPage * this.props.currentPageNumber + this.props.rowsPerPage
				);
			}
		}

		return rows.map((tr, key) => {
			if (!tr || !tr.props) {
				return null;
			}
			const { children, ...restProps } = tr.props;
			const last = rows.length === key + 1;
			const first = key === 0;
			if (headers && headers.length) {
				headers.reset();
			}
			let prom = null;
			if (this._hasCollapsable) {
				//a promise that resolves when the real dom renders
				prom = new ManualPromise();
				this.refProms.push(prom);
			}
			return (
				<tr
					st-first={first.toString()}
					st-last={last.toString()}
					{...restProps}
					key={tr.key}
					styleName={`_tbodyrow ${this.props.tbodyrowClass}`}
					ref={ref => {
						if (ref && this._hasCollapsable) {
							//coz refs arnt created until dom renders, create a promise to calculate el height
							prom.Resolve({
								height: ref.offsetHeight,
								key: "body_" + key
							});
						}
					}}
				>
					{React.Children.map(tr.props.children, (td: any, key2) => {
						if (td) {
							const { children, style, ...restProps } = td.props;

							const colspan = parseInt(td.props.colSpan || "1", 10);
							const header = headers && headers.length && headers.getHeader(colspan);

							return (
								<td
									{...restProps}
									key={td.key}
									styleName={`_td ${this.props.tdClass}`}
									style={this.getHeight("body_" + key, style)}
									ref={td.ref}
								>
									<div styleName={`_td-content ${this.props.tdContentClass}`}>
										{!!this.props.breakOn && (
											<div
												styleName={`_responsive-header ${this.props.collapsedTableHeadersClass}`}
												st-hide={this.props.hideHeaderWhenCollapsed.toString()}
											>
												{header && header.props.children}
											</div>
										)}
										{td.props.children}
									</div>
								</td>
							);
						}
					})}
				</tr>
			);
		});
	}

	render() {
		const { children, className, ...restProps } = this.props;
		if (this.props.hasTable) {
			if(this.renderElems && this.lastColCount !== this.props.columns){
				//if columns changed return last render result, then rerender in componentDidUpdate, after calcing new column vals
				this.lastColCount = this.props.columns;
				return this.renderElems;
			}

			this.lastColCount = this.props.columns;
			let renderData = [];
			this.dataCount = this.isServerSide ? this.state.serverSideAvailableDataCount : this.props.data.length;

			const childrenArr: any[] = React.Children.toArray(this.props.children);
			this.stickyTopOffset = 0;
			if (this._hasStickyHeaders) {
				let scroller: HTMLElement;
				if (instanceOfRefObject(this.props.stickyHeaderScrollRef)) {
					scroller = this.props.stickyHeaderScrollRef.current;
				} else {
					scroller = this.props.stickyHeaderScrollRef;
				}
				if (scroller) {
					this.stickyTopOffset = scroller.getBoundingClientRect().top;
					if (!this._stickyScrollEventBound) {
						//table existed before ref did.
						//bind the event now
						scroller.addEventListener("scroll", this.handleStickyScroll);
						this._stickyScrollEventBound = true;
					}
				}
			}

			//unless collapsable cols is enabled, this list will always have 1 table
			for (let index = 0; index < this.tables.length; index++) {
				const tblData = this.tables[index];
				const headers = new HeaderList();

				const table = (
					<table
						key={index}
						styleName={`_table ${this.props.tableClass} ${
							!!this.props.breakOn && !this._hasCollapsable ? "_responsive-table" : ""
						} ${this.props.breakOn}`}
					>
						{tblData.head &&
						!isNaN(tblData.head.index) && ( //has thead and tbody declared.
								<React.Fragment>
									<this.SmartTableHead
										{...restProps}
										key="head"
										className={childrenArr[tblData.head.index].props.className}
										headRef={tblData.head.theadref}
									>
										{this.renderHead(
											childrenArr[tblData.head.index].props.children,
											headers,
											tblData,
											false //not sticky
										)}
									</this.SmartTableHead>
									{/* create a copy of the real header as a sticky header */}
									{this._hasStickyHeaders && (
										<this.SmartTableHead
											{...restProps}
											key="stickyhead"
											isSticky={true}
											stickyTopPx={this.stickyTopOffset}
											className={childrenArr[tblData.head.index].props.className}
											headRef={tblData.head.stickytheadref}
										>
											{this.renderHead(
												childrenArr[tblData.head.index].props.children,
												headers,
												tblData,
												true //sticky
											)}
										</this.SmartTableHead>
									)}
								</React.Fragment>
							)}
						<this.SmartTableBody
							{...restProps}
							changePage={this.changePage}
							hidePaging={this.tables.length > 1}
							paging={this.Paging}
							key="body"
							dataCount={this.dataCount}
							className={childrenArr[tblData.body.index].props.className}
						>
							{this.renderBody(childrenArr[tblData.body.index].props.children, headers)}
						</this.SmartTableBody>
					</table>
				);
				renderData.push({ table, tblData });
			}

			//if tables > 1 then render those tables side by side. this creates collapsable cols with the appearence
			//of a single table
			this.renderElems =  this.tables.length > 1 ? (
				<div className={this.props.className}>
					<div styleName="_collapse-wrap">
						{renderData.map((x, i) => {
							const isScroll = (x.tblData.head && x.tblData.head.collapsable) || x.tblData.body.collapsable;
							return (
								<div key={i} st-scrollable={isScroll ? "true" : "false"} {...x.tblData.scrollProps}>
									{x.table}
								</div>
							);
						})}
					</div>
					{this.props.rowsPerPage > 0 && <this.Paging {...restProps} changePage={this.changePage} dataCount={this.dataCount} />}
				</div>
			) : (
				renderData.map(x => x.table)
			);
			return this.renderElems;
		} else {
			return null;
		}
	}

	componentDidUpdate(prevProps: props<T>) {
		if (this.props.columns !== prevProps.columns) {
			//if columns change we dont know if a sorted column was removed so remove all current sorts
			this._isSorting = false;
			this._currentSortedCol = null;
			this.tables = this.genTableConfigData();
			if (this.isServerSide) {
				this.queryBuilder.clearSorts();
				this.serverCallFunc = this.queryBuilder.buildQuery(this.props.callServer.func, this.props.callServer.pagingParametersIndex);
			}
			this.props.updateSortableColumns(this.props.smartTableId);
		} else if (prevProps.data !== this.props.data && this._currentSortedCol && !this._isSorting) {
			//if data was sorted and new data list came in, then sort that list
			this.sortCol(this._currentSortedCol, true);
		} else {
			this._isSorting = false;
		}

		if (this.refProms.length && this._hasCollapsable) {
			//get the hights of all tables' rows and adjust them so all rows line up
			if (this.refProms.length) {
				Promise.all(this.refProms.map(x => x.promise))
					.then(result => {
						//see if any rows changed height so we can update the others
						result.forEach(x =>
							this.tableDimension.addHeight({
								key: x.key,
								height: x.height
							})
						);
						if (this.tableDimension.next()) {
							//update with current row heights then clear after render
							//cant be done with setState so use forceRender. naughty
							this.refProms = [];
							this.forceUpdate(() => {
								setTimeout(() => {
									//clear proms to prevent inf loops
									this.refProms = [];
									//reset height data so rows can decrease in size if content adjusts
									this.tableDimension.reset();
								});
							});
						}
					})
					.catch(x => x);
			}
		}
	}

	componentWillUnmount() {
		if (this._hasStickyHeaders) {
			let scroller: HTMLElement;
			if (instanceOfRefObject(this.props.stickyHeaderScrollRef)) {
				scroller = this.props.stickyHeaderScrollRef.current;
			} else {
				scroller = this.props.stickyHeaderScrollRef;
			}
			if (scroller) {
				scroller.removeEventListener("scroll", this.handleStickyScroll);
			}
		}
		if (this.queryBuilder) {
			this.queryBuilder.dispose();
		}
		this.unsubActionEvent();
		this._unmounted = true;
	}
}

interface PagingProps<T> extends Partial<props<T>> {
	dataCount: number;
}

class SmartTablePaging<T> extends React.Component<PagingProps<T>> {
	render() {
		const { rowsPerPage, currentPageNumber, dataCount } = this.props;
		const currentRow = rowsPerPage * currentPageNumber;
		const lastRowOfPage = Math.min(currentRow + rowsPerPage, dataCount);
		return (
			<div styleName="_pagination-wrap">
				<Button
					raised={false}
					disabled={currentRow === 0}
					onClick={() => this.props.changePage(this.props.smartTableId, -1, Math.ceil(dataCount / rowsPerPage))}
				>
					<FontAwesomeIcon icon="chevron-left" />
				</Button>
				<div styleName={`_pagination-text ${this.props.paginationTextClass}`}>
					Showing {Math.min(currentRow + 1, dataCount)} - {lastRowOfPage} of {dataCount}
				</div>
				<Button
					raised={false}
					disabled={lastRowOfPage === dataCount}
					onClick={() => this.props.changePage(this.props.smartTableId, 1, Math.ceil(dataCount / rowsPerPage))}
				>
					<FontAwesomeIcon icon="chevron-right" />
				</Button>
			</div>
		);
	}
}

interface STHeadProps<T> extends Partial<props<T>> {
	className?: string;
	theadClass?: string;
	isSticky?: boolean;
	stickyTopPx?: number;
	headRef: React.RefObject<HTMLTableSectionElement>;
}

class SmartTableHead<T> extends React.Component<STHeadProps<T>> {
	static propTypes = {
		className: PropTypes.string,
		theadClass: PropTypes.string,
		isSticky: PropTypes.bool,
		stickyTopPx: PropTypes.number,
		headRef: PropTypes.exact({
			current: PropTypes.object
		})
	};
	render() {
		return (
			<thead
				className={this.props.className}
				styleName={`${this.props.theadClass} ${this.props.isSticky ? "_sticky-head" : ""}`}
				style={{ top: this.props.stickyTopPx }}
				ref={this.props.headRef}
			>
				{this.props.children}
			</thead>
		);
	}
}

interface STBodyProps<T> extends Partial<props<T>>, PagingProps<T> {
	hidePaging: boolean;
	paging: new (props: PagingProps<T>) => React.Component<PagingProps<T>>;
}

class SmartTableBody<T> extends React.Component<STBodyProps<T>> {
	render() {
		return (
			<tbody className={this.props.className} styleName={this.props.tbodyClass}>
				{this.props.children}

				{this.props.rowsPerPage > 0 && !this.props.hidePaging && (
					<tr>
						<td colSpan={this.props.columns}>
							<this.props.paging {...this.props} />
						</td>
					</tr>
				)}
			</tbody>
		);
	}
}

function getValue(item, propPath) {
	const propName = propPath[0];
	if (propName) {
		const sub = item[propName];
		if (sub && propPath.length > 1) {
			return getValue(sub, propPath.slice(1));
		} else {
			return sub;
		}
	} else {
		return undefined;
	}
}

//helper class to map column headers to columns for responsive tables
//deals with colspan in headers or tds
class HeaderList {
	_colSpan = 0;
	th = null;
	_colsRemaining = 0;

	//advance the index if needed
	_Next() {
		if (this._colsRemaining <= 0) {
			//increment header index based on this cols' colspan
			this._currentIndex += this._colsRemaining * -1 + 1;

			if (this._currentIndex >= this._thList.length) {
				//wrap back to start
				this._currentIndex = 0;
			}
			this.th = this._thList[this._currentIndex];
			this._colSpan = this.th.colSpan;
			this._colsRemaining = this._colSpan;
		}
	}
	getHeader(colspan) {
		this._colsRemaining -= colspan;
		const returns = this.th;
		this._Next();
		return returns.th;
	}

	reset() {
		this._currentIndex = 0;
		this.th = this._thList[this._currentIndex];
		this._colSpan = this.th.colSpan;
		this._colsRemaining = this._colSpan;
	}

	add(th) {
		this._thList.push({ colSpan: parseInt(th.props.colSpan || 1, 10), th: th });
	}

	get length() {
		return this._thList.length;
	}

	_currentIndex = 0;
	_thList = [];
}

class TableDimension {
	pendingRowHeights = {};
	rowHeights = {};

	reset() {
		this.pendingRowHeights = {};
		this.rowHeights = {};
	}

	addHeight(item) {
		const existing = this.pendingRowHeights[item.key];

		if (existing) {
			if (existing < item.height) {
				this.pendingRowHeights[item.key] = item.height;
				return true;
			}
		} else if (item.height > 0) {
			this.pendingRowHeights[item.key] = item.height;
			return true;
		}
		return false;
	}

	next() {
		const any = Object.keys(this.pendingRowHeights).some(prop => {
			if (isNaN(this.rowHeights[prop])) {
				return true;
			} else if (this.rowHeights[prop] !== this.pendingRowHeights[prop]) {
				return true;
			} else {
				return false;
			}
		});
		this.rowHeights = this.pendingRowHeights;
		this.pendingRowHeights = {};
		return any;
	}
}

function instanceOfRefObject<T>(item: any): item is React.RefObject<T> {
	return item && "current" in item;
}
