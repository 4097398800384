import * as React from "react";

import { Field, reduxForm, formValueSelector, InjectedFormProps, FormErrors } from "redux-form";
import * as styles from "./styles.scss";
import { connect } from "react-redux";
import * as router from "react-router-redux";
import { actions } from "redux/loginRedux";
import Spinner from "libs/spinner";
import toast from "components/Toast/Toast";
import * as CSSModules from "react-css-modules";
import { RenderInput } from "components/FormInputs";
import Button from "components/Button/Button";
import autoBind from "libs/react-autobind";
import Modal from "components/BaseModal/Modal";
import { ResetPassDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";

@CSSModules(styles, { allowMultiple: true })
class ResetForm_ extends React.PureComponent<Props & InjectedFormProps<ResetPassDto, Props>, {}> {
	changepw(data) {
		this.props.changePass(data);
	}

	render() {
		const { handleSubmit } = this.props;
		return (
			<form name="resetform" styleName="meform">
				<Field name="oldPassword" component={RenderInput} styleName="input-field" label="Existing Password" secure />
				<Field name="newPassword" component={RenderInput} styleName="input-field" label="Password" secure />
				<Field name="confirmPassword" component={RenderInput} styleName="input-field" label="Confirm Password" secure />

				<Button styleName="loginButton" onClick={handleSubmit(data => this.changepw(data))}>
					Submit
				</Button>
				<Button styleName="loginButton" onClick={this.props.reset}>
					Cancel
				</Button>
			</form>
		);
	}
}

@CSSModules(styles, { allowMultiple: true })
class Login extends React.PureComponent<Props, State> {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	render() {
		return (
			<div styleName="content">
				<div styleName="main">
					<div styleName="heading">Account {this.props.isBusy && <Spinner />}</div>
					<ResetForm {...this.props} />
				</div>
			</div>
		);
	}
}

const ResetForm = reduxForm<ResetPassDto, Props>({
	form: "login-reset-password",
	enableReinitialize: true,
	validate: (v, props) => {
		const errors: FormErrors<ResetPassDto> = {};
		if (!v.newPassword) {
			errors.newPassword = "Required";
		} else if (v.newPassword.length < 6) {
			errors.newPassword = "Password too short";
			//at least 1 upper and lower, one non alphanum char and one number
		} else if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[\W+])/.test(v.newPassword)) {
			errors.newPassword = `Passwords must have at least one non letter or digit character, at least one digit ('0'-'9') and at least one uppercase and one lowercase letter.`;
		}

		if (!v.oldPassword) {
			errors.oldPassword = "Required";
		}

		if (!v.confirmPassword) {
			errors.confirmPassword = "Required";
		}
		if (v.confirmPassword !== v.newPassword) {
			errors.confirmPassword = "Passwords do not match";
		}
		return errors;
	}
})(ResetForm_);

const bindAction = dispatch => {
	return {
		changePass: user => dispatch(actions.changePass(user)),

		navigate: (uri, data) => dispatch(router.push(uri, data)),
		goBack: () => dispatch(router.goBack())
	};
};

const mapStateToProps = (state: ReduxState) => ({
	isBusy: state.login.isBusy,
	errors: state.login.errors,
});

export default connect(
	mapStateToProps,
	bindAction
)(Login);

interface State {}
interface Props {
	isBusy: boolean;
	errors: any[];

	changePass: (user) => void;

	navigate: (uri, data) => void;
	goBack: () => void;
}
