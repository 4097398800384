import * as React from "react";
import * as router from "react-router-redux";
import { connect } from "react-redux";
import { actions as loginactions } from "redux/loginRedux";
import * as CSSModules from "react-css-modules";
import * as styles from "./styles.scss";
import Button from "components/Button/Button";
import { Auth } from "components/Auth/Auth";

@CSSModules(styles, { allowMultiple: true })
class SideMenu extends React.PureComponent<Props, {}> {
	render() {
		const { route } = this.props;
		return (
			<div styleName="root">
				{this.props.isLoggedIn ? (
					<React.Fragment>
						<Button
							styleName="btn"
							raised={false}
							onClick={() => {
								this.props.navigate("/Booking");
								this.props.onClose();
							}}
						>
							<div>
								<span>Bookings</span>
							</div>
						</Button>
						<Auth config={{ roles: ["IBMS Administrator", "System Administrator"] }}>
							<Button
								styleName="btn"
								raised={false}
								onClick={() => {
									this.props.navigate("/Reports");
									this.props.onClose();
								}}
							>
								<div>
									<span>Reports</span>
								</div>
							</Button>
						</Auth>
						<Auth config={{ roles: ["IBMS Administrator", "System Administrator"] }}>
							<Button
								styleName="btn"
								raised={false}
								onClick={() => {
									this.props.navigate("/User/Admin");
									this.props.onClose();
								}}
							>
								<div>
									<span>Users</span>
								</div>
							</Button>
						</Auth>
						<Auth config={{ roles: ["IBMS Administrator", "System Administrator"] }}>
							<Button
								styleName="btn"
								raised={false}
								onClick={() => {
									this.props.navigate("/Notifications");
									this.props.onClose();
								}}
							>
								<div>
									<span>Notifications</span>
								</div>
							</Button>
						</Auth>
						<Button
							styleName="btn"
							raised={false}
							onClick={() => {
								this.props.navigate("/User/Account");
								this.props.onClose();
							}}
						>
							<div>
								<span>Account</span>
							</div>
						</Button>
						<Button
							styleName="btn"
							raised={false}
							onClick={() => {
								this.props.logout();
								this.props.onClose();
							}}
						>
							<div>
								<span>Logout</span>
							</div>
						</Button>
					</React.Fragment>
				) : (
					<Button
						styleName="btn"
						raised={false}
						onClick={() => {
							this.props.navigate("/Login");
							this.props.onClose();
						}}
					>
						<div>
							<span>Login</span>
						</div>
					</Button>
				)}
			</div>
		);
	}
}

const bindAction = dispatch => {
	return {
		logout: () => dispatch(loginactions.logout()),
		navigate: (uri, data) => dispatch(router.push(uri, data)),
		goBack: () => dispatch(router.goBack())
	};
};

const mapStateToProps = state => ({
	isLoggedIn: state.login.isLoggedIn
});

export default connect(
	mapStateToProps,
	bindAction
)(SideMenu);

interface Props {
	route?: string;
	isLoggedIn: boolean;
	logout: () => void;
	onClose: () => void;
	navigate: (uri: string, data?: any) => void;
	goBack: () => void;
}
