import * as React from "react";
import * as PropTypes from "prop-types";
import * as CSSModules from "react-css-modules";
import * as styles from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AngleDown, AngleUp } from "@fortawesome/fontawesome-free";
import { shouldUpdate, MergeStyles, dom } from "utils/index";
import autoBind from "../../libs/react-autobind/index";
import ShellPortal from "components/ShellPortal/ShellPortal";
import { CommonComponentProps } from "redi-types";

/**

Use:
		<Select
			styleName="select-account"
			value={this.props.currentAccount}
			titleTransform={(value, actions) => value.name}
			onChange={value => this.props.setCurrentAccount(value.accountId)}
			actions={this.props.accounts}
			titleClass="select-box-title"
			dropdownIcon={Icon}
			classes={styles}
		/>

*/

@MergeStyles(styles)
export default class InlineSelect<T> extends React.Component<Props<T>, State> {
	static defaultProps = {
		actions: [],
		value: "", // object | string
		titleTransform: (item, actions) => item, //select title or name from value
		zIndex: 5,
		maxMenuHeight: 600,
		showIcon: true,
		titleComponent: null, //component
		widthOfRoot: false, //make popup width of root elem
		absolutePosition: false, //use getAbsoluteBoundingRect to get select pos

		titleClass: "",
		menuWrapClass: "",
		menuClass: "",
		rowClass: ""
	};
	static propTypes = {
		value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		actions: PropTypes.array.isRequired,
		zIndex: PropTypes.number,
		titleTransform: PropTypes.func,
		classes: PropTypes.object //style overrides
	};
	TitleComponent: new () => React.Component<any, any>;
	popup: React.RefObject<HTMLDivElement>;
	rowRefs: { [x: number]: HTMLDivElement };
	currentFocus: number;
	ignoreNextFocus: boolean;
	root: HTMLDivElement;
	ignoreNextBlur: boolean;

	constructor(props) {
		super(props);
		autoBind(this);
		this.state = {
			open: false
		};

		this.TitleComponent =
			this.props.titleComponent &&
			CSSModules(
				this.props.titleComponent,
				{ ...styles, ...this.props.classes },
				{
					allowMultiple: true
				}
			);

		this.popup = React.createRef<HTMLDivElement>();
		this.rowRefs = {};
		this.currentFocus = 0;
	}

	onChange(e, val) {
		e.stopPropagation();
		const selected = this.props.actions[val];
		this.props.onChange(selected);
		this.close(true);
		this.ignoreNextFocus = true;
		this.popup.current && this.popup.current.focus();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["titleComponent", "titleTransform"]);
	}

	toggle() {
		if (this.state.open) {
			this.close(false);
		} else {
			this.ignoreNextBlur = true;
			this.setState({ open: true });
		}
	}

	rootRef(ref: HTMLDivElement) {
		this.root = ref;
		if (ref) {
			// this.root.addEventListener("focus", this.onFocus);
			this.root.addEventListener("blur", this.onBlur);

			if (this.props.forwardedRef) {
				if (typeof this.props.forwardedRef === "function") {
					this.props.forwardedRef(ref);
				} else if ("current" in this.props.forwardedRef) {
					this.props.forwardedRef.current = ref;
				} else {
					throw new Error("Incorrect ref argument passed to Select");
				}
			}
		}
	}

	onFocus(e) {
		if (!this.ignoreNextFocus == true) {
			// Opening the menu is going to blur the. It will be focused back when closed.
			this.ignoreNextBlur = true;
			this.setState({ open: true }, this.focusFirstEl);
		}
		this.ignoreNextFocus = false;
	}

	onBlur(e) {
		if (!this.ignoreNextBlur) {
			this.close(false);
		}
		this.ignoreNextBlur = false;
	}

	focusFirstEl() {
		this.currentFocus = 0;
		this.focusItem();
	}

	focusItem() {
		if (this.state.open && this.props.actions.length) {
			if (this.rowRefs[this.currentFocus]) {
				this.rowRefs[this.currentFocus].focus();
			}
		}
	}

	close(focus) {
		this.setState({ open: false });
		if (focus) {
			this.ignoreNextFocus = true;
			//reset form tab focus back to root
			this.root.focus();
		}
	}

	handleKeyDown(event) {
		if (this.state.open) {
			event.preventDefault();
			this.ignoreNextBlur = true;
			if (event.keyCode == 32 || event.key === "Enter") {
				//32 = space
				this.onChange(event, this.currentFocus);
			} else {
				switch (event.key) {
					case "Tab":
						this.close(true);
						this.ignoreNextFocus = true;
						this.popup.current && this.popup.current.focus();
						break;
					case "ArrowDown":
						this.currentFocus++;
						if (this.currentFocus < this.props.actions.length) {
							this.focusItem();
						} else {
							this.currentFocus = 0;
							this.close(true);
						}
						break;
					case "ArrowUp":
						this.currentFocus--;
						if (this.currentFocus >= 0) {
							this.focusItem();
						} else {
							this.currentFocus = 0;
							this.close(true);
						}
						break;
				}
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				{this.state.open && <div styleName="close-area" onClick={this.close} style={{ zIndex: this.props.zIndex }} />}

				<div
					className={this.props.className}
					styleName="_root"
					ref={this.rootRef}
					onKeyDown={this.handleKeyDown}
					tabIndex={0}
					style={{ zIndex: this.props.zIndex }}
				>
					<div onClick={this.toggle} styleName={`_title ${this.props.titleClass}`}>
						{this.props.titleComponent ? (
							<this.TitleComponent />
						) : (
							<React.Fragment>
								<div>{this.props.titleTransform(this.props.value, this.props.actions)}</div>
								{this.props.showIcon && this.state.open && <FontAwesomeIcon icon="chevron-up" />}
								{this.props.showIcon && !this.state.open && <FontAwesomeIcon icon="chevron-down" />}
							</React.Fragment>
						)}
					</div>
					<div
						styleName={`_menu-wrap ${this.props.menuWrapClass}`}
						open={this.state.open}
						style={{ zIndex: this.props.zIndex ? this.props.zIndex + 1 : undefined, maxHeight: this.props.maxMenuHeight }}
						ref={this.popup}
					>
						{this.props.actions.map((x, i) => {
							return (
								<div
									styleName={`_row ${this.props.rowClass}`}
									tabIndex={-1}
									key={i}
									onClick={e => this.onChange(e, i)}
									ref={ref => (this.rowRefs[i] = ref)}
									onFocus={e => (this.currentFocus = i)}
								>
									{this.props.titleTransform(x, this.props.actions)}
								</div>
							);
						})}
						{!this.props.actions.length && (
							<div styleName={`_row ${this.props.rowClass}`} onClick={this.toggle}>
								<i styleName="none">None</i>
							</div>
						)}
					</div>
				</div>
			</React.Fragment>
		);
	}

	componentWillUnmount() {
		if (this.popup.current) {
			this.popup.current.removeEventListener("focus", this.onFocus);
			this.popup.current.removeEventListener("blur", this.onBlur);
		}
	}
}

interface Props<T> extends CommonComponentProps {
	actions: T[];
	value: T;
	onChange: (s: T) => void;
	titleTransform?: (item: T, actions?: T[]) => string; //select title or name from value
	zIndex?: number;
	showIcon?: boolean;
	titleComponent?: new () => React.Component<any, any>; //component
	widthOfRoot?: boolean; //make popup width of root elem
	absolutePosition?: boolean; //use getAbsoluteBoundingRect to get select pos
	maxMenuHeight?: number;

	titleClass?: string;
	menuWrapClass?: string;
	menuClass?: string;
	rowClass?: string;
}

interface State {
	open: boolean;
}
