import * as React from "react";
import * as styles from "./desktop.scss";
import Calender from "components/Calendar/Calendar";

import * as add from "assets/icons/add.png";

import Button from "components/Button/Button";
import NewBooking from "../NewBooking/NewBooking";
import Spinner from "libs/spinner";
import { DateTime, array, deepClone } from "utils";
import { BookingComponentType } from "./Booking";
import SwitchButton from "components/Switch/switch";

export function renderDesktop(this: BookingComponentType) {
	const weekStart = DateTime.startOf("week", this.props.selectedDate);
	this.schedulesDays = array.fill(7, x => {
		return DateTime.addDays(weekStart, x);
	});

	return (
		<div styleName="desktop-content" key="desktop">
			<div styleName="side">
				<Calender
					value={this.props.selectedDate}
					onChange={d => {
						if (d.getTime() !== this.props.selectedDate.getTime()) {
							this.actions.setSelectedDate(d);
							if (!this.schedulesDays.some(x => x.getTime() === d.getTime())) {
								let days = deepClone(this.props.scheduleAreasByDay);
								let schedulesToGet = [];
								array.forEachProps(days, areas => {
									areas.forEach(x => {
										x.schedules = [];
									});
								});
								this.props.scheduleAreas.forEach(x => {
									schedulesToGet.push(x.scheduleAreaId);
								});
								this.getSchedules(d, schedulesToGet);
								this.actions.setScheduleAreasByDayList(days);
							}
						}
					}}
					showSelectedDayBar={false}
					headerClass="calender-header"
					dayMarks={this.props.datesWithBookings}
					classes={styles}
				/>
				<Button onClick={this.openAllAreas}>Expand All</Button>
				<Button onClick={this.closeAllAreas}>Collapse All</Button>
				<div styleName="switch-wrap">
					<SwitchButton
						size={20}
						checked={this.state.showPastSwipes}
						onChange={val => this.setState({ showPastSwipes: val })}
						label="Show past Swipe Bookings"
					/>
				</div>
				{!this.props.isBusy && (
					<Button raised={false} styleName="add-btn" onClick={() => this.setState({ showNewBooking: true })}>
						<img src={add} /> <span>Make a Booking</span>
					</Button>
				)}
				{this.props.isBusy && <Spinner size={30} />}
			</div>
			<div styleName="main" ref={this.scrollRef}>
				<div styleName="days-header-wrap">
					{this.schedulesDays.map((date, i) => {
						const pubhol = this.props.publicHolidays.find(x => x.date.getTime() === date.getTime());
						return (
							<div
								key={i}
								styleName="day-header"
								isday={date.getTime() === this.props.selectedDate.getTime() ? "true" : "false"}
								ispubhol={pubhol ? "true": undefined}
								onClick={() => {
									this.actions.setSelectedDate(date);
								}}
							>
								<div>{DateTime.format(date, "EEE d")}</div>
								{pubhol && <div>{pubhol.name}</div>}
							</div>
						);
					})}
				</div>

				<div styleName="days-wrap">
					{this.schedulesDays.map((date, i) => {
						const datestr = DateTime.format(date, "yyyy-MM-dd");
						return (
							<div
								styleName="day"
								key={datestr}
								isday={date.getTime() === this.props.selectedDate.getTime() ? "true" : "false"}
							>
								{this.props.scheduleAreasByDay &&
									array.mapProps(this.props.scheduleAreasByDay, (areas, day) => {
										if (datestr === day) {
											return areas.map((s, i) => this.renderScheduleArea(s, i, date));
										}
									})}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
}
