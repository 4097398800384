//initial redux
import { put, call, takeLatest, fork, all, take, cancel, select } from "redux-saga/effects";
import securityservice from "services/security/security";
import { types as setuptypes } from "boot/setupRedux";
import * as router from "react-router-redux";
import { persist, string } from "utils/index";
import { ReduxAction, ScheduleAreaDto, UserDto, RegisterDto, UserBookingSummaryDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";
import scheduleareaservice from "services/schedulearea";
import { types as scheduletypes } from "redux/scheduleRedux";
import usersservice from "services/users";
import { actions as smarttableactions } from "components/SmartTable/redux";

interface State {
	users: UserDto[];
	usersummarys: UserBookingSummaryDto[];
	isBusy: boolean;
	errors: any[];
}

const initialState: State = {
	users: [],
	usersummarys: [],

	isBusy: false,
	errors: []
};

const persistConf = {
	whitelist: [],
	expireInNumHours: 1
};

/////////
//types
export const types = {
	ADD_USERS_TO_SCHEDULES: "users/ADD_USERS_TO_SCHEDULES",
	REMOVE_USERS_FROM_SCHEDULES: "users/REMOVE_USERS_FROM_SCHEDULES",

	ADD_USERS_TO_GROUPS: "users/ADD_USERS_TO_GROUPS",
	REMOVE_USERS_FROM_GROUPS: "users/REMOVE_USERS_FROM_GROUPS",

	SET_USER_LIST: "users/SET_USER_LIST",

	SET_USER_SUMMARY_LIST: "users/SET_USER_SUMMARY_LIST",

	CREATE_USER: "users/CREATE_USER",
	UPDATE_USER: "users/UPDATE_USER",

	DELETE_USERS: "users/DELETE_USERS",

	IS_BUSY: "users/IS_BUSY",
	ON_SUCCESS: "users/ON_SUCCESS",
	ON_ERROR: "users/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction): State {
		switch (action.type) {
			case types.REMOVE_USERS_FROM_GROUPS:
			case types.ADD_USERS_TO_GROUPS:
			case types.REMOVE_USERS_FROM_SCHEDULES:
			case types.ADD_USERS_TO_SCHEDULES:
			case types.CREATE_USER:
			case types.DELETE_USERS:
			case types.UPDATE_USER:
				return { ...state, isBusy: true };
				
			case types.IS_BUSY:
				return { ...state, isBusy: action.payload.val };

			case types.SET_USER_LIST:
				return { ...state, isBusy: false, users: action.payload.list };

			case types.SET_USER_SUMMARY_LIST:
				return { ...state, isBusy: false, usersummarys: action.payload.list };

			case types.ON_ERROR:
				return {
					...state,
					errors: state.errors.concat(action.payload).slice(-10),
					isBusy: false
				};

			case types.ON_SUCCESS:
				return { ...state, isBusy: false };

			default:
				return state;
		}
	}
};
export const reducer = persist("users", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([
			this.addUsersToSchedule(),
			this.createUser(),
			this.removeUsersFromGroups(),
			this.deleteUsers(),
			this.addUsersToGroups(),
			this.removeUsersFromSchedules(),
			this.updateUser()
		]);
	},

	*updateUser() {
		yield takeLatest<ReduxAction>(types.UPDATE_USER, function*({ payload }) {
			const result = yield call(securityservice.UpdateUser, payload.user);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(smarttableactions.refreshData("admin-page-table"));
			}
		});
	},

	*createUser() {
		yield takeLatest<ReduxAction>(types.CREATE_USER, function*({ payload }) {
			const result = yield call(securityservice.BulkCreateUser, payload.users);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(smarttableactions.refreshData("admin-page-table"));
			}
		});
	},

	*deleteUsers() {
		yield takeLatest<ReduxAction>(types.DELETE_USERS, function*({ payload }) {
			const result = yield call(securityservice.BulkDeleteUsers, payload.userIds);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(smarttableactions.refreshData("admin-page-table"));
			}
		});
	},

	*addUsersToSchedule() {
		yield takeLatest<ReduxAction>(types.ADD_USERS_TO_SCHEDULES, function*({ payload }) {
			const result = yield call(usersservice.AddUsersToSchedules, payload.userIds, payload.scheduleIds);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.onSuccess());
			}
		});
	},

	*removeUsersFromSchedules() {
		yield takeLatest<ReduxAction>(types.REMOVE_USERS_FROM_SCHEDULES, function*({ payload }) {
			const result = yield call(usersservice.RemoveUsersFromSchedules, payload.userIds, payload.scheduleIds);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.onSuccess());
			}
		});
	},

	*addUsersToGroups() {
		yield takeLatest<ReduxAction>(types.ADD_USERS_TO_GROUPS, function*({ payload }) {
			const result = yield call(usersservice.AddUsersToGroups, payload.userIds, payload.groupIds);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.onSuccess());
			}
		});
	},

	*removeUsersFromGroups() {
		yield takeLatest<ReduxAction>(types.REMOVE_USERS_FROM_GROUPS, function*({ payload }) {
			const result = yield call(usersservice.RemoveUsersFromGroups, payload.userIds, payload.groupIds);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.onSuccess());
			}
		});
	}
};

////////
//actions
export const actions = {
	setbusy(val: boolean) {
		return {
			type: types.IS_BUSY,
			payload: { val }
		};
	},

	addUsersToSchedules(userIds: string[], scheduleIds: string[]) {
		return {
			type: types.ADD_USERS_TO_SCHEDULES,
			payload: { userIds, scheduleIds }
		};
	},

	removeUsersFromSchedules(userIds: string[], scheduleIds: string[]) {
		return {
			type: types.REMOVE_USERS_FROM_SCHEDULES,
			payload: { userIds, scheduleIds }
		};
	},

	addUsersToGroups(userIds: string[], groupIds: string[]) {
		return {
			type: types.ADD_USERS_TO_GROUPS,
			payload: { userIds, groupIds }
		};
	},

	removeUsersFromGroups(userIds: string[], groupIds: string[]) {
		return {
			type: types.REMOVE_USERS_FROM_GROUPS,
			payload: { userIds, groupIds }
		};
	},

	setUserList(list: UserDto[]) {
		return {
			type: types.SET_USER_LIST,
			payload: { list }
		};
	},

	setUserSummaryList(list: UserBookingSummaryDto[]) {
		return {
			type: types.SET_USER_SUMMARY_LIST,
			payload: { list }
		};
	},

	deleteUsers(userIds: string[]) {
		return {
			type: types.DELETE_USERS,
			payload: { userIds }
		};
	},

	createUser(users: RegisterDto[]) {
		return {
			type: types.CREATE_USER,
			payload: { users }
		};
	},

	updateUser(user: UserDto) {
		return {
			type: types.UPDATE_USER,
			payload: { user }
		};
	},

	onSuccess() {
		return {
			type: types.ON_SUCCESS,
			payload: {}
		};
	},
	onError(data) {
		return {
			type: types.ON_ERROR,
			payload: data
		};
	}
};
