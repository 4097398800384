import { http, DateTime } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";

const route = "ScheduleGroup/";

export default class schedulegroupservice {
	static GetList() {
		let url = `${config.baseUrl + route}GetList`;

		return http({ url, method: "GET" })
			.then(data => data)
			.catch(error => {
				// showToast("error", error.error);
				return error;
			});
	}

	static GetVisibleGroups() {
		let url = `${config.baseUrl + route}GetVisibleGroups`;

		return http({ url, method: "GET" })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetBookingDatesForGroup(groupId: string) {
		let url = `${config.baseUrl + route}GetBookingDatesForGroup`;

		return http({ url, method: "GET", groupId })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

}
