import React, { PureComponent } from "react";

import { Route } from "react-router";
import routes from "config/routeConfig";
import Header from "components/Header/Header";
import { ConnectedRouter } from "react-router-redux";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";

import * as logo from "assets/icons/avani-white-logo.png";

@CSSModules(styles, { allowMultiple: true })
export default class extends PureComponent {
	render() {
		return (
			<div styleName="splashroot">
				<img src={logo} />
			</div>
		);
	}
}
