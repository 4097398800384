import * as React from "react";

import { Route } from "react-router";
import routes from "config/routeConfig";
import Header from "components/Header/Header";
import { ConnectedRouter } from "react-router-redux";
import * as CSSModules from "react-css-modules";
import * as styles from "./styles.scss";
import toast, { ToastDOM } from "components/Toast/Toast";
// import Menu from "components/Menu/Menu";
import "./unhide.css";
import Drawer from "components/Drawer/Drawer";
import SideMenu from "components/SideMenu/SideMenu";
import { TooltipProvider } from "components/Tooltip/tooltip";

@CSSModules(styles, { allowMultiple: true })
export default class extends React.PureComponent<Props, State> {
	constructor(props) {
		super(props);

		this.toggleMenu = this.toggleMenu.bind(this);
		this.state = {
			menuOpen: false
		};
	}

	toggleMenu() {
		this.setState({ menuOpen: !this.state.menuOpen });
	}

	renderRoutes() {
		const rtn = routes.map((s, index) => <Route {...s} key={s.path} />);
		return rtn;
	}

	render() {
		return (
			<ConnectedRouter history={this.props.history}>
				<div styleName="wraps" id="shell">
					{/* header */}
					<Header height={this.props.height} width={this.props.width} toggleMenu={this.toggleMenu} />

					{/* content */}
					<div styleName="content-wraps">
						{window.innerWidth < styles.small && ( //only show on mobile
							<Drawer open={this.state.menuOpen} floatOn="always" zIndex={24} onClose={this.toggleMenu}>
								<SideMenu onClose={this.toggleMenu} />
							</Drawer>
						)}
						<div id="content" styleName="content-class">
							{this.renderRoutes()}
						</div>
					</div>
					<div>{/* footer */}</div>
					<div id="shell-portal" styleName="portal" />
					<ToastDOM />
					<TooltipProvider />
				</div>
			</ConnectedRouter>
		);
	}
}

interface Props {
	history: object;
	height: number;
	width: number;
}

interface State {
	menuOpen: boolean;
}
