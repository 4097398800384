import * as React from "react";

import * as styles from "./styles.scss";
import { connect } from "react-redux";
import * as router from "react-router-redux";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { CommonUIProps, UserDto, ScheduleGroupDto, PropertiesOfType } from "redi-types";
import { ReduxState } from "config/reduxRoot";

import { actions as schedulegroupactions } from "redux/scheduleGroupRedux";
import { actions as timeactions } from "redux/timeRedux";
import SmartTable from "components/SmartTable/SmartTable";
import { actions as smarttableactions } from "components/SmartTable/redux";
import Spinner from "libs/spinner";
import CheckBox from "components/Checkbox/checkbox";
import { QueryPart, QueryGroup } from "services/query/buildquery";
import usersservice from "services/users";
import { AuthHoC } from "components/Auth/Auth";
import Select from "components/Select/select";
import Drawer from "components/Drawer/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBar from "components/SearchBar/SearchBar";
import Menu, { MenuAction } from "components/PopupMenu/menu";
import Tabs, { TabAction } from "components/Tabs/Tabs";
import Bookings from "./Bookings/Bookings";
import ScheduleSummary from "./ScheduleSummary/ScheduleSummary";
import UserSummary from "./UserSummary/UserSummary";
import { DateRangePicker } from "libs/react-date-range";
import { TimeRange, DateTime } from "utils";
import Button from "components/Button/Button";
import SwitchButton from "components/Switch/switch";

const TABLE_NAMES = ["report-booking-table", "report-schedule-table", "report-usersumm-table"];

@CSSModules(styles, { allowMultiple: true })
class Reports extends React.PureComponent<Props, State> {
	actions: Actions;
	rowsPerPageActions: number[];
	scrollRef: React.RefObject<HTMLDivElement>;
	mobileMenu: MenuAction[];
	_throttleAction: { action: Function; args: any[] };
	menuTabs: TabAction[];
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			includeSwipes: true,
			searchText: null,
			showCalender: false,
			tabIndex: 0,
			sideOpen: window.innerWidth > styles.small,
			rowsPerPage: 20,
			currentGroupsSelected: this.props.groups ? this.props.groups.map(x => x.scheduleGroupId) : []
		};

		this.actions = this.props.exposeActions(this);

		this.scrollRef = React.createRef<HTMLDivElement>();
		this.rowsPerPageActions = [10, 20, 40, 80, 160];

		this.menuTabs = [
			new TabAction("Bookings", () => this.setState({ tabIndex: 0 }), val => val === 0),
			new TabAction("Schedule Summary", () => this.setState({ tabIndex: 1 }), val => val === 1),
			new TabAction("User Summary", () => this.setState({ tabIndex: 2 }), val => val === 2)
		];

		this.mobileMenu = [
			new MenuAction("Bookings", () => this.setState({ tabIndex: 0 })),
			new MenuAction("Schedule Summary", () => this.setState({ tabIndex: 1 })),
			new MenuAction("User Summary", () => this.setState({ tabIndex: 2 }))
		];
	}

	throttle(ms: number, action: Function, ...args: any[]) {
		if (!this._throttleAction) {
			this._throttleAction = { action, args };
			setTimeout(() => {
				if (this._throttleAction) {
					this._throttleAction.action(...this._throttleAction.args);
					this._throttleAction = null;
				}
			}, ms);
		} else {
			this._throttleAction = { action, args };
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (!prevProps.groups.length && prevProps.groups !== this.props.groups) {
			this.setState({ currentGroupsSelected: this.props.groups.map(x => x.scheduleGroupId) }, this.actions.refreshData);
		}
	}

	changeTime(result) {
		result.range1.endDate = DateTime.endOf("day", result.range1.endDate);
		this.actions.setTimeRange(result.range1);
	}

	render() {
		return (
			<div styleName="root">
				<div styleName="calendar-wrap" isopen={this.state.showCalender.toString()}>
					<DateRangePicker
						ranges={[this.props.time]}
						dragSelectionEnabled={false}
						showSelectionPreview={true}
						onChange={s => this.changeTime(s)}
						showPreview={true}
						styleName="calender"
					/>
					<div styleName="drpCloseButtonContainer">
						<Button
							onClick={() => {
								this.setState({ showCalender: false });
								this.actions.refreshData();
							}}
						>
							Close
						</Button>
					</div>
				</div>

				<div styleName="header">
					<div styleName="header-date-time" onClick={() => this.setState({ showCalender: true })}>
						{`${DateTime.format(this.props.time.startDate, "d MMM y")} > ${DateTime.format(
							this.props.time.endDate,
							"d MMM y"
						)}`}
					</div>
					<SearchBar
						styleName="search-bar"
						onChange={val => {
							if (val) {
								this.setState({ searchText: val });
							} else if (!val && this.state.searchText) {
								this.setState({ searchText: null });
							}
						}}
					/>
					<div styleName="spinner">{this.props.isBusy && <Spinner color="#fff" />}</div>
					<div styleName="header-tabs">
						<Tabs actions={this.menuTabs} value={this.state.tabIndex} styleName="tabs" showFooterBars={false} />
					</div>

					<div styleName="mobile-menu-wrap">
						<Menu zIndex={15} actions={this.mobileMenu} />
					</div>
				</div>
				<div styleName="content">
					<Drawer
						open={this.state.sideOpen}
						floatOn="sm"
						onClose={() => this.setState({ sideOpen: false })}
						zIndex={4}
						width={250}
						styleName="drawer"
					>
						<div styleName="side" open={this.state.sideOpen}>
							<div styleName="side-content">
							{this.props.groups.length > 1 && (
								<CheckBox
									checked={this.state.currentGroupsSelected.length === this.props.groups.length}
									label="All"
									onChange={val => {
										if (val) {
											this.setState(
												{
													currentGroupsSelected: this.props.groups.map(f => f.scheduleGroupId)
												},
												() => this.throttle(500, this.actions.refreshData)
											);
										} else {
											this.setState(
												{
													currentGroupsSelected: []
												},
												() => this.throttle(500, this.actions.refreshData)
											);
										}
									}}
								/>
							)}
							{this.props.groups.map((x, i) => {
								return (
									<div key={i}>
										<CheckBox
											checked={this.state.currentGroupsSelected.some(d => d === x.scheduleGroupId)}
											label={x.name}
											onChange={val => {
												if (val) {
													this.setState(
														{
															currentGroupsSelected: this.state.currentGroupsSelected.concat(
																x.scheduleGroupId
															)
														},
														() => this.throttle(500, this.actions.refreshData)
													);
												} else {
													this.setState(
														{
															currentGroupsSelected: this.state.currentGroupsSelected.filter(
																f => f !== x.scheduleGroupId
															)
														},
														() => this.throttle(500, this.actions.refreshData)
													);
												}
											}}
										/>
									</div>
								);
							})}
							</div>
							<div styleName="toggle-swipes">
								<SwitchButton
									label="Swipe Cards Included"
									size={20}
									checked={this.state.includeSwipes}
									onChange={val => this.setState({ includeSwipes: val })}
								/>
							</div>
						</div>
					</Drawer>
					<div styleName="menu-btn-wrap" open={this.state.sideOpen}>
						<div onClick={() => this.setState({ sideOpen: true })}>
							<FontAwesomeIcon icon="chevron-right" />
						</div>
					</div>
					<div styleName="main" ref={this.scrollRef}>
						{this.state.tabIndex === 0 ? (
							<Bookings
								{...this.props}
								tableName={TABLE_NAMES[this.state.tabIndex]}
								scrollRef={this.scrollRef}
								currentGroupsSelected={this.state.currentGroupsSelected}
								includeSwipes={this.state.includeSwipes}
								query={this.state.searchText}
							/>
						) : this.state.tabIndex == 1 ? (
							<ScheduleSummary
								{...this.props}
								tableName={TABLE_NAMES[this.state.tabIndex]}
								scrollRef={this.scrollRef}
								currentGroupsSelected={this.state.currentGroupsSelected}
								includeSwipes={this.state.includeSwipes}
								query={this.state.searchText}
							/>
						) : this.state.tabIndex === 2 ? (
							<UserSummary
								{...this.props}
								tableName={TABLE_NAMES[this.state.tabIndex]}
								scrollRef={this.scrollRef}
								currentGroupsSelected={this.state.currentGroupsSelected}
								includeSwipes={this.state.includeSwipes}
								query={this.state.searchText}
							/>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

const bindAction = dispatch => {
	return {
		exposeActions: (component: Reports) => {
			return {
				refreshData: query => dispatch(smarttableactions.refreshData(TABLE_NAMES[component.state.tabIndex], query)),
				setTimeRange: time => dispatch(timeactions.setReportDateRange(time)),
				navigate: (uri, data) => dispatch(router.push(uri, data)),
				goBack: () => dispatch(router.goBack())
			};
		}
	};
};

const mapStateToProps = (state: ReduxState) => ({
	groups: state.schedulegroup.groups,
	time: state.time.reportsRange,

	isBusy: state.booking.isBusy || state.users.isBusy || state.schedule.isBusy
});

export default AuthHoC(
	connect(
		mapStateToProps,
		bindAction
	)(Reports)
);

interface State {
	sideOpen: boolean;
	rowsPerPage: number;
	currentGroupsSelected: string[];
	tabIndex: number;
	showCalender: boolean;
	searchText: string;
	includeSwipes: boolean;
}

interface Props extends CommonUIProps {
	exposeActions: (component: Reports) => Actions;
	isBusy: boolean;
	time: TimeRange;
	groups: ScheduleGroupDto[];
}

interface Actions {
	refreshData: (s?: QueryGroup | QueryPart | "reset") => void;
	setTimeRange: (s: TimeRange) => void;

	navigate: (uri: string, data?: any) => void;
	goBack: () => void;
}



export function formatSecs(num: number) {
	const secs = num % 60;
	const mins = Math.floor(num / 60) % 60;
	const hours = Math.floor(num / 60 / 60) % 24;
	const days = Math.floor(num / 24 / 60 / 60);

	return `${days ? days + " days " : ""}${hours ? hours + " h " : ""}${mins ? mins + " mins" : ""}`;
}

export function download(url: string) {
	const downloadLink = document.createElement("a");
	downloadLink.href = url;
	downloadLink.download = "data.csv";

	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
}
