import React from "react";
import ReactDOM from "react-dom";
import Setup from "./boot/setup";
import * as serviceWorker from "boot/registerServiceWorker.ts";

//add polyfills to config/polyfills.js

ReactDOM.render(<Setup />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
