import * as React from "react";
import * as PropTypes from "prop-types";
import * as CSSModules from "react-css-modules";
import * as styles from "./styles.scss";
import { shouldUpdate, MergeStyles, number } from "utils/index";
import ShellPortal from "components/ShellPortal/ShellPortal";
import autoBind from "../../libs/react-autobind/index";
import { CommonComponentProps } from "redi-types";

let providerInstance: TooltipProvider = null;

interface Props extends CommonComponentProps {
	/**Default 5 */
	zIndex?: number;
	/**Default 100 ms */
	delay?: number;
	disable?: boolean;
}

/**
 * Default zIndex 5.
 * 
 * Use:
 * 
 			<Tooltip>
					...  //on hover these elems..
					<TooltipContent>
							...	 //..show these elems
					</TooltipContent>
				</Tooltip>

	Tooltip not showing? add higher zindex

 */
@CSSModules(styles, { allowMultiple: true })
export default class Tooltip extends React.PureComponent<Props> {
	static defaultProps = {
		zIndex: 5,
		delay: 100 //ms popup delay or undefined for no delay
	};
	static propTypes = {
		zIndex: PropTypes.number,
		classes: PropTypes.object //style overrides
	};

	private timeout: any;
	private ignoreNextClose: boolean;

	constructor(props) {
		super(props);
		autoBind(this);
	}

	hover(e) {
		if (!this.props.disable) {
			if (!providerInstance.state.open) {
				const pos = { x: e.clientX, y: e.clientY };
				if (this.timeout) {
					clearTimeout(this.timeout);
					this.timeout = null;
				}
				this.timeout = setTimeout(() => {
					const children = React.Children.toArray(this.props.children) as React.ReactElement<any>[];
					const tooltipContent = children.find(x => x.type === TooltipContent);

					providerInstance.open(pos.x, pos.y + 20, tooltipContent, this.props);
				}, this.props.delay);
			} else {
				// this.ignoreNextClose = true;
			}
		}
	}

	//called when mouse leaves element
	leave(e) {
		setTimeout(() => {
			//if we left children hover elements but moved into the tooltip itself, dont hide the tooltip
			if (!this.ignoreNextClose) {
				if (this.timeout) {
					clearTimeout(this.timeout);
					this.timeout = null;
				}
				providerInstance.close();
			}
			this.ignoreNextClose = false;
		});
	}

	render() {
		const children = React.Children.toArray(this.props.children) as React.ReactElement<any>[];

		const kids = children
			.filter(x => x.type !== TooltipContent)
			.map(x => {
				const existingHover = x.props.onMouseOver;
				const existingLeave = x.props.onMouseLeave;
				const existingClick = x.props.onClick;
				const existingonTouchStart = x.props.onTouchStart;
				const clone = React.cloneElement(x, {
					onMouseOver: e => {
						this.hover(e);
						existingHover && existingHover(e);
					},
					onMouseLeave: e => {
						this.leave(e);
						existingLeave && existingLeave(e);
					},
					//add onclick so hover works on safari
					onClick: e => {
						existingClick && existingClick(e);
					},
					className: (x.props.className || "") + " _hasTooltip"
				});
				return clone;
			});

		return kids;
	}
}

export class TooltipContent extends React.PureComponent<{ [x: string]: any } & CommonComponentProps> {
	render() {
		return this.props.children;
	}
}

interface State {
	open: boolean;
	tooltipContent: React.ReactElement<any>;
	tooltipProps: Partial<Props>;
	pos: {
		x: number;
		y: number;
	};
	style: React.StyleHTMLAttributes<HTMLDivElement>;
}

/**
 * Use a provider so we dont end up with a million tooltip elements on the shell.
 *
 * This must be singleton global instance throughout the life time of the program. Place in `Shell` or similar location
 */
@CSSModules(styles, { allowMultiple: true })
export class TooltipProvider extends React.PureComponent<{}, State> {
	popup: React.RefObject<HTMLDivElement>;

	constructor(props) {
		super(props);
		autoBind(this);
		providerInstance = this;
		this.popup = React.createRef<HTMLDivElement>();

		document.addEventListener("click", this.close);

		this.state = {
			open: false,
			tooltipProps: null,
			tooltipContent: null,
			pos: null,
			style: { visibilty: "hidden" }
		};
	}

	open(x: number, y: number, tooltipContent: React.ReactElement<any>, props?: Partial<Props>) {
		//first render hidden with content to get dom element dimensions then renreder with XY coords
		this.setState({ pos: { x, y }, tooltipContent, open: true, tooltipProps: props }, () => {
			if (this.state.open) {
				let style: React.StyleHTMLAttributes<HTMLDivElement> = {};
				if (this.popup.current && this.state.pos) {
					const xLimit = this.state.pos.x + this.popup.current.clientWidth + 10;
					const yLimit = this.state.pos.y + Math.max(this.popup.current.clientHeight, 40) + 10;
					style.top = yLimit > window.innerHeight ? this.state.pos.y - (yLimit - window.innerHeight) : this.state.pos.y;
					style.left = xLimit > window.innerWidth ? this.state.pos.x - (xLimit - window.innerWidth) : this.state.pos.x;
				}
				this.setState({ style });
			}
		});
	}

	close() {
		if (this.state.open) {
			this.setState({ pos: null, tooltipContent: null, open: false, tooltipProps: null, style: { visibilty: "hidden" } });
		}
	}

	render() {
		return (
			<ShellPortal>
				<div
					styleName="_root"
					tooltip="1"
					className={this.state.tooltipProps ? this.state.tooltipProps.className : undefined}
					open={this.state.open && !("visibility" in this.state.style)}
					style={{
						zIndex: this.state.tooltipProps && this.state.tooltipProps.zIndex ? this.state.tooltipProps.zIndex + 1 : undefined,
						...this.state.style
					}}
					ref={this.popup}
				>
					{this.state.tooltipContent &&
						(() => {
							const { children, ...restProps } = this.state.tooltipContent.props;

							return (
								<div {...restProps} styleName="base">
									{this.state.tooltipContent}
								</div>
							);
						})()}
				</div>
			</ShellPortal>
		);
	}
}
