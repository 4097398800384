import React from "react";
import styles from "./styles.scss";
import { shouldUpdate, MergeStyles } from "utils/index";
import SignatureCanvas from "react-signature-canvas";
import ImagePreview from "components/Media/preview/ImagePreview";

class FormPic extends React.PureComponent {
	static defaultProps = {};
	constructor(props) {
		super(props);
		this.onUpload = this.onUpload.bind(this);
	}
	render() {
		let {
			input,
			label,
			meta: { touched, error }
		} = this.props;

		const value = Array.isArray(input.value) ? input.value : input.value ? [input.value] : [];

		return (
			<div className={this.props.className} styleName="input-group">
				<div>{label}</div>
				<ImagePreview allowUpload={true} onChange={this.onUpload} images={value} />
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	onUpload(filelinks) {
		this.props.input.onChange(filelinks);
	}
}

export const RenderPhoto = MergeStyles(styles, { forwardRef: false })(FormPic);
