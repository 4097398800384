import * as React from "react";

export default function asyncLoader(importComponent) {
	class AsyncComponent extends React.PureComponent<{}, { component: typeof React.Component }> {
		constructor(props) {
			super(props);

			this.state = {
				component: null
			};
		}

		async componentDidMount() {
			const { default: component } = await importComponent();

			this.setState({
				component: component
			});
		}

		render() {
			const C = this.state.component;

			return C ? <C {...this.props} /> : null;
		}
	}

	return AsyncComponent;
}
