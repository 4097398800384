import ManualPromise from "./promise";

export class ImageUtil {
	static load(file: File): Promise<HTMLImageElement> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			const image = new Image();
			reader.onload = (e: any) => {
				// the result image data
				image.src = e.target.result;
			};

			image.onload = e => {
				resolve(image);
			};

			reader.readAsDataURL(file);
		});
	}

	static loadUrl(url: string): Promise<HTMLImageElement> {
		return new Promise((resolve, reject) => {
			const image = new Image();
			image.onload = e => {
				resolve(image);
			};
			image.crossOrigin = "anonymous";
			image.src = url;
		});
	}

	static loadAsBlob(url: string): Promise<Blob> {
		return new Promise((resolve, reject) => {
			var xhr = new XMLHttpRequest();
			xhr.open("GET", url);
			xhr.responseType = "blob";
			xhr.onerror = function(e) {
				reject(e);
			};
			xhr.onload = () => {
				if (xhr.status === 200) {
					resolve(xhr.response);
				} else {
					reject(xhr.statusText);
				}
			};
			xhr.send();
		});
	}

	static getDimensions(image: HTMLImageElement) {
		return {
			width: image.width,
			height: image.height,
			naturalWidth: image.naturalWidth,
			naturalHeight: image.naturalHeight
		};
	}

	/**
	 * COnvert image blob to base64
	 *
	 */
	static toDataUrl(blob: Blob): Promise<string> {
		return new Promise((resolve, reject) => {
			var reader = new FileReader();
			reader.onloadend = function() {
				resolve(reader.result as string);
			};
			reader.readAsDataURL(blob);
		});
	}
}
