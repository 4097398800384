import * as React from "react";

import * as styles from "./styles.scss";
import { connect } from "react-redux";
import * as router from "react-router-redux";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { CommonUIProps, UserDto, ScheduleGroupDto, PropertiesOfType, ScheduleDto, UserScheduleDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";

import { actions as useractions } from "redux/userRedux";
import { actions as scheduleactions } from "redux/scheduleRedux";
import { actions as schedulegroupactions } from "redux/scheduleGroupRedux";
import SmartTable from "components/SmartTable/SmartTable";
import { actions as smarttableactions } from "components/SmartTable/redux";
import { DateTime, number, array } from "utils";
import Button from "components/Button/Button";
import Modal from "components/BaseModal/Modal";
import Spinner from "libs/spinner";
import CheckBox from "components/Checkbox/checkbox";
import buildqueryservice, { QueryPart, QuerySort, QueryGroup } from "services/query/buildquery";
import usersservice from "services/users";
import { AuthHoC, Auth, checkAuth } from "components/Auth/Auth";
import Select from "components/Select/select";
import Drawer from "components/Drawer/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBar from "components/SearchBar/SearchBar";
import Menu, { MenuAction } from "components/PopupMenu/menu";
import scheduleservice from "services/schedule";

const TABLE_NAME = "notifs-page-table";

@CSSModules(styles, { allowMultiple: true })
class Notifications extends React.PureComponent<Props, State> {
	actions: Actions;
	rowsPerPageActions: number[];
	scrollRef: React.RefObject<HTMLDivElement>;
	mobileMenu: MenuAction[];
	_throttleAction: { action: Function; args: any[] };
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			isBusy: false,
			schedules: [],
			sideOpen: window.innerWidth > styles.small,
			rowsPerPage: 20,
			currentGroupsSelected: this.props.groups ? this.props.groups.map(x => x.scheduleGroupId) : []
		};

		this.actions = this.props.exposeActions(this);

		this.actions.getVisibleGroups();

		this.scrollRef = React.createRef<HTMLDivElement>();
		this.rowsPerPageActions = [10, 20, 40, 80, 160];
	}

	throttle(ms: number, action: Function, ...args: any[]) {
		if (!this._throttleAction) {
			this._throttleAction = { action, args };
			setTimeout(() => {
				if (this._throttleAction) {
					this._throttleAction.action(...this._throttleAction.args);
					this._throttleAction = null;
				}
			}, ms);
		} else {
			this._throttleAction = { action, args };
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (!prevProps.groups.length && prevProps.groups !== this.props.groups) {
			this.setState({ currentGroupsSelected: this.props.groups.map(x => x.scheduleGroupId) }, this.actions.refreshData);
		}
	}

	render() {
		return (
			<div styleName="root">
				<div styleName="header">
					<div styleName="header-spacer" />
					<SearchBar
						styleName="search-bar"
						onChange={val => {
							this.actions.refreshData(new QueryPart("name", "contains", "string", val));
						}}
					/>
					<Button onClick={() => this.actions.save(this.state.schedules)}>Save</Button>
					{(this.props.isBusy || this.state.isBusy) && <Spinner color="#fff" />}
				</div>
				<div styleName="content">
					<Drawer
						open={this.state.sideOpen}
						floatOn="sm"
						onClose={() => this.setState({ sideOpen: false })}
						width={250}
						styleName="drawer"
						zIndex={6}
					>
						<div styleName="side" open={this.state.sideOpen}>
							{this.props.groups.length > 1 && (
								<CheckBox
									checked={this.state.currentGroupsSelected.length === this.props.groups.length}
									label="All"
									onChange={val => {
										if (val) {
											this.setState(
												{
													currentGroupsSelected: this.props.groups.map(f => f.scheduleGroupId)
												},
												() => this.throttle(500, this.actions.refreshData)
											);
										} else {
											this.setState(
												{
													currentGroupsSelected: []
												},
												() => this.throttle(500, this.actions.refreshData)
											);
										}
									}}
								/>
							)}
							{this.props.groups.map((x, i) => {
								return (
									<div key={i}>
										<CheckBox
											checked={this.state.currentGroupsSelected.some(d => d === x.scheduleGroupId)}
											label={x.name}
											onChange={val => {
												if (val) {
													this.setState(
														{
															currentGroupsSelected: this.state.currentGroupsSelected.concat(
																x.scheduleGroupId
															)
														},
														() => this.throttle(500, this.actions.refreshData)
													);
												} else {
													this.setState(
														{
															currentGroupsSelected: this.state.currentGroupsSelected.filter(
																f => f !== x.scheduleGroupId
															)
														},
														() => this.throttle(500, this.actions.refreshData)
													);
												}
											}}
										/>
									</div>
								);
							})}
						</div>
					</Drawer>
					<div styleName="menu-btn-wrap" open={this.state.sideOpen}>
						<div onClick={() => this.setState({ sideOpen: true })}>
							<FontAwesomeIcon icon="chevron-right" />
						</div>
					</div>
					<div styleName="main" ref={this.scrollRef}>
						<SmartTable
							smartTableId={TABLE_NAME}
							columns={4}
							changeData={schedules => {
								this.setState({ isBusy: false, schedules });
							}}
							callServer={{
								func: (pagingParams: PagingParameters) => {
									this.setState({ isBusy: true });
									return scheduleservice.GetUserSchedules(pagingParams, this.state.currentGroupsSelected);
								},
								pagingParametersIndex: 0
							}}
							allowColumnSort={true}
							breakOn="sm"
							stickyHeaderScrollRef={this.scrollRef}
							hideHeaderWhenCollapsed={true}
							rowsPerPage={this.state.rowsPerPage}
							tdClass="td"
							thClass="th"
							classes={styles}
							tableClass="table-root"
							tbodyrowClass="bodyrow"
						>
							<thead>
								<tr>
									<th sortable="ScheduleGroupName">Group</th>
									<th sortable="displayName">Schedule</th>
									<th sortable="NotifOnBookingGreaterThanLimit">
										<CheckBox
											checked={this.state.schedules
												.filter(x => x.bookingHourLimit > 0)
												.every(x => x.notifOnBookingGreaterThanLimit)}
											onChange={val => {
												let copy = this.state.schedules.slice();
												for (let index = 0; index < copy.length; index++) {
													const item = copy[index];
													if (item.bookingHourLimit > 0) {
														item.notifOnBookingGreaterThanLimit = val;
													}
												}
												this.setState({ schedules: copy });
											}}
										/>
										<span>Notify on Booking greater than</span>
									</th>
									<th sortable="NotifOnRecurBookingSoonEnd">
										<CheckBox
											checked={this.state.schedules.every(x => x.notifOnRecurBookingSoonEnd)}
											onChange={val => {
												let copy = this.state.schedules.slice();
												for (let index = 0; index < copy.length; index++) {
													const item = copy[index];
													item.notifOnRecurBookingSoonEnd = val;
												}
												this.setState({ schedules: copy });
											}}
										/>
										<span>Notify when repeat Booking ends in a week</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.schedules.map((row, i) => {
									return (
										<tr key={i}>
											<td styleName="th-padding">{row.scheduleGroupName}</td>
											<td styleName="th-padding">
												 {row.displayName}
											</td>
											<td styleName="th-padding">
												{row.bookingHourLimit > 0 ? (
													<CheckBox
														onChange={val => {
															const index = this.state.schedules.indexOf(row);
															let copy = this.state.schedules.slice();
															copy[index] = { ...row, notifOnBookingGreaterThanLimit: val };
															this.setState({ schedules: copy });
														}}
														checked={row.notifOnBookingGreaterThanLimit}
														label={`Notify on Booking greater than ${row.bookingHourLimit} hour(s)`}
													/>
												) : (
													<div>Schedule hour limit not set</div>
												)}
											</td>
											<td styleName="th-padding">
												<CheckBox
													onChange={val => {
														const index = this.state.schedules.indexOf(row);
														let copy = this.state.schedules.slice();
														copy[index] = { ...row, notifOnRecurBookingSoonEnd: val };
														this.setState({ schedules: copy });
													}}
													checked={row.notifOnRecurBookingSoonEnd}
													label="Repeat Booking"
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						</SmartTable>

						<div styleName="rows-selector">
							<span>Rows per page: </span>
							<Select
								styleName="rows-select"
								value={this.state.rowsPerPage}
								onChange={val => {
									this.setState({ rowsPerPage: val }, () => {
										this.actions.refreshData();
									});
								}}
								actions={this.rowsPerPageActions}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const bindAction = dispatch => {
	return {
		exposeActions: (component: Notifications) => {
			return {
				refreshData: query => dispatch(smarttableactions.refreshData(TABLE_NAME, query)),

				save: s => dispatch(scheduleactions.updateUserSchedules(s)),

				getVisibleGroups: () => dispatch(schedulegroupactions.getVisibleGroups()),

				navigate: (uri, data) => dispatch(router.push(uri, data)),
				goBack: () => dispatch(router.goBack())
			};
		}
	};
};

const mapStateToProps = (state: ReduxState) => ({
	groups: state.schedulegroup.visibleGroups,
	isBusy: state.schedule.isBusy
});

export default AuthHoC(
	connect(
		mapStateToProps,
		bindAction
	)(Notifications),
	{ roles: ["IBMS Administrator", "System Administrator"] }
);

interface State {
	sideOpen: boolean;
	rowsPerPage: number;
	currentGroupsSelected: string[];
	schedules: UserScheduleDto[];
	isBusy: boolean;
}

interface Props extends CommonUIProps {
	exposeActions: (component: Notifications) => Actions;
	groups: ScheduleGroupDto[];
	isBusy: boolean;
}

interface Actions {
	refreshData: (s?: QueryGroup | QueryPart | "reset") => void;
	getVisibleGroups: () => void;
	save: (s: UserScheduleDto[]) => void;

	navigate: (uri: string, data?: any) => void;
	goBack: () => void;
}
