import { combineReducers, Reducer } from "redux";
import { reducer as formReducer } from "redux-form";
import { all, spawn, put, call, fork } from "redux-saga/effects";
import { routerReducer } from "react-router-redux";

import * as smartTableComponentRedux from "components/SmartTable/redux";
import * as setupRedux from "boot/setupRedux";
import * as loginRedux from "redux/loginRedux";
import * as scheduleGroupRedux from "redux/scheduleGroupRedux";
import * as scheduleRedux from "redux/scheduleRedux";
import * as scheduleAreaRedux from "redux/scheduleAreaRedux";
import * as bookingRedux from "redux/bookingRedux";
import * as timeRedux from "redux/timeRedux";
import * as userRedux from "redux/userRedux";
import { GetReturnTypesOfMemberFuncs, PickSingle, UnionToIntersection } from "redi-types";

//any reducers returned by persist(...);
let persistedReducers = [
	loginRedux.reducer,
	scheduleGroupRedux.reducer,
	scheduleAreaRedux.reducer,
	scheduleRedux.reducer,
	timeRedux.reducer,
	userRedux.reducer,
	bookingRedux.reducer
];

//standard, non persisted, reducers
let rootReducer = {
	smartTable: smartTableComponentRedux.reducers.reducer,

	form: formReducer,
	router: routerReducer,
	setup: setupRedux.reducers.reducer
};

const combineSagas = function*() {
	const sagas = [
		setupRedux.sagas.rootSaga.bind(setupRedux.sagas),
		scheduleRedux.sagas.rootSaga.bind(scheduleRedux.sagas),
		scheduleAreaRedux.sagas.rootSaga.bind(scheduleAreaRedux.sagas),
		bookingRedux.sagas.rootSaga.bind(bookingRedux.sagas),
		userRedux.sagas.rootSaga.bind(userRedux.sagas),
		timeRedux.sagas.rootSaga.bind(timeRedux.sagas),
		scheduleGroupRedux.sagas.rootSaga.bind(scheduleGroupRedux.sagas),
		smartTableComponentRedux.sagas.rootSaga.bind(smartTableComponentRedux.sagas),
		loginRedux.sagas.rootSaga.bind(loginRedux.sagas)
	];

	yield all(
		sagas.map(saga =>
			spawn(function*() {
				let isSyncError = false;
				while (!isSyncError) {
					isSyncError = true;
					try {
						setTimeout(() => (isSyncError = false));
						yield call(saga);
					} catch (e) {
						console.error("Saga Error:", e);
						yield put({ type: "SAGA_FAILED", payload: e });
						if (isSyncError) {
							throw e;
						}
					}
				}
			})
		)
	);
};

export const rootSaga = combineSagas;

const merged = MergeReducers(rootReducer, persistedReducers);

export default combineReducers(merged);

export type ReduxState = GetReturnTypesOfMemberFuncs<typeof merged>;

/**
 * extract the names of props from [persisted] and put into root. this is so we dont have to type the name of props in 2 places
 */
function MergeReducers<R, T, U = PickSingle<T>>(root: R, persisted: T[]) {
	let rtns: R & Partial<T> = root;
	for (const pers of persisted) {
		for (let prop in pers) {
			if (pers.hasOwnProperty(prop)) {
				if (rtns[prop]) {
					throw new Error("Reducer prop " + prop + " already exists");
				} else {
					(<any>rtns)[prop] = pers[prop];
				}
			}
		}
	}
	return (rtns as unknown) as UnionToIntersection<U & R>;
}
