import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";
import { MergeStyles } from "utils/index";
/*

Use:


*/

@MergeStyles(styles)
export default class Radio extends PureComponent {
	static defaultProps = {
		actions: [],
		value: "", // object | string
		titleTransform: item => item, //select title or name from value

		titleClass: "",
		radioClass: "",
		radioWrapClass: ""
	};
	static propTypes = {
		actions: PropTypes.array.isRequired,
		onChange: PropTypes.func.isRequired,
		titleTransform: PropTypes.func,
		classes: PropTypes.object //style overrides
	};

	constructor(props) {
		super(props);
	}

	onChange(e, selected) {
		e.stopPropagation();
		this.props.onChange(selected);
	}

	render() {
		return (
			<div className={this.props.className} styleName="_root">
				{this.props.actions.map((x, i) => {
					return (
						<div
							key={i}
							styleName={`_radio ${this.props.radioWrapClass}`}
							onClick={e => this.onChange(e, x)}
							tabIndex={0}
						>
							<div
								styleName={`_radio-button ${this.props.radioClass}`}
								isselected={this.props.value === x ? 1 : undefined}
							>
								<div />
							</div>
							<div styleName={`_radio-title ${this.props.titleClass}`}>
								{this.props.titleTransform(x)}
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}
