import { TimeRange, persistType, number, DateTime } from "utils";
import { getState } from "boot/configureStore";

export type QueryOperator =
	| "eq"
	| "neq"
	| "lt"
	| "lte"
	| "gt"
	| "gte"
	| "null"
	| "notnull"
	| "in"
	| "startswith"
	| "endswith"
	| "contains"
	| "doesnotcontain"
	| "after"
	| "between"
	| "before";

export type QueryValueType = "integer" | "decimal" | "date" | "datetime" | "boolean" | "guid" | "guid?" | "string";

export type QueryLogic = "or" | "and";

@persistType("BuildQueryPart")
export class QueryPart {
	field: string;
	operator: QueryOperator;
	value: any;
	logic: QueryLogic;
	valueType: QueryValueType;
	value2: any;
	constructor(field: string, operator: QueryOperator, valueType: QueryValueType, value: any, logic?: QueryLogic);
	constructor(field: string, operator: "between", valueType: QueryValueType, value: any, logic?: QueryLogic, value2?: any);
	constructor(field: string, operator: QueryOperator, valueType: QueryValueType, value: any, logic?: QueryLogic, value2?: any) {
		this.field = field;
		this.operator = operator;
		this.value = value;
		this.value2 = value2;
		this.logic = logic;
		this.valueType = valueType;
	}
}

/**Used to group `QueryParts` in brackets in the final query */
@persistType("BuildQueryPartGroup")
export class QueryGroup {
	logic: QueryLogic;
	parts: (QueryGroup | QueryPart)[];
	constructor(group: (QueryPart | QueryGroup)[] = [], logic?: QueryLogic) {
		this.logic = logic;
		this.parts = group;
	}
}

@persistType("BuildQuerySort")
export class QuerySort {
	field: string;
	dir: "asc" | "desc";
	constructor(field: string, dir: "asc" | "desc") {
		this.field = field;
		this.dir = dir;
	}
}

@persistType("BuildQueryAggregate")
export class QueryAggregate {
	field: string;
	function: "max" | "min" | "average" | "sum";
	constructor(field: string, _function: "max" | "min" | "average" | "sum") {
		this.field = field;
		this.function = _function;
	}
}

export interface QueryConfig {
	defaultFilters?: (QueryGroup | QueryPart)[];
	expireInNumHours?: number;
}

const defaultConf: QueryConfig = {
	expireInNumHours: 24
};

const STORAGE_KEY = "_buildqueries_";
const TIME_KEY = "_buildqueries_expirations_";

export default class buildqueryservice {
	private queryName: string;
	private currentQuery: QueryGroup;
	private aggregates: QueryAggregate[];
	private config: QueryConfig;
	private sorts: QuerySort[];
	pageIndex: number;
	pageSize: number;

	/**Call `buildqueryservice.initialise` or `buildqueryservice.getQuery` instead. */
	private constructor(name: string, config: QueryConfig) {
		this.currentQuery = new QueryGroup();
		this.sorts = [];
		this.aggregates = [];
		this.queryName = name;
		this.config = config;
	}

	/**Create a new query */
	static initialise(QueryName: string, config?: QueryConfig) {
		config = { ...defaultConf, ...config };
		const exist = buildqueryservice.getFromStorage(QueryName);
		if (exist) {
			console.warn(`Query name '${QueryName}' already exists in storage. Deleting old query`);
		}

		const query = new buildqueryservice(QueryName, config);

		//Create default Query lines/filter
		if (config.defaultFilters && config.defaultFilters.length > 0) {
			for (var i = 0, len = config.defaultFilters.length; i < len; i++) {
				var filterObj = config.defaultFilters[i];
				query.addFilter(filterObj);
			}
		}
		if (config.expireInNumHours > 0) {
			buildqueryservice.updateExpireTime(QueryName, config.expireInNumHours);
		}
		buildqueryservice.addOrUpdateStorage(query);
		return query;
	}

	/**Get an existing query */
	static getQuery(QueryName: string) {
		return buildqueryservice.getFromStorage(QueryName);
	}

	static getOrInitialize(QueryName: string, config?: QueryConfig) {
		const exist = buildqueryservice.getFromStorage(QueryName);
		if (exist) {
			return exist;
		} else {
			return buildqueryservice.initialise(QueryName, config);
		}
	}

	/**Cleanup data from localstorage */
	dispose() {
		const storestr = localStorage.getItem(TIME_KEY);
		if (storestr) {
			const store = JSON.parse(storestr);
			delete store[this.queryName];
			localStorage.setItem(TIME_KEY, JSON.stringify(store));
		}
		let str = localStorage.getItem(STORAGE_KEY);
		if (str) {
			const arr: buildqueryservice[] = JSON.parse(str);
			localStorage.setItem(STORAGE_KEY, JSON.stringify(arr.filter(x => x.queryName !== this.queryName)));
		}
	}

	/**
	 * Add a new pquery part to the query string. Can also add a group of query parts to the query string, surrounded by brackets
	 * @param filter The query part or group of query parts to add
	 */
	addFilter(filter: QueryPart | QueryGroup) {
		//Add 'and' logic if there was a previous query/filter line
		var queryLine = this.currentQuery.parts.last();
		if (queryLine && !queryLine.logic) {
			queryLine.logic = "and";
		}

		this.currentQuery.parts.push(filter);
		buildqueryservice.addOrUpdateStorage(this);
	}

	/**
	 * Add an aggregate function to the query
	 * @param agg
	 */
	addAggregate(agg: QueryAggregate) {
		this.aggregates.push(agg);
		buildqueryservice.addOrUpdateStorage(this);
	}

	addSort(sort: QuerySort) {
		this.sorts.push(sort);
		buildqueryservice.addOrUpdateStorage(this);
	}

	clearAggregates() {
		if (this.aggregates.length) {
			this.aggregates = [];
			buildqueryservice.addOrUpdateStorage(this);
		}
	}

	clearSorts() {
		if (this.sorts.length) {
			this.sorts = [];
			buildqueryservice.addOrUpdateStorage(this);
		}
	}

	clearFilters() {
		this.currentQuery = new QueryGroup();
		buildqueryservice.addOrUpdateStorage(this);
	}

	/**Set current page and rows per page */
	setPage(pageSize: number, pageIndex: number) {
		this.pageSize = pageSize;
		this.pageIndex = pageIndex;
		buildqueryservice.addOrUpdateStorage(this);
	}

	/**Remove page settings. Default all data on one page */
	clearPage() {
		this.pageSize = 0;
		this.pageIndex = 0;
		buildqueryservice.addOrUpdateStorage(this);
	}

	/**
	 * Remove all filters and aggregates
	 */
	reset() {
		this.clearAggregates();
		this.clearFilters();
	}

	/**
	 * Wraps a given function and injects the `PagingParamaters` query object into that function, at the givin index
	 * whenever that function is called.
	 *
	 * Returns the wrapped function.
	 *
	 * @returns The wrapped function call.
	 * @param func The service to call
	 * @param pagingParametersArgIndex The index in the service func that takes the `PagingParamaters` dto object
	 * @param sort Optional sort config
	 * @param pageSize
	 * @param pageIndex
	 */
	buildQuery<R>(func: (...args: any[]) => R, pagingParametersArgIndex: number) {
		return (...funcArgs: any[]) => {
			funcArgs = funcArgs || [];
			while (funcArgs.length < pagingParametersArgIndex) {
				funcArgs.push(undefined);
			}
			funcArgs.splice(
				pagingParametersArgIndex,
				0,
				this.build(this.pageSize, this.pageIndex, [this.currentQuery], this.sorts, this.aggregates)
			);
			return func(...funcArgs);
		};
	}

	private static getFromStorage(name: string) {
		const storestr = localStorage.getItem(TIME_KEY);
		let remove = false;
		if (storestr) {
			const store = JSON.parse(storestr);
			if (store[name] && new Date() > new Date(store[name])) {
				remove = true;
			}
		}
		let str = localStorage.getItem(STORAGE_KEY);
		if (str) {
			const arr: buildqueryservice[] = JSON.parse(str);
			let item = arr.find(x => x.queryName === name);
			if (remove) {
				//expired
				localStorage.setItem(STORAGE_KEY, JSON.stringify(arr.filter(x => x.queryName !== name)));
				return null;
			} else {
				let classinstance = new buildqueryservice(name, {});
				//map the values back into a class instance
				for (const key in item) {
					if (item.hasOwnProperty(key)) {
						const val = item[key];
						classinstance[key] = val;
					}
				}
				return classinstance;
			}
		} else {
			localStorage.setItem(STORAGE_KEY, "[]");
			return null;
		}
	}

	private static addOrUpdateStorage(query: buildqueryservice) {
		let str = localStorage.getItem(STORAGE_KEY);
		const arr: buildqueryservice[] = JSON.parse(str);
		const index = arr.findIndex(x => x.queryName === query.queryName);
		if (index > -1) {
			arr[index] = query;
		} else {
			arr.push(query);
		}
		localStorage.setItem(STORAGE_KEY, JSON.stringify(arr));
	}

	private static updateExpireTime(QueryName: string, expireInNumHours: number): any {
		const storestr = localStorage.getItem(TIME_KEY);
		let store: { [s: string]: string };
		if (storestr) {
			store = JSON.parse(storestr);
		} else {
			store = {};
		}
		store[QueryName] = DateTime.add("hour", new Date(), expireInNumHours).toISOString();
		localStorage.setItem(TIME_KEY, JSON.stringify(store));
	}

	private build(pageSize, pageIndex, groups: QueryGroup[], sorts?: QuerySort[], aggregates?: QueryAggregate[]) {
		let params: PagingParameters = {
			filter: "",
			aggregate: "",
			pageSize: pageSize,
			pageIndex: pageIndex
		};

		if (sorts && sorts.length) {
			params.sort = sorts.map(x => x.field + "@@" + (x.dir ? x.dir : "asc")).join(",");
		}

		const processGroup = (group: QueryGroup) => {
			let rtns = "";
			if (group.parts.length) {
				if (group.parts.length > 1) rtns += "(";

				for (let index = 0; index < group.parts.length; index++) {
					const item = group.parts[index];
					if (item instanceof QueryGroup) {
						rtns += `${processGroup(item)}${group.logic ? group.logic + "," : ""}`;
					} else {
						rtns += processPart(item);
					}
				}
				if (group.parts.length > 1) rtns += ")";
				rtns += group.logic ? group.logic + "," : "";
			}
			if (rtns.startsWith("()")) {
				//all parts were ignored. return nothing
				return "";
			} else {
				return rtns;
			}
		};

		const processPart = (part: QueryPart) => {
			if (!this.ignoreFilter(part)) {
				return this.buildLine(part);
			} else {
				return "";
			}
		};

		if (groups) {
			for (let index = 0; index < groups.length; index++) {
				const group = groups[index];
				params.filter += processGroup(group);
			}
		}

		if (aggregates) {
			params.aggregate = aggregates.map(x => `${x.field}@@${x.function}`).join(",");
		}

		return params;
	}

	// Ignore string filters that have no search value
	private ignoreFilter(fl: QueryPart) {
		if (fl.operator == "startswith" || fl.operator == "endswith" || fl.operator == "contains") {
			if (fl.value === undefined || fl.value === null || fl.value === "") {
				return true;
			}
		}
		return false;
	}

	private buildLine(fl: QueryPart) {
		var value1 = fl.value;
		var value2 = fl.value2;
		var lines: string[] = [];
		if (fl.operator === "between") {
			var line =
				"(" + fl.field + "@@" + "gte" + "@@" + value1 + "@@" + (fl.valueType != null ? fl.valueType : "string") + "@@" + "and";
			lines.push(line);
			var line2 =
				fl.field +
				"@@" +
				"lte" +
				"@@" +
				value2 +
				"@@" +
				(fl.valueType != null ? fl.valueType : "string") +
				"@@" +
				(fl.logic != null ? fl.logic + "," : "") +
				")";
			lines.push(line2);
		} else {
			var line =
				fl.field +
				"@@" +
				fl.operator +
				"@@" +
				value1 +
				"@@" +
				(fl.valueType != null ? fl.valueType : "string") +
				"@@" +
				(fl.logic != null ? fl.logic + "," : "");
			lines.push(line);
		}
		return lines;
	}
}
