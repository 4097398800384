import { http, DateTime } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";
import { HttpResult } from "utils/http";
import { ScheduleSummaryDto, ScheduleDto, UserScheduleDto } from "redi-types";

const route = "Schedule/";

export default class scheduleservice {
	static GetList(scheduleAreaIds: string[], startDateLocal: Date, endDateLocal: Date, lastUpdateTimeUtc?: Date) {
		let url = `${config.baseUrl + route}GetList`;

		return http({ url, method: "POST", data: scheduleAreaIds, startDateLocal, endDateLocal, lastUpdateTimeUtc })
			.then(data => data)
			.catch(error => {
				if (!lastUpdateTimeUtc) {
					//no toast on syncer error
					showToast("error", error.error);
				}
				return error;
			});
	}

	static GetUserSchedules(pagingParams: PagingParameters, groupIds: string[]): Promise<HttpResult<DataSourceResult<UserScheduleDto>>> {
		let url = `${config.baseUrl + route}GetUserSchedules`;

		return http({ url, method: "POST", pagingParams, data: groupIds })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetSummaryList(
		pagingParams: PagingParameters,
		groupIds: string[],
		startTime: Date,
		endTime: Date,
		includeSwipes: boolean,
		groupBySchedule: boolean
	): Promise<HttpResult<DataSourceResult<ScheduleSummaryDto>>> {
		let url = `${config.baseUrl + route}GetSummaryList`;

		return http({ url, method: "POST", pagingParams, data: groupIds, startTime, endTime, includeSwipes, groupBySchedule })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetListInGroups(scheduleGroupIds: string[]) {
		let url = `${config.baseUrl + route}GetInGroups`;

		return http({ url, method: "GET", scheduleGroupIds })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static UpdateUserSchedules(data: UserScheduleDto[]) {
		let url = `${config.baseUrl + route}UpdateUserSchedules`;

		return http({ url, method: "POST", data })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}
}
