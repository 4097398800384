import * as React from "react";
import PropTypes, { string } from "prop-types";
import { shouldUpdate, MergeStyles, dom } from "utils/index";
import * as styles from "./styles.scss";
import { CommonComponentProps } from "redi-types";
import { connect } from "react-redux";

/**

Use:
	<Tabs
		actions={[
			{label: "Nearby", value:"Nearby" },
        	{label: "New", value:"New" },
        	{label: "Favourite", value:"Favourite" },
        	{label: "Popular", value:"Popular" },
		]}
		selected={this.props.currentTab}
		onChange={value => {
			this.props.setCurrentTab(value);
		}}
	/>

*/

@MergeStyles(styles, { forwardRef: false })
export default class Tabs extends React.PureComponent<TabsProps, State> {
	static defaultProps = {
		tabClass: "",
		showFooterBars: true
	};

	filtered: TabAction[];
	constructor(props) {
		super(props);
	}

	render() {
		this.filtered = this.props.actions.filter(x => !x.condition || x.condition());

		return (
			<div className={this.props.className} styleName="_root">
				{this.filtered.map((x, i) => {
					return (
						<div
							key={i}
							styleName={`_tab ${this.props.tabClass}`}
							isselected={x.isSelected(this.props.value).toString()}
							onClick={x.action}
						>
							<div styleName="_tab-content" content="1">{x.label}</div>
							{this.props.showFooterBars && <div styleName="_tab-footer" />}
						</div>
					);
				})}
			</div>
		);
	}
}


export interface TabsProps extends CommonComponentProps {
	actions: TabAction[];
	value: any;
	tabClass?: string;
	showFooterBars?: boolean;
}

interface State {}

export class TabAction {
	action: () => void;
	condition: any;
	isSelected: (val: any) => boolean;
	label: string | JSX.Element;
	constructor(label: string | JSX.Element, action: () => void, isSelected: (val: any) => boolean, condition?: () => boolean) {
		this.label = label;
		this.action = action;
		this.condition = condition;
		this.isSelected = isSelected;
	}
}
