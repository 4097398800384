import * as React from "react";

import * as styles from "./styles.scss";
import { connect } from "react-redux";
import * as router from "react-router-redux";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { CommonUIProps, CommonComponentProps, RegisterDto, UserDto, ScheduleGroupDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";

import { actions as useractions } from "redux/userRedux";
import SmartTable from "components/SmartTable/SmartTable";
import { DateTime } from "utils";
import Button from "components/Button/Button";
import Modal from "components/BaseModal/Modal";
import {
	WrappedFieldProps,
	InjectedFormProps,
	reduxForm,
	RecusiveFormErrors,
	FormErrors,
	Field,
	WrappedFieldArrayProps,
	FieldArray
} from "redux-form";
import { RenderInput, RenderDropdown, RenderMultiDropdown } from "components/FormInputs";
import { subscribeAction } from "boot/configureStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkAuth, AuthHoC } from "components/Auth/Auth";

@CSSModules(styles, { allowMultiple: true })
class RenderFormUser extends React.PureComponent<WrappedFieldArrayProps<any> & State & Props, {}> {
	render() {
		const {
			fields,
			meta: { error, submitFailed }
		} = this.props;

		if (fields.length === 0) {
			//add initial row
			fields.push({});
		}

		return (
			<div>
				{fields.map((member, index) => {
					return (
						<div styleName="user-row" key={index}>
							<Field name={member + ".userName"} component={RenderInput} label="Username" styleName="inputfield" />
							<Field name={member + ".firstName"} component={RenderInput} label="First name" styleName="inputfield" />
							<Field name={member + ".lastName"} component={RenderInput} label="Last name" styleName="inputfield" />
							<Field name={member + ".email"} component={RenderInput} label="Email" styleName="inputfield" />
							<div styleName="cross" onClick={() => fields.remove(index)}>
								<FontAwesomeIcon icon="times" />
							</div>
						</div>
					);
				})}
				<Button onClick={() => fields.push({})}>Add User</Button>
				{submitFailed && error && <div styleName="input-error">{error}</div>}
			</div>
		);
	}
}

@CSSModules(styles, { allowMultiple: true })
class UserCreate extends React.PureComponent<Props, State> {
	actions: Actions;
	roleactions: string[];
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			showUserModal: false
		};

		if (checkAuth(["IBMS Administrator"])) {
			this.roleactions = ["IBMS Administrator", "System Administrator", "User", "API User"];
		} else {
			this.roleactions = ["System Administrator", "User"];
		}

		this.actions = this.props.exposeActions(this);
	}

	save(data: MultiRegisterDto) {
		let users = data.users.map(x => ({ ...x, roles: data.roles, scheduleGroupId: data.group.scheduleGroupId }));

		this.actions.createUser(users);
		this.props.onClose();
	}

	render() {
		return (
			<div styleName="root">
				<div styleName="fields">
					<FieldArray name="users" component={RenderFormUser} />

					<Field
						name="group"
						component={RenderDropdown}
						label="Users' Group"
						styleName="inputfield"
						actions={this.props.groups}
						titleTransform={x => x.name}
						zIndex={105}
						classes={styles}
					/>

					<Field
						name="roles"
						component={RenderMultiDropdown}
						label="Roles for Users"
						styleName="inputfield"
						actions={this.roleactions}
						zIndex={105}
						classes={styles}
						titleComponent={props => {
							let checked = props.actions.filter(g => g.checked).map(g => props.titleTransform(g.item));
							if (checked.length === props.actions.length) {
								checked = ["All"];
							}
							return (
								<div styleName="multichevron">
									<div>{checked.join(", ")}</div>
									{props.open ? <FontAwesomeIcon icon="chevron-up" /> : <FontAwesomeIcon icon="chevron-down" />}
								</div>
							);
						}}
					/>
				</div>
				<div styleName="footer">
					<Button onClick={this.props.handleSubmit(data => this.save(data))}>Save</Button>
					<Button theme="secondary" onClick={this.props.onClose}>
						Cancel
					</Button>
				</div>
			</div>
		);
	}
}

const UserForm = reduxForm<MultiRegisterDto, Props>({
	form: "user-new",
	enableReinitialize: true,
	validate: (v, props) => {
		const errors: RecusiveFormErrors<MultiRegisterDto> = {};

		if (!v.users) {
			errors.users = { _error: "Required" };
		} else {
			errors.users = [];
			for (let index = 0; index < v.users.length; index++) {
				const user = v.users[index];
				const userErrors: RecusiveFormErrors<RegisterDto> = {};

				if (!user.userName) {
					userErrors.userName = "Required";
				} else if (user.userName.length < 5) {
					userErrors.userName = "Username must be at least 5 characters";
				}
				if (!user.firstName) {
					userErrors.firstName = "Required";
				}
				if (!user.lastName) {
					userErrors.lastName = "Required";
				}
				if (!user.email) {
					userErrors.email = "Required";
				}
				errors.users[index] = userErrors;
			}
		}

		if (!v.roles || !v.roles.length) {
			errors.roles = { _error: "Required" };
		} else if (!v.group && !v.roles.includes("IBMS Administrator")) {
			errors.group = { _error: "Required" };
		}

		return errors as any;
	}
})(UserCreate);

const bindAction = dispatch => {
	return {
		exposeActions: (component: UserCreate) => {
			return {
				createUser: user => dispatch(useractions.createUser(user))
			};
		}
	};
};

const mapStateToProps = (state: ReduxState) => ({
	groups: state.schedulegroup.visibleGroups
});

export default AuthHoC(
	connect(
		mapStateToProps,
		bindAction
	)(UserForm),
	{ roles: ["IBMS Administrator", "System Administrator"] }
);

interface State {}

interface Props extends InjectedFormProps<MultiRegisterDto, Props> {
	exposeActions: (component: UserCreate) => Actions;
	onClose: () => void;

	groups: ScheduleGroupDto[];
}

interface Actions {
	createUser: (user: RegisterDto[]) => void;
}

interface MultiRegisterDto {
	users: RegisterDto[];
	roles?: string[];
	group?: ScheduleGroupDto;
}
