//initial redux
import { put, call, takeLatest, fork, all, take, cancel, select } from "redux-saga/effects";
import securityservice from "services/security/security";
import { types as setuptypes } from "boot/setupRedux";
import * as router from "react-router-redux";
import { persist, string, array, DateTime, deepClone } from "utils/index";
import { ReduxAction, ScheduleAreaDto, ScheduleDto } from "redi-types";
import { ReduxState } from "config/reduxRoot";
import scheduleareaservice from "services/schedulearea";
import { types as scheduletypes } from "redux/scheduleRedux";
import { IPersistConfig } from "utils/persist";

interface State {
	scheduleAreas: ScheduleAreaDto[];
	scheduleAreasByDay: {
		[date: string]: ScheduleAreaDto[];
	};

	isBusy: boolean;
	errors: any[];
}

const initialState: State = {
	scheduleAreas: [],
	scheduleAreasByDay: {},

	isBusy: false,
	errors: []
};

const persistConf: IPersistConfig<State, "scheduleAreas"> = {
	whitelist: ["scheduleAreas"],
	expireInNumHours: 24 * 7,
	persistTransform: (key, val) => {
		if (key === "scheduleAreas") {
			//delete schedule list from persist otherwise it exceeds localStorage max limit when full of bookings
			let copy = val.slice();
			for (let index = 0; index < copy.length; index++) {
				const element = copy[index];
				//copy item so schedules is not deleted from redux state as well
				copy[index] = { ...element };
				delete copy[index];
			}
			return copy;
		}
		return val;
	}
};

/////////
//types
export const types = {
	GET_LIST: "scheduleArea/GET_LIST",
	SET_LIST: "scheduleArea/SET_LIST",
	SET_AREADAYS_LIST: "scheduleArea/SET_AREADAYS_LIST",

	ON_ERROR: "scheduleArea/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction): State {
		switch (action.type) {
			case types.GET_LIST:
				return { ...state, isBusy: true };

			case types.SET_LIST:
				return {
					...state,
					isBusy: false,
					scheduleAreas: array.sort(action.payload.scheduleAreas as ScheduleAreaDto[], x => x.name)
				};
			case types.SET_AREADAYS_LIST:
				return { ...state, isBusy: false, scheduleAreasByDay: action.payload.scheduleAreas };

			case scheduletypes.SET_LIST: {
				//read from schedule SETLIST type
				let areas = state.scheduleAreas.slice();
				const areaIds = Object.keys(action.payload.schedules);

				for (let i = 0; i < areaIds.length; i++) {
					const areaId = areaIds[i];
					const index = areas.findIndex(x => x.scheduleAreaId === areaId);
					areas[index] = {
						...areas[index],
						schedules: array.sort(action.payload.schedules[areaId] as ScheduleDto[], x => x.name)
					};
				}

				const bookings = array
					.sort(array.selectMany(array.selectMany(areas, x => x.schedules), x => x && x.bookings), x => x && x.beginTimeLocal)
					.filter(x => x);
				let bookingsByDay = {};
				let areasByDay: { [date: string]: ScheduleAreaDto[] } = {};

				//sort all bookings by day then create an area object for each day and assign those bookings to each area, by day
				if (bookings.length) {
					for (let index = 0; index < bookings.length; index++) {
						const booking = bookings[index];
						const start = DateTime.format(booking.beginTimeLocal, "yyyy-MM-dd");
						const end = DateTime.format(booking.endTimeLocal, "yyyy-MM-dd");
						bookingsByDay[start] = bookingsByDay[start] || [];
						bookingsByDay[start].push(booking);
						if (start !== end) {
							bookingsByDay[end] = bookingsByDay[end] || [];
							bookingsByDay[end].push(booking);
						}
					}

					const dayRange = DateTime.eachDayOfInterval(bookings[0].beginTimeLocal, bookings.last().endTimeLocal);
					for (let index = 0; index < dayRange.length; index++) {
						const date = dayRange[index];
						const key = DateTime.format(date, "yyyy-MM-dd");
						areasByDay[key] = deepClone(areas);
						const scheds = array.selectMany(areasByDay[key], x => x.schedules).filter(x => x);

						//merge in existing data
						for (let ai = 0; ai < areasByDay[key].length; ai++) {
							const area = areasByDay[key][ai];
							const exist =
								state.scheduleAreasByDay[key] &&
								state.scheduleAreasByDay[key].findIndex(x => x.scheduleAreaId === area.scheduleAreaId);
							if (exist !== undefined && exist > -1) {
								areasByDay[key][ai] = {
									...area,
									...state.scheduleAreasByDay[key][exist],
									schedules: scheds.filter(x => x.scheduleAreaId === area.scheduleAreaId)
								};
							}
						}

						for (let si = 0; si < scheds.length; si++) {
							const schedule = scheds[si];
							bookingsByDay[key] = bookingsByDay[key] || [];
							schedule.bookings = bookingsByDay[key].filter(x => x.scheduleId === schedule.scheduleId);
						}
					}
				}

				return { ...state, isBusy: false, scheduleAreas: areas, scheduleAreasByDay: areasByDay };
			}

			case scheduletypes.MERGE_LIST: {
				//read from schedule SETLIST type
				const areaIds = Object.keys(action.payload.schedules);
				let areas: ScheduleAreaDto[];

				if (areaIds.length) {
					areas = deepClone(state.scheduleAreas);

					for (let i = 0; i < areaIds.length; i++) {
						const areaId = areaIds[i];
						const index = areas.findIndex(x => x.scheduleAreaId === areaId);
						for (let ii = 0; ii < action.payload.schedules[areaId].length; ii++) {
							const newsch: ScheduleDto = action.payload.schedules[areaId][ii];
							const sch = areas[index].schedules.find(x => newsch.scheduleId === x.scheduleId);
							if (sch) {
								for (let sii = 0; sii < newsch.bookings.length; sii++) {
									const booking = newsch.bookings[sii];
									if (booking.deleted) {
										sch.bookings = sch.bookings.filter(x => x.bookingId !== booking.bookingId);
									} else {
										sch.bookings = array.removeDuplicates(sch.bookings.concat(booking), x => x.bookingId);
									}
								}
							} else {
								newsch.bookings = newsch.bookings.filter(x => !x.deleted);
								areas[index] = { ...areas[index], schedules: areas[index].schedules.concat(newsch) };
							}
						}
					}

					const bookings = array
						.sort(array.selectMany(array.selectMany(areas, x => x.schedules), x => x && x.bookings), x => x && x.beginTimeLocal)
						.filter(x => x);
					let bookingsByDay = {};
					let areasByDay: { [date: string]: ScheduleAreaDto[] } = {};

					//sort all bookings by day then create an area object for each day and assign those bookings to each area, by day
					if (bookings.length) {
						for (let index = 0; index < bookings.length; index++) {
							const booking = bookings[index];
							const start = DateTime.format(booking.beginTimeLocal, "yyyy-MM-dd");
							const end = DateTime.format(booking.endTimeLocal, "yyyy-MM-dd");
							bookingsByDay[start] = bookingsByDay[start] || [];
							bookingsByDay[start].push(booking);
							if (start !== end) {
								bookingsByDay[end] = bookingsByDay[end] || [];
								bookingsByDay[end].push(booking);
							}
						}

						const dayRange = DateTime.eachDayOfInterval(bookings[0].beginTimeLocal, bookings.last().endTimeLocal);
						for (let index = 0; index < dayRange.length; index++) {
							const date = dayRange[index];
							const key = DateTime.format(date, "yyyy-MM-dd");
							areasByDay[key] = deepClone(areas);
							const scheds = array.selectMany(areasByDay[key], x => x.schedules).filter(x => x);

							//merge in existing data
							for (let ai = 0; ai < areasByDay[key].length; ai++) {
								const area = areasByDay[key][ai];
								const exist =
									state.scheduleAreasByDay[key] &&
									state.scheduleAreasByDay[key].findIndex(x => x.scheduleAreaId === area.scheduleAreaId);
								if (exist !== undefined && exist > -1) {
									areasByDay[key][ai] = {
										...area,
										...state.scheduleAreasByDay[key][exist],
										schedules: scheds.filter(x => x.scheduleAreaId === area.scheduleAreaId)
									};
								}
							}

							for (let si = 0; si < scheds.length; si++) {
								const schedule = scheds[si];
								bookingsByDay[key] = bookingsByDay[key] || [];
								schedule.bookings = bookingsByDay[key].filter(x => x.scheduleId === schedule.scheduleId);
							}
						}
					}

					return { ...state, scheduleAreas: areas, scheduleAreasByDay: areasByDay };
				} else {
					return state;
				}
			}

			case types.ON_ERROR:
				return {
					...state,
					errors: state.errors.concat(action.payload).slice(-10),
					isBusy: false
				};

			default:
				return state;
		}
	}
};
export const reducer = persist("scheduleArea", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([this.getScheduleAreas()]);
	},

	*getScheduleAreas() {
		yield takeLatest<ReduxAction>(types.GET_LIST, function*({ payload }) {
			const result = yield call(scheduleareaservice.GetList, payload.scheduleAreaGroupId);
			if (result.error) {
				console.error(result.error);
				yield put(actions.onError(result.error));
			} else {
				yield put(actions.setScheduleAreasList(result.data));
			}
		});
	}
};

////////
//actions
export const actions = {
	getList(scheduleAreaGroupId: string) {
		return {
			type: types.GET_LIST,
			payload: { scheduleAreaGroupId }
		};
	},

	setScheduleAreasList(scheduleAreas: ScheduleAreaDto[]) {
		return {
			type: types.SET_LIST,
			payload: { scheduleAreas }
		};
	},

	setScheduleAreasByDayList(scheduleAreas: { [date: string]: ScheduleAreaDto[] }) {
		return {
			type: types.SET_AREADAYS_LIST,
			payload: { scheduleAreas }
		};
	},

	onError(data) {
		return {
			type: types.ON_ERROR,
			payload: data
		};
	}
};
