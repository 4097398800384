import * as React from "react";

import { connect } from "react-redux";
import * as router from "react-router-redux";
import { actions } from "redux/loginRedux";
import Spinner from "libs/spinner";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { ReduxState } from "config/reduxRoot";

import Typeahead from "components/Typeahead/Typeahead";
import * as styles from "./styles.scss";
import BaseModal from "components/BaseModal/ModalImpl/ModalImpl";
import Button from "components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScheduleGroupDto, ScheduleAreaDto, ScheduleDto, BookingDto, RecursivePartial, BookingRepeatType, ScheduleTypes } from "redi-types";
import { actions as schedulegroupactions } from "redux/scheduleGroupRedux";
import InlineSelect from "components/InlineSelect/InlineSelect";
import { actions as scheduleareaactions } from "redux/scheduleAreaRedux";
import { actions as bookingactions } from "redux/bookingRedux";
import { actions as timeactions } from "redux/timeRedux";
import { actions as scheduleactions } from "redux/scheduleRedux";
import { DateTime, array, Time, number, string, FormDefaultValues } from "utils";

export const NewBookingContext = React.createContext<Partial<NewBookingContext>>({}); //this needs to be exported before import NewBookingModal
import NewBookingModal from "./Modal/NewBookingModal";
import SwitchButton from "components/Switch/switch";

import * as switchon from "assets/icons/switchon.png";
import * as switchoff from "assets/icons/switchoff.png";

import {
	reduxForm,
	InjectedFormProps,
	Field,
	formValueSelector,
	WrappedFieldProps,
	RecusiveFormErrors,
	FormErrors,
	change
} from "redux-form";
import { RenderSwitch, RenderInput, RenderButtonListMulti, RenderCheckBox, RenderTabs, RenderTypeahead } from "components/FormInputs";
import { DaySelectorForm, EndsForm, MonthRepeatForm } from "./FormFields";
import scheduleiconservice from "utils/scheduleTypeIconService";
import Modal from "components/BaseModal/Modal";
import { genBlockout, Day } from "./TimePicker/TimePicker";

@CSSModules(styles, { allowMultiple: true })
class NewBooking extends React.PureComponent<Props & InjectedFormProps<BookingDto, Props>, State> {
	actions: Actions;
	pendingSave: BookingDto;
	constructor(props) {
		super(props);
		autoBind(this);

		this.actions = this.props.exposeActions(this);
		let now = DateTime.round("minute", new Date(), 5);
		//use selected date but get the now time rounded to nearest 5 min
		now = new Date(
			this.props.selectedDate.getFullYear(),
			this.props.selectedDate.getMonth(),
			this.props.selectedDate.getDate(),
			now.getHours(),
			now.getMinutes(),
			now.getSeconds(),
			0
		);

		this.state = {
			showSaveConfirm: false,
			showDeleteConfirm: false,
			newBookingOpen: false,
			context: {
				currentSchedulesForBooking: this.props.schedulesForBooking || this.getSchedulesForBooking(this.props.currentAreaOrSchedule),
				selectedDate: this.props.selectedDate,
				setSchedulesList: this.actions.setSchedulesList,
				setScheduleAreasList: this.actions.setScheduleAreasList,
				setSelectedDate: this.actions.setSelectedDate,
				getSchedules: this.actions.getSchedules,
				currentScheduleGroup: this.props.currentScheduleGroup,
				scheduleGroups: this.props.scheduleGroups,
				scheduleAreas: this.props.scheduleAreas,
				bookingStartTime: this.props.currentBooking ? this.props.currentBooking.beginTimeLocal : now,
				bookingEndTime: this.props.currentBooking ? this.props.currentBooking.endTimeLocal : DateTime.add("hour", now, 1),
				setContext: this.setContext,
				currentBooking: this.props.currentBooking,
				isEdit: this.props.isEdit
			}
		};

		const offset = this.calculateBlockoutOffset();
		//we can modify the state directly while inside the ctor
		this.state.context.bookingStartTime = offset.bookingStartTime;
		this.state.context.bookingEndTime = offset.bookingEndTime;

		let schedulesToGet = [];
		this.props.scheduleAreas.forEach(x => {
			schedulesToGet.push(x.scheduleAreaId);
		});
	}

	calculateBlockoutOffset(schedules?: ScheduleDto[]) {
		const publicHolidays = array.removeDuplicates(
			array.selectMany(schedules || this.state.context.currentSchedulesForBooking || [], x => x.publicHolidays).filter(x => x),
			x => x.publicHolidayId
		);

		const days = array.fill(2, x => {
			const date = DateTime.addDays(this.props.selectedDate, x);
			let noBlockout = false;
			const pubHol = publicHolidays.find(x => x.date.getDate() === date.getDate() && x.date.getMonth() === date.getMonth());
			if (!!pubHol && (schedules || this.state.context.currentSchedulesForBooking).every(x => x.blockoutApplysOnPublicHoliday)) {
				noBlockout = true;
			}
			const blockout = noBlockout
				? null
				: genBlockout(schedules || this.state.context.currentSchedulesForBooking, DateTime.addDays(this.props.selectedDate, x));
			const day = new Day(date, blockout);
			return day;
		});

		let offset = { bookingStartTime: this.state.context.bookingStartTime, bookingEndTime: this.state.context.bookingEndTime };
		for (let index = 0; index < days.length; index++) {
			const day = days[index];
			offset = day.offsetIfInBlockout(offset.bookingStartTime, offset.bookingEndTime, true);
		}
		return offset;
	}

	setContext(newcontext) {
		this.setState({ context: { ...this.state.context, ...newcontext } });
	}

	toggleNewBooking(showDate = false) {
		this.setState({ newBookingOpen: !this.state.newBookingOpen, showDate });
	}

	createBooking(data: BookingDto) {
		//redux form will already have the value unless its been offset by a blockout
		data.beginTimeLocal = this.state.context.bookingStartTime;
		data.endTimeLocal = this.state.context.bookingEndTime;
		if (this.props.isEdit) {
			this.pendingSave = data;
			this.setState({ showSaveConfirm: true });
		} else {
			this.actions.createBooking(data);
			this.props.onClose();
		}
	}

	static getDerivedStateFromProps(props: Props, state: State) {
		let newContext = {};
		let changed = false;
		//merged changed props into context state
		for (const prop in state.context) {
			if (state.context.hasOwnProperty(prop) && props.hasOwnProperty(prop)) {
				if (state.context[prop] !== props[prop]) {
					newContext[prop] = props[prop];
					changed = true;
				}
			}
		}

		if (changed) {
			//create new context and update child componts
			return { ...state, context: { ...state.context, ...newContext } };
		} else {
			return state;
		}
	}

	getSchedulesForBooking(currentAreaOrSchedule: ScheduleAreaDto | ScheduleDto | ScheduleAreaDto[] | ScheduleDto[]) {
		if (currentAreaOrSchedule) {
			let schedules: ScheduleDto[] = [];

			if(currentAreaOrSchedule["length"] != undefined) {
				if (instanceOfScheduleDto(currentAreaOrSchedule[0])) {
					schedules = [...(currentAreaOrSchedule as ScheduleDto[])];
				} else {
					// is area
					for(let ii = 0, ilen = currentAreaOrSchedule["length"]; ii < ilen; ii++) {
						schedules = schedules.concat(this.props.scheduleAreas.find(x => x.scheduleAreaId === currentAreaOrSchedule[ii].scheduleAreaId).schedules);
					}
				}
			} else {
				if (instanceOfScheduleDto(currentAreaOrSchedule)) {
					schedules = [currentAreaOrSchedule];
				} else {
					// is area
					schedules = this.props.scheduleAreas.find(x => x.scheduleAreaId === (currentAreaOrSchedule as any).scheduleAreaId).schedules;
				}
			}

			
			return schedules;
		} else {
			return [];
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		// if typeahead changed, merge new schedules into context
		if (this.props.currentAreaOrSchedule !== prevProps.currentAreaOrSchedule && this.props.currentAreaOrSchedule) {
			const scheds = this.getSchedulesForBooking(this.props.currentAreaOrSchedule);
			const offset = this.calculateBlockoutOffset(scheds);
			//update state with new currentAreaOrSchedule for typeahead value and new schedules in context
			this.setState({
				context: {
					...this.state.context,
					bookingStartTime: offset.bookingStartTime,
					bookingEndTime: offset.bookingEndTime,
					currentSchedulesForBooking: scheds
				}
			});
		}
	}

	repeatType() {
		if (this.props.formValues.repeatConfig) {
			switch (this.props.formValues.repeatConfig.type) {
				case "Week": {
					return (
						<React.Fragment>
							<Field
								name="repeatConfig.daysOfWeek"
								component={RenderButtonListMulti}
								label="Every"
								actions={DateTime.daysOfWeek()}
								titleTransform={x => x[0]}
								variant="circle"
								selectedColor={styles.offColor}
								selectedBackColor={styles.primaryColor}
								containerClass="btn-list-container"
								classes={styles}
							/>
							<div styleName="line" />
						</React.Fragment>
					);
				}
				case "Month": {
					return (
						<React.Fragment>
							<Field
								name="repeatConfig.month"
								component={MonthRepeatForm}
								currentDate={this.state.context.selectedDate}
								label="Every"
							/>
							<div styleName="line" />
						</React.Fragment>
					);
				}
				case "Day":
				case "Year":
					return null;
			}
		}
		return null;
	}

	repeatTypeEnds() {
		if (this.props.formValues.repeatConfig) {
			switch (this.props.formValues.repeatConfig.type) {
				case "Day":
				case "Year":
				case "Month":
				case "Week": {
					return (
						<React.Fragment>
							<Field
								name="repeatConfig.ends"
								component={EndsForm}
								currentDate={this.state.context.selectedDate}
								type={this.props.formValues.repeatConfig.type.toLowerCase()}
								amount={this.props.formValues.repeatConfig.repeatEveryXOfType}
								label="Ends"
								clearField={this.actions.clearField}
							/>
							<div styleName="line" />
						</React.Fragment>
					);
				}
			}
		}
		return null;
	}

	repeatText(type: BookingRepeatType): string {
		switch (type) {
			case "Day":
			case "Week":
			case "Month":
			case "Year":
				return (
					`Repeats every ${this.props.formValues.repeatConfig.repeatEveryXOfType} ${type +
						(this.props.formValues.repeatConfig.repeatEveryXOfType > 1 ? "s" : "")}` +
					(this.props.formValues.repeatConfig.ends === "number" && this.props.formValues.repeatConfig.endAfterXRepeats
						? ` - ${this.props.formValues.repeatConfig.endAfterXRepeats} times`
						: this.props.formValues.repeatConfig.ends === "date" && this.props.formValues.repeatConfig.endDate
						? ` - until ${DateTime.format(this.props.formValues.repeatConfig.endDate, "dd MMM yyyy")}`
						: "")
				);

			default:
				break;
		}
	}

	render() {
		if (!this.props.currentScheduleGroup) {
			this.props.onClose();
			return;
		}

		return (
			<Modal
				isOpen={true}
				title={this.props.isEdit ? "Edit Booking" : `New Booking - ${this.props.currentScheduleGroup.siteName}: ${this.props.currentScheduleGroup.name}`}
				onClose={this.props.onClose}
				rootClass="root"
				headerClass="modal-header"
				hashUrl={true}
				classes={styles}
			>
				<div styleName="content">
					{!this.props.isEdit && (
						<div>
							<Field
								name="currentAreaOrSchedule"
								component={RenderTypeahead}
								typeaheadClass="floorinput"
								errorClass="typea-error"
								classes={styles}
								getData={query => {
									return new Promise((resolve, reject) => {
										query = query.toLowerCase();
										const flat = array.selectMany(
											this.props.scheduleAreas.filter(x => x.schedules && x.schedules.length),
											x => [x, ...x.schedules]
										);
										const data = flat.filter(x => !query || (x && x.name.toLowerCase().includes(query)));
										resolve({ data });
									});
								}}
								zIndex={105}
								titleTransform={x => {
									if(x["length"] != undefined) {
										let r = "";
										for(let ii = 0, ilen = x["length"]; ii < ilen; ii++) {
											if(ii > 0) {
												r += ", ";
											}
											r += x[ii].description;
										}
										return r;
									} else if(x.description != undefined) {
										return x.description;
									} else {
										return x;
									}
								}}
								rowTemplate={Typaheadtemplate}
								placeHolder="Select Area"
							/>
						</div>
					)}
					{(this.props.currentAreaOrSchedule || this.props.isEdit) && (
						<div styleName="main">
							{this.props.currentAreaOrSchedule && 
								(this.props.currentAreaOrSchedule["length"] == undefined) && 
								(
									<div styleName="areadescript">
										<span styleName="icons-wrap">
											{!!this.state.context.currentSchedulesForBooking &&
												array //dont grab dupe icons
													.groupByTransform(
														this.state.context.currentSchedulesForBooking,
														x => x.type,
														(x, type: ScheduleTypes) => type
													)
													.map((x, i) => scheduleiconservice.GetIcon(x, { key: i }))}
										</span>
										{
											instanceOfScheduleDto(this.props.currentAreaOrSchedule) &&
											<span styleName="areaname">{(this.props.currentAreaOrSchedule as any).scheduleAreaName}</span>
										}
										<span>{(this.props.currentAreaOrSchedule as any).description}</span>
									</div>
								)}

							{
								this.props.currentAreaOrSchedule &&
								(this.props.currentAreaOrSchedule["length"] != undefined && this.props.currentAreaOrSchedule["length"] > 0) &&
								(
									(this.props.currentAreaOrSchedule as any).map(caos => {
										return (
											<div key={caos.scheduleId} styleName="areadescript">
												<span styleName="icons-wrap">
													{!!this.state.context.currentSchedulesForBooking &&
														array //dont grab dupe icons
															.groupByTransform(
																this.state.context.currentSchedulesForBooking,
																x => x.type,
																(x, type: ScheduleTypes) => type
															)
															.map((x, i) => scheduleiconservice.GetIcon(x, { key: i }))}
												</span>
												{
													instanceOfScheduleDto(caos) &&
													<span styleName="areaname">{(caos as any).scheduleAreaName}</span>
												}
												<span>{(caos as any).description}</span>
											</div>	
										)
									})
								)
							}

							<div styleName="datetime">
								<div styleName="date" onClick={() => this.toggleNewBooking(true)}>
									{DateTime.format(this.props.selectedDate, "d MMM")}
								</div>
								<div styleName="time" onClick={() => this.toggleNewBooking(false)}>
									{`${DateTime.format(this.state.context.bookingStartTime, "hh:mm a")} > ${DateTime.format(
										this.state.context.bookingEndTime,
										"hh:mm a"
									)}`}
								</div>
							</div>
							{this.props.currentBooking && (
								<div styleName="form">
									<Field
										name="repeats"
										component={RenderSwitch}
										switchLabel={
											this.props.formValues.repeats
												? this.repeatText(this.props.formValues.repeatConfig.type)
												: "Does not repeat"
										}
										size={20}
									>
										{this.props.formValues.repeats ? (
											<img src={switchon} style={{ height: 20 }} />
										) : (
											<img src={switchoff} style={{ height: 20 }} />
										)}
									</Field>

									{this.props.formValues.repeats && (
										<React.Fragment>
											<div styleName="repeat-title">Repeats Every</div>
											<div styleName="repeat-wrap">
												<Field
													name="repeatConfig.repeatEveryXOfType"
													component={RenderInput}
													type="number"
													inputClass="repeat-input"
													min={1}
													styleName="noflex"
													classes={styles}
												/>
												<Field name="repeatConfig.type" component={DaySelectorForm} styleName="repeat-tabs" />
											</div>

											<div styleName="line" />
											{this.repeatType()}
											{this.props.formValues.repeatConfig &&
												this.props.formValues.repeatConfig.repeatEveryXOfType &&
												this.repeatTypeEnds()}

											<Field name="applyPublicHolidays" component={RenderCheckBox} label="Apply to public holidays" />
										</React.Fragment>
									)}
								</div>
							)}
							<div styleName="footer">
								{this.props.isEdit && (
									<Button onClick={() => this.setState({ showDeleteConfirm: true })}>Delete Booking</Button>
								)}
								<Button
									onClick={this.props.handleSubmit(this.createBooking)}
									styleName="create-btn"
									disabled={!this.props.currentBooking}
								>
									{this.props.isEdit ? "Save Booking" : "Create Booking"}
								</Button>
							</div>

							<Modal
								isOpen={this.state.showSaveConfirm}
								title="Are you sure?"
								zIndex={199}
								onClose={() => this.setState({ showSaveConfirm: false })}
							>
								<div styleName="confirm-delete-body">
									{this.props.currentBooking.bookingGroupId
										? "Booking has repeat Bookings. Would you like to update them?"
										: "Are you sure you want to update this Booking?"}
									<div>
										<Button
											onClick={() => {
												this.actions.updateBooking(this.pendingSave, false);
												this.props.onClose();
											}}
										>
											Update this Booking
										</Button>
										{this.props.currentBooking.bookingGroupId && (
											<Button
												onClick={() => {
													this.actions.updateBooking(this.pendingSave, true);
													this.props.onClose();
												}}
											>
												Update all Repeating Bookings
											</Button>
										)}
										<Button theme="secondary" onClick={() => this.setState({ showSaveConfirm: false })}>
											Cancel
										</Button>
									</div>
								</div>
							</Modal>

							<Modal
								isOpen={this.state.showDeleteConfirm}
								onClose={() => this.setState({ showDeleteConfirm: false })}
								title="Are you sure?"
								zIndex={199}
							>
								<div styleName="confirm-delete-body">
									{this.props.currentBooking.bookingGroupId
										? "Delete just this instance of the Booking or all occurrences of this Bookings?"
										: "Are you sure you want to delete this Booking?"}
									<div>
										<Button
											onClick={() => {
												this.actions.deleteBooking(this.props.currentBooking.bookingId, false);
												this.setState({ showDeleteConfirm: false });
												this.props.onClose();
											}}
										>
											Delete This Instance
										</Button>
										{this.props.currentBooking.bookingGroupId && (
											<Button
												onClick={() => {
													this.actions.deleteBooking(this.props.currentBooking.bookingId, true);
													this.setState({ showDeleteConfirm: false });
													this.props.onClose();
												}}
											>
												Delete All Repeating
											</Button>
										)}
										<Button theme="secondary" onClick={() => this.setState({ showDeleteConfirm: false })}>
											Cancel
										</Button>
									</div>
								</div>
							</Modal>
						</div>
					)}
				</div>
				{/* use a context so we dont have to pass a bunch of crap down 3 levels of components */}
				<NewBookingContext.Provider value={this.state.context}>
					<NewBookingModal
						setCurrentBooking={this.actions.setCurrentBooking}
						newBookingOpen={this.state.newBookingOpen}
						onClose={this.toggleNewBooking}
						showDate={this.state.showDate}
					/>
				</NewBookingContext.Provider>
			</Modal>
		);
	}

	componentWillUnmount() {
		if (this.props.currentBooking) {
			this.actions.setCurrentBooking(null);
		}
	}
}

const FORM_NAME = "new-booking";
const NewBookingForm = reduxForm<BookingDto, Props>({
	form: FORM_NAME,
	enableReinitialize: true,
	validate: (v, props) => {
		const errors: RecusiveFormErrors<BookingDto> = { repeatConfig: {} };

		if (v.repeats) {
			if (!v.repeatConfig || !v.repeatConfig.repeatEveryXOfType) {
				errors.repeatConfig.repeatEveryXOfType = "Required";
			}

			if (!v.repeatConfig || !v.repeatConfig.type) {
				errors.repeatConfig.type = "Required";
			}

			if (!v.repeatConfig || !v.repeatConfig.ends) {
				errors.repeatConfig.ends = "Required";
			}

			if (!v.repeatConfig || (!v.repeatConfig.endAfterXRepeats && v.repeatConfig.ends === "number")) {
				errors.repeatConfig.endAfterXRepeats = "Required";
			}

			if (!v.repeatConfig || (!v.repeatConfig.endDate && v.repeatConfig.ends === "date")) {
				errors.repeatConfig.endDate = "Required";
			}

			if (!v.repeatConfig || (!v.repeatConfig.month && v.repeatConfig.type === "Month")) {
				errors.repeatConfig.month = "Required";
			}

			if (!v.repeatConfig || !v.repeatConfig.daysOfWeek) {
				errors.repeatConfig.daysOfWeek = "Required";
			}
		}

		if (!v.endTimeLocal) {
			errors.endTimeLocal = { _error: "Required" };
		}
		if (!v.beginTimeLocal) {
			errors.beginTimeLocal = { _error: "Required" };
		}

		if (!props.isEdit && !(v as any).currentAreaOrSchedule) {
			(errors as any).currentAreaOrSchedule = "Required";
		}

		return errors as any;
	}
})(NewBooking);
const selector = formValueSelector(FORM_NAME);

const bindAction = dispatch => ({
	exposeActions: (component: NewBooking) => {
		return {
			setCurrentGroup: group => dispatch(schedulegroupactions.setCurrentGroup(group)),
			getSchedules: (start, end, areaid) => dispatch(scheduleactions.getList(start, end, areaid)),
			setSelectedDate: date => dispatch(timeactions.setBookingTime(date)),

			setCurrentBooking: data => dispatch(bookingactions.setCurrentBooking(data)),
			createBooking: data => dispatch(bookingactions.createBooking(data, component.state.context.currentSchedulesForBooking)),
			updateBooking: (data, all) => dispatch(bookingactions.updateBooking(data, all)),
			deleteBooking: (id, d) => dispatch(bookingactions.deleteBooking(id, d)),

			clearField: name => dispatch(change(FORM_NAME, name, null)),

			setSchedulesList: (schedules: ScheduleDto[]) =>
				dispatch(scheduleactions.setList(array.groupBy(schedules, x => x.scheduleAreaId))),
			setScheduleAreasList: scheduleareas => dispatch(scheduleareaactions.setScheduleAreasList(scheduleareas)),

			navigate: (uri, data) => dispatch(router.push(uri, data)),
			goBack: () => dispatch(router.goBack())
		};
	}
});

const mapStateToProps = (state: ReduxState) => ({
	currentScheduleGroup: state.schedulegroup.currentGroup,
	scheduleGroups: state.schedulegroup.groups,
	scheduleAreas: state.scheduleArea.scheduleAreas,
	selectedDate: state.time.bookingSelectedDay,
	currentBooking: state.booking.currentBooking,

	formValues: selector(
		state,
		"repeats",
		"repeatConfig.type",
		"repeatConfig.repeatEveryXOfType",
		"repeatConfig.endDate",
		"repeatConfig.endAfterXRepeats",
		"repeatConfig.ends"
	), //get current val out of form

	currentAreaOrSchedule: selector(state, "currentAreaOrSchedule"),

	//filter current booking into redux form
	initialValues: state.booking.currentBooking
});

export default connect(
	mapStateToProps,
	bindAction
)(
	FormDefaultValues(NewBookingForm, (props, prev) => props.initialValues !== prev.initialValues, [
		x => x.initialValues,
		x => ({ currentAreaOrSchedule: x.currentAreaOrSchedule || x.currentSchedule })
	])
);

interface State {
	/**current value for dropdown. if is area, all its schedules will be put into `currentSchedulesForBooking` */
	// currentAreaOrSchedule: ScheduleAreaDto | ScheduleDto;
	showDeleteConfirm: boolean;
	newBookingOpen: boolean;
	context: NewBookingContext;
	showDate?: boolean;
	showSaveConfirm: boolean;
}
interface Props {
	onClose: () => void;
	currentScheduleGroup: ScheduleGroupDto;
	scheduleGroups: ScheduleGroupDto[];
	scheduleAreas: ScheduleAreaDto[];
	selectedDate: Date;
	currentBooking: BookingDto;
	formValues: Partial<BookingDto>;
	isEdit: boolean;
	/**pass in schedules for booking group when editing */
	schedulesForBooking: ScheduleDto[];
	currentAreaOrSchedule: ScheduleAreaDto | ScheduleDto | ScheduleAreaDto[] | ScheduleDto[];
	currentSchedule?: ScheduleDto;

	exposeActions: (c: NewBooking) => Actions;
}

interface Actions {
	setCurrentGroup: (group: ScheduleGroupDto) => void;
	getSchedules: (start: Date, end: Date, areaid: string[]) => void;
	setSchedulesList: (schedules: ScheduleDto[]) => void;
	setCurrentBooking: (dd: BookingDto) => void;
	createBooking: (dd: BookingDto) => void;
	updateBooking: (dd: BookingDto, all: boolean) => void;
	deleteBooking: (id: string, deleteGroup: boolean) => void;
	clearField: (name: string) => void;

	setSelectedDate: (date: Date) => void;
	setScheduleAreasList: (scheduleareas: ScheduleAreaDto[]) => void;

	navigate: (uri, data) => void;
	goBack: () => void;
}

export interface NewBookingContext {
	selectedDate: Date;
	currentScheduleGroup: ScheduleGroupDto;
	scheduleGroups: ScheduleGroupDto[];
	scheduleAreas: ScheduleAreaDto[];
	/**this schedules that the current booking will be assigned */
	currentSchedulesForBooking: ScheduleDto[];
	bookingStartTime: Date;
	bookingEndTime: Date;
	currentBooking: BookingDto;
	isEdit: boolean;

	setSelectedDate: (date: Date) => void;
	setSchedulesList: (schedules: ScheduleDto[]) => void;
	setScheduleAreasList: (scheduleareas: ScheduleAreaDto[]) => void;
	getSchedules: (start: Date, end: Date, areaid: string[]) => void;
	setContext: (newContext: Partial<NewBookingContext>) => void;
}

@CSSModules(styles, { allowMultiple: true })
class Typaheadtemplate extends React.PureComponent<{ row: ScheduleAreaDto | ScheduleDto }, {}> {
	render() {
		if (instanceOfScheduleDto(this.props.row)) {
			return (
				<div styleName="template-row-root template-schedules">
					<div><i nounderline="1">{this.props.row.scheduleArea.name}:</i></div>
					<div><span> {this.props.row.name}</span></div>
				</div>
			);
		} else {
			return <div styleName="template-row-root">
				{this.props.row && <div>{this.props.row.name}</div>}
			</div>;
		}
	}
}

function instanceOfScheduleDto(item: any): item is ScheduleDto {
	return item && "scheduleId" in item;
}
