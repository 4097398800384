import * as React from "react";

import * as styles from "./styles.scss";
import { connect } from "react-redux";
import * as router from "react-router-redux";
import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";
import { CommonUIProps, UserDto, ScheduleGroupDto, PropertiesOfType } from "redi-types";
import { ReduxState } from "config/reduxRoot";

import { actions as useractions } from "redux/userRedux";
import { actions as schedulegroupactions } from "redux/scheduleGroupRedux";
import SmartTable from "components/SmartTable/SmartTable";
import { actions as smarttableactions } from "components/SmartTable/redux";
import { DateTime, number, array } from "utils";
import Button from "components/Button/Button";
import Modal from "components/BaseModal/Modal";
import UserCreate from "./UserEdit/UserCreate";
import Spinner from "libs/spinner";
import UserEdit from "./UserEdit/UserEdit";
import CheckBox from "components/Checkbox/checkbox";
import buildqueryservice, { QueryPart, QuerySort, QueryGroup } from "services/query/buildquery";
import usersservice from "services/users";
import { AuthHoC, Auth, checkAuth } from "components/Auth/Auth";
import Select from "components/Select/select";
import Drawer from "components/Drawer/Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBar from "components/SearchBar/SearchBar";
import Menu, { MenuAction } from "components/PopupMenu/menu";
import AddRemoveSchedule from "./AddRemoveSchedule/AddRemoveSchedule";
import AddRemoveGroup from "./AddRemoveGroup/AddRemoveGroup";
import SwitchButton from "components/Switch/switch";

@CSSModules(styles, { allowMultiple: true })
class AdminPage extends React.PureComponent<Props, State> {
	actions: Actions;
	rowsPerPageActions: number[];
	scrollRef: React.RefObject<HTMLDivElement>;
	mobileMenu: MenuAction[];
	_throttleAction: { action: Function; args: any[] };
	roleactions: string[];
	constructor(props) {
		super(props);
		autoBind(this);

		if (checkAuth(["IBMS Administrator"])) {
			this.roleactions = ["IBMS Administrator", "System Administrator", "User", "API User"];
		} else {
			this.roleactions = ["System Administrator", "User"];
		}

		this.state = {
			showGroupsSchedules: false,
			showUserModal: false,
			showEditModal: false,
			showEditUserDetailModal: false,
			showRemoveScheduleModal: false,
			showRemoveGroupModal: false,
			showAddScheduleModal: false,
			showAddGroupModal: false,
			confirmDeleteUsers: false,
			sideOpen: window.innerWidth > styles.small,
			rowsPerPage: 20,
			currentGroupsSelected: this.props.groups ? this.props.groups.map(x => x.scheduleGroupId) : [],
			currentRolesSelected: this.roleactions.slice()
		};

		this.actions = this.props.exposeActions(this);

		this.actions.getVisibleGroups();

		this.scrollRef = React.createRef<HTMLDivElement>();
		this.rowsPerPageActions = [10, 20, 40, 80, 160];
		this.mobileMenu = [
			new MenuAction(
				"New Users",
				() => this.setState({ showUserModal: !this.state.showUserModal }),
				() => checkAuth(["IBMS Administrator"])
			),
			new MenuAction(
				"Edit Selected",
				() => this.setState({ showEditModal: !this.state.showEditModal }),
				() => this.props.users.filter(x => x.selected).length > 0
			)
		];
	}

	throttle(ms: number, action: Function, ...args: any[]) {
		if (!this._throttleAction) {
			this._throttleAction = { action, args };
			setTimeout(() => {
				if (this._throttleAction) {
					this._throttleAction.action(...this._throttleAction.args);
					this._throttleAction = null;
				}
			}, ms);
		} else {
			this._throttleAction = { action, args };
		}
	}

	checkAllUsers(checked: boolean) {
		const arr = this.props.users.slice();
		for (let index = 0; index < arr.length; index++) {
			const item = arr[index];
			arr[index] = { ...item, selected: checked };
		}
		this.actions.setUserList(arr);
	}

	closeModalAndRefresh(modal: keyof PropertiesOfType<State, boolean>) {
		this.setState({ [modal]: false } as any);
		this.actions.refreshData();
	}

	componentDidUpdate(prevProps: Props) {
		if (!prevProps.groups.length && prevProps.groups !== this.props.groups) {
			this.setState({ currentGroupsSelected: this.props.groups.map(x => x.scheduleGroupId) }, this.actions.refreshData);
		}
	}

	render() {
		const usersSelected = this.props.users.filter(x => x.selected);
		const numUsersSelected = usersSelected.length;

		return (
			<div styleName="root">
				<div styleName="header">
					<div styleName="header-spacer" />
					<SearchBar
						styleName="search-bar"
						onChange={val => {
							this.actions.refreshData(
								new QueryGroup([
									new QueryPart("username", "contains", "string", val, "or"),
									new QueryPart("firstName", "contains", "string", val, "or"),
									new QueryPart("lastName", "contains", "string", val)
								])
							);
						}}
					/>
					<Button onClick={() => this.setState({ showUserModal: !this.state.showUserModal })}>New Users</Button>
					{numUsersSelected > 0 && (
						<Button onClick={() => this.setState({ showEditModal: !this.state.showEditModal })}>Edit Selected</Button>
					)}
					{this.props.isBusy && <Spinner color="#fff" />}
					<div styleName="mobile-menu-wrap">
						<Menu zIndex={15} actions={this.mobileMenu} />
					</div>
				</div>
				<div styleName="content">
					<Drawer
						open={this.state.sideOpen}
						floatOn="sm"
						onClose={() => this.setState({ sideOpen: false })}
						width={250}
						styleName="drawer"
						zIndex={6}
					>
						<div styleName="side" open={this.state.sideOpen}>
							<div styleName="side-content">
								{this.props.groups.length > 1 && (
									<CheckBox
										checked={this.state.currentGroupsSelected.length === this.props.groups.length}
										label="All"
										onChange={val => {
											if (val) {
												this.setState(
													{
														currentGroupsSelected: this.props.groups.map(f => f.scheduleGroupId)
													},
													() => this.throttle(500, this.actions.refreshData)
												);
											} else {
												this.setState(
													{
														currentGroupsSelected: []
													},
													() => this.throttle(500, this.actions.refreshData)
												);
											}
										}}
									/>
								)}
								{this.props.groups.map((x, i) => {
									return (
										<div key={i}>
											<CheckBox
												checked={this.state.currentGroupsSelected.some(d => d === x.scheduleGroupId)}
												label={x.siteName + ": " + x.name}
												onChange={val => {
													if (val) {
														this.setState(
															{
																currentGroupsSelected: this.state.currentGroupsSelected.concat(
																	x.scheduleGroupId
																)
															},
															() => this.throttle(500, this.actions.refreshData)
														);
													} else {
														this.setState(
															{
																currentGroupsSelected: this.state.currentGroupsSelected.filter(
																	f => f !== x.scheduleGroupId
																)
															},
															() => this.throttle(500, this.actions.refreshData)
														);
													}
												}}
											/>
										</div>
									);
								})}
							</div>
							<div styleName="roles">
								<CheckBox
									checked={this.state.currentRolesSelected.length === this.roleactions.length}
									label="All"
									onChange={val => {
										if (val) {
											this.setState(
												{
													currentRolesSelected: this.roleactions.slice()
												},
												() => this.throttle(500, this.actions.refreshData)
											);
										} else {
											this.setState(
												{
													currentRolesSelected: []
												},
												() => this.throttle(500, this.actions.refreshData)
											);
										}
									}}
								/>
								{this.roleactions.map((x, i) => {
									return (
										<div key={i}>
											<CheckBox
												checked={this.state.currentRolesSelected.some(d => d === x)}
												label={x}
												onChange={val => {
													if (val) {
														this.setState(
															{
																currentRolesSelected: this.state.currentRolesSelected.concat(x)
															},
															() => this.throttle(500, this.actions.refreshData)
														);
													} else {
														this.setState(
															{
																currentRolesSelected: this.state.currentRolesSelected.filter(f => f !== x)
															},
															() => this.throttle(500, this.actions.refreshData)
														);
													}
												}}
											/>
										</div>
									);
								})}
							</div>
						</div>
					</Drawer>
					<div styleName="menu-btn-wrap" open={this.state.sideOpen}>
						<div onClick={() => this.setState({ sideOpen: true })}>
							<FontAwesomeIcon icon="chevron-right" />
						</div>
					</div>
					<div styleName="main" ref={this.scrollRef}>
						<div styleName="switch-wrap">
							<SwitchButton
								size={20}
								checked={this.state.showGroupsSchedules}
								onChange={val => this.setState({ showGroupsSchedules: val })}
								label="Show Groups and Schedules"
							/>
						</div>
						<SmartTable
							smartTableId="admin-page-table"
							columns={this.state.showGroupsSchedules ? 6 : 4}
							changeData={users => {
								if (usersSelected) {
									for (let index = 0; index < usersSelected.length; index++) {
										const user = usersSelected[index];
										if (user.selected) {
											const newuser = users.find(x => x.id === user.id);
											if (newuser) {
												newuser.selected = true;
											}
										}
									}
								}
								this.actions.setUserList(users);
							}}
							callServer={{
								func: (pagingParams: PagingParameters, groupIds: string[], roles: string[]) => {
									this.actions.setbusy();
									return usersservice.GetList(pagingParams, groupIds,roles);
								},
								pagingParametersIndex: 0,
								funcArgs: [this.state.currentGroupsSelected, this.state.currentRolesSelected]
							}}
							allowColumnSort={true}
							breakOn="sm"
							stickyHeaderScrollRef={this.scrollRef}
							rowsPerPage={this.state.rowsPerPage}
							tdClass="td"
							thClass="th"
							classes={styles}
							tableClass="table-root"
							tbodyrowClass="bodyrow"
						>
							<thead>
								<tr>
									<th>
										<CheckBox
											styleName="hidesm"
											checked={this.props.users.length === numUsersSelected}
											onChange={this.checkAllUsers}
											size={20}
										/>
									</th>
									<th sortable="userName">Username</th>
									<th sortable="firstName">First Name</th>
									<th sortable="lastName">Last Name</th>
									{this.state.showGroupsSchedules && <th>Groups</th>}
									{this.state.showGroupsSchedules && <th style={{ width: "99%" }}>Schedules</th>}
								</tr>
							</thead>
							<tbody>
								{this.props.users.map((row, i) => {
									return (
										<tr key={i}>
											<td styleName="th-padding">
												<CheckBox
													checked={row.selected}
													size={20}
													onChange={val => {
														const index = this.props.users.indexOf(row);
														const arr = this.props.users.slice();
														arr[index] = { ...row, selected: val };
														this.actions.setUserList(arr);
													}}
												/>
											</td>
											<td styleName="th-padding">{row.userName}</td>
											<td styleName="th-padding">{row.firstName}</td>
											<td styleName="th-padding">{row.lastName}</td>
											{this.state.showGroupsSchedules && (
												<td styleName="sub-items">
													{row.groups.map((x, i) => {
														return <span key={i}>{x.siteName}: {x.name}</span>;
													})}
												</td>
											)}
											{this.state.showGroupsSchedules && (
												<td styleName="sub-items">
													{row.schedules.map((x, i) => {
														return (
															<span key={i}>
																<span styleName="area">{x.scheduleAreaName}</span>
																<span>{x.name}</span>
															</span>
														);
													})}
												</td>
											)}
										</tr>
									);
								})}
							</tbody>
						</SmartTable>

						<div styleName="rows-selector">
							<span>Rows per page: </span>
							<Select
								styleName="rows-select"
								value={this.state.rowsPerPage}
								onChange={val => {
									this.setState({ rowsPerPage: val }, () => {
										this.actions.refreshData();
									});
								}}
								actions={this.rowsPerPageActions}
							/>
						</div>
					</div>
				</div>

				<Modal
					rootClass="largemodal"
					classes={styles}
					isOpen={this.state.showUserModal}
					onClose={() => this.setState({ showUserModal: false })}
					title="New Users"
				>
					{this.state.showUserModal && <UserCreate onClose={() => this.setState({ showUserModal: false })} />}
				</Modal>

				<Modal
					rootClass="smallmodal"
					classes={styles}
					isOpen={this.state.showEditModal}
					onClose={() => this.setState({ showEditModal: false })}
					title={numUsersSelected > 1 ? `${numUsersSelected} Users Selected` : "1 User Selected"}
				>
					<div styleName="edit-modal">
						<Button
							disabled={this.state.currentGroupsSelected.length === 0}
							onClick={() => this.setState({ showEditModal: false, showAddScheduleModal: true })}
						>
							Add Users to Schedules
						</Button>
						<Button
							disabled={this.state.currentGroupsSelected.length === 0}
							onClick={() => this.setState({ showEditModal: false, showRemoveScheduleModal: true })}
						>
							Remove Users from Schedules
						</Button>
						<Auth config={{ roles: ["IBMS Administrator","System Administrator"] }}>
							<Button onClick={() => this.setState({ showEditModal: false, showAddGroupModal: true })}>
								Add Users to Groups
							</Button>
							<Button
								disabled={this.state.currentGroupsSelected.length === 0}
								onClick={() => this.setState({ showEditModal: false, showRemoveGroupModal: true })}
							>
								Remove Users from Groups
							</Button>
						</Auth>
						{numUsersSelected === 1 && (
							<Button
								onClick={() =>
									this.setState({ showEditModal: false, showEditUserDetailModal: !this.state.showEditUserDetailModal })
								}
							>
								Edit User
							</Button>
						)}
						<Button color="#E83D3B" onClick={() => this.setState({ showEditModal: false, confirmDeleteUsers: true })}>
							Delete Users
						</Button>
					</div>
				</Modal>

				{/* edit user */}
				<Modal
					isOpen={this.state.showEditUserDetailModal}
					onClose={() => this.setState({ showEditUserDetailModal: false })}
					title="Edit User Details"
				>
					<UserEdit
						//exactly one user is selected, so find it
						initialValues={this.props.users.find(x => x.selected)}
						onClose={() => this.closeModalAndRefresh("showEditUserDetailModal")}
					/>
				</Modal>

				{/* add to schedule */}
				<Modal
					isOpen={this.state.showAddScheduleModal}
					onClose={() => this.setState({ showAddScheduleModal: false })}
					title="Add Users to Schedules"
				>
					<AddRemoveSchedule
						onClose={() => this.closeModalAndRefresh("showAddScheduleModal")}
						selectedGroupIds={this.state.currentGroupsSelected}
						selectedUsers={usersSelected}
					/>
				</Modal>

				{/* remove from schedule */}
				<Modal
					isOpen={this.state.showRemoveScheduleModal}
					onClose={() => this.setState({ showRemoveScheduleModal: false })}
					title="Remove Users from Schedules"
				>
					<AddRemoveSchedule
						onClose={() => this.closeModalAndRefresh("showRemoveScheduleModal")}
						selectedGroupIds={this.state.currentGroupsSelected}
						selectedUsers={usersSelected}
						existingSchedules={array.removeDuplicates(array.selectMany(usersSelected, x => x.schedules), x => x.scheduleId)}
					/>
				</Modal>

				{/* add to group */}
				<Modal
					isOpen={this.state.showAddGroupModal}
					onClose={() => this.setState({ showAddGroupModal: false })}
					title="Add Users to Groups"
				>
					<AddRemoveGroup
						onClose={() => this.closeModalAndRefresh("showAddGroupModal")}
						selectedGroupIds={this.state.currentGroupsSelected}
						selectedUsers={usersSelected}
					/>
				</Modal>

				{/* remove from group */}
				<Modal
					isOpen={this.state.showRemoveGroupModal}
					onClose={() => this.setState({ showRemoveGroupModal: false })}
					title="Remove Users from Groups"
				>
					<AddRemoveGroup
						onClose={() => this.closeModalAndRefresh("showRemoveGroupModal")}
						selectedGroupIds={this.state.currentGroupsSelected}
						selectedUsers={usersSelected}
						existingGroups={array.removeDuplicates(array.selectMany(usersSelected, x => x.groups), x => x.scheduleGroupId)}
					/>
				</Modal>

				{/* delete users */}
				<Modal
					isOpen={this.state.confirmDeleteUsers}
					title="Are you sure"
					bodyText={`Are you sure you want to delete ${numUsersSelected} User(s)? This Cannot be undone`}
					onConfirm={() => {
						this.setState({ confirmDeleteUsers: false });
						this.actions.deleteUsers(usersSelected.map(x => x.id));
					}}
					onClose={() => this.setState({ confirmDeleteUsers: false })}
				/>
			</div>
		);
	}
}

const bindAction = dispatch => {
	return {
		exposeActions: (component: AdminPage) => {
			return {
				setUserList: users => dispatch(useractions.setUserList(users)),
				deleteUsers: users => dispatch(useractions.deleteUsers(users)),
				setbusy: () => dispatch(useractions.setbusy(true)),

				refreshData: query => dispatch(smarttableactions.refreshData("admin-page-table", query)),

				getVisibleGroups: () => dispatch(schedulegroupactions.getVisibleGroups()),

				navigate: (uri, data) => dispatch(router.push(uri, data)),
				goBack: () => dispatch(router.goBack())
			};
		}
	};
};

const mapStateToProps = (state: ReduxState) => ({
	users: state.users.users,
	groups: state.schedulegroup.visibleGroups,

	isBusy: state.users.isBusy
});

export default AuthHoC(
	connect(
		mapStateToProps,
		bindAction
	)(AdminPage),
	{ roles: ["IBMS Administrator", "System Administrator"] }
);

interface State {
	showGroupsSchedules: boolean;
	showUserModal: boolean;
	showEditModal: boolean;
	showAddScheduleModal: boolean;
	showRemoveScheduleModal: boolean;
	showAddGroupModal: boolean;
	showRemoveGroupModal: boolean;
	showEditUserDetailModal: boolean;
	confirmDeleteUsers: boolean;
	sideOpen: boolean;
	rowsPerPage: number;
	currentGroupsSelected: string[];
	currentRolesSelected: string[];
}

interface Props extends CommonUIProps {
	exposeActions: (component: AdminPage) => Actions;
	users: UserDto[];
	isBusy: boolean;
	groups: ScheduleGroupDto[];
}

interface Actions {
	setUserList: (users: UserDto[]) => void;
	refreshData: (s?: QueryGroup | QueryPart | "reset") => void;
	getVisibleGroups: () => void;
	deleteUsers: (userIds: string[]) => void;
	setbusy: () => void;

	navigate: (uri: string, data?: any) => void;
	goBack: () => void;
}
