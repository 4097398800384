import * as React from "react";

import * as CSSModules from "react-css-modules";
import autoBind from "libs/react-autobind";

import * as styles from "./styles.scss";
import { DateTime, array, Time } from "utils";
import { NewBookingContext } from "../NewBooking";

@CSSModules(styles, { allowMultiple: true })
export default class TimePickerDay extends React.PureComponent<Props, State> {
	static contextType = NewBookingContext;
	context: NewBookingContext;
	rootRef: React.RefObject<HTMLDivElement>;
	scrollRef: HTMLDivElement;

	constructor(props, context: NewBookingContext) {
		super(props);
		autoBind(this);

		this.rootRef = React.createRef<HTMLDivElement>();

		this.state = {
			hours: array.fill(24, i => {
				const hour = DateTime.add("hour", this.props.selectedDate, i);
				return hour;
			}),
			rootWidth: 350,
		};

		window.addEventListener("resize", this.onresize);
	} //end ctor

	onresize() {
		if (this.rootRef.current.clientWidth !== this.state.rootWidth || this.rootRef.current.clientHeight !== this.props.rootHeight) {
			this.setState({ rootWidth: this.rootRef.current.clientWidth });
			this.props.onSetRootHeight(this.rootRef.current.clientHeight);
		}
	}

	render() {
		return (
			<div styleName="root" ref={this.rootRef}>
				{this.state.hours.map((x, i) => {
					return (
						<div styleName="row" key={i}>
							<div styleName="row-date">{DateTime.format(x, "h a")}</div>
							<div styleName="row-main">
								<div styleName="line" />
								<div styleName="line" />
							</div>
						</div>
					);
				})}

				{this.props.blockout && (
					<div
						style={{ top: this.props.blockoutPos.start, height: this.props.blockoutPos.height }}
						styleName="blocktext floatelem"
					>
						Time blocked
					</div>
				)}
			</div>
		);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.onresize);
	}
}

interface State {
	hours: Date[];
	rootWidth: number;
}
interface Props {
	blockoutPos: ElPos;
	selectedDate: Date;
	blockout: TP_TimeRange;
	rootHeight: number;
	onSetRootHeight: (h: number) => void;
}

/**range 2 start overlaps range 1 */
export function insideEnd(range1: TP_DataRange, range2: TP_DataRange): boolean {
	return range2.v1 > range1.v1 && range2.v1 < range1.v2;
}

/**range 2 end overlaps range 1 */
export function insideStart(range1: TP_DataRange, range2: TP_DataRange): boolean {
	return range1.v1 < range2.v2 && range1.v2 > range2.v2;
}

/** range2 inside range 1 */
export function inside(range1: TP_DataRange, range2: TP_DataRange): boolean {
	return range1.v1 < range2.v1 && range1.v2 > range2.v2;
}

export type TP_DataRange = { v1: number; v2: number };

export interface ElPos {
	height: number;
	start: number;
	end: number;
}

export type TP_TimeRange = { start: Time; end: Time };
export type TP_DateRange = { start: Date; end: Date };
