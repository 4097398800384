import * as React from "react";
import * as styles from "./styles.scss";
import { connect } from "react-redux";
import * as CSSModules from "react-css-modules";

import { WrappedFieldProps, Field } from "redux-form";
import Tabs, { TabAction } from "components/Tabs/Tabs";
import { shouldUpdate, number, DateTime } from "utils";
import { CommonComponentProps, BookingRepeatConfig, BookingRepeatTypeLower } from "redi-types";
import { RenderInput, RenderCalender } from "components/FormInputs";

@CSSModules(styles, { allowMultiple: true })
export class DaySelectorForm extends React.PureComponent<{ label: string; className?: string } & WrappedFieldProps, {}> {
	static defaultProps = {};
	actions: TabAction[];
	constructor(props) {
		super(props);
		this.actions = [
			new TabAction("Day", () => this.props.input.onChange("Day"), val => val === "Day"),
			new TabAction("Week", () => this.props.input.onChange("Week"), val => val === "Week"),
			new TabAction("Month", () => this.props.input.onChange("Month"), val => val === "Month"),
			new TabAction("Year", () => this.props.input.onChange("Year"), val => val === "Year")
		];
	}
	render() {
		let {
			input,
			meta: { touched, error },
			label,
			...other
		} = this.props;

		return (
			<div className={this.props.className}>
				<Tabs actions={this.actions} value={input.value} styleName="flex1" tabClass="tabclass" classes={styles} />
				{touched && error && <div styleName="input-error">{error}</div>}
			</div>
		);
	}
}

@CSSModules(styles, { allowMultiple: true })
export class EndsForm extends React.Component<
	{
		actions: (string | JSX.Element)[];
		label?: string;
		currentDate: Date;
		type: BookingRepeatTypeLower;
		amount: number;
		clearField: (name: string) => void;
	} & CommonComponentProps &
		WrappedFieldProps,
	{ tabIndex: number; minDate: Date }
> {
	actions: TabAction[];
	constructor(props) {
		super(props);
		this.actions = [
			new TabAction(
				"Never",
				() => {
					this.props.input.onChange("never");
					this.setState({ tabIndex: 0 });
				},
				val => val === 0
			),
			new TabAction(
				"Pick a date",
				() => {
					this.props.input.onChange("date");
					this.setState({ tabIndex: 1 });
				},
				val => val === 1
			),
			new TabAction(
				"After X repeats",
				() => {
					this.props.input.onChange("number");
					this.setState({ tabIndex: 2 });
				},
				val => val === 2
			)
		];

		this.state = {
			tabIndex:
				this.props.input.value === "never"
					? 0
					: this.props.input.value === "date"
					? 1
					: this.props.input.value === "number"
					? 2
					: 0,
			minDate: DateTime.add(this.props.type, this.props.currentDate, this.props.amount)
		};
	}
	static defaultProps = {};

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.amount !== this.props.amount ||
			prevProps.type !== this.props.type ||
			prevProps.currentDate !== this.props.currentDate
		) {
			this.props.clearField("repeatConfig.endDate");
			this.props.clearField("repeatConfig.endAfterXRepeats");
			this.props.input.onChange("never");
			this.setState({ minDate: DateTime.add(this.props.type, this.props.currentDate, this.props.amount), tabIndex: 0 });
		}
	}

	render() {
		let {
			input,
			meta: { touched, error },
			classes,
			label,
			className,
			actions,
			...other
		} = this.props;

		return (
			<div className={className} styleName="week-input">
				<div>{label}</div>
				<Tabs actions={this.actions} value={this.state.tabIndex} styleName="margin-bot" tabClass="tabclass" classes={styles} />
				{this.state.tabIndex === 1 && (
					<div>
						<Field
							name="repeatConfig.endDate"
							component={RenderCalender}
							showSelectedDayBar={false}
							autoWidth={window.innerWidth < styles.small}
							minValue={this.state.minDate}
						/>
					</div>
				)}
				{this.state.tabIndex === 2 && (
					<div>
						<Field
							name="repeatConfig.endAfterXRepeats"
							component={RenderInput}
							type="number"
							inputClass="repeat-input"
							styleName="noflex"
							label="Enter number of repeats"
							min={1}
							classes={styles}
						/>
					</div>
				)}
				{touched && error && <div styleName="input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onBlur", "onChange"]);
	}
}

@CSSModules(styles, { allowMultiple: true })
export class MonthRepeatForm extends React.Component<
	{
		label?: string;
		currentDate: Date;
	} & CommonComponentProps &
		WrappedFieldProps,
	{ tabIndex: number }
> {
	actions: TabAction[];
	constructor(props) {
		super(props);
		this.actions = [
			new TabAction(
				`${DateTime.format(this.props.currentDate, "do")} of the month`,
				() => {
					this.setState({ tabIndex: 0 });
					this.props.input.onChange(this.props.currentDate.getDate().toString());
				},
				val => val === 0
			),
			new TabAction(
				`${DateTime.getDayNumberOfMonth(this.props.currentDate)} ${DateTime.format(this.props.currentDate, "EEEE")}`,
				() => {
					this.setState({ tabIndex: 1 });
					this.props.input.onChange(
						`${DateTime.getDayNumberOfMonth(this.props.currentDate)} ${DateTime.format(this.props.currentDate, "EEEE")}`
					);
				},
				val => val === 1
			),
			new TabAction(
				"Last day of month",
				() => {
					this.setState({ tabIndex: 2 });
					this.props.input.onChange("Last day of month");
				},
				val => val === 2
			)
		];

		this.state = {
			tabIndex: this.props.input.value == this.props.currentDate.getDate() ? 0 : 1
		};
	}
	static defaultProps = {};

	render() {
		let {
			input,
			meta: { touched, error },
			classes,
			label,
			className,
			...other
		} = this.props;

		return (
			<div className={className} styleName="week-input">
				<div>{label}</div>
				<Tabs actions={this.actions} value={this.state.tabIndex} tabClass="tabclass" classes={styles} />
				{touched && error && <div styleName="input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onBlur", "onChange"]);
	}
}
