//initial redux
import { put, call, takeLatest, fork, all, take, cancel, select } from "redux-saga/effects";
import { persist, string, TimeRange, DateTime } from "utils/index";
import { ReduxAction } from "redi-types";

interface State {
	times: { [x: string]: Date | TimeRange };

	bookingSelectedDay: Date;
	reportsRange: TimeRange;
}

const initialState: State = {
	reportsRange: new TimeRange(),
	times: {},
	bookingSelectedDay: DateTime.startOf("day")
};

const persistConf = {
	whitelist: ["reportsRange", "bookingSelectedDay"],
	expireInNumHours: 24
};

/////////
//types
export const types = {
	SET_TIME: "time/SET_TIME",
	SET_REPORT_DATE_RANGE: "time/SET_REPORT_DATE_RANGE",
	SET_BOOKING_TIME: "time/SET_BOOKING_TIME",
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction): State {
		switch (action.type) {
			case types.SET_TIME:
				return { ...state, times: { ...state.times, [action.payload.id]: action.payload.time } };

				case types.SET_BOOKING_TIME:
				return { ...state, bookingSelectedDay: action.payload.time };

				case types.SET_REPORT_DATE_RANGE:
				return { ...state, reportsRange: action.payload.range };

			default:
				return state;
		}
	}
};
export const reducer = persist("time", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([]);
	}
};

////////
//actions
export const actions = {
	setTime(id: string, time: Date | TimeRange) {
		return {
			type: types.SET_TIME,
			payload: { id, time }
		};
	},

	setReportDateRange( range: TimeRange) {
		return {
			type: types.SET_REPORT_DATE_RANGE,
			payload: { range }
		};
	},

	setBookingTime(time: Date) {
		return {
			type: types.SET_BOOKING_TIME,
			payload: { time }
		};
	}
};
