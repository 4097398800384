import "./theme/global.scss";

//import these files so webpack processes them
import "config/$env";

const config = {
	defaultRoute: "/Booking",

	//change this to delete all persists
	persistVersion: 1,

	//anything that comes before this line can be overriden by config $env files
	...window.REDI_enviromentConfig,
	apiPrefix: "api/",
	get baseUrl() {
		return window.REDI_enviromentConfig.apiBaseUrl + this.apiPrefix;
	},
	tokenKey: "token-key",
	tokenExpDateKey: "tokenExpDateKey",
	getDetailsFromJwt: true,
	indexedDbName: "revata_sch_ui_db",
	//roles required to do anything
	defaultRoles: []
};

export default config;
